/**
 * Схема для линейки
 * @property {Object} rulerPolylineParams - параметры для полилинии в линейке
 * @property {Object} rulerMarkerIconParams - параметры для иконки маркеров в линейке
 * @property {Object} rulerMarkerLabelParams - параметры для надписи маркеров в линейке
 */
const SchemeRuler = {
    rulerPolylineParams: {
        strokeOpacity: 0.4,
        strokeColor: '#ff0101',
        editable: false,
        draggable: false
    },
    rulerMarkerIconParams: {
        fillColor: '#ff0101',
        fillOpacity: 1.0,
        strokeColor: '#ff0101',
        scale: 3,
    },
    rulerMarkerLabelParams: {
        color: '#ff0101',
        fontWeight: 'bold'
    }
};

export default SchemeRuler;