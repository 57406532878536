<template>
    <div class="legend-panel">
        <div class="legend-panel__header" :class="{'no-mb': !isShow}" @click="onToggleShowItems">
            <div class="legend-panel__title">
                {{ title }}
            </div>
        </div>
        <template v-if="isShow">
            <legend-panel-item
                v-for="item in markersStatuses"
                :key="item.key"
                :status="item.value"
                :color="item.color"
                :status-key="item.key"
                :active-statuses="activeMarkersStatuses"
                @onClickLegendPanelItem="onChangeActiveStatus"
            />
        </template>

    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import legendPanelItem from './LegendPanelItem';

export default {
    name: "LegendPanel",
    components: {
        legendPanelItem,
    },
    /**
     * Входные данные компонента
     * @property {String} title - Название шапки
     * @property {Array} markersStatuses - Статусы маркеров
     */
    props: {
        title: {
            type: String,
            default: 'Базовая легенда',
        },
        markersStatuses: {
            type: Array,
            default: () => ([]),
        },
    },
    /**
     * Данные компонента
     * @property {Array} activeMarkersStatuses - Ключи активных статусов, для которых отрисовываются маркеры
     * @property {Boolean} isShow - Показать/скрыть список panelItem
     */
    data: () => ({
        activeMarkersStatuses: new Set(),
        isShow: true,
    }),
    watch: {
        /** Следим когда приходят статусы маркеров от бекенда
         * и формируем дефолтный список активных маркеров.
         * При инициализации все panelItem должны быть активны  */
        markersStatuses() {
            this.defaultActiveMarkers();
        },

        activeLegendPanelMarkersStatuses() {
            this.activeMarkersStatuses = new Set(this.activeLegendPanelMarkersStatuses);
        },
    },
    computed: {
        ...mapState('pageBayuerdesk', [
            'activeLegendPanelMarkersStatuses',
        ])
    },

    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('pageBayuerdesk', [
            'setActiveMarkerStatuses',
            'setActiveWithoutStatus',
        ]),
        /**
         * Проверка статусов маркера.
         * @param {Boolean} isActive - Включен/Отключен текущий статус
         * @param {String} statusKey - Ключ текущего статуса
         * Формируем и отправляем родителю activeMarkersStatuses
         */
        onChangeActiveStatus(isActive = true, statusKey = '') {
            if (isActive) {
                this.activeMarkersStatuses.add(statusKey);
            } else {
                const hasStatusKey = this.activeMarkersStatuses.has(statusKey);
                if (hasStatusKey) {
                    this.activeMarkersStatuses.delete(statusKey);
                }
            }
            this.setActiveMarkerStatuses([...this.activeMarkersStatuses]);
            this.$emit('activeLegendPanel');
        },
        /**
         * Клик по заголовку панели показывает/скрывает элементы panelItem.
         */
        onToggleShowItems() {
            this.isShow = !this.isShow;
        },
        /**
         * Формируем список статусов макреров, которые будут активны при инициализации компонента.
         */
        defaultActiveMarkers() {
            this.markersStatuses.forEach((el) => {
                this.activeMarkersStatuses.add(el.key);
            });
        },
    },
}
</script>

<style lang="scss" scoped>
    .legend-panel {
        width: 147px;
        background: #FFFFFF;
        box-shadow: 2px 3px 20px #3B4A741A;
        border-radius: 10px;
        padding: 8px;
        box-sizing: border-box;

        &__header {
            background: #F2F2F2;
            padding: 4px 5px;
            border-radius: 4px;
            cursor: pointer;
            color: #AFB0B4;
            transition: all .4s ease-out;
            margin-bottom: 2px;
            &:hover { color: #000; }
        }
        &__title {
            font-size: 8px;
            letter-spacing: 0;
        }
    }

    .no-mb {
        margin-bottom: 0;
    }
</style>
