<template>
    <div class="template">
        <div class="template__title">
            {{ title }}
        </div>
        <p class="template__text">Название шаблона</p>
        <div class="template__content">
            <BaseInput class="template__buffer-name" v-model="bufferName" />
        </div>
        <p class="template__text">Какие столбцы показывать и их порядок</p>
        <div class="template__container">
            <div
                v-for="(visibleItem, visibleIndex) of bufferTemplate"
                :key="'v' + visibleIndex"
            >
                <div class="ghost-item" v-if="ghostPositionUp === visibleIndex">
                    Переместить сюда
                </div>
                <div
                    @mousedown="onMove($event, visibleIndex)"
                    class="js-draggable visible-item"
                >
                    <div class="cell-checkbox">
                        <base-checkbox-v3
                            :key="visibleItem.attribute"
                            :checked="visibleItem.checked"
                            :size="16"
                            @change="
                                onChangeVisibility(
                                    $event,
                                    visibleItem.attribute
                                )
                            "
                        />
                    </div>
                    <div
                        @dblclick="onEditLabel($event, visibleItem.attribute)"
                        v-if="!isEditing(visibleItem.attribute)"
                    >
                        {{ visibleItem.label }}
                    </div>
                    <div
                        v-if="isEditing(visibleItem.attribute)"
                        style="margin-left: 5px"
                    >
                        <BaseInput
                            :value="visibleItem.label"
                            :ref="'input_item_' + visibleItem.attribute"
                            @onBlur="onClearEditing"
                        />
                    </div>
                </div>
                <div
                    class="ghost-item"
                    v-if="ghostPositionDown === visibleIndex"
                >
                    {{ selectedItemLabel }}
                </div>
            </div>
        </div>
        <div class="template__button-container">
            <BaseButton @click="onCancel">Отменить</BaseButton>
            <BaseButton view="secondary" @click="onSaveTemplate"
                >Сохранить</BaseButton
            >
        </div>
    </div>
</template>

<script>
import BaseCheckboxV3 from "@/components/Base/BaseCheckboxV3";
import BaseButton from "@/components/Base/BaseButton";
import BaseInput from "@/components/Base/BaseTextInput";

export default {
    name: "ExportTemplateEdit",
    components: {
        BaseCheckboxV3,
        BaseButton,
        BaseInput,
    },
    /**
     * Входные данные
     * @property {Array} items - массив атрибутов для экспорта
     * @property {String} name - название шаблона
     * @property {Number} - id шаблона
     * @property {Number} - countTemplates количество шаблонов
     */
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            default: "",
        },
        id: {
            type: Number,
            default: 0,
        },
        countTemplates: {
            type: Number,
            default: null,
        },
    },
    /**
     * Локальные данные
     * @property {String} title - заголовок
     * @property {Number} pointX - координата нажатия по Х
     * @property {Number} pointY - координата нажатия по Y
     * @property {Number} ghostPositionUp - индекс верхней тени возможного перемещения (при перетаскивании верх)
     * @property {Number} ghostPositionDown - индекс нижней тени возможного перемещения (при перетаскивании вниз)
     * @property {Array} bufferTemplate - буффер с атрибутами
     * @property {String} editingAttribute - редактируемый атрибут
     * @property {String}  bufferName
     */
    data() {
        return {
            title: "Изменить шаблон",
            pointX: 0,
            pointY: 0,
            ghostPositionUp: null,
            ghostPositionDown: null,
            bufferTemplate: [],
            editingAttribute: "",
            bufferName: "",
            selectedItemLabel: "",
        };
    },
    mounted() {
        this.bufferName = this.name;
        this.initBuffer();
    },
    methods: {
        onDrag(item, index) {
            console.log(item, index);
        },
        onEditLabel(event, attribute) {
            this.editingAttribute = attribute;
            this.$nextTick(() => {
                this.$refs["input_item_" + attribute][0].$refs["input"].focus();
            });
        },
        isEditing(attribute) {
            return this.editingAttribute === attribute;
        },
        initBuffer() {
            this.bufferTemplate = Object.assign([], this.items);
            if (this.id === 0) {
                this.bufferName = "Новый шаблон " + this.countTemplates;
                this.title = "Создать шаблон";
            }
        },
        /**
         * Перетаскивание атрибутов
         * @param event
         * @param index
         */
        onMove(event, index) {
            if(index === 0){
                return;
            }
            let boxTag = event.target.closest(".js-draggable");
            let itemHeight = boxTag.clientHeight + 2;
            document.body.style.userSelect = "none";
            this.pointX = event.clientX;
            this.pointY = event.clientY;

            const move = (moveEvent) => {
                const differenceY = moveEvent.clientY - this.pointY;
                let countItems = Math.round(differenceY / itemHeight);
                let newIndex = index + countItems;
                if (newIndex < index) {
                    newIndex = newIndex + 1;
                }
                if (newIndex < 1) {
                    newIndex = 1;
                }
                if (newIndex >= this.bufferTemplate.length) {
                    newIndex = this.bufferTemplate.length - 1;
                }
                if (newIndex >= index) {
                    this.ghostPositionDown = newIndex;
                    this.ghostPositionUp = null;
                } else {
                    this.ghostPositionUp = newIndex;
                    this.ghostPositionDown = null;
                }
            };

            // Удаляю слушатели
            document.addEventListener("mousemove", move);
            document.addEventListener(
                "mouseup",
                (moveEventUp) => {
                    document.removeEventListener("mousemove", move);
                    this.ghostPositionUp = null;
                    this.ghostPositionDown = null;
                    const differenceY = moveEventUp.clientY - this.pointY;
                    let countItems = Math.round(differenceY / itemHeight);
                    let newIndex = index + countItems;
                    if (newIndex < index) {
                        newIndex = newIndex + 1;
                    }
                    if (newIndex < 1) {
                        newIndex = 1;
                    }
                    if (newIndex >= this.bufferTemplate.length) {
                        newIndex = this.bufferTemplate.length - 1;
                    }
                    this.arrayMove(index, newIndex);
                    document.body.style.userSelect = "initial";
                    boxTag.style.borderStyle = "none";
                },
                { once: true }
            );
        },
        /**
         * Перенос элементов в массиве
         * @param oldIndex
         * @param newIndex
         */
        arrayMove(oldIndex, newIndex) {
            let data = this.bufferTemplate;
            const movingItem = { ...data[oldIndex] };
            data.splice(oldIndex, 1);
            data.splice(newIndex, 0, movingItem);
            this.bufferTemplate = Object.assign([], data);
        },
        /**
         * Нажатие на чекбокс
         * @param value
         * @param attribute
         */
        onChangeVisibility(value, attribute) {
            let data = this.bufferTemplate;
            const itemIndex = data.findIndex((item) => {
                const { attribute: itemAttribute = "" } = item;
                return String(itemAttribute) === String(attribute);
            });
            const itemNew = { ...data[itemIndex], checked: value };
            data.splice(itemIndex, 1, itemNew);
            this.bufferTemplate = Object.assign([], data);
        },
        /**
         * Сохранение шаблона
         */
        onSaveTemplate() {
            let data = {
                id: this.id,
                name: this.bufferName,
                data: this.bufferTemplate,
            };
            this.$emit("onSaveTemplate", data);
        },
        onCancel() {
            this.$emit("onCancel");
        },
        /**
         * Завершение редактирования названия атрибута
         * @param event
         */
        onClearEditing(event) {
            if (event.target.value && this.editingAttribute) {
                let data = this.bufferTemplate;
                const itemIndex = data.findIndex((item) => {
                    const { attribute: itemAttribute = "" } = item;
                    return itemAttribute === this.editingAttribute;
                });
                const itemNew = {
                    ...data[itemIndex],
                    label: event.target.value,
                };
                data.splice(itemIndex, 1, itemNew);
                this.bufferTemplate = Object.assign([], data);
            }
            this.editingAttribute = "";
        },
    },
};
</script>

<style lang="scss">
.template__buffer-name .input {
    border: none !important;
}

.cell-checkbox .base-checkbox input:checked + span[data-v-22ccad6e]:before {
    width: 4px;
    height: 6px;
    top: 1px;
    left: 3px;
}
</style>

<style lang="scss" scoped>
.template {
    position: relative;
    background: white;
    border-radius: 10px;

    &__title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #c8cad1;
    }

    &__text {
        font-size: 12px;
        margin-bottom: 5px;
        cursor: default;
    }

    &__buffer-name {
        padding: 10px 15px;
        border-radius: 4px;
        background: #f5f5f5;
        margin-bottom: 15px;

        .input {
            border: none;
        }
    }

    &__subtitle {
        font-size: 14px;
    }

    &__input {
        padding: 5px;
        margin: 15px 0px;
        width: 100%;
    }

    &__button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__container {
        overflow-y: auto;
        max-height: 295px;
        margin-bottom: 30px;

        .ghost-item {
            height: 40px;
            display: flex;
            align-items: center;
            background-color: #f2f2f2;
            width: 100%;
            text-align: center;
            border-radius: 4px;
            padding-left: 38px;
        }

        .visible-item {
            padding: 10px 15px;
            display: flex;
            align-items: center;
            cursor: grab;
            border-radius: 4px;
            margin-bottom: 1px;
        }

        .hidden-item {
            display: flex;
            align-items: center;
            height: 40px;
            cursor: pointer;
        }

        .cell-checkbox {
            margin-right: 10px;
        }
    }

    &-content {
        width: 100%;
        display: flex;
        padding: 20px;
    }
}
</style>
