<template>
    <div class="page-container">
        <div class="container">
            <h1>Доступ к продукту закрыт</h1>
            <p>Обратитесь к администратору.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageAccessDenied'
}
</script>

<style lang="scss" scoped>
.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Занимает всю высоту видимой области */
    background-color: #f0f0f0; /* Светло-серый фон для всей страницы */
}

.container {
    text-align: center;
    padding: 20px;
}

.container h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #000000;
}

.container p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #000000;
}
</style>
