<template>
	<div class="identifier">
		<p class="identifier__first">{{firstId}}</p>
		<p class="identifier__second">{{secondId}}</p>
	</div>
</template>

<script>

export default {
	name: "Identifier",
	props: {
		firstId: {
			type: [String, Number],
			default: ""
		},
		secondId: {
			type: [String, Number],
			default: ""
		}
	}
}
</script>

<style lang="scss" scoped>
.identifier {
	&__first {
		font-size: 11px;
	}
	&__second {
		margin-top: 8px;
	}
}
</style>