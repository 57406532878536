<template>
    <div
        :data-active="active"
        @dragenter.prevent="setActive"
        @dragover.prevent="setActive"
        @dragleave.prevent="setInactive"
        @drop.prevent="onDrop"
    >
        <slot :dropZoneActive="active"></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            events: ["dragenter", "dragover", "dragleave", "drop"],
            active: false,
            inactiveTimeout: null,
        };
    },

    mounted() {
        this.events.forEach((eventName) => {
            document.body.addEventListener(eventName, this.preventDefaults);
        });
    },

    destroyed() {
        this.events.forEach((eventName) => {
            document.body.removeEventListener(eventName, this.preventDefaults);
        });
    },

    methods: {
        setActive() {
            this.active = true;
            clearTimeout(this.inactiveTimeout);
        },

        setInactive() {
            this.inactiveTimeout = setTimeout(() => (this.active = false), 50);
        },

        onDrop(event) {
            this.setInactive();
            this.$emit("files-dropped", event.dataTransfer.files);
        },

        preventDefaults(event) {
            event.preventDefault();
        },
    },
};
</script>
