<template>
	<div
		v-if="isOpen"
		class="popup"
		@click.self="onClose"
	>
		<div
            class="popup-inner"
            :style="innerStyle"
        >
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: "Popup",
	data() {
		return {
			isOpen: true
		}
	},
    props: {
        innerStyle: {
            type: Object,
            default: () => ({})
        }
    },
	mounted() {
		this.$root.$el.append(this.$el)
	},
	methods: {
		onClose() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="scss" scoped>
.popup {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 3999999999;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;

	// &-inner {}
}
</style>
