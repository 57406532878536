<template>
	<div class="description">
		<div class="description__address">{{ city }}, {{ address }}</div>
		<div class="description-cards">
			<CardPhoto
				:photoSmall="photoSmall"
				:photoBig="photoBig"
				class="description-cards__photo"
			/>
			<CardDefault
				title="Оператор"
				:value="supplier"
				class="description-cards__supplier"
			/>
			<CardDefault
				title="Вид"
				:value="view"
				class="description-cards__type"
			/>
			<CardDefault
				title="OTS"
				:value="ots"
				class="description-cards__ots"
			/>
			<CardDefault
				title="GRP"
				:value="grp"
				class="description-cards__grp"
			/>
            <CardDefault
                v-if="lastPrice"
                title="Последняя цена"
                :value="lastPrice"
                class="description-cards__lp"
            />
			<CardDescription
				:tableData="tableData"
				class="description-cards__table"
			/>
		</div>
	</div>
</template>

<script>
import CardPhoto from "./Cards/CardPhoto"
import CardDefault from "./Cards/CardDefault"
import CardDescription from "./Cards/CardDescription"

export default {
	name: "Description",
	components: {
		CardPhoto,
		CardDefault,
		CardDescription
	},
	props: {
		tableData: {
			type: Array,
			default: () => []
		},
		address: {
			type: String,
			default: '-'
		},
		city: {
			type: String,
			default: '-'
		},
		view: {
			type: String,
			default: '-'
		},
		supplier: {
			type: String,
			default: '-'
		},
		photoSmall: {
			type: String,
			default: '-'
		},
		photoBig: {
			type: String,
			default: '-'
		},
		ots: {
			type: String,
			default: '-'
		},
		grp: {
			type: String,
			default: '-'
		},
        lastPrice: {
            type: String,
            default: ''
        },
	}
}
</script>

<style lang="scss" scoped>
.description {
	&__address {
		font-size: 16px;
		font-weight: 700;
		max-width: 700px;
	}

	&-cards {
		margin-top: 15px;
		display: grid;
		grid-template-rows: repeat(2, 80px);
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 13px;

		&__photo {
			grid-row: 1/3;
			grid-column: 1/4;
		}
		&__supplier {
			grid-column: 4/7;
		}
		&__type {
			grid-column: 4/7;
		}
		&__ots {
			grid-row: 1/2;
			grid-column: 7/9;
		}
		&__grp {
			grid-row: 2/3;
			grid-column: 7/9;
		}
        &__lp{
            grid-row: 3/3;
            grid-column: 1/13;
        }
		&__table {
			grid-row: 1/3;
			grid-column: 9/13;
		}
	}
}
</style>
