<template>
    <BaseDropdown
        :class="['datepicker-wrapper', `is-theme--${theme}`, {'is-selected': isSelected}]"
        :position="position"
        :padding="0"
        @changeVisible="onChangeVisibleDatepicker"
    >
        <template #preview>
            <BaseButton
                :active="isDatepickerOpen"
                class="button"
            >
                <span v-if="isShowIcon" class="button__icon icon-tabler-icon-calendar-minus"></span>
                <span class="button__title">
					{{ dateTitle }}
				</span>
            </BaseButton>
        </template>

        <template #content>
            <DatePicker
                :isEdit="isEdit"
                :isMultiple="isMultiple"
                :accessDates="accessDates"
                :checkedDates="checkedDates"
                @cancel="$emit('$closeDropdown')"
                @approve="onApproveDatepicker"
            />
        </template>
    </BaseDropdown>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton"
import BaseDropdown from "@/components/Base/BaseDropdown"
import DatePicker from "@/components/Base/DatePicker"

export default {
    name: "DatePickerDropdown",
    components: {
        BaseButton,
        BaseDropdown,
        DatePicker,
    },
    props: {
        accessDates: {
            type: Array,
            default: () => []
        },
        checkedDates: {
            type: Array,
            default: () => []
        },
        isMultiple: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'left'
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        defaultTitle: {
            type: String,
            default: ""
        },
        theme: {
            type: String,
            default: 'default',
            validator: value => ['default', 'filter'].includes(value)
        },
        isHideSelectedTitle: {
            type: Boolean,
            default: false
        },
        isShowIcon: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            months: [
                'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
                'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
            ],
            monthsFull: new Map(Object.entries({
                'Янв': 'Январь',
                'Фев': 'Февраль',
                'Мар': 'Март',
                'Апр': 'Апрель',
                'Май': 'Май',
                'Июн': 'Июнь',
                'Июл': 'Июль',
                'Авг': 'Август',
                'Сен': 'Сентябрь',
                'Окт': 'Октябрь',
                'Ноя': 'Ноябрь',
                'Дек': 'Декабрь',
            })),
            isDatepickerOpen: false,
        }
    },
    computed: {
        isSelected() {
            return this.checkedDates && this.checkedDates.length
        },
        dateTitle() {
            if (this.checkedDates.length === 0 || this.isHideSelectedTitle) {
                return this.defaultTitle ?? 'Выберите дату';
            } else {
                let uniqueMonths = []

                this.checkedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item - 1])
                const result = uniqueMonths.join(', ')

                return this.checkedDates.length === 1 ? this.monthsFull.get(String(result)) : `${result}`;
            }
        },
    },
    methods: {
        onChangeVisibleDatepicker(boolean) {
            this.isDatepickerOpen = boolean
        },
        onApproveDatepicker(event) {
            this.$emit('$closeDropdown')

            this.$emit('onApprove', event)
        }
    }
}
</script>

<style lang="scss" scoped>
.datepicker-wrapper {
    .button {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        // width: 180px;
        // border-radius: 100px;
        // padding: 12px 15px;
        text-align: left;

        &__icon {
            flex-shrink: 0;

            & + .button__title {
                margin-left: 10px;
            }
        }

        &__title {
            display: block;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.is-theme {
        &--filter {
            .button {
                padding: 2px 8px;
                background-color: var(--d-color--white);
                border: 1px solid transparent;
                border-radius: var(--d-border-radius--4xs);
                transition: var(--d-transition--low);

                &__title {
                    color: var(--d-color--gray-500);
                    font-size: var(--d-font-size--sm);
                    font-weight: var(--d-font-weight--regular);
                    line-height: 18px;
                    transition: var(--d-transition--low);
                }

                &.btn_active {
                    border-color: var(--d-color--green-primary);

                    .button__title {
                        color: var(--d-color--text-primary);
                    }
                }

                &:hover {
                    .button__title {
                        color: var(--d-color--text-primary);
                    }
                }
            }

            &.is-selected {
                .button {
                    border-color: var(--d-color--green-primary);

                    &__title {
                        color: var(--d-color--text-primary);
                    }
                }
            }
        }
    }
}
</style>
