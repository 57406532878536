export default {
    /**
     * Данные компонента
     * @property {Object} clickTimer - идентификатор таймера
     * @property {Number} clickCount - счетчик кликов
     * @property {Boolean} renameMode - режим переименования
     * @property {String} newName - новое название
     * @property {String} refInput - название ref для инпута
     */
    data: () => ({
        clickTimer: null,
        clickCount: 0,
        renameMode: false,
        newName: '',
        refInput: ''
    }),
    methods: {
        /**
         * Событие клика по названию
         */
        onClickName() {
            if (this.clickTimer !== null) {
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
            }
            this.clickCount += 1;
            this.clickTimer = setTimeout(() => {
                if (this.clickCount === 2) {
                    this.renameMode = true;
                    this.$nextTick(() => {
                        this.$refs[this.refInput].onFocus();
                    });
                }
                this.clickCount = 0;
                this.clickTimer = null;
            }, 200);
        },
        /**
         * Событие обновления названия
         * @param {String} newName - новое название
         */
        onUpdateName(newName = '') {
            this.newName = newName;
        },
        /**
         * Событие снятия фокуса с инпута
         */
        onBlurInput() {
            this.renameMode = false;
        },
    }
};