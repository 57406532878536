<template>
	<div class="range-wrapper">
		<div class="range-wrapper__label">{{label}}</div>
		<div class="range-container">
			<div class="range-container-indicators">
				<div class="range-container-indicators__val">
					<span :title="computedMin">{{computedMin}}</span>
				</div>
				<div class="range-container-indicators__val">
					<span :title="computedMax">{{computedMax}}</span>
				</div>
			</div>

			<div
				ref="slider"
				class="range-slider"
			>
				<div
					class="range-slider__road"
					:class="getRoadClasses"
					:style="getRoadStyles"
				/>
				<div
					class="range-slider__thumb range-slider__thumb-left"
					:class="getThumbClasses"
					:style="getleftThumbStyles"
					@mousedown="onLeftThumb"
				/>
				<div
					class="range-slider__thumb range-slider__thumb-right"
					:class="getThumbClasses"
					:style="getRightThumbStyles"
					@mousedown="onRightThumb"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseRangeSlider",
	props: {
		min: {
			type: Number,
			required: true
		},
		max: {
			type: Number,
			required: true
		},
		curMax: {
			type: Number,
			default: null
		},
		curMin: {
			type: Number,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
		}
	},
	data() {
		return {
			step: 0,
			sliderWidth: 0,
			leftThumbData: {
				indent: 0,
				value: 0,
			},
			rightThumbData: {
				indent: 0,
				value: 0,
			}
		}
	},
	computed: {
		computedMin() {
			return parseFloat(this.leftThumbData.value.toFixed(2))
		},
		computedMax() {
			return parseFloat(this.rightThumbData.value.toFixed(2))
		},
		leftThumbIndent() {
			return this.leftThumbData.indent
		},
		rightThumbIndent() {
			return this.rightThumbData.indent
		},
		getThumbClasses() {
			return {
				'range-slider__thumb_disabled': this.disabled
			}
		},
		getleftThumbStyles() {
			return {
				left: this.leftThumbData.indent + 'px'
			}
		},
		getRightThumbStyles() {
			return {
				right: this.rightThumbData.indent + 'px'
			}
		},
		getRoadStyles() {
			return {
				left: this.leftThumbData.indent + 'px',
				right: this.rightThumbData.indent + 'px'
			}
		},
		getRoadClasses() {
			return {
				'range-slider__road_disabled': this.disabled
			}
		}
	},
	// created() {
	// 	if (this.min > this.max) { throw new Error("property 'min' is greater than 'max'") }
	// 	if (this.curMin > this.curMax) { throw new Error("property 'curMin' is greater than 'curMax'") }
	// 	if (this.curMin < this.min) { throw new Error("property 'min' is greater than 'curMin'") }
	// 	if (this.curMax > this.max) { throw new Error("property 'curMax' is greater than 'max'") }
	// },
	mounted() {
		this.sliderWidth = this.$refs.slider.offsetWidth
		this.step = (this.max - this.min) / this.sliderWidth

		this.setLeftThumbValue()
		this.setRightThumbValue()
	},
	methods: {
		onLeftThumb(event) {
			if (this.disabled) {return}

			const startPoint = event.clientX - this.leftThumbData.indent

			const move = (moveEvent) => {
				const finishPoint = moveEvent.clientX
				const difference = finishPoint - startPoint
				
				this.leftThumbData.indent = parseInt(difference)

				if (this.leftThumbData.indent < 0) {
					this.leftThumbData.indent = 0
				}
				if (this.leftThumbData.indent > this.$refs.slider.offsetWidth - this.rightThumbData.indent) {
					this.leftThumbData.indent = this.$refs.slider.offsetWidth - this.rightThumbData.indent
				}
			}

			document.addEventListener('mousemove', move)

			document.addEventListener('mouseup', () => {
				document.removeEventListener('mousemove', move)
				this.emit()
			}, {once: true})

			document.body.style.userSelect = 'none'
		},
		onRightThumb(event) {
			if (this.disabled) {return}
			
			const startPoint = event.clientX + this.rightThumbData.indent

			const move = (moveEvent) => {
				const finishPoint = moveEvent.clientX
				const difference = finishPoint - startPoint

				this.rightThumbData.indent = parseInt(-difference)

				if (this.rightThumbData.indent < 0) {
					this.rightThumbData.indent = 0
				}
				if (this.rightThumbData.indent > this.$refs.slider.offsetWidth - this.leftThumbData.indent) {
					this.rightThumbData.indent = this.$refs.slider.offsetWidth - this.leftThumbData.indent
				}
			}

			document.addEventListener('mousemove', move)

			document.addEventListener('mouseup', () => {
				document.removeEventListener('mousemove', move)
				this.emit()
			}, {once: true})

			document.body.style.userSelect = 'none'
		},
		setLeftThumbValue() {
			if (this.curMin !== null) {
				this.leftThumbData.value = this.curMin
				const percent = this.sliderWidth/(this.max-this.min)
				this.leftThumbData.indent = percent * this.curMin - percent * this.min
			} else {
				this.leftThumbData.value = this.min
			}
		},
		setRightThumbValue() {
			if (this.curMax !== null) {
				this.rightThumbData.value = this.curMax
				const percent = this.sliderWidth/(this.max-this.min)
				this.rightThumbData.indent = percent * this.max - percent * this.curMax
			} else {
				this.rightThumbData.value = this.max
			}
		},
		emit() {
			this.$emit('change', {min: this.computedMin, max: this.computedMax})
		}
	},
	watch: {
		leftThumbIndent(newValue) {
			if (this.min === this.max) {
				this.leftThumbData.value = this.min
			} else {
				this.leftThumbData.value = this.min + newValue * this.step
			}
		},
		rightThumbIndent(newValue) {
			// console.log('rightThumbIndent', this.max - Math.abs(newValue) * this.step);
			if (this.min === this.max) {
				this.rightThumbData.value = this.max
			} else {
				this.rightThumbData.value = this.max - Math.abs(newValue) * this.step
			}
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.range-wrapper {
	width: 140px;

	&__label {
		margin-bottom: 7px;
		font-weight: 500;
	}

	.range-container {
		$thumbSize: 18px;

		box-shadow: 0 0 0px 3px #EFF1F4;
		position: relative;
		width: 100%;
		height: 60px;
		padding: 14px 0;
		background: #fff;
		border-radius: 4px;

		&-indicators {
			display: flex;
			justify-content: center;
			height: 100%;

			&__val {
				width: 50%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 1;
				flex-grow: 1;

				span {
					max-width: 80%;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					font-size: 13px;
				}

				&:first-child {
					border-right: 1px solid #D2D7DE;
				}
			}
		}

		.range-slider {
			position: absolute;
			bottom: 0;
			right: $thumbSize;
			left: $thumbSize;

			&__road {
				height: 2px;
				position: absolute;
				right: 0;
				left: 0;
				bottom: -1px;
				background: $active-color;

				&_disabled {
					background: $assistant-color;
				}
			}

			&__thumb {
				width: $thumbSize;
				height: $thumbSize;
				padding: 2px;
				background: rgb(255, 255, 255);
				border-radius: 50%;
				position: absolute;
				cursor: pointer;
				outline: none;

				&_disabled {
					&:before {
						background: $assistant-color !important;
					}
				}

				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background: $active-color;
					border-radius: inherit;
				}
			}

			&__thumb-left {
				transform: translateX(-100%) translateY(-50%);
			}
			&__thumb-right {
				transform: translateX(100%) translateY(-50%);
			}
		}
	}
}
</style>