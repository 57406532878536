<template>
	<div class="filter-panel">
        <date-picker-dropdown
            v-if="isDate"
            :isMultiple="false"
            :checkedDates="selectedDates"
            :accessDates="dates"
            @onApprove="onApproveDatepicker"
        />
        <BaseSelectMultiple
            v-if="isCity"
            class="filter-panel__select"
            view="primary"
            position="left"
            :isMultiple="false"
            :options="cities"
            :value="selectedCities"
            :canSearch="true"
            :defaultTitle="cityTitle"
            @check="onCheckCity"
        >
            <template #header>
                <span class="filter-panel__select-title">Выберите город</span>
            </template>
        </BaseSelectMultiple>

        <BaseSelectMultiple
            v-if="isClient"
            class="filter-panel__select"
            view="primary"
            position="left"
            :isMultiple="false"
            :options="clients"
            :value="selectedClients"
            :canSearch="true"
            :defaultTitle="clientTitle"
            @check="onCheckClient"
        >
            <template #header>
                <span class="filter-panel__select-title">Выберите клиента</span>
            </template>
        </BaseSelectMultiple>

        <BaseButton
            v-if="false"
            form="oval"
            class="panel-btn"
            @click="onClear"
        >
            <BaseIcon icon="icon-tabler-icon-eraser" size="lg" class="panel-btn__icon" />
            <span class="panel-btn__title">Сбросить всё</span>
        </BaseButton>
	</div>
</template>

<script>
import BaseSelectMultiple from '@/components/Base/BaseSelectMultiple'
import DatePickerDropdown from "@/components/DatePickerDropdown";
import BaseButton from "@/components/Base/BaseButton";
import BaseIcon from "@/components/Base/BaseIcon";

export default {
	name: "FilterPanel",
	components: {
        DatePickerDropdown,
        BaseSelectMultiple,
        BaseButton,
        BaseIcon
	},
    props: {
        /**
         * @property {Array} dates - массив доступных дат
         * @property {Array} cities - массив доступных городов
         * @property {Array} clients - массив доступных клиентов
         * @property {Array} selectedCities - массив выбранных городов
         * @property {Array} selectedDates - массив выбранных дат
         * @property {Array} selectedClients - массив выбранных клиентов
         */
        dates: {
            type: Array,
            default: () => []
        },
        cities: {
            type: Array,
            default: () => []
        },
        clients: {
            type: Array,
            default: () => []
        },
        selectedCities: {
            type: Array,
            default: () => []
        },
        selectedDates: {
            type: Array,
            default: () => []
        },
        selectedClients: {
            type: Array,
            default: () => []
        },
        isDate: {
            type: Boolean,
            default: true
        },
        isCity: {
            type: Boolean,
            default: true
        },
        isClient: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {

        }
    },
    computed: {
        /**
         * @returns {String} - заголовок для select
         */
        cityTitle() {
            if (this.selectedCities.length === 0) {
                return 'Выберите город'
            } else if (this.selectedCities.length === 1) {
                const selectedCityId = this.selectedCities[0]
                const selectedCity = this.cities.find(item => String(item.id) === String(selectedCityId))
                return selectedCity.value
            } else {
                return `Выделено: ${this.selectedCities.length}`
            }
        },
        /**
         * @returns {String} - заголовок для select
         */
        clientTitle() {
            if (this.selectedClients.length === 0) {
                return 'Выберите клиента'
            } else if (this.selectedClients.length === 1) {
                const selectedClientId = this.selectedClients[0]
                const selectedClient = this.clients.find(item => String(item.id) === String(selectedClientId))
                return selectedClient.value
            } else {
                return `Выделено: ${this.selectedClients.length}`
            }
        },
    },
	methods: {
        /** -
         * Выбрал город
         * @param {Object} event
         */
        onCheckCity(event) {
            this.$emit('approveFilterParam', 'city_id', event)
        },
        /** -
         * Выбрал клиента
         * @param {Object} event
         */
        onCheckClient(event) {
            this.$emit('approveFilterParam', 'user_id', event)
        },
        /** -
         * @param {Array} event - массив объектов в формате {year: 2022, month: 6}
         */
        onApproveDatepicker(event) {
            let {year, month} = event[0];
            month = month > 9 ? String(month) : ('0' + String(month));
            let date = String(year) + '-' + month + '-01';
            this.$emit('approveFilterParam', 'date', date)
        },
        /**
         * Поиск по выделенным городам
         */
        onClose() {
            // для прослушки события в компонентк BaseSelect
            this.$emit('$closeSelect')
        },
        /**
         * Сброс фильтра
         */
        onClear() {
            this.$emit('clear')
        }
	},
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.filter-panel {
	display: flex;
	align-items: center;
    height: 40px;

    &__select-title {
        font-weight: 700;
        font-size: 14px;
    }

    & > div:not(:first-child) {
        margin-left: 10px;
    }

    &__select {
        height: 100%;
        flex-shrink: 0;

        .select-preview {
            height: 100%;
        }
    }

    &-date {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 180px;
        border-radius: 100px;
        padding: 0 15px;
        text-align: left;

        &__icon {
            flex-shrink: 0;
        }

        &__title {
            display: block;
            margin-left: 10px;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__date-wrapper {
        height: 100%;
    }

    .filter-info {
        height: 100%;
        display: flex;
        align-items: center;

        &__wrapper {
            height: 100%;
            border-radius: 1000px;
            display: flex;

            &_has {
                border: 1px solid $primary-color;
            }
        }

        &__chips-place {
            display: flex;
            align-items: center;
            padding: 0 15px;
        }

        &-btn {
            height: 100%;
            border-radius: 1000px;
            display: flex;
            align-items: center;
            padding: 0 22px 0 15px;

            &_active {
                background: #fff;
                border-color: $primary-color;
            }

            span {
                display: block;
                margin-left: 10px;
            }
        }
    }

    .panel-btn {
        display: flex;
        align-items: center;
        margin-left: 10px;

        @media screen and (max-width: 620px) {
            margin: 10px 0;
            flex-wrap: wrap;
            width: 100%;
            margin-right: 6px !important;
            margin-left: 6px !important;
        }

        &:not(:last-child) {
            margin-right: 6px;
        }

        &__icon {
            font-weight: 700;
        }

        &__title {
            display: block;
            margin-left: 6px;
        }
    }
}
</style>

<style lang="scss">
.filter-panel {
    .filter-panel__select {
        width: 180px!important;

        .select-preview {
            height: 100%;
            border-radius: 100px!important;
        }
        .select-preview__label {
            font-weight: 700;
        }
    }
    .d-wrap__content-wrapper {
        height: 100%;
    }
}
</style>
