export default {
    /**
     * @property {Boolean} isMatchingFromFile - флаг экспорта
     */
    data: () => ({
        isMatchingFromFile: false,
    }),
    methods: {
        /**
         * Событие создания набора
         */
        onMatchFromFile() {
            this.isMatchingFromFile = true;
        },
        /**
         * Закрытие окна мэтчинга
         */
        onCloseMatching(){
            this.isMatchingFromFile = false;
        },
    }
};
