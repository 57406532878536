<template>
	<div class="loader">
		<BasePreloader :size="50" />
	</div>
</template>

<script>
import BasePreloader from "@/components/Base/BasePreloader"
export default {
	name: "Loader",
	components: {
		BasePreloader
	}
}
</script>

<style lang="scss" scoped>
.loader {
	// position: absolute;
	// top: 0;
	// left: 0;
	// right: 0;
	// bottom: 0;
	// z-index: 1;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.95);
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>