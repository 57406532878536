<template>
    <base-modal @close="onCloseModal">
        <template v-slot:modalBody>
            <div class="modal-share__body">
                <div class="modal-share__title">Скопируйте ссылку</div>
                <div
                    v-tooltip="{
                        content: 'Ссылка скопированная в буфер обмена',
                        show: isTooltip,
                        trigger: 'manual',
                    }"
                    @click="onClipboard"
                >
                    <base-text-input
                        :value="shareLink"
                        :styleObj="{width: '400px'}"
                        view="primary"
                        :isReadOnly="true"
                    />
                </div>
                <div
                    v-if="poiItems.length"
                    class="modal-share__activity-container"
                >
                    <div class="modal-share__activity-title">
                        Расшаренные POI
                    </div>

                    <div class="modal-share__activity-tags">
                        <div
                            class="modal-share__activity-tag"
                            v-for="(poiItem, poiIndex) in poiItems"
                            :key="poiIndex"
                        >
                            <img v-if="poiItem.icon" width="20" :src="poiItem.icon" style="margin-right: 5px">
                            <span
                                v-if="!poiItem.icon"
                                :style="{background: '#' + poiItem.color}"
                                style="width: 15px; height:15px; margin-right: 5px"
                            ></span>
                            <span class="modal-share__activity-tag-name">{{poiItem.name}} ({{poiItem.poi_count}})</span>
                        </div>
                    </div>
                </div>

                <div class="modal-share__button-container">
<!--                    <base-button-->
<!--                        class="modal-share__button-cancel"-->
<!--                        view="border"-->
<!--                        @click="onCloseModal"-->
<!--                    >-->
<!--                        Отменить-->
<!--                    </base-button>-->
                    <base-button
                        :style="{marginLeft: '10px'}"
                        class="modal-share__button-remove"
                        view="border"
                        @click="onRefresh"
                    >
                        Обновить
                    </base-button>
                    <base-button
                        :style="{marginLeft: '10px'}"
                        view="secondary"
                        @click="onClipboard"
                    >
                        Копировать
                    </base-button>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
/** Компонент модального окна для отображения ссылки для шары */
import BaseModal from '@/components/Base/BaseModal';
import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'ModalShare',
    components: {
        BaseModal,
        BaseTextInput,
        BaseButton
    },
    /**
     * Входные данные компонента
     * @property {String} shareLink - ссылка
     */
    props: {
        shareLink: {
            type: String,
            default: ''
        },
        poiItems: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        /**
         * Условие рендера тултипа
         * @type {Boolean}
         */
        isTooltip: false
    }),
    methods: {
        /**
         * Событие копирования ссылки в буфер обмена
         */
        onClipboard() {
            this.isTooltip = true;
            setTimeout(() => {
                this.isTooltip = false
                this.onCloseModal()
            }, 1000);
            this.$clipboard(this.shareLink);
        },
        /**
         * Событие закрытия модального окна
         */
        onCloseModal() {
            this.$emit('onCloseModal');
        },

        /**
         * Событие удаления ссылки
         */
        onRefresh() {
            this.$emit('onRefreshLink');
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal-share {
        &__title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        &__activity-container {
            padding-top: 15px;
        }
        &__activity-title{
            font-size: 12px;
            font-weight: 500;
            color: #ADB0BB;
            margin-bottom: 5px;

            &_margin {
                margin-left: 10px;
            }
        }

        &__activity-tags {
            display: flex;
            flex-wrap: wrap;
        }

        &__activity-tag{
            // display: flex;
            // align-items: center;
            margin-right: 5px;
            // margin-bottom: 5px;
            display: flex;
            padding: 6px 10px;
            background: #F5F5F5;
            border-radius: 50px;
            margin-bottom: 4px;
        }

        &__activity-tag-name {
            display: flex;
            align-items: center;
        }

        &__activity-label{
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-size: 12px;
            color: #ADB0BB;
        }

        &__button-container {
            width: 100%;
            display: flex;
            justify-content: right;
            margin-top: 30px;
        }
        &__button-cancel {
            color: #C8CAD1;

            &:hover {
                color: #000;
            }
        }
        &__button-remove {
            color: #C8CAD1;

            &:hover {
                color: #000;
            }
        }
    }
</style>
