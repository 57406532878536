<template>
	<div
		class="photo"
		:class="getRootClasses"
	>
		<BaseIcon
			v-if="!small"
			icon="icon-tabler-icon-camera"
			style="color: #ccc"
		/>
		<img
			v-else
			:src="small"
			class="photo__min-image"
			@click="onOpen"
		>

		<AppPopup
			v-if="isPopupOpen"
			@close="onClose"
		>
			<div class="photo-popup">
				<img :src="big" class="photo-popup__image">
			</div>
		</AppPopup>
	</div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'
import AppPopup from '@/components/Popup'

export default {
	name: "Photo",
	components: {
		BaseIcon,
		AppPopup
	},
	props: {
		small: {
			type: String,
			default: ""
		},
		big: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			isPopupOpen: false
		}
	},
	computed: {
		getRootClasses() {
			return {
				photo_empty: !this.small
			}
		}
	},
	methods: {
		onOpen() {
			this.isPopupOpen = true
		},
		onClose() {
			this.isPopupOpen = false
		}
	}
}
</script>

<style lang="scss" scoped>
.photo {
	position: relative;
	display: block;
	width: 56px;
	height: 56px;
	overflow: hidden;
	border-radius: 4px;
	border: 1px solid transparent;

	&_empty {
		border: 1px solid #F3F3F3;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__min-image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}

	&-popup {
		// width: 100%;
		width: 832px;
		height: 632px;
		position: relative;

		&__image {
			// max-width: 800px;
			// max-height: 600px;
			// display: block;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
</style>
