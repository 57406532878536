import ServicePoi from './ServicePoi';

export default class ServicePoiRealtimeSystem {
    static poiRealtimeSystemUpdateAfter(poiData = {}) {
        const {poi = {}} = poiData;
        const {is_last_page: isLastPage = false, query = '', poi_request_items} = poi;
        const {resultItems: poiItems = []} = ServicePoi._autoCompleteAfter({poi_request_items});

        const poiDataAfter = {
            isLastPage: Boolean(isLastPage),
            query: String(query),
            poiItems
        };

        return poiDataAfter;
    }
}
