export default {
    namespaced: true,
    state: {
        historyItems: []
    },
    mutations: {
        /**
         *
         * @param state
         * @param historyItems
         */
        setHistoryItems(state, historyItems) {
            state.historyItems = historyItems;
        },

        /**
         *
         * @param state
         * @param item
         */
        addHistoryItem(state, item) {
            let historyItemsCopy = [...state.historyItems];
            const itemIndex = historyItemsCopy.findIndex(historyItem => Number(historyItem.id) === Number(item.id));
            if(itemIndex === -1){
                historyItemsCopy.push(item);
            }else{
                historyItemsCopy.splice(itemIndex, 1, item);
            }
            state.historyItems = [...historyItemsCopy];
        }
    },
}
