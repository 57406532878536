import ServiceApi from "@/services/ServiceApi";

export default {
    namespaced: true,
    state: {
        isLoadingKits: false,
        kitsList: []
    },
    getters: {
        isLoadingKits(state) {
            return state.isLoadingKits;
        },
        kitsList(state) {
            return state.kitsList;
        },
        reversedKitsList(state) {
            return state.kitsList.reverse();
        },
        findFirstKit(state) {
            return state.kitsList[0] ?? undefined;
        },
        /**
         * Получить набор по переданному id
         * @param state
         * @return {Object | undefined}
         */
        findKitById: (state) => (kitId) => {
            return state.kitsList.find(kitItem => String(kitItem.id) === String(kitId));
        }
    },
    mutations: {
        setLoadingKitsList(state, value = false) {
            state.isLoadingKits = value;
        },
        setKitsList(state, data = []) {
            state.kitsList = data;
        },
    },
    actions: {
        /**
         * Запрос для получения списка кастомных наборов
         */
        async fetchKitsList({commit}) {
            commit('setLoadingKitsList', true);
            try {
                const response = await ServiceApi.post('free', '/map/set-list');

                if ("data" in response && "data" in response.data) {
                    commit('setKitsList', response.data.data);
                }
            }
            catch(error) {
                console.log(error);
            } finally {
                commit('setLoadingKitsList', false);
            }
        },
    }
}
