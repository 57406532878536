export default {
    /**
     * Данные компонента
     * @property {Array} historyItems
     */
    data: () => ({
        historyItems: [],
    }),
    methods: {
        /**
         * Событие клика по названию
         */
        afterPostHistory(items){
            this.historyItems = items
        }
    }
};
