import ServiceBasePolygon from '../ServicesBase/ServiceBasePolygon';
const google = window.google;
/**
 * Сервис для работы с полигонами в гугл мап апи
 * @extends ServiceBasePolygon
 */
export default class ServiceGooglePolygon extends ServiceBasePolygon {
    /**
     * Конструктор
     * @param {Object} params - параметры для полигона
     */
    constructor(params = {}) {
        super(params);
        this.polygonObj = new google.maps.Polygon({
            map: this.map,
            strokeColor: this.strokeColor,
            strokeOpacity: this.strokeOpacity,
            strokeWeight: this.strokeWeight,
            fillColor: this.fillColor,
            fillOpacity: this.fillOpacity,
            paths: this.paths
        });
    }
}