/* eslint-disable no-extra-semi */
import ServiceBaseCircle from '../ServicesBase/ServiceBaseCircle';
const google = window.google;
/**
 * Сервис для работы с окружностями в гугл мап апи
 * @extends ServiceBaseCircle
 */
export default class ServiceGoogleCircle extends ServiceBaseCircle {
    /**
     * Конструктор
     * @param {Object} params - параметры для окружности
     */
    constructor(params = {}) {
        super(params);
        this.circleObj = new google.maps.Circle({
            map: this.map,
            strokeColor: this.strokeColor,
            strokeOpacity: this.strokeOpacity,
            strokeWeight: this.strokeWeight,
            fillColor: this.fillColor,
            fillOpacity: this.fillOpacity,
            center: new google.maps.LatLng(this.centerLat, this.centerLng),
            radius: this.radius,
        });
    };
};