import UtilLocalStorage from "../../utils/UtilLocalStorage";

export default class ServicePoiSelector {
    static _localStoragePoiDataCheckedKey = 'poiDataChecked';

    static _getPoiDataChecked() {
        return UtilLocalStorage.getData(this._localStoragePoiDataCheckedKey) ?? {};
    }

    static _getPoiDataGroupsChecked(cityId = '') {
        const poiDataChecked = this._getPoiDataChecked();
        return poiDataChecked[cityId] ?? {};
    }

    static _setPoiDataChecked(cityId = '', poiGroupsChecked = {}) {
        const poiDataCheckedBuff = this._getPoiDataChecked();
        const poiDataChecked = {
            ...poiDataCheckedBuff,
            [cityId]: {...poiGroupsChecked}
        };

        if(Object.keys(poiDataChecked[cityId]).length === 0)
            delete poiDataChecked[cityId];

        UtilLocalStorage.setData(this._localStoragePoiDataCheckedKey, poiDataChecked);
    }

    static getPoiGroupsChecked(cityId = '') {
        return this._getPoiDataGroupsChecked(cityId);
    }

    static setPoiGroupChecked(cityId = '', groupId = '', markersIdsChecked = []) {
        const poiGroupsCheckedBuff = this._getPoiDataGroupsChecked(cityId);
        const poiGroupsChecked = {
            ...poiGroupsCheckedBuff,
            [groupId]: [...markersIdsChecked]
        };

        this._setPoiDataChecked(cityId, poiGroupsChecked);
    }

    static setPoiMarkerChecked(cityId = '', groupId = '', markerId = '') {
        let poiGroupsChecked = this._getPoiDataGroupsChecked(cityId);
        let poiMarkersChecked = poiGroupsChecked[groupId] ?? [];

        poiMarkersChecked.push(markerId);
        poiGroupsChecked[groupId] = poiMarkersChecked;

        this._setPoiDataChecked(cityId, poiGroupsChecked);
    }

    static unsetPoiGroupChecked(cityId = '', groupId = '') {
        let poiGroupsChecked = this._getPoiDataGroupsChecked(cityId);
        delete poiGroupsChecked[groupId];

        this._setPoiDataChecked(cityId, poiGroupsChecked);
    }

    static unsetPoiMarkerChecked(cityId = '', groupId = '', markerId = '') {
        let poiGroupsChecked = this._getPoiDataGroupsChecked(cityId);
        let poiMarkersChecked = poiGroupsChecked[groupId] ?? [];
        const markerIndex = poiMarkersChecked.findIndex(poiMarkerIdChecked => String(poiMarkerIdChecked) === String(markerId));

        if (markerIndex !== -1) {
            poiMarkersChecked.splice(markerIndex, 1);
            poiGroupsChecked[groupId] = poiMarkersChecked;

            if (poiGroupsChecked[groupId].length === 0)
                delete poiGroupsChecked[groupId];

            this._setPoiDataChecked(cityId, poiGroupsChecked);
        }
    }
}
