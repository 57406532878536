<template>
    <div
        :draggable="isDraggable"
        :class="draggableClass"
        class="match-side-item"
        @dragstart="onDragStart"
    >
        <div
            v-for="(sideInfoKey, index) in Object.keys(sideInfoAdapted)"
            :key="index"
            class="match-side-item__info"
            :class="getItemInfoClasses(Object.keys(sideInfoAdapted).length, index, combinationId)"
        >
            <template v-if="sideInfoKey !== 'fromFileGid' && sideInfoKey !== 'approxGid'">
                {{getSideInfoProp(sideInfoKey, 'value')}}
            </template>
            <template v-if="sideInfoKey === 'fromFileGid' || sideInfoKey === 'approxGid'">
                <span
                    v-for="(item, index) in sideInfoAdapted[sideInfoKey].value"
                    :key="index"
                    class="match-side-item__info-gid"
                    :class="{'match-side-item__info-gid--color': diffGidIdx.includes(index)}"
                >
                    {{item}}
                </span>
            </template>
        </div>
        <div v-if="isShowDistance" class="match-side-item__info-approx">{{ distanceTitle }}</div>
        <div class="match-side-item__side">
            {{ sideName }}
        </div>
    </div>
</template>

<script>
/** Компонент элемента стороны мэтчинга */
export default {
    name: 'MatchSideItem',
    /**
     * Входные данные компонента
     * @property {Object} side - сторона
     * @property {Object} sideInfo - объект с ключами и заголовками с информацией о стороне
     * @property {Boolean} isDraggable - таскаемый ли компонент
     * @property {Boolean} isShowDistance - Показать/скрыть радиус стороны
     * @property {Number} combinationId - Id комбинации (1 - координаты, 2 - Gid+Оператор+сторона, 3 - Адрес+сторона)
     * @property {Boolean} isShowApprox - Показать/скрыть набор похожее
     */
    props: {
        side: {
            type: Object,
            default: () => ({})
        },
        sideInfo: {
            type: Object,
            default: () => ({})
        },
        isDraggable: {
            type: Boolean,
            default: false
        },
        isShowDistance: {
            type: Boolean,
            default: false
        },
        isShowApprox: {
            type: Boolean,
            default: false
        },
        combinationId: {
            type: Number,
            default: -1
        },
    },

    /**
     * Данные компонента
     * @property {Array} diffGidIdx - индексы отличающихся отличающихся знаков в GID
     */
    data: () => ({
        diffGidIdx: [],
    }),

    watch: {
        gid(oldVal, newVal) {
            if (oldVal !== newVal) {
                this.diffGidIdx = [];
                this.getDiffGidIds();
            }
        },
    },

    /**
     * При загрузке если выбран шаблон Gid+оператор+сторна, вычислить различающиеся символы в gid
     */
    mounted() {
        if (this.combinationId === 2) {
            this.getDiffGidIds();
        }
    },

    computed: {
        /**
         * Адаптированный объект с ключами и заголовками с информацией о стороне
         * @returns {Object}
         */
        sideInfoAdapted() {
            const sideInfoAdapted = Object.keys(this.sideInfo).reduce((sideInfoAdapted, sideInfoKey) => {
                const {match_info: matchInfo = {}} = this.side;
                const {side = {}} = matchInfo;

                let sideInfo = {};
                const inlineValuesFromFile = this.modifyValue(this.side, ['side_name', 'match_info', 'row_num']);
                const inlineValuesApprox = this.modifyValue(side, ['side_name', 'id']);

                // Если это набор Похожие
                if (this.isShowApprox) {
                    // Если шаблон не Gid+сторона+оператор
                    if (this.combinationId !== 2) {
                        sideInfo = {
                            ...sideInfoAdapted,
                            fromFile: {
                                title: 'fromFile',
                                value: `Из файла: ${inlineValuesFromFile}`
                            },
                            approx: {
                                title: 'approxFile',
                                value: `Похожие: ${inlineValuesApprox}`
                            },
                        };
                        return sideInfo;
                    }

                    // Если шаблон Gid+сторона+оператор
                    if (this.combinationId === 2) {
                        const fromFileOptions = this.modifyValue(this.side, ['side_name', 'match_info', 'row_num', 'gid']);
                        const approxOptions = this.modifyValue(side, ['side_name', 'id', 'gid']);
                        sideInfo = {
                            ...sideInfoAdapted,
                            fromFile: {
                                title: 'fromFile',
                                value: `Из файла: ${fromFileOptions}`
                            },
                            fromFileGid: {
                                title: 'fromFile',
                                value: String(this.side.gid)
                            },
                            approx: {
                                title: 'approxFile',
                                value: `Похожие: ${approxOptions}`
                            },
                            approxGid: {
                                title: 'approxFile',
                                value: String(side.gid)
                            },
                        }
                        return sideInfo;
                    }

                }

                // Если шаблон координаты+сторона
                if (this.combinationId === 1) {
                    sideInfo = {
                        ...sideInfoAdapted,
                        coords: {
                            title: 'coords',
                            value: inlineValuesFromFile
                        }
                    };
                    return sideInfo;
                }

                if (sideInfoKey !== 'side_name') {
                    sideInfo = {
                        ...sideInfoAdapted,
                        [sideInfoKey]: {
                            title: String(this.sideInfo[sideInfoKey] ?? ''),
                            value: side !== false ? String(side[sideInfoKey] ?? '') : String(this.side[sideInfoKey] ?? '')
                        }
                    };
                    return sideInfo;
                }

                return {...sideInfoAdapted}

            }, {});
            return sideInfoAdapted;
        },

        /**
         * Класс для таскаемого элемента
         * @returns {String}
         */
        draggableClass() {
            return this.isDraggable ? `match-side-item_draggable` : '';
        },

        /**
         * Значение стороны
         * @returns {String}
         */
        sideName() {
            return this.side['side_name'] || "";
        },

        /**
         * Вывод текста для радиуса
         * @returns {String}
         */
        distanceTitle() {
            const { match_info: matchInfo = {} } = this.side;
            const { side = {} } = matchInfo;
            const { distance = 0 } = side;

            if (distance === 0) {
                return 'Не найдено похожих';
            }

            return `Рядом в ${distance} м`;
        },

        /**
         * Вывод gid
         * @returns {String}
         */
        gid() {
            const {gid = 0} = this.side;
            return String(gid);
        },
    },
    methods: {
        /**
         * Получить значение ключа информации о стороне
         * @param {String} sideInfoKey - ключ объекта информации
         * @param {String} propName - название свойства информации
         * @returns {String}
         */
        getSideInfoProp(sideInfoKey = '', propName = '') {
            const sideInfoItem = this.sideInfoAdapted[sideInfoKey] ?? {};
            return String(sideInfoItem[propName]) ?? '';
        },

        /**
         * Событие начала перетаскивания
         * @param {Object} event - объект события
         */
        onDragStart(event) {
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
            this.$emit('onDragStart', this.side);
        },

        /**
         * Расчитать класс для текущего элемента
         * @param {Number} length - кол-во элементов
         * @param {Number} index - индекс текущего элемента
         * @param {Number} combinationId - выбранная комбинация полей
         * @returns {String}
         */
        getItemInfoClasses(length, index, combinationId) {
            if (length < 2 || index === length - 1) {
                return 'match-side-item__info--no-margin';
            }
            if (combinationId === 2 && (index === 0 || index === 2)) {
                return 'match-side-item__info--no-margin';
            }
            return '';
        },

        /**
         * Вспомогательная ф-я для вывода нескольких значений в одну строку
         * @property {Array} targetArr - сторона
         * @property {Array} keysWithout - сторона
         * @returns {String}
         */
        modifyValue(targetArr, keysWithout) {
            return Object.entries(targetArr)
                .filter(([key, ]) => !keysWithout.includes(key))
                .map(([ , value]) => value)
                .join(', ');
        },

        /**
         * Поиск индексов отличающихся символов gid
         */
        getDiffGidIds() {
            const fromFileGid = String(this.side.gid) ?? '';
            const {match_info: matchInfo = {}} = this.side;
            const {side = {}} = matchInfo;
            const {gid = 0} = side;

            fromFileGid
                .split('')
                .forEach((el, index) => {
                    if (String(el).toLowerCase() !== String(gid[index]).toLowerCase()) {
                        this.diffGidIdx.push(index);
                    }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .match-side-item {
        position: relative;
        display: flex;
        flex-direction: column;

        border-radius: 10px;
        background: #F8F8F8;
        color: #000;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        padding: 15px;
        margin: 0 5px 5px 0;

        &_draggable {
            cursor: move;
        }

        &__info {
            margin-bottom: 5px;

            &-approx {
                margin-top: 5px;
                margin-bottom: 0;
            }
            &-gid {
                letter-spacing: -2px;
                &--color {
                    color: $red-color;
                }
            }

            &--no-margin {
                margin-bottom: 0;
            }
        }

        &__side {
            position: absolute;
            bottom: 15px;
            right: 15px;

            margin-bottom: 0;
        }
    }
</style>
