<template>
	<div class="draggble-window">
		<div
			v-if="canTopResize"
			class="draggble-window__top-resizer"
			@mousedown="onResize($event, 'top')"
		/>
		<div
			v-if="canRightResize"
			class="draggble-window__right-resizer"
			@mousedown="onResize($event, 'right')"
		/>
		<div
			v-if="canBottomResize"
			class="draggble-window__bottom-resizer"
			@mousedown="onResize($event, 'bottom')"
		/>
		<div
			v-if="canLeftResize"
			class="draggble-window__left-resizer"
			@mousedown="onResize($event, 'left')"
		/>

		<div
			class="draggble-window__header"
			@mousedown="onMove($event, $el)"
		>
			<slot name="header"></slot>
		</div>

		<div class="draggble-window__content">
			<slot name="content" />
		</div>
	</div>
</template>

<script>

export default {
	name: "DraggbleWindow",
	props: {
		canRightResize: {
			type: Boolean,
			default: true
		},
		canLeftResize: {
			type: Boolean,
			default: true
		},
		canTopResize: {
			type: Boolean,
			default: true
		},
		canBottomResize: {
			type: Boolean,
			default: true
		},
		canMove: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			startPoint: 0,
            finishPoint: 0,

			startPointX: 0,
			startPointY: 0,
			finishPointX: 0,
			finishPointY: 0,
		}
	},
	methods: {
		onMove(event, target) {
			if (!this.canMove) { return }

            document.body.style.userSelect = 'none'
			this.startPointX = event.clientX
			this.startPointY = event.clientY

            const move = (event) => {
				this.finishPointX = event.clientX
				const differenceX = this.finishPointX - this.startPointX
				const left  = parseInt(getComputedStyle(target).left)
				target.style.left = left + differenceX + 'px'
				this.startPointX = this.finishPointX

				this.finishPointY = event.clientY
				const differenceY = this.finishPointY - this.startPointY
				const top  = parseInt(getComputedStyle(target).top)
				target.style.top = top + differenceY + 'px'
				this.startPointY = this.finishPointY
            }
			
            // Удаляю слушатели
            document.addEventListener('mousemove', move)
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', move)
                document.body.style.userSelect = 'initial'
            }, {once: true})
        },

		onResize(event, direction) {
			const target = this.$el
            document.body.style.userSelect = 'none'
            if (direction == 'right' || direction == 'left') {
				this.startPoint = event.clientX
			} else if (direction == 'top' || direction == 'bottom') {
				this.startPoint = event.clientY
			}

            const onResizeCeilEnd = (event) => {
				if (direction === 'right') {
					this.finishPoint = event.clientX
					const difference = this.finishPoint - this.startPoint
					const sum = difference + target.offsetWidth
					this.startPoint = this.finishPoint
					target.style.width = sum+'px'
				}
				if (direction === 'bottom') {
					this.finishPoint = event.clientY
					const difference = this.finishPoint - this.startPoint
					const sum = difference + target.offsetHeight
					this.startPoint = this.finishPoint
					target.style.height = sum + 'px'
				}
				if (direction === 'top') {
					this.finishPoint = event.clientY
					const difference = this.finishPoint - this.startPoint
					const sum = target.offsetHeight - difference
					const top  = parseInt(getComputedStyle(target).top)
					target.style.top = top + difference + 'px'
					this.startPoint = this.finishPoint
					target.style.height = sum+'px'
				}
				if (direction === 'left') {
					this.finishPoint = event.clientX
					const difference = this.finishPoint - this.startPoint
					const sum = target.offsetWidth - difference
					const left  = parseInt(getComputedStyle(target).left)
					target.style.left = left + difference + 'px'
					this.startPoint = this.finishPoint
					target.style.width = sum+'px'
				}
            }
			
            // Удаляю слушатели
            document.addEventListener('mousemove', onResizeCeilEnd)
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', onResizeCeilEnd)
                document.body.style.userSelect = 'initial'
				this.finishPoint = 0
				this.startPoint = 0
            }, {once: true})
        }
	}
}
</script>

<style lang="scss" scoped>
$resizerSize: 5px;
%resizer {
	background: transparent !important;
	position: absolute;
	z-index: 2;
}

.draggble-window {
	position: absolute;
	background: #fff;
	min-width: 140px;
	min-height: 140px;
	display: flex;
	flex-direction: column;

	&__content {
		flex-grow: 1;
		overflow: auto;
	}

	&__header {        
		position: relative;
		cursor: grab;
	}

	&__right-resizer {
		@extend %resizer;
		cursor: ew-resize;
		width: $resizerSize;
		height: 100%;
		right: 0px;
		top: 0px;
	}
	&__top-resizer {
		@extend %resizer;
		cursor: ns-resize;
		width: 100%;
		height: $resizerSize;
		right: 0px;
		top: 0px;
	}
	&__bottom-resizer {
		@extend %resizer;
		cursor: ns-resize;
		width: 100%;
		height: $resizerSize;
		right: 0px;
		bottom: 0px;
	}
	&__left-resizer {
		@extend %resizer;
		cursor: ew-resize;
		width: $resizerSize;
		height: 100%;
		left: 0px;
		top: 0px;
	}
}
</style>