/* eslint-disable no-extra-semi */
/** Базовый сервис для работы с информационными окнами */
export default class ServicesBaseInfoWindow {
    /**
     * Конструктор
     * @param {Object} params - параметры для информационных окон
     */
    constructor(params = {}) {
        const {
            content = '',
			disableAutoPan = false,
			maxWidth = 1200,
			minWidth = 200,
			positionLat = '',
            positionLng = '',
			zIndex = 100
        } = params;
        
		this.content = String(content);
		this.disableAutoPan = Boolean(disableAutoPan);
		this.maxWidth = parseFloat(maxWidth);
		this.minWidth = parseFloat(minWidth);
		this.positionLat = parseFloat(positionLat);
        this.positionLng = parseFloat(positionLng);
		this.zIndex = parseFloat(zIndex);
    };
};