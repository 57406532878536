<template>
<svg class="svg-icon" :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C11.4477 21 11 20.5523 11 20L11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4L13 20C13 20.5523 12.5523 21 12 21Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 19C18.5523 19 19 18.5523 19 18L19 6C19 5.44771 18.5523 5 18 5L6 5C5.44772 5 5 5.44771 5 6L5 18C5 18.5523 5.44771 19 6 19L18 19ZM21 18C21 19.6569 19.6569 21 18 21L6 21C4.34315 21 3 19.6569 3 18L3 6C3 4.34314 4.34315 3 6 3L18 3C19.6569 3 21 4.34315 21 6L21 18Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.79289 19.2071C3.40237 18.8166 3.40237 18.1834 3.79289 17.7929L11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929C13.0976 10.6834 13.0976 11.3166 12.7071 11.7071L5.20711 19.2071C4.81658 19.5976 4.18342 19.5976 3.79289 19.2071Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 14.7071C2.90237 14.3166 2.90237 13.6834 3.29289 13.2929L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L4.70711 14.7071C4.31658 15.0976 3.68342 15.0976 3.29289 14.7071Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 9.70711C2.90237 9.31658 2.90237 8.68342 3.29289 8.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L4.70711 9.70711C4.31658 10.0976 3.68342 10.0976 3.29289 9.70711Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 20.7071C6.90237 20.3166 6.90237 19.6834 7.29289 19.2929L11.2929 15.2929C11.6834 14.9024 12.3166 14.9024 12.7071 15.2929C13.0976 15.6834 13.0976 16.3166 12.7071 16.7071L8.70711 20.7071C8.31658 21.0976 7.68342 21.0976 7.29289 20.7071Z" :fill="color"/>
</svg>
</template>

<style scoped>
    .svg-icon path {
        fill: currentColor;
    }
</style>

<script>
export default {
    name: 'IconFull',
    props: {
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'black'
        },
    },
}
</script>
