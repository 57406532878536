<template>
	<div class="filter-item">
		<div class="filter-item__title" v-tooltip.bottom="title">
			{{ title }}
		</div>

		<button
			class="sign"
			@click="onTransfer"
		>
            <span class="sign__icon icon-tabler-icon-plus"></span>
		</button>
	</div>
</template>

<script>

export default {
	name: "FilterItemInactive",
	components: {
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		id: [Number, String]
	},
	methods: {
		onTransfer() {
			this.$emit('transfer', this.id)
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: #F0F3F8 !default;
$black: #000;
$gray: #676767;
$gray-light: #d0d0d0;

.filter-item {
	position: relative;
	width: 100%;
    background: $primary-color;
    padding: 18px 14px;
    border-radius: 10px;
    color: $black;

	&__title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-weight: 500;
		max-width: 80%;
	}

	.sign {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 34px;
		height: 34px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		color: $gray-light;
        transition: color 300ms ease-out;

		&__icon {
			width: 10px;
		}

        &:hover,
        &:active{
            color: $gray;
        }
	}
}
</style>
