/* eslint-disable no-extra-semi */
import ServiceBaseMarker from '../ServicesBase/ServiceBaseMarker';
const google = window.google;
/**
 * Сервис для работы с маркерами в гугл мап апи
 * @extends ServiceBaseMarker
 */
export default class ServiceGoogleMarker extends ServiceBaseMarker {
    /**
     * Конструктор
     * @param {Object} params - параметры для маркера
     */
    constructor(params = {}) {
        super(params);
        const {icon = null, isRender = true} = params;
        this.isRender = Boolean(isRender);
        this.markerObj = new google.maps.Marker({
			map: isRender ? this.map : null,
			position: new google.maps.LatLng(this.lat, this.lng),
            icon: icon !== null ? icon : {
                url: this.src ? this.src : this.getIconUrl(),
                ...this.getIconParams()
            },
            optimized: true
		});
    };
    /**
     * Получить параметры для иконки
     * @returns {Object}
     */
    getIconParams() {
        let circleSmall = 0;
        let circleBig = 0;
        switch (this.iconType) {
            case 'directionAngleView':
            case 'directionFlowSide':
                circleSmall = 28;
                circleBig = 28;
                break;
            case 'poi':
                circleSmall = 20;
                circleBig = 20;
                break;
            case 'poiIcon':
                circleSmall = 30;
                circleBig = 30;
                break;
            default:
                circleSmall = 20;
                circleBig = 22;
                break;
        }
        const state = this.getState();
        const iconParams = {
            scaledSize: state === 1 ? new google.maps.Size(circleBig, circleBig) : new google.maps.Size(circleSmall, circleSmall),
            anchor: state === 1 ? new google.maps.Point(circleBig / 2, circleBig / 2) : new google.maps.Point(circleSmall / 2, circleSmall/ 2)
        };
        return iconParams;
    };
    /**
     * Обновить иконку маркера
     * @param {String} type - тип иконки маркера
     * @param {Boolean} isRedraw - условие для перерендера
     */
    updateIcon(type = 'default', isRedraw) {
        this.iconType = type;
        let newIcon = {
            url: this.src ? this.src : this.getIconUrl(),
            ...this.getIconParams()
        };

        this.markerObj.setIcon(newIcon);

        if (isRedraw) {
            this.markerObj.setMap(null);
            this.markerObj.setMap(this.map);
        }
    };
    /**
     * Обновить состояние маркера
     * @param {Number} state - состояние
     * @param {Boolean} isRedraw - условие для перерендера
     */
    updateState(state = -1, isRedraw = false) {
        this.oldState = this.state;
        this.state = state;
        this.updateIcon(this.iconType, isRedraw);
    };
    clearMarker() {
        this.markerObj.setMap(null);
        this.isRender = false;
    };
    drawMarker() {
        this.markerObj.setMap(this.map);
        this.isRender = true;
    };

    afterCreate() {
        // если есть src то вызвать updateIcon
    }
};
