<template>
    <div class="toolbar-block">
        <control-panel-item
            v-for="(toolbarItem, index) in toolbarBlockItems"
            :key="index"
            :panelItem="toolbarItem"
            :isActive="false"
            @onClickPanelItem="onClickToolbarItem"
        />
    </div>
</template>

<script>
import ControlPanelItem from '../ControlPanelV2/ControlPanelItem';
export default {
    name: 'ToolbarBlock',
    components: {ControlPanelItem},
    /**
     * Входные данный компонента
     * @property {Object} toolbarBlock - блок тулбара
     */
    props: {
        toolbarBlock: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * Элементы блока тулбара
         * @returns {Array}
         */
        toolbarBlockItems() {
            const {items = []} = this.toolbarBlock;
            return items;
        }
    },
    methods: {
        /**
         * Событие клика по элементу тулбара
         * @param {string} toolbarItemId - идентификатор элемента тулбара
         */
        onClickToolbarItem(toolbarItemId = '') {
            this.$emit('onClickToolbarItem', toolbarItemId);
        },
    }
}
</script>

<style lang="scss" scoped>
    .toolbar-block {
        display: flex;
        margin-right: 8px;
    }
</style>