/* eslint-disable no-extra-semi */
import ServiceBaseMap from '../ServicesBase/ServiceBaseMap';
import ServiceGooglePolyline from './ServiceGooglePolyline';
import ServiceGoogleMarker from './ServiceGoogleMarker';
import ServiceGoogleClusterer from './ServiceGoogleClusterer';
import FactoryGoogleMarker from '../../factories/FactoriesGoogle/FactoryGoogleMarker';
import FactoryGoogleCircle from '../../factories/FactoriesGoogle/FactoryGoogleCircle';
import FactoryGooglePolygon from '../../factories/FactoriesGoogle/FactoryGooglePolygon';
import FactoryGoogleInfoWindow from '../../factories/FactoriesGoogle/FactoryGoogleInfoWindow';
import SchemeMap from '../../schemes/SchemeMap';
import SchemeRuler from '../../schemes/SchemeRuler';
const {mapStylesGoogle} = SchemeMap;
const {rulerPolylineParams, rulerMarkerIconParams, rulerMarkerLabelParams} = SchemeRuler;
const google = window.google;
/**
 * Сервис для работы с картой в гугл мап апи
 * @extends ServiceBaseMap
 */
export default class ServiceGoogleMap extends ServiceBaseMap {
    /**
     * Конструктор
     * @param {Object} params - параметры для карты
     */
    constructor(params) {
        super(params);
        this.mapStyles = mapStylesGoogle;
        this.trafficLayer = new google.maps.TrafficLayer();
        ServiceGoogleMap.disableInfoWindow();
    };
    /**
     * Отключит всплывающие окна на карте. Нужно чтобы у стандартных элементов не было всплывашек.
     * Если нужно подключить кастомные информационные окна, нужно установить свойство noSuppress в true
     * @static
     */
    static disableInfoWindow() {
        let set = google.maps.InfoWindow.prototype.set;
        google.maps.InfoWindow.prototype.set = function (key) {
            if (key === 'map' && ! this.get('noSuppress')) {
                console.warn('This InfoWindow is suppressed.');
                console.log('To enable it, set "noSuppress" option to true.');
                return;
            }
            set.apply(this, arguments);
        }
    };
    /**
     * Инициализировать карту
     * @param {String} renderDomElementId - идентификатор dom элемента в котором будет рендериться карта
     * @param {Object} mapParams - параметры для карты
     * @returns {Object}
     */
    initMap(renderDomElementId = 'map-wrapper', mapParams = {}) {
        this.map = new google.maps.Map(document.getElementById(renderDomElementId), {
            ...this.mapPramsDefault,
            ...mapParams,
        });
        return this.map;
    };
    /**
     * Масштабировать карту
     * @param {Array} coords - координаты в пределах которых масштабируем
     * @returns {Object}
     */
    scaleMap(coords = []) {
        const latLngCoords = coords.map(coord => {
            const {lat = '', lng = ''} = coord;
            const latLng = new google.maps.LatLng(lat, lng);
            return latLng;
        });
        if (latLngCoords.length > 1) {
            let bounds = new google.maps.LatLngBounds();
            latLngCoords.forEach((latLng) => {
                bounds.extend(latLng);
            });
            this.map.setCenter(bounds.getCenter());
            this.map.fitBounds(bounds);
        }
        else if (latLngCoords.length === 1) {
            this.map.setCenter(latLngCoords[0]);
            this.map.setZoom(10);
        }
        return this.map;
    };
    /**
     * Событие зумирования карты
     * @param {String} zoomType - тип зумирования (in|out)
     */
    zoomMap(zoomType = '') {
        const currentZoom = this.map.getZoom();
        const newZoom = zoomType === 'in' ? currentZoom + ServiceBaseMap.zoomChangeRange : currentZoom - ServiceBaseMap.zoomChangeRange
        this.map.setZoom(newZoom);
        return this.map;
    }
    /**
     * Включить панораму гугл мап апи
     * @param {String} renderDomElementId - идентификатор dom элемента в котором будет рендериться карта
     * @param {String} lat - широта
     * @param {String} lng - долгота
     * @param {Function} actionCloseClick - событие, сработающее при закрытии панорамы
     */
    setStreetView(renderDomElementId = 'map-wrapper', lat = null, lng = null, actionCloseClick = () => {}) {
        const streetViewCoords = {lat: parseFloat(lat), lng: parseFloat(lng)};
        const streetViewParams = {
            position: streetViewCoords,
            pov: {
                heading: 34,
                pitch: 10,
            },
            enableCloseButton: true
        }
        const streetView = new google.maps.StreetViewPanorama(
            document.getElementById(String(renderDomElementId)),
            streetViewParams
        );
        streetView.addListener('closeclick', () => {
            actionCloseClick();
        })
        this.map.setStreetView(streetView);
    }
    /**
     * Включить/выключить кластеризацию на карте
     * @param {Boolean} isClustering - значение флага кластеризации
     */
    setIsClustering(isClustering = false) {
        this.isClustering = isClustering;
        if (this.isClustering)
            this.initClusterer();
        else {
            this.destroyClusterer();
            this.updateMarkers((marker) => {
                const {extraData = {}, markerObj = {}, isRender = true} = marker;
                const {kitId = null} = extraData;
                if ((String(kitId) === "0" || String(kitId) === '-2') && isRender)
                    markerObj.setMap(this.map);
            });
        }
    }
    /**
     * Инициализировать создание полигона
     * @param {Object} params - параметры полигона
     * @param {Function} eventAfterInit - событие, которое сработает после создания полигона
     */
    initPolygon(params = {}, eventAfterInit = () => {}) {
        let polyline = new ServiceGooglePolyline({...params, map: this.map});
        const polylineDraw = this.map.addListener("click", (event) => {
            const {latLng = {}} = event;
            let path = polyline.polylineObj.getPath();
            path.push(latLng);
        });
        const polylineClick = polyline.polylineObj.addListener("click", (event) => {
            const {vertex = -1} = event;
            const paths = polyline.polylineObj.getPath().getArray();
            if (vertex === 0 && paths.length > 2) {
                const polygon = {
                    ...params,
                    paths: paths.map(path => {
                        let latLng = {lat: path.lat(), lng: path.lng()};
                        return latLng;
                    })
                };
                this.destroyInitPolygon();
                eventAfterInit(polygon);
            }
        });
        this.polygonInitData = {isInit: true, polyline, polylineDraw, polylineClick};
    };
    /**
     * Инициализировать создание окружности
     * @param {Object} params - параметры окружности
     * @param {Function} eventAfterInit - событие, которое сработает после создания окружности
     */
    initCircle(params = {}, eventAfterInit = () => {}) {
        const {centerLat = '', centerLng = ''} = params;
        const infoWindow = FactoryGoogleInfoWindow.createInfoWindow({}, 'infoWindowRadius');
        let circle = {...params};
        const circleSetCenter = this.map.addListener("click", (event) => {
            const {latLng = {}} = event;
            circle = {...circle, centerLat: latLng.lat(), centerLng: latLng.lng()};
            infoWindow.open(latLng, this.map);
        });
        const circleInfoWindow = infoWindow.infoWindowObj.addListener('domready', () => {
            document.getElementById("js-set-radius").addEventListener('click', () => {
                let radius = document.getElementById('radius-value').value;
                if (radius === '') {
                    // alert('Заполните все поля');

                    this.$notify.dNotify({
                        type: 'warning',
                        title: '',
                        description: 'Заполните все поля',
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }
                else {
                    radius = Number(radius) * 1000;
                    circle = {...circle, radius};
                    eventAfterInit(circle);
                }
            });
        });
        if (centerLat !== '' && centerLng !== '') {
            infoWindow.open({lat: parseFloat(centerLat), lng: parseFloat(centerLng)}, this.map);
        }
        this.circleInitData = {isInit: true, infoWindow, circleSetCenter, circleInfoWindow};
    };
    /**
     * Инициализировать окно выбора сторон
     * @param {Object} params - параметры окружности
     * @param {Function} eventAfterInit - событие, которое сработает после создания окружности
     */
    initSideSelectionBox(params = {}, eventAfterInit = () => {}) {
        const {centerLat = '', centerLng = '', sides = []} = params;
        const infoWindow = FactoryGoogleInfoWindow.createInfoWindow(
            {},
            'infoWindowSideSelection',
            FactoryGoogleInfoWindow.generateCheckboxList('js-side-checkbox', sides)
        );
        infoWindow.open(new google.maps.LatLng(centerLat, centerLng), this.map);
        let selectionInfoWindow = infoWindow.infoWindowObj.addListener('domready', () => {
            let elements = document.getElementsByClassName("js-side-checkbox");
            Object.keys(elements).forEach(elementKey => {
                elements[elementKey].addEventListener('click', (element) => {
                    eventAfterInit({
                        sideIds: [element.target.value],
                        state: element.target.checked ? 0 : 1
                    });
                });
            });
            document.getElementById("js-side-select-all").addEventListener('click', () => {
                let elements = document.getElementsByClassName("js-side-checkbox");
                let sideIds = [];
                Object.keys(elements).forEach(elementKey => {
                    sideIds.push(elements[elementKey].value);
                    elements[elementKey].checked = true;
                });
                eventAfterInit({
                    sideIds: sideIds,
                    state: 0
                });
            });
            document.getElementById("js-side-clear-all").addEventListener('click', () => {
                let elements = document.getElementsByClassName("js-side-checkbox");
                let sideIds = [];
                Object.keys(elements).forEach(elementKey => {
                    sideIds.push(elements[elementKey].value);
                    elements[elementKey].checked = false;
                });
                eventAfterInit({
                    sideIds: sideIds,
                    state: 1
                });
            });
        });
        this.sideSelectionInitData = {isInit: true, infoWindow, selectionInfoWindow};
    };

    /**
     * Инициализировать информационное окно для пои
     * @param {Object} coordinates - координаты точки пои
     * @param {Object} poiInfo - координаты точки пои
     */
    initWindowInfoPoi(coordinates, poiInfo) {
        const {lat = '', lng = ''} = coordinates;
        const selectionInfoWindow = () => {};
        const infoWindow = FactoryGoogleInfoWindow.createInfoWindow(
            {},
            'infoWindowPoi',
            FactoryGoogleInfoWindow.generatePoiInfo(poiInfo)
        );
        infoWindow.open(new google.maps.LatLng(lat, lng), this.map);
        this.sideSelectionInitData = {isInit: true, infoWindow, selectionInfoWindow};
    };

    /**
     * Инициализировать линейку
     */
    initRuler() {
        let polyline = new ServiceGooglePolyline({...rulerPolylineParams, map: this.map});
        let rulerMarkers = [];
        const polylineDraw = this.map.addListener("click", (event) => {
            let path = polyline.polylineObj.getPath();
            if (path.length === 2) {
                this.destroyInitRuler();
                this.initRuler();
            }
            else if (path.length < 2) {
                const {latLng = {}} = event;
                path.push(latLng);
                let rulerMarker = new ServiceGoogleMarker({
                    icon: {
                        ...rulerMarkerIconParams,
                        path: google.maps.SymbolPath.CIRCLE,
                        labelOrigin: new google.maps.Point(5, 5)
                    },
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                    map: this.map
                });
                if (path.length === 2) {
                    rulerMarker.markerObj.setLabel({
                        ...rulerMarkerLabelParams,
                        text: this.getLengthRuler(path)
                    });
                }
                rulerMarkers.push(rulerMarker);
                this.rulerInitData = {...this.rulerInitData, rulerMarkers};
            }
        });
        this.rulerInitData = {isInit: true, polyline, polylineDraw};
    };
    /**
     * Инициализировать кластеризацию
     */
    initClusterer() {
        if (this.markers.length > 1500 && this.isClustering) {
            this.clusterer = new ServiceGoogleClusterer({map: this.map, markers: this.markers});
        }
    }
    /**
     * Удалить текущий инициализированный полигон
     */
    destroyInitPolygon() {
        const {isInit = false, polyline = {}, polylineDraw = () => {}, polylineClick = () => {}} = this.polygonInitData;
        if (isInit) {
            google.maps.event.removeListener(polylineDraw);
            google.maps.event.removeListener(polylineClick);
            polyline.polylineObj.setMap(null);
            this.polygonInitData = {isInit: false, polyline: null, polylineDraw: () => {}, polylineClick: () => {}};
        }
    };
    /**
     * Удалить текущую инициализируемое окно выбора стороны
     */
    destroySelectionSideInfo() {
        const {isInit = false, infoWindow = {}, selectionInfoWindow = () => {}} = this.sideSelectionInitData;
        if (isInit) {
            google.maps.event.removeListener(selectionInfoWindow);
            infoWindow.infoWindowObj.close();
            this.sideSelectionInitData = {isInit: false, infoWindow: {}, selectionInfoWindow: () => {}};
        }
    };

    /**
     * Удалить информационное окно пои
     */
    destroyWindowInfoPoi() {
        const {isInit = false, infoWindow = {}} = this.sideSelectionInitData;
        if (isInit) {
            infoWindow.infoWindowObj.close();
            this.sideSelectionInitData = {isInit: false, infoWindow: {}, selectionInfoWindow: () => {}};
        }
    };

    /**
     * Удалить текущую инициализируемую окружность
     */
    destroyInitCircle() {
        const {isInit = false, infoWindow = {}, circleSetCenter = () => {}, circleInfoWindow = () => {}} = this.circleInitData;
        if (isInit) {
            google.maps.event.removeListener(circleSetCenter);
            google.maps.event.removeListener(circleInfoWindow);
            infoWindow.infoWindowObj.close();
            this.circleInitData = {isInit: false, infoWindow: {}, circleSetCenter: () => {}, circleInfoWindow: () => {}};
        }
    };
    /**
     * Удалить инициализируемую линейку
     */
    destroyInitRuler() {
        const {isInit = false, polyline = {}, polylineDraw = () => {}, rulerMarkers = []} = this.rulerInitData;
        if (isInit) {
            google.maps.event.removeListener(polylineDraw);
            polyline.polylineObj.setMap(null);
            rulerMarkers.forEach(marker => {
                marker.markerObj.setMap(null);
            });
            this.rulerInitData = {isInit: false, polyline: null, polylineDraw: () => {}, rulerMarkers: []};
        }
    };
    /**
     * Удалить кластера
     */
    destroyClusterer() {
        if (this.clusterer !== null) {
            this.clusterer.destroyClusterer();
            this.clusterer = null;
        }
    }
    /**
     * Получить длину полилинии
     * @param {Array} path - путь
     * @returns {String}
     */
    getLengthRuler(path = []) {
        const length = google.maps.geometry.spherical.computeLength(path);
        const lengthKm = length / 1000;
        const lengthFixed = lengthKm.toFixed(1) > 0.0 ? `${lengthKm.toFixed(1)} км` : `${length.toFixed(1)} м`;
        return lengthFixed;
    };
    /**
     * Установить слой с автомобильным/пешеходным трафиком
     * @param {String} type - тип траффика
     * @param {Boolean} active - активность
     * @returns {Object}
     */
    setBestPlaceTraffic(type = 'auto', active = false) {
        this.map.overlayMapTypes.pop();
        if (active) {
            const imageMapType = new google.maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    return [
                        this.tileUrl,
                        type === 'auto' ? 0 : 1,
                        zoom,
                        coord.x,
                        coord.x + '_' + coord.y + '.png'
                    ].join('/');
                },
                tileSize: new google.maps.Size(256, 256)
            });
            this.map.overlayMapTypes.push(imageMapType);
        }
        return this.map;
    };
    /**
     * Установить слой с автомобильными пробками
     * @param {Boolean} active - активность
     */
    setJamsTraffic(active = false) {
        if (active) {
            this.trafficLayer.setMap(this.map);
        } else {
            this.trafficLayer.setMap(null);
        }
    };
    /**
     * Установить цвет карты
     * @param {String} styleMap - стиль гугл карты
     * @returns {Object}
     */
    setColorMap(mapStyle = 'default') {
        this.map.setOptions({styles: this.mapStyles[mapStyle]});
        return this.map;
    };
    /**
     * Установить направления для маркеров
     * @param {Boolean} active - активность
     */
    setDirection(active = false) {
        const iconType = active ? 'direction' : 'default'
        let markersOpn = this.markers.filter(marker => marker.mode === 'opn');
        markersOpn.forEach(marker => marker.updateIcon(iconType));
    };

    setMarkerIconType(markerIconType = 'default', actionFilter = () => {}) {
        this.updateMarkers((marker) => {
            if (actionFilter(marker))
                marker.updateIcon(markerIconType, marker.isRender);
        }, true);
    }

    /**
     * @param {Object} marker - активность
     * @param {Boolean} isFocus - фокусировка
     */
    setMarkerClicked(marker, isFocus= false) {
        let foundMarkers = this.markers.filter((markerFound) => {
            return markerFound.state === 2;
        });
        foundMarkers.forEach(foundMarker => {
            foundMarker.updateState(foundMarker.oldState);
        })
        if(isFocus){
            let latLng = new google.maps.LatLng({lat: marker.lat, lng: marker.lng});
            this.map.setCenter(latLng);
        }
        marker.updateState(2);
        this.markerClicked = marker;
    }
    unsetMarkerClicked() {
        if(this.markerClicked.state === 2){
            this.markerClicked.updateState(this.markerClicked.oldState);
        }
        this.markerClicked = null;
    }
    /**
     * Нарисовать массив маркеров
     * @param {Array} markers - маркеры
     * @returns {Array}
     */
    drawMarkers(markers = []) {
        if (this.clusterer !== null)
            this.destroyClusterer();
        this.markers.forEach(marker => marker.markerObj.setMap(null));
        this.markers = [];
        this.markers = FactoryGoogleMarker.createMarkers(markers, this.map, this.markerClickEvent, true);
        if (this.markers.length > 1500 && this.isClustering)
            this.initClusterer();
        return this.markers;
    };
    /**
     * Перерисовать маркеры
     * @param {Function} actionFilter - событие для фильтрации маркеров которые нужно стереть с карты
     * @param {Array} markers - массив маркеров
     * @param {Boolean} isRender -
     * @param {Boolean} isUpdateClustering - условие обновления кластера
     * @returns {Array}
     */
    redrawMarkers(actionFilter = () => {}, markers = [], isRender = true, isUpdateClustering = true) {
        if (this.clusterer !== null && isUpdateClustering) {
            this.destroyClusterer();
        }
        const markersNoRemove = this.markers.filter(marker => !actionFilter(marker));
        const markersRemove =  this.markers.filter(marker => actionFilter(marker));
        this.markers = [...markersNoRemove];
        markersRemove.forEach(marker => marker.markerObj.setMap(null));
        const markersNew = FactoryGoogleMarker.createMarkers(markers, this.map, this.markerClickEvent, isRender);
        this.markers.push(...markersNew);
        if (this.markers.length > 1500 && isUpdateClustering && this.isClustering)
            this.initClusterer();
        return this.markers;
    };
    /**
     * Обновить маркеры
     * @param {Function} actionUpdate - функция обновления
     */
    updateMarkers(actionUpdate = () => {}, isUpdateClustering = true) {
        if (this.clusterer !== null && isUpdateClustering) {
            this.destroyClusterer();
        }
        this.markers.forEach(marker => {
            actionUpdate(marker);
        });
        if (this.markers.length > 1500 && isUpdateClustering && this.isClustering)
            this.initClusterer();
    };
    /**
     * Нарисовать массив окружностей
     * @param {Array} circles - окружности
     */
    drawCircles(circles = []) {
        this.circles.forEach(circle => circle.circleObj.setMap(null));
        this.circles = [];
        this.circles = FactoryGoogleCircle.createCircles(circles, this.map);
    };
    /**
     * Нарисовать массив полигонов
     * @param {Array} polygons - полигоны
     */
    drawPolygons(polygons = []) {
        this.polygons.forEach(polygon => polygon.polygonObj.setMap(null));
        this.polygons = [];
        this.polygons = FactoryGooglePolygon.createPolygons(polygons, this.map);
    };
    /**
     * Отфильтровать маркеры по вхождению внутрь полигона
     * @param {Object} polygon - полигон
     * @returns {Array}
     */
    containsLocation(polygon = {}) {
        const {paths = []} = polygon;
        const polygonObj = new google.maps.Polygon({paths});
        const markersContainsLocation = this.markers.filter(marker => {
            const latLng = marker.markerObj.getPosition();
            return google.maps.geometry.poly.containsLocation(latLng, polygonObj);
        });
        return markersContainsLocation;
    };
    /**
     * Масштабировать карту
     */
    fitBounds() {
        const coords = this.markers.filter((marker) => {
            const {extraData = {}} = marker;
            const {kitId = ''} = extraData;
            return String(kitId) === '0';
        }).map(marker => {
            const {markerObj = {}} = marker;
            const latLng = markerObj.getPosition();
            return {lat: latLng.lat(), lng: latLng.lng()};
        });
        this.scaleMap(coords);
    };
};
