<template>
    <div
        v-bind="$attrs"
        :class="getClasses"
        :is="role"
        class="btn"
        @click="$emit('click')"
    >
        <div v-if="isLoading" class="btn__loader">
            <BasePreloader :size="20" class="btn__loader-icon"/>
        </div>
        <slot/>
    </div>
</template>

<script>
import BasePreloader from "@/components/Base/BasePreloader.vue";

export default {
    name: "BaseButton",
    components: {BasePreloader},
    props: {
        view: {
            type: String,
            default: 'primary',
            validator: value => ['primary', 'secondary', 'success', 'cancel', 'border', 'simple', 'green-border', 'third'].includes(value)
        },
        form: {
            type: String,
            default: 'square',
            validator: value => ['oval', 'square'].includes(value)
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        role: {
            type: String,
            default: 'button'
        },
        size: {
            type: String,
            default: 'normal',
            validator: value => ['small', 'normal', 'large', 'extra-large'].includes(value)
        },
    },
    computed: {
        getClasses() {
            return [
                `btn-${this.view}`,
                `btn_${this.form}`,
                `btn_${this.size}`,
                {'btn_active': this.active},
                {'btn_disabled': this.disabled},
                {'btn_loading': this.isLoading}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: #F0F3F8 !default;
$primary-lighten-color: #F9FAFB !default;
$primary-disabled-color: #DCE1E8 !default;
$assistant-color: #ADB0BB !default;
$active-color: #4A92F6 !default;
$active-darker-color: #1f79d2 !default;
$danger-color: #F84967 !default;
$success-color: #6EC87A !default;
$success-lighten-color: #C5E9CA !default;
$reserved-color: #FFB300 !default;

$color-text--third: #FFFFFF !default;
$color-background--third: #F70068 !default;
$color-border--third: #F70068 !default;
$color-background--third-hover: #C70054 !default;
$color-border--third-hover: #C70054 !default;

.btn {
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    text-align: center;
    font: inherit;
    font-weight: 700;
    transition: .2s;
    font-size: 12px;
    padding: 10px 20px;
    white-space: nowrap;

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: inherit;
        &-icon {
            display: flex;
            ::v-deep path {
                fill: currentColor;
            }
        }
    }

    &_loading {
        cursor: not-allowed;
        pointer-events: none;
    }

    &_extra-large {
        padding: 18px 40px;
        font-size: 18px;
        line-height: 1;
    }

    &_large {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        padding: 11px 40px;
    }

    &_oval {
        border-radius: 1000px;
    }

    &_square {
        // border-radius: 4px;
        border-radius: 10px;
    }

    &_disabled {
        cursor: initial;
        pointer-events: none;
    }

    &-primary {
        // background: $primary-color;
        background: #F4F5F5;

        &:hover {
            background: $primary-lighten-color;
        }

        &.btn_active {
            background: #fff;
            border: 1px solid $primary-color;
        }
    }

    &-secondary {
        background: $active-color;
        color: #fff;

        &:hover {
            background: $active-darker-color;
        }

        &.btn_disabled {
            background: #bdc0c3;

            &:hover {
                background: #bdc0c3;
            }
        }
    }

    &-success {
        background: #6BCC9C;
        border-radius: 1000px;
        color: #fff;
    }

    &-cancel {
        background: transparent;
        border: 1px solid $assistant-color;
        color: $assistant-color;
        border-radius: 1000px;

        &:hover {
            color: $active-color;
        }
    }

    &-border {
        background: transparent;
        border: 1px solid $primary-color;

        &:hover {
            background: $primary-color;
        }
    }

    &-simple {
        color: $assistant-color;
        padding: 0;

        &:hover {
            color: $active-color;
        }
    }

    &-green-border {
        background-color: white;
        border: 1px solid $success-color;
    }

    &-third {
        color: $color-text--third;
        background-color: $color-background--third;
        border: 1px solid $color-border--third;
        transition: all 0.15s ease-in-out;

        &:hover {
            background-color: $color-background--third-hover;
            border-color: $color-border--third-hover;
        }
    }
}
</style>
