<template>
	<div class="mini-table">
		<div
			v-for="(item, index) of tableData"
			:key="index"
			class="row"
		>
			<div v-if="!isLink(item)" v-html="item.key" class="row__key" />
            <a
                v-else
                class="row__key underlined-link"
                :href="item.link"
                target="_blank"
            >
                {{ item.key }}
            </a>
			<div v-if="!isLink(item)" v-html="getValueHtml(item)" class="row__value" />
		</div>
	</div>
</template>

<script>
export default {
	name: "MiniTAble",
	props: {
		tableData: {
			type: Array,
			default: () => []
		}
	},
    methods: {
        getValueHtml(item) {
            const {value = ""} = item;
            return value;
        },

        isLink(item) {
            const {link = null} = item;
            return link !== null;
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.mini-table {
	.row {
		display: flex;
		justify-content: space-between;
		font-size: 10px;
		padding-bottom: 5px;
		color: $assistant-color;

		&:not(:last-child) {
			border-bottom: 1px solid $primary-color;
			margin-bottom: 8px;
		}

		&__value {
			text-align: right;
		}
	}
    .underlined-link {
        text-decoration: underline;
    }
}
</style>
