<template>
    <div
        class="cap"
        :class="getRootClasses"
    >
        <div class="cap__left">
            <div
                v-if="hasLogo"
                class="logo"
            >
                <!-- <img width="70" height="70" src="@/assets/images/logo-sv-min.svg"> -->
                <img width="70" height="70" src="@/assets/images/OohdeskBUYER.svg">
            </div>
            <BaseButton
                class="cap__humburger-wrap"
                :active="isHumburgerActive"
                @click="onHumburger"
            >
                <BaseHumburger
                    :isActive="isHumburgerActive"
                    class="cap__humburger"
                />
            </BaseButton>
            <div class="cap__title-wrap">
                <p class="cap__title">{{ title }}</p>
            </div>
        </div>

        <div class="cap__center">
            <slot />
        </div>

        <div class="cap__right">
            <div class="cap__panel">
<!--                <div>-->
<!--                    <router-link to="/help">-->
<!--                        <BaseIcon icon="icon-help" class="cap__notifications"/>-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <a href="https://map.oohdesk.ru/notify" class="cap__notifications">-->
<!--                    <BaseIcon icon="icon-bell" />-->
<!--                </a>-->

                <History></History>

                <BaseDropdown
                    class="cap-auth-dropdown"
                    :padding="0"
                    @changeVisible="onAuthDropdownChangeVisible"
                >
                    <template #preview>
                        <BaseButton
                            class="cap-auth__wrap"
                            :active="isAuthDropdownVisible"
                        >ДК</BaseButton>
                    </template>

                    <template #content>
                        <div class="cap-auth-dropdown__wrapper">
                            <div class="cap-auth-dropdown__header">
                                <p class="cap-auth-dropdown__name">{{company}}</p>
                                <p class="cap-auth-dropdown__login">{{email}}</p>
                            </div>
                            <div class="cap-auth-dropdown__options">
                                <div
                                    v-for="(item, index) of dropdownItems"
                                    :key="index"
                                    class="cap-auth-dropdown__option"
                                >
                                    <span>{{item.value}}</span>
                                </div>
                            </div>
                            <div class="cap-auth-dropdown__unique">
                                <BaseButton
                                    view="simple"
                                    @click="logout"
                                >Выход</BaseButton>
                            </div>
                        </div>
                    </template>
                </BaseDropdown>
            </div>
        </div>
    </div>
</template>

<script>
import BaseHumburger from '@/components/Base/BaseHumburger'
// import BaseIcon from '@/components/Base/BaseIcon'
import BaseDropdown from '@/components/Base/BaseDropdown'
import BaseButton from '@/components/Base/BaseButton'
import History from '@/components/History/History'
import ServiceUser from '@/services/ServicesAuth/ServiceUser';
import ServiceAuth from '@/services/ServicesAuth/ServiceAuth';
export default {
    name: 'DefaultLayoutCap',
    components: {
        BaseHumburger,
        // BaseIcon,
        BaseDropdown,
        BaseButton,
        History
    },
    props: {
        isHumburgerActive: {
            type: Boolean,
            required: true
        },
        view: {
            type: String,
            default: 'base',
            validator: val => ['base', 'transparent'].includes(val)
        },
        title: {
            type: String,
            default: ''
        },
        hasLogo: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dropdownItems: [
                // {value: 'Мой профиль'},
                // {value: 'Права доступа'},
                // {value: 'Менеджер ссылок'},
                // {value: 'Настройки'},
                // {value: 'Выход'},
            ],
            isAuthDropdownVisible: false,
            profile: {
                company: 'ГК «А101»',
                email: 'A101@oohdesk.ru'
            }
        }
    },
    computed: {
        getRootClasses() {
            return [`cap_${this.view}`]
        },
        email() {
            const {email = ''} = this.profile;
            return String(email);
        },
        company() {
            const {company = ''} = this.profile;
            return String(company);
        }
    },
    mounted() {
        const {currentRoute = {}} = this.$router;
        const {name = ''} = currentRoute;
        if (name === 'bayuerdeskV2' || name === 'price')
            this.profile = ServiceUser.profile;
    },
    methods: {
        onHumburger() {
            this.$emit('onHumburger')
        },
        onFilter() {
            this.$emit('onFilter')
        },
        onAuthDropdownChangeVisible(boolean) {
            this.isAuthDropdownVisible = boolean
        },
        async logout() {
            const {currentRoute = {}} = this.$router;
            const {name = ''} = currentRoute;
            if (name === 'bayuerdeskV2' || name === 'price')
                await ServiceAuth.logout();
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F5F5F5 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;


.cap {
    display: grid;
    grid-template-columns: 298px 1fr 107px;
    grid-template-rows: 70px;
    align-items: center;
    box-sizing: border-box;

    .logo {
        margin-right: 20px;
        img {
            width: 30px;
            display: block;
        }
    }

    &_base {
        background: #fff;
        border-bottom: 1px solid #e1e1e1;

        .cap__humburger-wrap {
            border-right: 1px solid #e1e1e1;
            padding-left: 25px;
            padding-right: 25px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .cap__title-wrap {
            border-right: 1px solid #e1e1e1;
            padding: 0 42px;
        }

        .cap__notifications:hover {
            background: #f3f3f3;
        }
    }

    &_transparent {
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #F2F4F5;
        .cap__humburger-wrap {
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            box-shadow: 0px 3px 5px #00000003;
            text-align: left;
            padding: 0px;
        }

        .cap__title-wrap {
            padding: 0 21px;
        }

        .cap__notifications:hover {
            background: #fff;
        }
    }

    &__left {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__center {
        width: 100%;
        padding-left: 20px;
        position: relative;
    }

    &__title-wrap {
        height: 100%;
        display: flex;
        align-items: center;
    }
    &__title {
        line-height: 1;
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
    }

    &__right {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &__panel {
        display: flex;
        align-items: center;
    }

    &__notifications {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 5px;
        transition: .2s;

        &:hover {
            background: $primary-color !important;
        }
    }

    &-auth {
        &__wrap {
            width: 40px;
            height: 40px;
            margin-left: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: $primary-color;
            color: #000;
            font-weight: 700;

            &.btn_active {
                border-color: #6EC87A;
            }
        }

        &-dropdown {
            // z-index: 9;

            &__wrapper {
                padding-bottom: 30px;
            }

            &__header {
                padding: 25px;
                padding-bottom: 0px;
            }

            &__name {
                font-weight: 700;
                font-size: 14px;
                margin-bottom: 2px;
            }
            &__login {
                font-size: 12px;
                color: $assistant-color;
                font-weight: 500;
            }

            &__options {
                padding: 10px;
            }

            &__option {
                position: relative;
                display: flex;
                align-items: center;
                padding: 8px 15px;
                height: 36px;
                border-radius: 36px;
                width: 100%;
                white-space: nowrap;
                line-height: 1;
                transition: all 150ms;
                cursor: pointer;
                font-size: 12px;
                // margin: 4px 0;

                &:hover {
                    background: #f3f3f3;
                }
            }

            &__unique {
                display: flex;
                justify-content: flex-end;
                padding-right: 40px;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: 298px 107px;
        grid-template-rows: repeat(2, minmax(70px, 1fr));
        justify-content: space-between;

        &__center {
            order: 3;
            grid-column: 1 / 3;
            padding: 15px 0;
        }
    }

    @media screen and (max-width: 577px) {
        grid-template-columns: 258px 107px;
        .cap_transparent &__humburger-wrap {
            display: none;
        }
    }
}
</style>
