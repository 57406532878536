<template>
    <div class="layout">
        <div class="layout__main" :style="{overflow: layoutOverflow}">
            <Cap
                class="layout__cap"
                :title="capTitle"
                :logo-src="capLogoSrc"
                :logoSize="capLogoSize"
                view="transparent"
                :config="capConfig"
                :user-menu-data="profile"
                :historyItems="historyItems"
                :isShowAppMenu="isMenuOpen"
                :menuItems="menuItems"
                :zIndexStep="folderQueue.length"
                @onHamburger="onHamburger"
                @onHistoryDropdownChangeVisible="historyDropdownChangeVisible"
                @onUserMenuLogout="logout"
                @prev="onPrev"
            />
            <div
                class="layout__content"
                :class="{'layout__content_reconciliation': isReconciliationMode}"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import ServiceAuth from "@/services/ServicesAuth/ServiceAuth";
import MixiHistory from "@/mixins/MixiHistory";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import { mapGetters } from "vuex";

const RECONCILIATION_PAGES = ['reconciliationKits', 'reconciliationOffers', 'reconciliationApproved', 'reconciliationMore', 'reconciliationFact']
const CLOUD_IMPORT_PAGES = ['cloudImport', 'cloudImportPreview', 'cloudImportEditSettings', 'cloudImportLogs', 'cloudImportLog'];
const BUYER_PAGES = ['bayuerdeskV2', 'price'];
const CATALOG_PREVIEW_PAGE = 'catalogPreview';

export default {
    name: 'DefaultLayout',
    components: {},
    mixins: [MixiHistory, MixinApi],

    data() {
        /**
         * Данные компонента
         * @param {Boolean} isMenuOpen - Флаг открыто/закрыто App Menu
         * @param {citiesList} citiesList - Список городов
         * @param {Object} profile - Профиль пользователя
         */
        return {
            isMenuOpen: false,
            citiesList: [],
            profile: {},
            isReconciliationMode: false,
            menuItems: [
                {
                    style: {
                        backgroundColor: '#f8c7dd'
                    },
                    title: 'Buyerdesk',
                    links: [
                        {
                            label: 'Подбор предложений',
                            path: '/buyerdesk'
                        },
                        {
                            label: 'Наборы',
                            path: '/reconciliation/kits'
                        },
                        {
                            label: 'Отправленые предложения',
                            path: '/reconciliation/offers'
                        },
                        {
                            label: 'Предфакт',
                            path: '/reconciliation/approved'
                        },
                        {
                            label: 'Факт',
                            path: '/reconciliation/fact'
                        },
                    ]
                },
            ],
            capLogoSize: {
                width: '150',
                height: '100'
            }
        }
    },

    mounted() {
        if (
            BUYER_PAGES.includes(this.currentRoute)
            || RECONCILIATION_PAGES.includes(this.currentRoute)
            || CLOUD_IMPORT_PAGES.includes(this.currentRoute)
        )
            this.profile = ServiceUser.profile;
        if (RECONCILIATION_PAGES.includes(this.currentRoute))
            this.isReconciliationMode = true;
    },

    computed: {
        ...mapGetters('kitsStore', ['kitsList']),
        ...mapGetters('folders', ['folderQueue']),
        capTitle() {
            if (this.currentRoute === 'price') {
                const kit = this.kitsList.find(kit => String(kit?.id ?? '') === String(this.$route?.query?.setId ?? '')) ?? {};
                return String(kit?.name ?? '');
            } else
                return String(this.$route?.meta?.title ?? 'Oohdesk');
        },
        currentRoute() {
            const {currentRoute = {}} = this.$router;
            const {name = ''} = currentRoute;
            return name
        },

        layoutOverflow() {
            // if (RECONCILIATION_PAGES.includes(this.currentRoute)) {
            //     return 'auto'
            // }
            return 'hidden'
        },

        capConfig() {
            if (this.currentRoute === CATALOG_PREVIEW_PAGE)
                return {
                    isConfigPrev: false,
                    isConfigHamburger: false,
                    isConfigStatus: false,
                    isConfigNotification: false,
                    isConfigSettings: false,
                    isConfigUserMenu: false,
                }
            else
                return {
                    isConfigPrev: true,
                    isConfigHamburger: false,
                    isConfigStatus: false,
                    isConfigNotification: false,
                    isConfigSettings: true,
                };
        },

        capLogoSrc() {
            // return require('@/assets/images/logo-sv-min.svg');
            return require('@/assets/images/OohdeskBUYER.svg');
        }
    },

    methods: {
        onHamburger(isShow) {
            if (this.currentRoute !== CATALOG_PREVIEW_PAGE)
                this.isMenuOpen = isShow;
        },

        // onHamburger() {},

        async logout() {
            const {currentRoute = {}} = this.$router;
            const {name = ''} = currentRoute;
            if (
                BUYER_PAGES.includes(name)
                || RECONCILIATION_PAGES.includes(name)
                || CLOUD_IMPORT_PAGES.includes(name)
            )
                await ServiceAuth.logout();
        },

        historyDropdownChangeVisible(isShow) {
            if(isShow && this.historyItems.length === 0){
                this.postHistory({}, this.afterPostHistory);
            }
        },

        onPrev() {
            if(CLOUD_IMPORT_PAGES.includes(this.currentRoute)){
                this.$router.push({ name: CLOUD_IMPORT_PAGES[0] });
            }else{
                this.$router.go(-1);
            }
            // this.$router.push({ name: 'bayuerdeskV2' });

        }
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow-x: hidden !important;
        display: flex;
        flex-direction: column;
    }

    &__content {
        position: relative;
        //z-index: 100;
        width: 100%;
        height: calc(100% - 71px);
        background: #fff;
        //overflow: hidden;
        // padding: 10px 40px 40px 40px;

        &_reconciliation {
            // background: #FFFFFF;
            background: #FAFAFA;
            // height: auto;
        }
    }
}
</style>
