<template>
    <div class="home">
        <BaseSlider
            :curMin="10"
            :curMax="10"
            :min="10"
            :max="10"
            :disabled="10 === 10"
            @change="onChange"
        />
    </div>
</template>

<script>
import BaseSlider from "../components/Base/BaseRangeSlider"

export default {
    name: 'Home',
    components: {
        BaseSlider
    },
    methods: {
        onChange(event) {
            console.log(event);
        }
    }
}
</script>

<style>
.ss {
    width: 10px;
    color: red;
}
</style>