<template>
    <app-login-v2 :responseMessage="responseLogin" @onLogin="onLogin"/>
</template>

<script>
import AppLoginV2 from "@/layouts/components/LoginV2";
import ServiceAuth from "../services/ServicesAuth/ServiceAuth";

export default {
    name: "LayoutLoginV2",
    components: {
        AppLoginV2,
    },
    data: () => ({
        responseLogin: null,
    }),
    methods: {
        async onLogin(login = '', password = '') {
            this.responseLogin = '';

            await ServiceAuth.login(
                login,
                password,
                () => {
                    this.$router.push({name: 'bayuerdeskV2'});
                },
                (error) => {
                    if (error.response.status === 500) {
                        this.responseLogin = 'Произошла ошибка. Попробуйте снова';
                    } else {
                        this.responseLogin = 'Email или пароль указаны неверно';
                    }
                },
            );
        }
    }
}
</script>

<style scoped>

</style>
