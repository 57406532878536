<template>
    <div>
        <div class="select-wrapper" v-for="select in selects" :key="select.id">
            <div
                style="
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 5px;
                "
            >
                {{ select.label }}
            </div>
            <CheckFileDataSelect
                :default-title="select.value || select.placeholder"
                :options="select.options"
                :is-can-remove="isCanRemove(select.options, select.id)"
                is-close-after-check
                is-full-width
                @check="
                    (option) =>
                        $emit('onSelectAction', { ...option, id: select.id })
                "
                @removeChecked="
                    (option) =>
                        $emit('onRemoveChecked', { ...option, id: select.id })
                "
            />
        </div>
    </div>
</template>

<script>
import CheckFileDataSelect from "./CheckFileDataSelect";

export default {
    components: {
        CheckFileDataSelect,
    },

    props: {
        selects: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        isCanRemove(options, selectId) {
            return options.every((option) => option.id === selectId);
        },
    },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
