export default {
    /**
     * @property {Boolean} isLoadingExport - статус экспорта
     * @property {Boolean} isExporting - флаг экспорта
     * @property {Array} userTemplates - шаблоны пользователя
     * @property {Object} currentTemplate - выбранный шаблон пользователя
     * @property {Number} exportingSetId - id экспортируемого набора
     */
    data: () => ({
        isLoadingExport: false,
        isExporting: false,
        userTemplates: [],
        currentTemplate: {},
        exportingSetId: null
    }),
    methods: {
        /**
         * Действие после сохранения шаблона
         * @param toSaveTemplate
         * @param savedTemplate
         */
        afterPostTemplateSave(toSaveTemplate, savedTemplate){
            let userTemplates = [...this.userTemplates];
            this.currentTemplate = Object.assign({}, savedTemplate);
            if(!toSaveTemplate.id){
                userTemplates.push(savedTemplate);
            }else {
                userTemplates.forEach(item => {
                    if(item.id == toSaveTemplate.id){
                        item.name = savedTemplate.name;
                        item.data = savedTemplate.data;
                    }
                });
            }
            this.userTemplates = [...userTemplates];
        },
        /**
         * Действие после удаления шаблона
         */
        afterPostTemplateRemove(){
            const indexRemove = this.userTemplates.findIndex(item => item.id === this.currentTemplate.id);
            this.userTemplates.splice(indexRemove, 1);
            this.currentTemplate = this.userTemplates[this.userTemplates.length - 1]
        },
        /**
         * Действие после получения шаблонов
         * @param templates
         */
        afterPostTemplateList(templates){
            let activeIndex = templates.findIndex(item => item.is_last_used === 1);
           if(activeIndex === -1){
               activeIndex = templates.length - 1;
           }
            this.userTemplates = templates;
            this.currentTemplate = Object.assign({}, templates[activeIndex]);
        },
        /**
         * Выбор шаблона
         * @param id
         */
        onCheckTemplate(id){
            this.currentTemplate = Object.assign({}, this.userTemplates.find(item => item.id == id));
        },
        /**
         * Открытие окна экспорта
         */
        onExportFolder(setId) {
            this.exportingSetId = setId;
            this.isExporting = true;
        },
        /**
         * Закрытие окна экспорта
         */
        onCloseExportFolder(){
            this.exportingSetId = null;
            this.isExporting = false;
        },
        /**
         * Событие после экспорта
         * @param link
         */
        afterPostExportKit(link){
            window.open(link);
        },
    }
};
