<template>
    <div class="wrapper">
        <div
            class="item"
            :style="itemData.style"
        >
            <p class="item__title">{{itemData.title}}</p>
            <div class="item__links">
                <a
                    v-for="(link, index) of itemData.links"
                    :key="index"
                    :href="link.path"
                    class="item__link"
                >{{link.label}}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        itemData: {
            type: Object,
            required: true
        }    
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    display: inline-block;
    width: 25%;
    min-width: 200px;
    padding: 5px;

    .item {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        min-height: 190px;
        padding: 30px;
        color: #222;
        overflow: hidden;
        border-radius: 4px;

        &__title {
            display: block;
            margin-bottom: 15px;
            font-weight: bold;
            font-size: 16px;
        }

        &__link {
            font-size: 12px;
            padding: 7px 15px;
            border-radius: 25px;
            display: block;
            opacity: .7;
            transition: all 250ms;

            &:hover {
                background: #fff;
                opacity: 1;
            }
        }
    }
}
</style>