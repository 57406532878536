import ServiceApi from '../ServiceApi';
/** Сервис для работы с картой */
export default class ServiceBuyerdeskMap {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'free';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     */
    static _requestRouts = {
        search: '/poi/search'
    };

    /**
     * Поиск по карте
     * @public
     * @static
     * @async
     * @param {String} searchString - строка поиска
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async search(searchString = '', actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._searchBefore(searchString);
        try {
            const data = await this._baseRequest(reqParams);
            const dataAfter = this._searchAfter(data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Метод для запроса
     * @private
     * @static
     * @async
     * @param {Object} reqParams - параметры запроса
     * @returns {Object|Error}
     */
    static async _baseRequest(reqParams = {}) {
        const {reqBody = {}, reqConfig = {}, routeKey = ''} = reqParams;
        try {
            const {data = {}} = await ServiceApi.post(this._microserviceName, this._requestRouts[routeKey], reqBody, reqConfig);
            const {data: dataRes = []} = data;
            return dataRes;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }

    /**
     * Получить параметры для запроса на поиск по карте
     * @private
     * @static
     * @param {String} searchString - строка поиска
     * @returns {Object}
     */
    static _searchBefore(searchString = '') {
        const reqParams = {
            routeKey: 'search',
            reqBody: {query: String(searchString)}
        };

        return reqParams;
    }

    /**
     * Обработка результата запроса поиска по карте
     * @private
     * @static
     * @param {Array} data - данные поиска
     * @returns {Array}
     */
    static _searchAfter(data = []) {
        const dataAfter = data.map((searchItem, index) => {
            const {data: searchItemData = {}} = searchItem;
            const {
                city_with_type: cityWithType = '',
                street_with_type: streetWithType = '',
                house_type: houseType = '',
                house = '',
                flat_type: flatType = '',
                flat = '',
                block = '',
                block_type: blockType = '',
                geo_lat: lat = '',
                geo_lon: lon = ''
            } = searchItemData;
            let finalAddress = '';
            if(cityWithType){
                finalAddress += cityWithType
            }
            if(streetWithType){
                finalAddress += finalAddress ? (", " + streetWithType) : streetWithType;
            }
            if(house){
                let houseFinal = houseType ? (houseType + " " + house) : house;
                finalAddress += finalAddress ? (", " + houseFinal) : houseFinal;
            }
            if(block){
                let blockFinal = blockType ? (blockType + " " + block) : block;
                finalAddress += finalAddress ? (", " + blockFinal) : blockFinal;
            }
            if(flat){
                let flatFinal = flatType ? (flatType + " " + flat) : flat;
                finalAddress += finalAddress ? (", " + flatFinal) : flatFinal;
            }
            const searchItemAfter = {
                id: String(index),
                value: finalAddress,
                lat: String(lat),
                lng: String(lon)
            };
            return searchItemAfter;
        });

        return dataAfter;
    }
}
