<template>
    <svg class="svg-icon" id="icon-delete" :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.99951 6C2.99951 5.44772 3.4083 5 3.91256 5H16.0865C16.5907 5 16.9995 5.44772 16.9995 6C16.9995 6.55228 16.5907 7 16.0865 7H3.91256C3.4083 7 2.99951 6.55228 2.99951 6Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.33333 8.33325C8.79357 8.33325 9.16667 8.70635 9.16667 9.16659V14.1666C9.16667 14.6268 8.79357 14.9999 8.33333 14.9999C7.8731 14.9999 7.5 14.6268 7.5 14.1666V9.16659C7.5 8.70635 7.8731 8.33325 8.33333 8.33325Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.6668 8.33325C12.1271 8.33325 12.5002 8.70635 12.5002 9.16659V14.1666C12.5002 14.6268 12.1271 14.9999 11.6668 14.9999C11.2066 14.9999 10.8335 14.6268 10.8335 14.1666V9.16659C10.8335 8.70635 11.2066 8.33325 11.6668 8.33325Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.09767 5.00292C4.55632 4.9647 4.95911 5.30553 4.99733 5.76418L5.83066 15.7642C5.83258 15.7872 5.83354 15.8103 5.83354 15.8334C5.83354 16.0544 5.92134 16.2664 6.07762 16.4226C6.2339 16.5789 6.44586 16.6667 6.66688 16.6667H13.3335C13.5546 16.6667 13.7665 16.5789 13.9228 16.4226C14.0791 16.2664 14.1669 16.0544 14.1669 15.8334C14.1669 15.8103 14.1678 15.7872 14.1698 15.7642L15.0031 5.76418C15.0413 5.30553 15.4441 4.9647 15.9027 5.00292C16.3614 5.04115 16.7022 5.44394 16.664 5.90258L15.8332 15.8715C15.8234 16.5208 15.5611 17.1413 15.1013 17.6011C14.6325 18.07 13.9966 18.3334 13.3335 18.3334H6.66688C6.00383 18.3334 5.36795 18.07 4.89911 17.6011C4.43927 17.1413 4.17706 16.5208 4.16717 15.8715L3.33642 5.90258C3.2982 5.44394 3.63902 5.04115 4.09767 5.00292Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.83341C13.3332 6.29365 12.9601 6.66675 12.4998 6.66675C12.0396 6.66675 11.6665 6.29365 11.6665 5.83341V3.33341H8.33317L8.33317 5.83341C8.33317 6.29365 7.96007 6.66675 7.49984 6.66675C7.0396 6.66675 6.6665 6.29365 6.6665 5.83341V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549Z" :fill="color"/>
</svg>
</template>

<style scoped>
    .svg-icon path {
        fill: currentColor;
    }
</style>

<script>
export default {
    name: 'IconShare',
    props: {
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'black'
        },
    },
}
</script>
