/** Сервис по работе с данными пользователя */
export default class ServiceUser {
    static profileKey = 'USER_PROFILE';
    
    /**
     * Получить данные пользователя из локального хранилища
     * @static
     */
    static get profile() {
        return JSON.parse(localStorage.getItem(this.profileKey));
    }
    
    /**
     * Установить данные пользователя в локальное хранилище
     * @static
     * @param {Object} profile - данные пользователя
     */
    static setProfile(profile = {}) {
        localStorage.setItem(this.profileKey, JSON.stringify(profile));
    }

    /**
     * Удалить данные пользователя из локального хранилища
     * @static
     */
    static removeProfile() {
        localStorage.removeItem(this.profileKey);
    }
}