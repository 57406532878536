/* eslint-disable no-extra-semi */
/** Базовый сервис для работы с полилиниями */
export default class ServiceBasePolyline {
    /**
     * Конструктор
     * @param {Object} params - параметры для полилинии
     */
    constructor(params = {}) {
        const {
            map = null, 
            strokeColor = '#FF0000', 
            strokeOpacity = 0.8, 
            strokeWeight = 2, 
            clickable = true,
            draggable = true,
            editable = true,
            paths = [],
        } = params;
        this.map = map;
        this.strokeColor = String(strokeColor);
        this.strokeOpacity = parseFloat(strokeOpacity);
        this.strokeWeight = parseInt(strokeWeight);
        this.clickable = Boolean(clickable);
        this.draggable = Boolean(draggable);
        this.editable = Boolean(editable);
        this.paths = paths.map(path => {
            const {lat = '', lng = ''} = path;
            const latLng = {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            };
            return latLng;
        });
    };
};