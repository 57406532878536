/* eslint-disable no-extra-semi */
/** Базовый сервис для работы с маркерами */
export default class ServiceBaseMarker {
    /**
     * Конструктор
     * @param {Object} params - параметры для маркера
     */
    constructor(params = {}) {
        const {
            map = null,
            id = '',
            lat = '',
            lng = '',
            color = '',
            figure = 'square',
            sidesColors = {},
            src = '',
            state = -1,
            direction = null,
            sides = {},
            mode = '',
            iconType = 'default',
            extraData = {}
        } = params;
        this.map = map;
        this.id = String(id);
        this.lat = parseFloat(lat);
        this.lng = parseFloat(lng);
        this.iconType = iconType;
        this._color = this.correctionColor(color);
        this.state = parseInt(state);
        this.direction = direction === null ? direction: parseInt(direction);
        this.sides = sides;
        this.sidesCorrect = this.correctionSides(sides);
        this.sidesColors = this.correctionSidesColors(sidesColors);
        this.sidesFiltered = [...Object.keys(this.sidesColors).map(sideKey => String(sideKey).toLowerCase())];
        this.mode = String(mode);
        this.extraData = extraData;
        this.src = String(src);
        this.figure = String(figure);
    };
    /**
     * Привести цвет маркера к нужному формату
     * @param {String} color - цвет маркера
     * @returns {String}
     */
    correctionColor(color = '') {
        return String(color).replace('#', '').toLowerCase();
    };

    /**
     * Геттер для переопределения цвета маркера
     * @return {String}
     */
    get color() {
        return this._color;
    };

    /**
     * Сеттер для переопределения цвета маркера
     * @param {String} newColor - цвет маркера
     */
    set color(newColor) {
        this._color = this.correctionColor(newColor);
    };

    get iconUrlSides() {
        return Object.keys(this.sidesColors).map(sideKey => String(sideKey).toLowerCase()).filter(sideKey => this.sidesFiltered.includes(sideKey)).join('');
    }

    get iconUrlHexCode() {
        if (this.iconUrlSides === 'a')
            return this.sidesColors.A ?? this.color;
        else if (this.iconUrlSides === 'b')
            return this.sidesColors.B ?? this.color;
        else
            return this.color;
    }

    get iconUrlSidesColors() {
        if (this.iconUrlSides === 'a')
            return {colorA: this.sidesColors.A ?? this.color};
        else if (this.iconUrlSides === 'b')
            return {colorB: this.sidesColors.B ?? this.color};
        else
            return {
                colorA: this.sidesColors.A ?? this.color,
                colorB: this.sidesColors.B ?? this.color,
            };
    }

    get iconUrlBaseParams() {
        return {
            ...this.iconUrlSidesColors,
            hexCode: this.iconUrlHexCode,
            stroke: this.getState(),
            sides: Object.keys(this.sidesColors).map(sideKey => String(sideKey).toLowerCase()).filter(sideKey => this.sidesFiltered.includes(sideKey)).join(''),
        };
    }

    get iconUrlDirectionParams() {
        return {
            ...this.iconUrlBaseParams,
            showMode: this.direction === null ? 4 : 1,
            type: this.iconType === 'directionFlowSide' ? 1 : 0,
            updateStamp: 145
        };
    }

    get iconUrlDefaultParams() {
        return {
            ...this.iconUrlBaseParams,
            showMode: 0,
            updateStamp: 145
        };
    }

    get iconUrlDirection() {
        const params = this.direction === null ? {...this.iconUrlDirectionParams} : {...this.iconUrlDirectionParams, direction: this.direction};
        const url = Object.keys(params).reduce((url, param, index) => {
            return `${url}${index === 0 ? '?' : '&'}${param}=${params[param]}`;
        }, `${process.env.VUE_APP_FREE_BACKEND_HOST}/map/draw/marker`);
        return url;
    }

    get iconUrlDefault() {
        const url = Object.keys(this.iconUrlDefaultParams).reduce((url, param, index) => {
            return `${url}${index === 0 ? '?' : '&'}${param}=${this.iconUrlDefaultParams[param]}`;
        }, `${process.env.VUE_APP_FREE_BACKEND_HOST}/map/draw/marker`);
        return url;
    }

    get iconUrlPoiParams() {
        return {
            figure: this.figure,
            hexCode: this.color,
            showMode: 2,
        };
    }

    get iconUrlPoi() {
        const defaultUrl = `${process.env.VUE_APP_FREE_BACKEND_HOST}/map/draw/marker`;
        return Object
            .keys(this.iconUrlPoiParams)
            .reduce((url, param, index) => {
                return `${url}${index === 0 ? '?' : '&'}${param}=${this.iconUrlPoiParams[param]}`;
             }, defaultUrl);
    }

    /**
     * Привести объект сторон маркера к нужному формату
     * @param {Object} sides - стороны
     * @returns {Object}
     */
    correctionSides(sides = {}) {
        let sidesCorrect = {};
        const enB = String.fromCharCode(66);
        const ruB = String.fromCharCode(1042);
        Object.keys(sides).forEach(sideKey => {
            const sideNameCorrect = String(sides[sideKey]).toUpperCase().substring(0, 1);
            sidesCorrect[sideKey] = sideNameCorrect === enB || sideNameCorrect === ruB ? 'B' : 'A';
        });
        return sidesCorrect;
    };

    correctionSidesColors(sidesColors) {
        const enB = String.fromCharCode(66);
        const ruB = String.fromCharCode(1042);
        const sidesColorsArr = Object.entries(sidesColors);
        const sidesColorsArrCorrect = sidesColorsArr.map(([sideKey, sideColor]) => {
            const sideKeyCorrect = String(sideKey).toUpperCase().substring(0, 1);
            return [sideKeyCorrect === enB || sideKeyCorrect === ruB ? 'B' : 'A', this.correctionColor(sideColor)];
        });
        const sidesColorsCorrect = Object.fromEntries(sidesColorsArrCorrect);
        return sidesColorsCorrect;
    }

    /**
     * Получить url иконки маркера
     * @returns {String}
     */
    getIconUrl() {
        let url = ``;
        switch (this.iconType) {
            case 'default':
                url = this.iconUrlDefault;
                break;
            case 'directionAngleView':
            case 'directionFlowSide':
                url = this.iconUrlDirection;
                break;
            case 'poiIcon':
                url = this.src;
                break;
            case 'poi': {
                url = this.iconUrlPoi;
                break;
            }
            default:
                break;
        }
        return url;
    };
    getState() {
        const {kitId = ''} = this.extraData;
        return String(kitId) === '0' || String(kitId) === '-2' || this.state === 2 ? this.state : 0;
    };
};
