import ServiceApi from '../ServiceApi';

/** Сервис для мэтчинга */
export default class ServiceBuyerdeskMatch {
    static isRun = true;

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'free';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     */
    static _requestRouts = {
        upload: '/upload/excel',
        matchPrepare: '/map/match-prepare',
        matchSearch: '/map/match-search',
        exportFileRows: '/export/file-rows',
        matchLink: '/map/match-link'
    };

    /**
     * Загрузка файла
     * @public
     * @static
     * @async
     * @param {Object} file - файл
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async uploadFile(file = null, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._uploadFileBefore(file);
        try {
            const data = await this._baseRequest(reqParams);
            actionAfter(data);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Подготовка данных для мэтчинга
     * @public
     * @static
     * @async
     * @param {Number} fileId - id файла
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async matchPrepare(fileId = -1, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._matchPrepareBefore(fileId);
        try {
            const data = await this._baseRequest(reqParams);
            const dataAfter = this._matchPrepareAfter(data);
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Поиск конструкций для мэтчинга
     * @public
     * @static
     * @async
     * @param {Object} matchSearchData - данные для поиска
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async matchSearch(matchSearchData = {}, actionAfter = () => {}, actionError = () => {}) {
        const {stepsCount = 0, step = 0} = matchSearchData;
        const reqParams = this._matchSearchBefore(matchSearchData);
        try {
            const data = await this._baseRequest(reqParams);
            const dataAfter = this._matchSearchAfter(data);
            const matchSearchDataNew = this._matchSearchDataEnrich(matchSearchData, dataAfter);
            if (step < stepsCount - 1 && this.isRun) {
                this.matchSearch(matchSearchDataNew, actionAfter, actionError);
            }
            else
                actionAfter(matchSearchDataNew);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Экспрот строк
     * @public
     * @static
     * @async
     * @param {Object} exportFileRowsData - данные для экспорта
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async exportFileRows(exportFileRowsData = {}, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._exportFileRowsBefore(exportFileRowsData);
        try {
            const data = await this._baseRequest(reqParams);
            const dataAfter = this._exportFileRowsAfter(data)
            actionAfter(dataAfter);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Создание набора по результату мэтчинга
     * @public
     * @static
     * @async
     * @param {Object} matchLinkData - данные для создания набора
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async matchLink(matchLinkData = {}, actionAfter = () => {}, actionError = () => {}) {
        const reqParams = this._matchLinkBefore(matchLinkData);
        try {
            const data = await this._baseRequest(reqParams);
            actionAfter(data);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Метод для запроса
     * @private
     * @static
     * @async
     * @param {Object} reqParams - параметры запроса
     * @returns {Object|Error}
     */
    static async _baseRequest(reqParams = {}) {
        const {reqBody = {}, reqConfig = {}, routeKey = ''} = reqParams;
        try {
            const {data = {}} = await ServiceApi.post(this._microserviceName, this._requestRouts[routeKey], reqBody, reqConfig);
            const {data: dataRes = {}} = data;
            return dataRes;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }

    /**
     * Получить параметры запроса для загрузка файла
     * @private
     * @static
     * @param {Object} file - файл
     * @returns {Object}
     */
    static _uploadFileBefore(file = null) {
        let formData = new FormData();
        formData.append('file', file);
        const reqConfig = {headers: {'Content-Type': 'multipart/form-data'}};

        const reqParams = {
            routeKey: 'upload',
            reqBody: formData,
            reqConfig
        };

        return reqParams;
    }

    /**
     * Получить параметры запроса для подготовка данных для мэтчинга
     * @private
     * @static
     * @param {Number} fileId - id файла
     * @returns {Object}
     */
    static _matchPrepareBefore(fileId = -1) {
        const reqParams = {
            routeKey: 'matchPrepare',
            reqBody: {file_id: Number(fileId)}
        };

        return reqParams;
    }

    /**
     * Получить параметры запроса для поиска конструкций для мэтчинга
     * @private
     * @static
     * @param {Object} matchSearchData - данные для поиска
     * @returns {Object}
     */
    static _matchSearchBefore(matchSearchData = {}) {
        const {
            fileId = -1,
            combinationId = -1,
            step = 0,
            fields = {},
            sideIds = []
        } = matchSearchData;

        const reqParams = {
            routeKey: 'matchSearch',
            reqBody: {
                file_id: parseInt(fileId),
                combination_id: parseInt(combinationId),
                step: parseInt(step),
                fields,
                used_sides: sideIds
            }
        };
        return reqParams;
    }

    /**
     * Получить параметры запроса для экспрот строк
     * @private
     * @static
     * @param {Object} exportFileRowsData - данные для экспорта
     * @returns {Object}
     */
    static _exportFileRowsBefore(exportFileRowsData = {}) {
        const {fileId = -1, sidesNums = []} = exportFileRowsData;
        const reqParams = {
            routeKey: 'exportFileRows',
            reqBody: {
                file_id: parseInt(fileId),
                file_row_ids: sidesNums
            }
        };
        return reqParams;
    }

    /**
     * Получить параметры запроса для создания набора по результату мэтчинга
     * @private
     * @static
     * @param {Object} matchLinkData - данные для создания набора
     * @returns {Object}
     */
    static _matchLinkBefore(matchLinkData = {}) {
        const {fileId = -1, sideIds = []} = matchLinkData;
        const reqParams = {
            routeKey: 'matchLink',
            reqBody: {
                file_id: parseInt(fileId),
                side_ids: sideIds
            }
        };
        return reqParams;
    }

    /**
     * Обработка результата подготовки данных для мэтчинга
     * @private
     * @static
     * @param {Object} data - ответ от запроса
     * @returns {Object}
     */
    static _matchPrepareAfter(data = {}) {
        const {combinations = [], header = [], matched = [], stepsCount = -1} = data;
        const combinationsMapped = combinations.map(combination => {
            const {items = []} = combination;
            const itemsMapped = Object.keys(items).map(itemKey => {
                const label = {
                    key: String(itemKey),
                    value: String(items[itemKey])
                };

                const selectValueMatch = matched.find(match => {
                    const {attribute = ''} = match;
                    return String(attribute) === String(itemKey);
                }) ?? {};

                const {index = -1} = selectValueMatch;
                const selectValueHeader = header[index] ?? '';

                const selectValue = {
                    value: String(selectValueHeader),
                    index: parseInt(index)
                };

                return {label, selectValue};
            });
            return {...combination, items: itemsMapped};
        });

        const dataAfter = {
            combinations: combinationsMapped,
            values: header,
            stepsCount: parseInt(stepsCount)
        }
        return dataAfter;
    }

    /**
     * Обработка результата поиска конструкций для мэтчинга
     * @private
     * @static
     * @param {Array} rows - строки
     * @returns {Object}
     */
    static _matchSearchAfter(rows = []) {
        const rowsStatuses = {approx: 'approx', not_found: 'notFound', strict: 'strict'};
        const dataAfter = rows.reduce((rowsReduced, row) => {
            const {match_info: matchInfo = {}} = row;
            const {status = '', side = {}} = matchInfo;
            const rowsReducedKey = rowsStatuses[status];

            rowsReduced = {
                ...rowsReduced,
                [rowsReducedKey]: [...rowsReduced[rowsReducedKey], row],
            };

            if (side !== false) {
                const {id = ''} = side;
                if (id !== '')
                    rowsReduced.sideIds.push(parseInt(id));
            }

            return rowsReduced;
        }, {approx: [], notFound: [], strict: [], sideIds: []});

        return dataAfter;
    }

    /**
     * Обработка результата экспрота строк
     * @private
     * @static
     * @param {Object} data - ответ от запроса
     * @returns {String}
     */
    static _exportFileRowsAfter(data = {}) {
        const {link = ''} = data;
        return String(link);
    }

    /**
     * Обогащение результата поиска конструкций для мэтчинга
     * @private
     * @static
     * @param {Object} matchSearchDataOld - данные для поиска строк с предыдущего шага
     * @param {Object} dataAfterRequest - данные после запроса
     * @returns {Object}
     */
    static _matchSearchDataEnrich(matchSearchDataOld = {}, dataAfterRequest = {}) {
        const {
            rows: {
                approx: matchSearchDataOldApprox = [],
                notFound: matchSearchDataOldNotFound = [],
                strict: matchSearchDataOldStrict  = [],
            } = {},
            sideIds: matchSearchDataOldSideIds  = [],
            step = 0
        } = matchSearchDataOld;

        const {
            approx: dataAfterRequestApprox = [],
            notFound: dataAfterRequestNotFound = [],
            strict: dataAfterRequestStrict  = [],
            sideIds: dataAfterRequestSideIds  = []
        } = dataAfterRequest;

        const matchSearchDataNew = {
            ...matchSearchDataOld,
            rows: {
                approx: [...matchSearchDataOldApprox, ...dataAfterRequestApprox],
                notFound: [...matchSearchDataOldNotFound, ...dataAfterRequestNotFound],
                strict: [...matchSearchDataOldStrict, ...dataAfterRequestStrict],
            },
            sideIds: [...matchSearchDataOldSideIds, ...dataAfterRequestSideIds],
            step: step + 1
        };

        return matchSearchDataNew;
    }
}
