<template>
    <div 
        :style="{'background-color': color}"
        class="base-color-tag"
    />
</template>

<script>
export default {
    name: 'BaseColorTag',
    props: {
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-color-tag {
        width: 14px;
        height: 14px;
        border-radius: 50%;
    }
</style>