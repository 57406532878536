<template>
    <button
        v-tooltip="panelItemDescription"
        :class="{'control-panel-item_active': isActive}"
        class="control-panel-item"
        @click="onClickPanelItem"
    >
        <div class="control-panel-item__icon">
            <span :class="`${panelItemIcon}`"></span>
        </div>
    </button>
</template>

<script>
export default {
    name: "ControlPanelItem",
    /**
     * Входные данные
     * @property {Object} panelItem - элемент панели
     * @property {boolean} isActive - активность элемента панели
     */
    props: {
        panelItem: {
            type: Object,
            default: () => ({})
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Идентификатор элемента панели
         * @returns {String}
         */
        panelItemId() {
            let {id = ''} = this.panelItem;
            return String(id);
        },
        /**
         * Описание элемента панели
         * @returns {string}
         */
        panelItemDescription() {
            const {description = ''} = this.panelItem;
            return String(description);
        },
        /**
         * Иконка панели
         * @returns {string}
         */
        panelItemIcon() {
            let {icon = ''} = this.panelItem;
            return String(icon);
        },
    },
    methods: {
        /**
         * Событие клика по элементу панели
         */
        onClickPanelItem() {
            this.$emit('onClickPanelItem', this.panelItemId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel-item {
        width: 36px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #fff;
        //margin: 2px 2px 0 0;
        border-radius: 4px;
        transition: all .4s ease-out;

        &:hover {
            background-color: #F5F5F5;
        }

        &__icon {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            padding: 5px;
            transition: all .6s ease-out;
        }
        &_active {
            background: #FFFFFF;
            border: 1px solid #6EC87A;
        }
    }
</style>
