<template>
	<div
		class="default-cell"
		:class="{'default-cell_empty': !content}"
		v-tooltip="showTooltip"
		v-clipboard="() => content"
	>
		{{ shortContent }}
	</div>
</template>

<script>

export default {
	name: "BaseCell",
	props: {
		content: {
			default: '',
		},
        shortContent: {
            default: '',
        },
        isShowTooltip: {
            type: Boolean,
            default: true,
        },
	},
    computed: {
        showTooltip() {
            if (!this.isShowTooltip) return '';

            return this.content;
        }
    },
}
</script>

<style lang="scss" scoped>
.default-cell {
	width: 100%;
	max-width: 100%;

    line-height: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &_empty {
		color: #BEC0C7;
	}
}
</style>
