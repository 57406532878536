import { render, staticRenderFns } from "./LayoutLoginV2.vue?vue&type=template&id=af04474c&scoped=true&"
import script from "./LayoutLoginV2.vue?vue&type=script&lang=js&"
export * from "./LayoutLoginV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af04474c",
  null
  
)

export default component.exports