import UtilLocalStorage from '../../utils/UtilLocalStorage';
export default class ServiceMapLegends {
    static _localStoragePositionLegendsKey = 'positionLegends';

    static _localStorageGroupSelectedLegendsKey = 'groupSelectedLegends';

    static _localStorageControlPanelBlocksKeysShowedKey = 'controlPanelBlocksKeysShowed';

    static _localStorageLegendPanelKeysShowed = 'legendPanelKeysShowed';

    static get _isPositionLegends() {
        return UtilLocalStorage.isData(this._localStoragePositionLegendsKey);
    }

    static get _isGroupSelectedLegends() {
        return UtilLocalStorage.isData(this._localStorageGroupSelectedLegendsKey);
    }

    static get _isControlPanelBlocksKeysShowed() {
        return UtilLocalStorage.isData(this._localStorageControlPanelBlocksKeysShowedKey);
    }

    static get _isLegendPanelKeysShowed() {
        return UtilLocalStorage.isData(this._localStorageLegendPanelKeysShowed);
    }

    static get _positionLegendsDefault() {
        return {
            controlPanel: {
                top: '10px',
                // left: `${window.innerWidth - 165}px`
                left: `${window.innerWidth - 170}px`
            },
            found: {
                top: '10px',
                left: `${window.innerWidth - 330}px`
            },
            custom: {
                top: '10px',
                left: `${window.innerWidth - 490}px`
            },
            without_prices: {
                top: '10px',
                left: `${window.innerWidth - 650}px`
            },
            colorPanel: {
                top: '10px',
                left: `${window.innerWidth - 870}px`
            },
            heatmapPanel: {
                top: '10px',
                left: `${window.innerWidth - 970}px`
            }
        };
    }

    static _groupSelectedLegendsDefault = {
        found: 'default',
        custom: 'default',
        without_prices: 'default'
    };

    static _controlPanelBlocksKeysShowedDefault = ['mode', 'markers', 'traffic', 'tool'];

    static _legendPanelKeysShowedDefault = ['found', 'custom', 'without_prices', 'heatmap'];

    static getPositionLegends() {
        return this._isPositionLegends ? UtilLocalStorage.getData(this._localStoragePositionLegendsKey) : this._positionLegendsDefault;
    }

    static setPositionLegends(positionLegends = {}) {
        UtilLocalStorage.setData(this._localStoragePositionLegendsKey, positionLegends);
    }

    static setPositionLegendsDefault() {
        UtilLocalStorage.setData(this._localStoragePositionLegendsKey, this._positionLegendsDefault);
    }

    static getGroupSelectedLegends() {
        return this._isGroupSelectedLegends ? UtilLocalStorage.getData(this._localStorageGroupSelectedLegendsKey) : this._groupSelectedLegendsDefault;
    }

    static setGroupSelectedLegends(groupSelectedLegends = {}) {
        UtilLocalStorage.setData(this._localStorageGroupSelectedLegendsKey, groupSelectedLegends);
    }

    static getControlPanelBlocksKeysShowed() {
        return this._isControlPanelBlocksKeysShowed ? UtilLocalStorage.getData(this._localStorageControlPanelBlocksKeysShowedKey) : this._controlPanelBlocksKeysShowedDefault;
    }

    static setControlPanelBlocksKeysShowed(controlPanelBlocksKeysShowed = []) {
        UtilLocalStorage.setData(this._localStorageControlPanelBlocksKeysShowedKey, controlPanelBlocksKeysShowed);
    }

    static getLegendPanelKeysShowed() {
        return this._isLegendPanelKeysShowed ? UtilLocalStorage.getData(this._localStorageLegendPanelKeysShowed) : this._legendPanelKeysShowedDefault;
    }

    static async setLegendPanelKeysShowed(legendPanelKeysShowed = []) {
        UtilLocalStorage.setData(this._localStorageLegendPanelKeysShowed, legendPanelKeysShowed);
    }
}