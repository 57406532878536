<template>
    <label class="base-checkbox">
        <input
            type="checkbox"
            :value="value"
            v-model="model"
        >
        <span class="checkmark" :style="style"/>
    </label>
</template>

<script>
export default {
    name: 'BaseCheckboxV2',
    model: {
        prop: 'checked',
        event: 'change',
    },
    /**
     * Входные данные
     * @property {Number|String} value - значение
     * @property {Boolean} checked - флаг
     * @property {Boolean} isPartChecked - флаг частичного выделения
     */
    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        isPartChecked: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        /**
         * Модель для привязки к v-model
         */
        model: {
            /**
             * Геттер модели
             * @returns {Boolean}
             */
            get() {
                return this.checked;
            },
            /**
             * Сеттер модели
             * @param {Number|String} val - значение
             */
            set(val) {
                this.$emit('change', val);
            },
        },
        /**
         * Инлайн-стили
         * @returns {Object}
         */
        style() {
            const style = this.isPartChecked ? {'background-image': `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='5.995' cy='5.995' r='5.995' fill='%23fff'/%3e%3c/svg%3e"`} : {};
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-checkbox {

        .checkmark {
            position: relative;
            width: 24px;
            height: 24px;
            border: 2px solid #ccc;
            display: block;
            border-radius: 4px;
            cursor: pointer;
            transition: all .3s ease-out;
        }

        input {
            display: none;

            &:checked + span {
                background-color: #0b76ef;
                border-color: #0b76ef;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 10px;
                    position: absolute;
                    top: 2px;
                    left: 6px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }

        &:hover input ~ .checkmark{
            border-color: #0b76ef;
        }

        &--small {
            .checkmark {
                width: 16px;
                height: 16px;
                border: 1px solid #D7D9D9;
                border-radius: 2px;
            }

            input:checked + span {
                &:before {
                    width: 4px;
                    height: 6px;
                    top: 2px;
                    left: 4px;
                }
            }
        }

        &--parent-select {
            input:checked + span {
                animation: shrink-bounce 250ms cubic-bezier(0.4, 0, 0.23, 1);
                box-shadow: inset 0 0 0 10px #258df5;

                &:before {
                    top: 4px;
                    left: 4px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #fff;
                }
            }
        }
    }
</style>
