<template>
    <div
        :style="boxStyle"
        class="base-search-box"
    >
        <div
            v-for="boxItem in boxItems"
            :key="getBoxItemId(boxItem)"
            class="base-search-box__item"
            @click="onClickBoxItem(boxItem)"
        >
            {{ getBoxItemValue(boxItem) }}
        </div>
    </div> 
</template>

<script>
export default {
    name: 'BaseSearchBox',
    props: {
        boxWidth: {
            type: String,
            default: ''
        },
        boxItems: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        boxStyle() {
            return { width: this.boxWidth }
        }
    },
    methods: {
        getBoxItemId(boxItem = {}) {
            return String(boxItem?.id ?? '');
        },
        getBoxItemValue(boxItem = {}) {
            return String(boxItem?.value ?? '');
        },
        onClickBoxItem(boxItem = {}) {
            this.$emit('clickBoxItem', boxItem);
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-search-box {
        position: absolute;
        background: #fff;
        white-space: nowrap;
        z-index: 10;
        box-shadow: 0 3px 15px #21242D17;
        border: 1px solid #F4F5F5;
        border-radius: 10px;
        width: 100%;
        padding: 10px;
        max-height: 80vh;
        overflow: auto;

        &__item {
            color: #000;
            
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &:hover {
                color: #6EC87A;
            }
        }
    }
</style>