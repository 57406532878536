<template>
	<div class="filter">
<!--        <div-->
<!--			v-show="isLoading"-->
<!--			class="loading"-->
<!--		><BasePreloader :size="80" /></div>-->

		<div class="filter__top">
			<AppPanel
				:sidesNumber="sidesNumber"
				@close="onClose"
				@clear="onClear"
			/>
		</div>

		<div class="filter__bottom">
			<div class="filter-mediametry">
                <div class="filter-mediametry__title">Медиаметрия</div>
                <div class="filter-mediametry__wrapper">
                    <div class="filter-mediametry__filter slider">
                        <RangeSlider
                            v-for="item of sliderItems"
                            :key="item.id"
                            :min="Number(item.range.min)"
                            :max="Number(item.range.max)"
                            :curMin="Number(Object.keys(selected).includes(item.id) ? selected[item.id].min : item.range.min)"
                            :curMax="Number(Object.keys(selected).includes(item.id) ? selected[item.id].max : item.range.max)"
                            :disabled="Number(item.range.min) === Number(item.range.max)"
                            :label="item.name"
                            class="slider__item"
                            @change="onCheckRangeSlider({min: $event.min, max: $event.max, id: item.id})"
                        />
                    </div>

                    <AppCheckboxes
                        :items="panelData"
                        :selected="selectedPanelData"
                        class="filter-mediametry__checkboxes"
                        @check="onCheck"
                    />
                </div>

			</div>

			<div class="active-items">
				<div class="active-items__title">Параметры конструкций</div>
				<div class="active-items__list">
					<FilterItemActive
						v-for="(param, index) of activeItems"
						:key="index"
						:title="param.name"
						:id="param.id"
						:options="param.items"
						:selected="selected[param.id]"
                        :isLoading="isLoading"
						class="active-items__item"
						@check="onCheckFilterItem"
                        @onSelectAll="onSelectAllFilterItem"
                        @onUnselectAll="onUnselectAllFilterItem"
						@transfer="onTransfer"
					/>
				</div>
			</div>

			<div class="inactive-items" v-show="inactiveItems.length > 0">
				<div class="inactive-items__title">Набор данных</div>
				<div class="inactive-items__list">
					<FilterItemInactive
						v-for="(param, index) of inactiveItems"
						:key="index"
						:title="param.name"
						:id="param.id"
						class="inactive-item"
						@transfer="onTransfer"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FilterItemActive from "./FilterItemActive"
import FilterItemInactive from "./FilterItemInactive"
import AppCheckboxes from "./Checkboxes"
import AppPanel from "./Panel"
import RangeSlider from "@/components/Base/BaseRangeSlider"
// import BasePreloader from "@/components/Base/BasePreloader"

export default {
	name: "AppFilter",
	components: {
		FilterItemActive,
		FilterItemInactive,
		AppCheckboxes,
		AppPanel,
		RangeSlider,
		// BasePreloader,
	},
	props: {
		params: {
			type: Array,
			default: () => []
		},
		selected: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		panelData: {
			type: Array,
			default: () => []
		},
		sidesNumber: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			sliderItems: [],
			activeItems: [],
			inactiveItems: [],
		}
	},
	computed: {
		selectedPanelData() {
			const result = []

			for (let item of this.panelData) {
				if (this.selected?.[item.value] === true) {
					result.push(item.value)
				}
			}

			console.log(result);

			return result
		}
	},
	watch: {
		params() {
			this.sortFilterItems()
		}
	},
	created() {
		this.sortFilterItems()
	},
	methods: {
        onSelectAllFilterItem(checkData = {}) {
            const {filterItemId = '-1', optionIds = []} = checkData;
            const body = {filterItemId: String(filterItemId), value: optionIds};
            this.$emit('check', body);
        },
        onUnselectAllFilterItem(id = '-1') {
            const body = {filterItemId: String(id), value: []};
            this.$emit('check', body);
        },
		onCheckFilterItem(event) {
			const { optionId, filterItemId } = event
			const body = { filterItemId }
            const selectedIdentifiers = this.selected[filterItemId]

            if (selectedIdentifiers.includes(optionId)) {
				body.value = selectedIdentifiers.filter(item => item != optionId)
            } else {
				body.value = [...selectedIdentifiers, optionId]
            }

			this.$emit('check', body)
		},
		onCheckRangeSlider(event) {
			const {min, max, id} = event
			const body = {
				filterItemId: id,
				value: {min, max}
			}
			this.$emit('check', body)
		},
		onTransfer(filterItemId) {
			this.$emit('transfer', filterItemId)
		},
		onClose() {
			this.$emit('close')
		},
		onClear() {
			this.$emit('clear')
		},
		onCheck(event) {
			const body = {
				filterItemId: event.id,
				value: event.checked
			}
			this.$emit('check', body)
		},
		sortFilterItems() {
			const activeItems = this.params.filter(item => item.items && item.hidden === false)
			const inactiveItems = this.params.filter(item => item.items && item.hidden === true)
			const sliderItems = this.params.filter(item => item.range)

			this.activeItems = activeItems
			this.inactiveItems = inactiveItems
			this.sliderItems = sliderItems
		},
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

%title {
	color: $assistant-color;
	margin-bottom: 20px;
	font-size: 16px;
}

.filter {
	display: grid;
	grid-template-rows: auto 1fr;
	grid-row-gap: 30px;

	.loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
    }

	&__bottom {
		display: grid;
        grid-template-columns: 160px 1fr auto;
        grid-template-rows: 1fr;
        column-gap: 30px;
        row-gap: 0;
        order: 1;
		overflow: auto;
	}

    .filter-mediametry {
        &__title {
            @extend %title;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__filter {
            padding-left: 3px;
        }

        .slider {
            &__item {
                margin-bottom: 30px;
            }
        }
    }

	.active-items {
		&__title {
			@extend %title;
		}

		&__list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
            gap: 20px;
        }

	}

	.inactive-items {
		display: flex;
		flex-direction: column;
        margin-right: 10px;

		&__title {
			@extend %title;
		}

		&__list {
			width: 100%;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
		}

		.inactive-item {
			margin-bottom: 10px;
		}
	}

    @media screen and (max-width: 750px) {
        &__bottom {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            column-gap: 0;
            row-gap: 30px;
        }

        .active-items {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            overflow: initial;

            &__list {
                padding-right: 20px;
            }
        }

        .inactive-items {
            order: 3;
        }
    }

    @media screen and (min-width: 686px) and (max-width: 750px) {
        .active-items {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        .filter-mediametry {
            order: 2;

            &__wrapper {
                flex-direction: row;
            }

            &__filter {
                margin-bottom: 0;
            }

            .slider {
                display: flex;
                align-items: flex-start;

                &__item {
                    margin-right: 30px;
                }
            }

            &__checkboxes {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
            }
        }
    }

    @media screen and (max-width: 685px) {
        .filter-mediametry {
            order: 2;

            &__wrapper {
                flex-direction: column;
            }

            &__filter {
                margin-bottom: 20px;
            }

            .slider {
                display: flex;
                align-items: flex-start;

                &__item {
                    margin-right: 30px;
                }
            }

            &__checkboxes {
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }
        }
    }
}
</style>
