<template>
    <button
        v-tooltip="{content: tooltipText, placement: tooltipPlacement}"
        class="base-button-icon"
        :class="{
            'base-button-icon--active': isActive,
            'base-button-icon--circle': form === 'circle',
            'base-button-icon--gray': isGray,
            }"
        @click="$emit('onBaseButtonIcon')"
    >
        <span class="base-button-icon__icon" :class="'icon-'+ iconName"></span>
    </button>
</template>

<script>
export default {
    name: "BaseButtonIcon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        tooltipText: {
            type: String,
            default: '',
            required: true,
        },
        form: {
            type: String,
            default: 'square',
            validation: (value) => ['square', 'circle'].includes(value),
        },
        iconName: {
            type: String,
            default: '',
            required: true,
        },
        isGray: {
            type: Boolean,
            default: false,
        },
        tooltipPlacement: {
            type: String,
            default: 'top',
            validation: (value) => ['top', 'left', 'right', 'bottom'].includes(value),
        },
    },
}
</script>

<style lang="scss" scoped>
$color-gray-hover: #F2F2F2;
$color-gray-text: #AFB0B4;
$color-green: #6EC87A;

    .base-button-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 36px;
        height: 36px;
        border-radius: 4px;
        font-size: 18px;
        background-color: #fff;
        border: 1px solid #fff;
        box-sizing: border-box;
        padding: 5px;
        transition: all .6s ease-out;

        &:not(:last-child) {
            margin-right: 5px;
        }

        &:hover {
            background-color: $color-gray-hover;
            transition: all .6s ease-out;
        }

        &--active {
            background-color: #fff;
            border-color: $color-green;

            &:hover {
                background-color: #fff;
                transition: all .6s ease-out;
            }
        }

        &--circle {
            border-radius: 100%;
        }

        &--gray {
            background-color: $color-gray-hover;

            &:hover {
                background-color: #fff;
                transition: all .6s ease-out;
            }
        }
    }
</style>
