<template>
    <div class="tab-list">
        <tab-item
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            :tab="tab"
            :count="counts[tabIndex]"
            :isActive="isActive(tab)"
            @onCheck="onCheck"
            @onDownload="onDownload"
        />
    </div>
</template>

<script>
/** Компонент для списка табов */
import TabItem from './TabItem';
export default {
    name: 'TabList',
    components: {TabItem},
    /**
     * Входные данные компонента
     * @property {Array} tabs - список табов
     * @property {Boolean} tabIdActive - идентификатор активного таба
     * @property {Array} counts - Список значений count для каждого таба
     */
    props: {
        tabs: {
            type: Array,
            default: () => ([])
        },
        tabIdActive: {
            type: String,
            default: '-1'
        },
        counts: {
            type: Array,
            default: () => ([])
        },
    },
    methods: {
        /**
         * Условие активности таба
         * @param {Object} tab - таб
         * @returns {Boolean}
         */
        isActive(tab = {}) {
            const {id = '-1'} = tab;
            return String(id) === this.tabIdActive;
        },
        /**
         * Выбор таба
         * @param {String} id - идентификатор таба
         */
        onCheck(id = '-1') {
            this.$emit('onCheck', id);
        },
        /** Событие загрузки */
        onDownload() {
            this.$emit('onDownload');
        },
    }
}
</script>

<style lang="scss" scoped>
    .tab-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
