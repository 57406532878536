<template>
	<CardLayout>
		<div class="card-default">
			<div class="card-default__key">{{ title }}</div>
			<div class="card-default__val">{{ value }}</div>
		</div>
	</CardLayout>
</template>

<script>
import CardLayout from "./Layout"

export default {
	name: "CardDefault",
	components: {
		CardLayout
	},
	props: {
		title: {
			type: String,
			default: '-'
		},
		value: {
			type: String,
			default: '-'
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.card-default {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	&__key {
		color: $assistant-color;
		font-size: 10px;
	}
	&__val {
		font-weight: 700;
		font-size: 12px;
		margin-top: 5px;
	}
}
</style>