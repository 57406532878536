<template>
        <div class="template">
            <div class="template__title">
                Скачать файл Excel
            </div>
            <p class="template__text">Выберите шаблон для скачивания</p>
            <div class="template__content">
                <BaseSelect
                    class="filter-panel__select"
                    view="primary"
                    position="left"
                    style="width: 100%"
                    :options="selectValues"
                    :value="selectedTemplate"
                    :canSearch="false"
                    :isCloseAfterCheck="true"
                    :isFullWidth="true"
                    :defaultTitle="defaultTitle"
                    @check="onCheckTemplate"
                >
                </BaseSelect>
            </div>
            <div class="template__button-subcontainer btn-list">
                <div
                    class="btn-list__item"
                    v-if="selectedTemplate.length"
                    @click="onCreateTemplate"
                >
                    Создать новый
                </div>
                <div
                    class="btn-list__item"
                    v-if="selectedTemplate[0]"
                    @click="onEditTemplate"
                >
                    Изменить
                </div>
                <div
                    class="btn-list__item"
                    v-if="selectedTemplate[0]"
                    @click="onRemoveTemplate"
                >
                    Удалить
                </div>
            </div>
            <div class="template__button-container">
                <div class="template__button-container__left">
                    <div
                        class="template__button-container__left__box"
                        @click="onClickRemoveDoubles"
                    >
                        <base-checkbox-v3
                            :key="'isRemoveDoubles'"
                            :checked="isRemoveDoubles"
                            :size="16"
                            @change="onClickRemoveDoubles"
                        />
                        <div class="template__button-container__left__text">
                            Удалить дубли
                        </div>
                    </div>
                </div>
                <div class="template__button-container__right">
                    <BaseButton
                        class="template__button-cancel"
                        view="border"
                        @click="$emit('onClosePopup')"
                    >
                        Отменить
                    </BaseButton>
                    <BaseButton
                        v-if="selectedTemplate.length"
                        :style="{marginLeft: '10px'}"
                        :disabled="isLoadingExport"
                        view="secondary"
                        @click="onExportTemplate"
                    >
                        Скачать
                    </BaseButton>
                </div>

            </div>
        </div>
</template>

<script>

import BaseSelect from "@/components/Base/BaseSelect";
import BaseButton from "@/components/Base/BaseButton";
import BaseCheckboxV3 from "@/components/Base/BaseCheckboxV3.vue";

export default {
    name: 'ExportTemplateBase',
    components: {
        BaseCheckboxV3,
        BaseSelect,
        BaseButton,
    },
    /**
     * Входные данные
     * @property {Array} selectValues - данные для селекта
     * @property {Array} selectedTemplate - выбранный шаблон
     */
    props: {
        selectValues: {
            type: Array,
            default: () => ([])
        },
        selectedTemplate: {
            type: Array,
            default: () => ([])
        },
        isRemoveDoubles: {
            type: Boolean,
            default: false
        },
        isLoadingExport: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        defaultTitle(){
            if(!this.selectedTemplate.length){
                return "Выберите шаблон";
            }
            let selected = this.selectValues.find(item => item.id === this.selectedTemplate[0]);
            return selected.value;
        }
    },
    methods:{
        onEditTemplate(){
            this.$emit('onEditTemplate')
        },
        onRemoveTemplate(){
            if(confirm('Уверены что хотите удалить шаблон?')){
                this.$emit('onRemoveTemplate')
            }
        },
        onCreateTemplate(){
            this.$emit('onCreateTemplate')
        },
        onCheckTemplate(value){
            this.$emit('onCheckTemplate', value.id)
        },
        onExportTemplate(){
            this.$emit('onExportTemplate')
        },

        onClickRemoveDoubles(){
            this.$emit('onClickRemoveDoubles')
        }
    }
}
</script>

<style lang="scss">
    .template__content .filter-panel__select .select-preview {

        &__label {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .filter-panel__select .select-preview.btn_active {
        border: 1px solid #6EC87A;
        color: #6EC87A;
    }

    .filter-panel__select .select-options {
        margin-top: 0;
    }

</style>

<style lang="scss" scoped>
.template{
    position: relative;
    background: white;
    border-radius: 10px;

    &__title{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        width: 100%;
    }

    &__text {
        font-size: 12px;
        margin-bottom: 5px;
    }

    &__content{
        width: 100%;
        display: flex;
        padding: 0;
        margin-bottom: 12px;
    }

    &__subtitle {
        font-size: 14px;
    }

    &__input{
        padding: 5px;
        margin: 15px 0px;
        width: 100%;
    }

    &__button-subcontainer{
        width: 100%;
        display: flex;
        padding-bottom: 30px;
        justify-content: flex-start;
    }

    &__button-container{
        width: 100%;
        display: flex;


        &__left{
            justify-content: left;
            margin-right: auto;
            display: flex;

            &__box{
                display: flex;
                color: #C8CAD1;
                cursor: pointer;

                &:hover {
                    color: #000;
                }
            }

            &__text{
                font-size: 12px;
                margin-left: 5px;
            }


        }

        &__right{
            justify-content: right;
            display: flex;
        }
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #C8CAD1;
        &:hover {
            color: #000;
        }
    }

    &__button-cancel {
        color: #C8CAD1;

        &:hover {
            color: #000;
        }
    }

}

.btn-list {
    &__item {
        color: #C8CAD1;
        font-size: 12px;
        cursor: pointer;
        margin-right: 20px;
        transition: all .25s;

        &:first-child {
            margin-right: auto;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: #000;
        }
    }
}
</style>
