<template>
    <div
        class="sidebar"
        :class="getClasses"
    >
        <div class="sidebar__logo-wrap">
            <img
                src="@/assets/images/logo.svg"
                class="sidebar__logo"
            />
        </div>
        <div class="sidebar__items">
            <SidebarItem
                v-for="(item, index) of sidebarItems"
                :key="index"
                :label="item.label"
                :path="item.path"
                :icon="item.icon"
                :isHideLabel="isHide"
            />
        </div>
        <div
            class="sidebar__rull-up"
            @click="onHide"
        >
            <div class="sidebar__rull-up-icon">
                <BaseIcon icon="icon-tabler-icon-chevron-left" size="md" />
            </div>
            <span class="sidebar__rull-up-label">Свернуть</span>
        </div>
    </div>
</template>

<script>
import SidebarItem from './SidebarItem'
import BaseIcon from '@/components/Base/BaseIcon'

export default {
    name: 'DefaultLayoutSidebar',
    components: {
        SidebarItem,
        BaseIcon
    },
    props: {
        sidebarItems: {
            type: Array,
            required: true
        },
        isHide: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getClasses() {
            return {
                'sidebar_hide': this.isHide
            }
        }
    },
    methods: {
        onHide() {
            this.$emit('onHide', !this.isHide)
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar {
    background: #fff;
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    border-right: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    padding: 14px 5px;
    transition: all 250ms;

    &__logo-wrap {
        margin-left: 10px;
    }

    &__logo {
        width: 20px;
    }

    &__items {
        margin-top: 120px;
    }

    &__rull-up {
        display: flex;
        align-items: center;
        margin-top: auto;
        cursor: pointer;
        height: 40px;
        padding: 0 13px;
        border-radius: 100px;

        &:hover {
            background: #f3f3f3;
        }

        &-icon {
            display: flex;
        }

        &-label {
            margin-left: 8px;
            display: block;
            font-size: 12px;
        }
    }
}
.sidebar_hide {
    width: 50px;

    .sidebar__rull-up-label {
        display: none;
    }

    .sidebar__rull-up-icon {
        transform: scale(-1,1);
    }
}
</style>
