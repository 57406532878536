<!-- eslint-disable no-debugger -->
<template>
    <div class="container-poi">
        <WidgetMapPoi
            title="POI"
            ref="widgetMapPoi"
            :is-empty="isEmpty"
            :head-menu-items="headerDropdown"
            :head-menu-create-items="headerCreateDropdown"
            :widget-map-tabs="widgetMapTabs"
            :widget-map-active-tab-id="widgetMapActiveTabId"
            :items-list="dataGroups"
            :children-list="childrenList"
            :active-items-list="checkedParentList"
            :active-children-list="checkedChildrenList"
            :moi-poi-items="moiPoiItems"
            :active-color-item-id="activeColorItemId"
            :dropdown-options="itemsDropdown"
            :children-dropdown-options="childrenItemsDropdown"
            :icons="dataIcons"
            :marker-params="markerParams"
            :radius="radius"
            :radiusDirectionType="radiusDirectionType"
            :widget-poi-config="widgetPoiConfig"
            :item-config="itemConfig"
            :children-config="childrenConfig"
            :is-loading="isLoading"
            :is-groups-checked="isAllGroupsChecked"
            :range="range"
            :additional-filter-options="additionalFilterOptions"
            :selected-additional-filter="selectedAdditionalFilter"
            :additional-filter-title="'Выберите город'"
            class="widget-map-poi"
            @onActionButton="onActionButton"
            @onEmptyContentButton="onEmptyContentButton"
            @onDropdownOption="onDropdownOption"
            @onDropdownChildOption="onDropdownChildOption"
            @onChecked="onCheckedGroup"
            @onCheckedChild="onCheckedPoi"
            @onToggleTitle="onToggleShowPoiList"
            @onTitleChildren="onTitleChildren"
            @onShowColorPanel="onShowColorPanel"
            @onFilterPoiRadius="filterPoiRadius"
            @onClickTab="onClickTab"
            @onChangeRadiusDirectionType="onChangeRadiusDirectionType"
        />

        <ColorPanel
            v-if="isShowColorPanel && !isShare"
            :title="colorPanelTitle"
            :active-color="activeColor"
            :icons="shortDataIcons"
            :config="colorPanelConfig"
            :is-loading="isLoading"
            class="color-panel"
            @onClose="onCloseColorPanel"
            @onChangeColor="changeColorMarker"
            @onChangeIcon="changeIconMarker"
            @onSubmitFile="fetchPoiFileIcon"
            @onRemoveIcon="fetchRemoveIcon"
            v-click-outside="onCloseColorPanel"
        />

        <AddPoiModal
            v-if="isShowAddPoiModal && !isShare"
            :poi-tabs-list="poiTabsList"
            :active-tab-id="activeTabId"
            :icons="shortDataIcons"
            :is-loading="isLoadingAddList"
            :upload-icon-id="uploadIconId"
            :isLoadingIcon="isLoadingIcon"
            :isLoadingRequest="isWaitingAutoCompleteResponse"
            :requestItems="requestItems"
            :requestId="requestId"
            :requestError="requestError"
            :iconUploadErrors="iconUploadErrors"
            :file-composing-type="fileComposingType"
            :composingType="fileComposingType"
            @onCloseModal="isShowAddPoiModal = false"
            @onTabClickAction="(id) => (activeTabId = id)"
            @onCreateNewGroup="createNewGroup"
            @onUploadIcon="onUploadIcon"
            @onAutoComplete="onAutoComplete"
            @onCreateNewMoiPoiGroup="createNewMoiPoiGroup"
            @onCreateNewFileGroup="onCreateNewFileGroup"
            @onChangeFileComposingType="onChangeFileComposingType"
        />

        <AddMoiPoiPointsModal
            v-if="isShowAddMoiPoiPointsModal && !isShare"
            :user-tags="userTags"
            :clicked-moi-poi-coords="clickedMoiPoiCoords"
            :is-loading="isLoadingAddList"
            :is-loading-request="isWaitingMoiPoiAutoCompleteResponse"
            :is-loading-tag-request="isWaitingMoiPoiTagsAutoCompleteResponse"
            :request-items="requestMoiPoiItems"
            :request-error="requestMoiPoiError"
            :tags-request-error="tagsMoiPoiRequestError"
            @onCloseModal="isShowAddMoiPoiPointsModal = false"
            @onAddPoint="addMoiPoints"
            @onAutoComplete="onAutoMoiPoiComplete"
            @openedTags="openedTags"
        />

        <CheckDataFromFileModal
            v-if="isShowCheckDataFromFileModal && !isShare"
            :is-loading="isLoadingAddList"
            :columns="fileTableColumns"
            :table-data="fileTableData"
            :file="uploadedFile"
            :file-composing-type="fileComposingType"
            @onCloseModal="isShowCheckDataFromFileModal = false"
            @onComposeFileData="onComposeFileData"
        />

        <RealtimeSystemPoi :query="queryPoi" @poiUpdate="poiUpdate" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AddPoiModal from "./AddPoiModal";
import AddMoiPoiPointsModal from "./AddMoiPoiPointsModal";
import CheckDataFromFileModal from "./CheckDataFromFileModal";
import schemeWidgetMap from "../../schemes/SchemeWidgetMap";
import ServicePoi from "../../../../services/ServicesPoi/ServicePoi";
import ServicePoiSelector from "../../../../services/ServicesPoi/ServicePoiSelector";
import RealtimeSystemPoi from "../../RealtimeSystem/RealtimeSystemPoi.vue";
import containerPoiSchemes from "../../schemes/SchemeWidgetMap";
const { containerPoi } = containerPoiSchemes;

const ACTIVE_TAB_ID = containerPoi.poiTabsList[0].id;
const MOI_POI_TAB = "moi";
const ALL_POI_TAB = "all";
const OOHDESK_POI_TAB = "oohdesk";

export default {
    name: "ContainerPoi",
    components: {
        AddPoiModal,
        AddMoiPoiPointsModal,
        RealtimeSystemPoi,
        CheckDataFromFileModal,
    },
    props: {
        clickedMoiPoiCoords: {
            type: Array,
            default: () => [],
        },
    },
    /**
     * Данные компонента
     * @property {Number} localCityId - ID текущего города
     * @property {array} dataGroups - Список всех групп
     * @property {array} dataPoints - Список POI
     * @property {array} dataIcons - Список всех иконок POI
     * @property {array} checkedParentList - Список отмеченных чекбосов групп
     * @property {String} radius - Радиус POI
     * @property {Object} markerParams - Цвет или кастомная иконка маркера POI
     * @property {Boolean} isLoading - Показать/скрыть прелоадер
     * @property {Boolean} isLoadingAddList - Показать/скрыть прелоадер списка групп доступных для добавления
     * @property {Boolean} isShowAddPoiModal - Показать/скрыть виджет POI
     * @property {String} activeColorItemId - id poi где активный colorBox
     * @property {String} uploadIconId - id иконки после выполнения UploadIcon
     * @property {Object} widgetPoiConfig - конфиг для widgetPoi
     * @property {Object} itemConfig - конфиг для групп
     * @property {Object} childrenConfig - конфиг для poi
     * @property {Object} colorPanelConfig - конфиг для ColorPanel
     * @property {Object} groupMarkers - буфер для маркеров пои
     * @property {String} childrenGroupId - id группы для аккордеона
     *
     * @property {Boolean} isWaitingAutoCompleteResponse - ожидание ответа от запроса на поиск группы
     * @property {Array} requestItems - найденные похожие подзапросы
     * @property {Number} requestId - id запроса к сервису
     * @property {Number} waitingSocketTimerId - id таймера ожидания сокетов
     * @property {String} Текст ошибки выполнения запроса
     * @property {Array} iconUploadErrors - ошибки валидации иконки
     */
    data: () => ({
        localCityId: null,
        dataGroups: [],
        recentCreatedMoiPoiGroup: [],
        dataIcons: [],
        checkedParentList: [],
        radius: "10",
        radiusDirectionType: "",

        userTags: [],

        isLoading: false,
        isLoadingAddList: false,
        isShowAddPoiModal: false,
        isShowAddMoiPoiPointsModal: false,
        isShowCheckDataFromFileModal: false,
        isLoadingIcon: false,
        activeColorItemId: "",
        activeColor: "",
        uploadIconId: "",
        range: {
            min: "10",
            max: "5000",
        },
        widgetPoiConfig: {
            isShowRadius: true,
            isShowAdditionFilter: true,
            isRadiusDirection: true,
            isShowAddButton: true
        },
        itemConfig: {
            isShowColorBox: true,
            isShowCountBox: true,
            isShowDropdown: true,
            isShowChildren: true,
        },
        childrenConfig: {
            isShowColorBox: false,
            isShowCountBox: false,
            isShowDropdown: false,
            isShowChildren: false,
        },
        colorPanelConfig: {
            isShowColorBlock: true,
            isShowIconBlock: true,
            isShowFigureBlock: false,
        },
        isMounted: false,
        additionalFilterOptions: [],
        // selectedAdditionalFilter: "",
        groupMarkers: {},
        childrenGroupId: "",
        isWaitingAutoCompleteResponse: false,
        isWaitingMoiPoiAutoCompleteResponse: false,
        isWaitingMoiPoiTagsAutoCompleteResponse: false,
        requestItems: [],
        requestMoiPoiItems: [],
        requestId: null,
        waitingSocketTimerId: null,
        moiPoiWaitingSocketTimerId: null,
        moiPoiTagsWaitingSocketTimerId: null,
        requestError: "По Вашему запросу ничего не найдено",
        requestMoiPoiError: "По Вашему запросу ничего не найдено",
        tagsMoiPoiRequestError:
            "По Вашему запросу ничего не найдено. Создайте новый тег при клике на него",
        iconUploadErrors: [],
        queryPoi: "",
        queryMoiPoi: "",
        queryMoiPoiTags: "",
        widgetMapTabs: [
            {
                id: ALL_POI_TAB,
                name: "Все",
                emit: "",
            },
            {
                id: MOI_POI_TAB,
                name: "Мои",
                emit: "onAddNewMoiPoiGroup",
            },
            {
                id: OOHDESK_POI_TAB,
                name: "Oohdesk",
                emit: "onAddNewGroup",
            },
        ],
        widgetMapActiveTabId: ALL_POI_TAB,
        moiPoiItems: [],
        poiTabsList: containerPoi.poiTabsList,
        activeTabId: ACTIVE_TAB_ID,
        isAllGroupsCheckbox: false,
        fileComposingType: "address",
        fileData: {},
        uploadedFile: {},
        filePoiRequestBody: {},
        currentPoiGroupId: ''
    }),

    /**
     * После рендере компонента получить данные от сервера
     */
    mounted() {
        // this.getMountCities();
        if (!this.isShare) {
            this.getIcons();
        } else {
            this.widgetPoiConfig.isShowAddButton = false;
                // this.widgetPoiConfig.isShowAdditionFilter = false;
            this.itemConfig.isShowDropdown = false;
            ServicePoi.getGroups(0, (groups) => {
                groups.forEach((group) => {
                    const { iconId = null, key = "", src = "" } = group;
                    if (iconId !== null) {
                        this.dataIcons.push({
                            iconId,
                            src,
                        });
                        group.src = src;
                    }
                    this.dataGroups.push(group);
                    this.onCheckedGroup(true, key);
                });
                this.isLoading = false;
            });

            ServicePoi.getMoiPoiGroups(0, (groups) => {
                groups.forEach((group) => {
                    const { iconId = null, key = "", src = "" } = group;
                    if (iconId !== null) {
                        this.dataIcons.push({
                            iconId,
                            src,
                        });
                        group.src = src;
                    }
                    this.moiPoiItems.push(group);
                    this.onCheckedGroup(true, key);
                });
                this.isLoading = false;
            });
        }

        this.$emit(
            "changeActiveList",
            "poi",
            this.checkedParentList.length > 0
        );
        this.$emit(
            "changeActiveList",
            "poi",
            this.checkedChildrenList.length > 0
        );

        this.isAllGroupsCheckbox = this.isAllGroupsChecked;
    },

    watch: {
        selectedAdditionalFilter() {
            this.checkedParentList.forEach((groupId) => {
                this.$emit("onClearPoiMarkers", groupId);
            });
            this.checkedParentList = [];
            this.groupMarkers = {};
            this.dataGroups = [];
            this.moiPoiItems = [];

            this.$refs.widgetMapPoi.isCanReset = false;
            this.$emit("onFilterPoiRadius", 0);
            this.radius = "10";

            this.getGroups(0);
            this.getMoiPoiGroups(0);
        },
        checkedParentList() {
            this.$emit(
                "changeActiveList",
                "poi",
                this.checkedParentList.length > 0
            );
        },
        // checkedChildrenList() {
        //     this.$emit(
        //         "changeActiveList",
        //         "poi",
        //         this.checkedChildrenList.length > 0
        //     );
        // },
    },

    computed: {
        ...mapState({ filterSelectedData: state => state.filter.filterSelectedData }),
        selectedAdditionalFilter() {
            const cities = this.filterSelectedData?.city_id ?? [];
            return String(cities[0] ?? '');
        },
        isAllGroupsChecked() {
            return (
                this.currentPoiItems?.length === this.checkedParentList?.length
            );
        },

        widgetMapActiveTab() {
            return this.widgetMapTabs.find(
                (tab) => tab.id === this.widgetMapActiveTabId
            );
        },

        allPoiItems() {
            return [...this.moiPoiItems, ...this.dataGroups];
        },

        currentPoiItems() {
            if (this.widgetMapActiveTabId === OOHDESK_POI_TAB) {
                return this.itemsList;
            } else if (this.widgetMapActiveTabId === MOI_POI_TAB) {
                return this.moiPoiItems;
            }
            return this.allPoiItems;
        },

        isShare() {
            const { query = {} } = this.$route;
            const { shareToken = null } = query;
            return shareToken !== null;
        },

        colorPanelTitle() {
            if (!this.activeColorItemId) {
                return "";
            }
            return this.currentPoiItems.filter(
                (dataGroup) => this.activeColorItemId === dataGroup.key
            )[0].name;
        },
        isShowColorPanel() {
            return this.activeColorItemId !== "";
        },
        childrenList() {
            if (!this.childrenGroupId) {
                return [];
            }
            let dataPoints = this.groupMarkers[this.childrenGroupId] || [];
            const returnData = dataPoints.map((dataPoint) => {
                const {
                    categories = [],
                    extraData = {},
                    isMoiPoi = false,
                } = dataPoint;
                const { id = "", groupId: key = "", address = "" } = extraData;
                return {
                    id,
                    key,
                    categories,
                    description: address,
                    isMoiPoi,
                };
            });
            return returnData;
        },
        // childrenListAll() {
        //     if(!this.childrenGroupId){
        //         return [];
        //     }

        //     const groupIdsMarkers = Object.keys(this.groupMarkers).filter(groupId => String(groupId) !== String(this.childrenGroupId));
        //     return groupIdsMarkers.reduce((childrenListAll, groupId) => {
        //         const markers = this.groupMarkers[groupId] ?? [];
        //         markers.forEach(marker => {
        //             const {extraData = {}} = marker;
        //             const {id = '', groupId = '', address = ""} = extraData;
        //             childrenListAll.push({id: String(id), key: String(groupId), description: String(address)});
        //         });

        //         return childrenListAll;
        //     }, []);
        // },
        // activeChildrenList() {
        //     return Object.values(this.groupMarkers).reduce((activeChildrenList, markers) => {
        //         markers.forEach(marker => {
        //             const {extraData = {}} = marker;
        //             const {id = '', groupId = '', isActive = true} = extraData;
        //             if (isActive)
        //                 activeChildrenList.push({id: String(id), key: String(groupId)});
        //         });

        //         return activeChildrenList;
        //     }, []);
        // },
        checkedChildrenList() {
            if (!this.childrenGroupId) {
                return [];
            }
            let dataPoints = this.groupMarkers[this.childrenGroupId] || [];
            return dataPoints.reduce((checkedPois, dataPoint) => {
                const { extraData = {} } = dataPoint;
                const { id = "", isActive = true, groupId = "" } = extraData;
                if (isActive) {
                    checkedPois.push({ id, key: groupId });
                }
                return checkedPois;
            }, []);
        },
        /**
         * Показать заглушку если нет групп в списке
         */
        isEmpty() {
            return !this.isLoading && this.allPoiItems.length === 0;
        },

        /**
         *
         */
        markerParams() {
            return this.dataGroups.map((dataGroup) => {
                return {
                    key: dataGroup.key,
                    color: dataGroup.color,
                    iconId: dataGroup.iconId,
                    src: dataGroup.src,
                    figure: "square",
                };
            });
        },

        /**
         * Элементы Dropdown
         */
        headerDropdown() {
            const { headerDropdownItems = [] } = schemeWidgetMap.containerPoi;
            return this.isShare ? [] : headerDropdownItems;
        },
        headerCreateDropdown() {
            const { headerCreateDropdownItems = [] } =
                schemeWidgetMap.containerPoi;
            return this.isShare ? [] : headerCreateDropdownItems;
        },

        /**
         * Элементы Dropdown
         */
        itemsDropdown() {
            const { groupMenuItems = [] } = schemeWidgetMap.containerPoi;
            return groupMenuItems;
        },

        /**
         * Элементы Dropdown
         */
        childrenItemsDropdown() {
            const { poiMenuItems = [] } = schemeWidgetMap.containerPoi;
            return poiMenuItems;
        },

        /**
         * Длинна массива отмеченных групп пои
         */
        checkedParentListLength() {
            return this.checkedParentList.length;
        },

        /**
         * Длинна массива списка групп пои
         */
        dataGroupsLength() {
            return this.dataGroups.length;
        },

        /**
         * Обрезать массив иконок до последних 5
         */
        shortDataIcons() {
            if (this.dataIcons.length > 0) {
                const sortIcons = [...this.dataIcons]
                    .sort(
                        (prevIcon, nextIcon) =>
                            Number(prevIcon.iconId) - Number(nextIcon.iconId)
                    )
                    .slice(-5);
                return sortIcons;
            }
            return [];
        },
        fileTableColumns() {
            // const { titles = [] } = this.fileData;
            // return Object.values(titles);

            return this.fileData.preview[0];
        },
        fileTableData() {
            // const { preview = [] } = this.fileData;
            // return [...preview];

            return [...this.fileData.preview].splice(1);
        },
    },
    methods: {
        /**
         * Клик по кнопке в компоненнте BaseActionButtons
         * @param {String} emitName - Имя события кнопки
         */
        onActionButton(emitName) {
            if (emitName === "onRemoveSelected") {
                this.checkedParentList.forEach((groupId) => {
                    this.onDropdownOption("onRemoveGroupPoi", groupId);
                });
            }
            if (emitName === "onRemoveAll") {
                this.currentPoiItems.forEach((group) => {
                    this.onDropdownOption("onRemoveGroupPoi", group.id);
                });
            }
            if (emitName === "onSelectAll") {
                this.isAllGroupsCheckbox = !this.isAllGroupsCheckbox;
                this.currentPoiItems.forEach((group) => {
                    this.onCheckedGroup(!this.isAllGroupsCheckbox, group.id);
                });
            }
            if (emitName === "reset") {
                this.$emit("onFilterPoiRadius", 0);
                this.radius = "10";
            }
        },

        /**
         * Клик по кнопке в компоненте BaseEmpty
         */
        onEmptyContentButton() {
            this.isShowAddPoiModal = true;
        },

        /**
         * Клик по пункту в dropdown группы
         * @param {string} methodName - название функции обработчика события
         * @param {string} groupId - id элемента по которому произошел клик
         */
        onDropdownOption(methodName, data) {
            if (methodName === "onClosePoi") {
                this.$emit(methodName);
            }
            if (methodName === "onRemoveAll") {
                this.onActionButton(methodName);
            }
            if (methodName === "onAddNewGroup") {
                this.isShowAddPoiModal = true;
            }
            if (methodName === "onAddNewCurrentGroup") {
                this.activeTabId = data === "all" ? "empty" : data;
                this.isShowAddPoiModal = true;
            }
            if (methodName === "onAddNewMoiPoint") {
                this.recentCreatedMoiPoiGroup = this.moiPoiItems.find(
                    (item) => item.id === data
                );
                this.isShowAddMoiPoiPointsModal = true;
            }
            if (methodName === "onRemoveGroupPoi") {
                this.removePoiGroup(data);
                if (!this.isShare)
                    ServicePoiSelector.unsetPoiGroupChecked(
                        this.selectedAdditionalFilter,
                        data
                    );
            }
        },

        /**
         * Клик по пункту в dropdown poi
         * @param {string} methodName - название функции обработчика события
         * @param {string} childId - id элемента по которому произошел клик
         */
        // eslint-disable-next-line no-unused-vars
        onDropdownChildOption(methodName, childId) {
            console.log(methodName, childId);
            // if (methodName === "removePoi") {
            // this.removePoi(childId);
            // }
        },

        /**
         * Удаление группы пои
         *
         * @param {String} groupId
         */
        removePoiGroup(groupId) {
            this.isLoading = true;
            const isMoiPoi = this.moiPoiItems.find(
                (item) => item.id === groupId
            );

            if (isMoiPoi) {
                ServicePoi.removeMoiPoiGroup(groupId, () => {
                    this.$emit("onClearPoiMarkers", groupId);
                    this.moiPoiItems = this.moiPoiItems.filter((dataGroup) => {
                        const { key = "" } = dataGroup;
                        return key !== groupId;
                    });
                    let groupMarkers = { ...this.groupMarkers };
                    if (Object.keys(groupMarkers).includes(groupId)) {
                        delete groupMarkers[groupId];
                    }
                    this.isLoading = false;
                });
            } else {
                ServicePoi.removeGroup(groupId, () => {
                    this.$emit("onClearPoiMarkers", groupId);
                    this.dataGroups = this.dataGroups.filter((dataGroup) => {
                        const { key = "" } = dataGroup;
                        return key !== groupId;
                    });
                    let groupMarkers = { ...this.groupMarkers };
                    if (Object.keys(groupMarkers).includes(groupId)) {
                        delete groupMarkers[groupId];
                    }
                    this.isLoading = false;
                });
            }
        },

        /**
         * Обработка клика по чекбоксу poi
         * @async
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} poiId - id poi
         */
        async onCheckedPoi(isChecked, poiId) {
            this.checkPoi(isChecked, poiId, this.childrenGroupId, true);
        },

        checkPoi(isChecked, poiId, childrenGroupId, isUpdateStore = false) {
            let groupMarkers = { ...this.groupMarkers };
            let dataPoints = groupMarkers[childrenGroupId] || [];
            let markerIndex = dataPoints.findIndex((dataPoint) => {
                const { extraData = {} } = dataPoint;
                const { id = "" } = extraData;
                return id === poiId;
            });
            let updatingMarker = dataPoints[markerIndex];
            updatingMarker.extraData.isActive = isChecked;
            dataPoints.splice(markerIndex, 1, updatingMarker);
            groupMarkers[childrenGroupId] = [...dataPoints];
            this.groupMarkers = { ...groupMarkers };
            this.$emit("onTogglePoiMarker", updatingMarker);

            const isCheckedGroup = this.checkedParentList.includes(
                String(childrenGroupId)
            );
            const dataPointsChecked = dataPoints.filter((dataPoint) => {
                const { extraData = {} } = dataPoint;
                const { isActive = false } = extraData;
                return Boolean(isActive);
            });

            if (!isCheckedGroup && isChecked)
                this.checkedParentList.push(childrenGroupId);
            else if (isCheckedGroup && dataPointsChecked.length === 0) {
                const groupCheckedIndex = this.checkedParentList.findIndex(
                    (groupId) => String(groupId) === String(childrenGroupId)
                );
                this.checkedParentList.splice(groupCheckedIndex, 1);
            }

            if (isUpdateStore && !this.isShare) {
                if (isChecked)
                    ServicePoiSelector.setPoiMarkerChecked(
                        this.selectedAdditionalFilter,
                        childrenGroupId,
                        poiId
                    );
                else
                    ServicePoiSelector.unsetPoiMarkerChecked(
                        this.selectedAdditionalFilter,
                        childrenGroupId,
                        poiId
                    );
            }
        },

        /**
         * Обработка клика по названию poi
         * @param {String} childId - id poi
         */
        onTitleChildren(childId) {
            console.log("onTitleChildren", childId);
        },

        /**
         * Клик по colorLabel
         * @param {String} itemId - id элемента
         */
        onShowColorPanel(itemId = "") {
            this.activeColorItemId = itemId;
        },

        /**
         * Фильтрация размещений по выбранным точкам poi
         * @param {String} radius - радиус поиска poi
         **/
        filterPoiRadius(radius) {
            this.radius = String(radius);
            this.$emit("onFilterPoiRadius", Number(radius), this.radiusDirectionType);
        },

        /**
         * Смена города
         *
         * @param {Object} city
         */
        // onCheckAdditionalFilter(city) {
        //     const { id } = city;
        //     this.selectedAdditionalFilter = id;
        // },

        /**
         * Клик по закрыть ColorPanel
         */
        onCloseColorPanel() {
            this.activeColorItemId = "";
        },

        /**
         * Изменить цвет маркера
         * @param {String} color - Цвет маркера
         */
        changeColorMarker(color = "000000") {
            this.isLoading = true;
            const moiPoiItem = this.moiPoiItems.find(
                (item) => item.id === this.activeColorItemId
            );

            if (moiPoiItem) {
                ServicePoi.updateMoiPoiGroup(
                    {
                        group_id: Number(this.activeColorItemId),
                        color,
                        name: moiPoiItem.name,
                    },
                    () => {
                        ServicePoi.unlinkMoiPoiIcon(
                            Number(this.activeColorItemId),
                            () => {
                                this.setColorAndUnlinkGroup(
                                    this.activeColorItemId,
                                    color
                                );
                                this.isLoading = false;
                            }
                        );
                    }
                );
            } else {
                ServicePoi.updateGroup(
                    { group_id: Number(this.activeColorItemId), color },
                    () => {
                        ServicePoi.unlinkIcon(
                            Number(this.activeColorItemId),
                            () => {
                                this.setColorAndUnlinkGroup(
                                    this.activeColorItemId,
                                    color
                                );
                                this.isLoading = false;
                            }
                        );
                    }
                );
            }
        },

        setColorAndUnlinkGroup(groupId, color) {
            const isMoiPoi = this.moiPoiItems.find(
                (item) => item.id === groupId
            );

            if (isMoiPoi) {
                this.moiPoiItems
                    .filter((dataGroup) => dataGroup.key === groupId)
                    .forEach((dataGroup) => {
                        dataGroup.color = color;
                        dataGroup.src = "";
                        dataGroup.iconId = "";
                    });
            } else {
                this.dataGroups
                    .filter((dataGroup) => dataGroup.key === groupId)
                    .forEach((dataGroup) => {
                        dataGroup.color = color;
                        dataGroup.src = "";
                        dataGroup.iconId = "";
                    });
            }

            if (Object.keys(this.groupMarkers).includes(groupId)) {
                let markers = { ...this.groupMarkers };
                markers[this.activeColorItemId].forEach((marker) => {
                    marker.fill_color = color;
                    marker.iconType = "poi";
                    marker.src = "";
                });
                this.groupMarkers = { ...markers };
                this.$emit("onUpdateColorMarkers", groupId, color);
            }
        },

        /**
         * Изменить иконку маркера
         * @param {Object} params - Параметры иконки маркера
         */
        changeIconMarker(params = {}) {
            const { iconId, src } = params;
            const isMoiPoi = this.moiPoiItems.find(
                (item) => item.id === this.activeColorItemId
            );
            this.isLoading = true;

            if (isMoiPoi) {
                ServicePoi.linkMoiPoiIcon(
                    this.activeColorItemId,
                    iconId,
                    () => {
                        this.moiPoiItems
                            .filter(
                                (dataGroups) =>
                                    dataGroups.key === this.activeColorItemId
                            )
                            .forEach((dataGroups) => {
                                dataGroups.src = src;
                                dataGroups.iconId = iconId;
                            });
                    }
                );
            } else {
                ServicePoi.linkIcon(this.activeColorItemId, iconId, () => {
                    this.dataGroups
                        .filter(
                            (dataGroups) =>
                                dataGroups.key === this.activeColorItemId
                        )
                        .forEach((dataGroups) => {
                            dataGroups.src = src;
                            dataGroups.iconId = iconId;
                        });
                });
            }

            if (
                Object.keys(this.groupMarkers).includes(this.activeColorItemId)
            ) {
                let markers = { ...this.groupMarkers };
                markers[this.activeColorItemId].forEach((marker) => {
                    marker.src = src;
                    marker.iconType = "poiIcon";
                });
                this.groupMarkers = { ...markers };
                this.$emit("onUpdateIconMarkers", this.activeColorItemId, src);
            }
            this.isLoading = false;
        },

        /**
         * Удалить иконку из списка
         * @param {object} params - параметры удаляемой иконки
         */
        async fetchRemoveIcon(params = {}) {
            const { iconId } = params;
            this.isLoading = true;

            ServicePoi.removeIcon(iconId);

            this.dataIcons = this.dataIcons.filter(
                (dataIcon) => String(dataIcon.iconId) !== String(iconId)
            );
            this.dataGroups
                .filter(
                    (dataGroup) =>
                        String(dataGroup.iconId) === String(iconId) && iconId
                )
                .forEach((dataGroup) => {
                    let { color, id } = dataGroup;
                    this.setColorAndUnlinkGroup(id, color);
                });
            this.isLoading = false;
        },

        /**
         * Добавление группы
         * @async
         * @param {Object} requestBody - параметры новой группы
         */
        async createNewGroup(requestBody) {
            this.isLoading = true;
            this.isShowAddPoiModal = false;
            requestBody.city_id = this.selectedAdditionalFilter;
            ServicePoi.createGroup(
                requestBody,
                (group, newMarkers, pageCount) => {
                    this.getGroups();
                    const { key: groupId = "" } = group;
                    this.dataGroups.push(group);
                    this.checkedParentList.push(groupId);
                    this.appendAndDrawNewMarkers(groupId, newMarkers);
                    this.setPoiGroupChecked(groupId);
                    if (pageCount > 1) {
                        this.getPoiMarkers(groupId, 2, (markers) => {
                            this.appendAndDrawNewMarkers(groupId, markers);
                            this.setPoiGroupChecked(groupId);
                        });
                    }
                    this.isLoading = false;
                }
            );
        },

        createNewMoiPoiGroup(requestBody) {
            this.isLoading = true;
            this.isShowAddPoiModal = false;
            requestBody.city_id = this.selectedAdditionalFilter;
            ServicePoi.createMoiPoiGroup(requestBody, (group) => {
                const { key: groupId = "" } = group;
                this.moiPoiItems.push(group);
                this.checkedParentList.push(groupId);
                this.appendAndDrawNewMarkers(groupId, []);
                this.setPoiGroupChecked(groupId);
                this.isLoading = false;
                this.recentCreatedMoiPoiGroup = this.moiPoiItems.find(
                    (item) => item.id === groupId
                );
                this.isShowAddMoiPoiPointsModal = true;
            });
        },

        onCreateNewFileGroup(requestBody) {
            this.uploadedFile = requestBody.file;
            this.filePoiRequestBody = requestBody;

            this.isLoading = true;
            requestBody.city_id = this.selectedAdditionalFilter;
            // ServicePoi.createGroup(
            //     requestBody,
            //     (group, newMarkers, pageCount) => {
            //         this.getGroups();
            //         const { key: groupId = "" } = group;
            //         this.currentPoiGroupId = String(groupId);
            //         this.dataGroups.push(group);
            //         this.checkedParentList.push(groupId);
            //         this.appendAndDrawNewMarkers(groupId, newMarkers);
            //         this.setPoiGroupChecked(groupId);
            //         if (pageCount > 1) {
            //             this.getPoiMarkers(groupId, 2, (markers) => {
            //                 this.appendAndDrawNewMarkers(groupId, markers);
            //                 this.setPoiGroupChecked(groupId);
            //             });
            //         }
            //     }
            // );
            ServicePoi.uploadFile(requestBody.file, (result) => {
                console.log(result);

                this.fileData = result;

                this.isLoading = false;
                this.isShowAddPoiModal = false;
                this.isShowCheckDataFromFileModal = true;
            });
        },

        async addMoiPoints(pointParams) {
            this.isShowAddMoiPoiPointsModal = false;
            const requestBody = {
                city_id: this.selectedAdditionalFilter,
                group_id: this.recentCreatedMoiPoiGroup.id,
                lat: pointParams.lat,
                lng: pointParams.lng,
                name: pointParams.value,
                address: pointParams.value,
                categories: pointParams.categories.length
                    ? pointParams.categories
                    : [""],
            };
            await ServicePoi.moiPoiCreatePoint(requestBody, (data) => {
                this.checkedParentList.push(data[0].extraData.groupId);
                this.setPoiGroupChecked(data[0].extraData.groupId);
                this.appendAndDrawNewMarkers(data[0].extraData.groupId, data);
                this.getMoiPoiGroups();

                this.isLoading = true;
            });
        },

        onChangeFileComposingType(composingType) {
            this.fileComposingType = composingType;
        },

        onComposeFileData(composeData) {
            this.isLoading = true;
            composeData.uniq_id = this.fileData.uniq_id;

            ServicePoi.createMoiPoiGroup(this.filePoiRequestBody, (group) => {
                const { key: groupId = "" } = group;
                this.moiPoiItems.push(group);
                // this.isLoading = false;
                this.recentCreatedMoiPoiGroup = this.moiPoiItems.find(
                    (item) => item.id === groupId
                );
                this.isShowCheckDataFromFileModal = false;

                ServicePoi.composeFile(composeData, ({ error = [], success: points = [] }) => {
                    if (error.length > 0) {
                        // alert('Во время импорта произошла ошибка. Проверьте корректность данных');
                        this.$notify.dNotify({
                            type: 'error',
                            title: 'Ошибка импорта',
                            description: 'Во время импорта произошла ошибка. Проверьте корректность данных',
                            duration: Infinity,
                            position: 'bottom-right'
                        });
                    }

                    ServicePoi.createPointsBatch(groupId, points, () => {
                        this.isLoading = false;
                        this.onCheckedGroup(true, groupId);
                    });

                    // points.forEach((point) =>
                    //     ServicePoi.moiPoiCreatePoint(
                    //         {
                    //             city_id: this.selectedAdditionalFilter,
                    //             group_id: groupId,
                    //             lat: point.lat,
                    //             lng: point.lng,
                    //             name: point.address,
                    //             address: point.address,
                    //             categories: point.categories.length
                    //                 ? point.categories
                    //                 : [""],
                    //         },
                    //         (data) => {
                    //             this.setPoiGroupChecked(
                    //                 data[0].extraData.groupId
                    //             );
                    //             this.appendAndDrawNewMarkers(
                    //                 data[0].extraData.groupId,
                    //                 data
                    //             );
                    //             this.getMoiPoiGroups();

                    //             this.isLoading = false;
                    //         }
                    //     )
                    // );
                });
            });
        },

        /**
         *
         */
        appendAndDrawNewMarkers(groupId, markers) {
            if (!Object.keys(this.groupMarkers).includes(groupId)) {
                this.groupMarkers[groupId] = [];
            }
            this.groupMarkers[groupId] = [
                ...this.groupMarkers[groupId],
                ...markers,
            ];
            this.$emit("onDrawPoiMarkers", markers);
        },

        /**
         * Загрузили с ПК иконку для маркера POI
         * @async
         * @param {File} fileIcon - цвет или id картинки для маркера POI
         */
        async fetchPoiFileIcon(fileIcon = null) {
            this.onUploadIcon(fileIcon, true);
        },

        /**
         * Выбор иконки из списка
         * @property {File} file - выбранная иконка
         * @property {Boolean} isAlertErrors
         */
        async onUploadIcon(file, isAlertErrors = false) {
            this.isLoadingIcon = true;
            this.isLoading = true;
            ServicePoi.uploadIcon(
                file,
                (icon) => {
                    this.dataIcons.push(icon);
                    this.uploadIconId = icon.iconId;
                    this.isLoadingIcon = false;
                    this.isLoading = false;
                    this.iconUploadErrors = [];
                },
                (errors) => {
                    this.isLoadingIcon = false;
                    this.isLoading = false;
                    let iconErrors = errors["icon"] ?? [];
                    if (!isAlertErrors) {
                        this.iconUploadErrors = iconErrors;
                    } else {
                        // alert(iconErrors.join(", "));
                        this.$notify.dNotify({
                            type: 'error',
                            title: 'Ошибка загрузки иконки',
                            description: iconErrors.join(", "),
                            duration: Infinity,
                            position: 'bottom-right'
                        });
                    }
                }
            );
        },

        /**
         * Автозаполнение похожих групп для запроса
         * @param {String} value - поисковой запрос
         */
        onAutoComplete(value) {
            if (!value) {
                this.queryPoi = "";
                return false;
            }
            this.queryPoi = String(value);
            this.requestId = null;
            this.requestItems = [];
            this.isWaitingAutoCompleteResponse = true;
            ServicePoi.autoComplete(
                value,
                this.selectedAdditionalFilter,
                (result) => {
                    const { requestId = null, resultItems = [] } = result;
                    if (requestId === null) {
                        this.waitingSocketTimerId = setTimeout(() => {
                            this.waitingSocketTimerId = null;
                            this.requestError =
                                "По Вашему запросу ничего не найдено";
                            this.isWaitingAutoCompleteResponse = false;
                        }, 60000);
                    } else {
                        this.requestId = requestId;
                        const requestItemsIds = this.requestItems.map(
                            (requestItem) => String(requestItem?.id)
                        );
                        const resultItemsFiltered = resultItems.filter(
                            (resultItem) =>
                                !requestItemsIds.includes(resultItem?.id)
                        );
                        // this.requestItems = resultItems;
                        this.requestItems.push(...resultItemsFiltered);
                        this.isWaitingAutoCompleteResponse = false;
                    }
                },
                (responseErrors) => {
                    this.isWaitingAutoCompleteResponse = false;
                    this.requestError = "По Вашему запросу ничего не найдено";
                    let keys = Object.keys(responseErrors);
                    if (keys.length) {
                        this.requestError = Array.isArray(
                            responseErrors[keys[0]]
                        )
                            ? responseErrors[keys[0]][0]
                            : responseErrors[keys[0]];
                    }
                }
            );
        },

        onAutoMoiPoiComplete(value) {
            if (!value) {
                this.queryMoiPoi = "";
                return false;
            }
            this.queryMoiPoi = String(value);
            this.requestMoiPoiItems = [];
            this.isWaitingMoiPoiAutoCompleteResponse = true;
            ServicePoi.getPoiAddress(value, (result) => {
                if (!result.length) {
                    this.moiPoiWaitingSocketTimerId = setTimeout(() => {
                        this.moiPoiWaitingSocketTimerId = null;
                        this.requestError =
                            "По Вашему запросу ничего не найдено";
                        this.isWaitingMoiPoiAutoCompleteResponse = false;
                    }, 60000);
                } else {
                    const requestItemsIds = this.requestMoiPoiItems.map(
                        (requestItem) => String(requestItem?.id)
                    );
                    const resultItemsFiltered = result.filter(
                        (resultItem) =>
                            !requestItemsIds.includes(resultItem?.id)
                    );
                    const resultItemsAdapted = resultItemsFiltered.map(
                        (item) => ({
                            id: item.data.city_fias_id,
                            value: item.value,
                            lat: item.data.geo_lat,
                            lng: item.data.geo_lon,
                        })
                    );
                    this.requestMoiPoiItems.push(...resultItemsAdapted);
                    this.isWaitingMoiPoiAutoCompleteResponse = false;
                }
            });
        },

        async openedTags() {
            await ServicePoi.getUserTags((result) => {
                this.userTags = result;
            });
        },

        poiUpdate(poiItems = []) {
            if (this.requestId === 0) {
                return false;
            }
            if (this.requestId === null) {
                this.requestId = 0;
                ServicePoi.autoComplete(
                    this.queryPoi,
                    this.selectedAdditionalFilter,
                    (result) => {
                        const { requestId = null } = result;
                        this.requestId = requestId;
                    }
                );
            }
            poiItems.sort(function(a, b) {
                return b.count - a.count;
            });
            this.requestItems = poiItems.reduce(
                (requestItems, item) => {
                    const requestItemIndex = requestItems.findIndex(
                        (requestItem) =>
                            String(requestItem?.id) === String(item?.id)
                    );

                    if (requestItemIndex !== -1)
                        requestItems.splice(requestItemIndex, 1, item);
                    else requestItems.push(item);

                    return requestItems;
                },
                [...this.requestItems]
            );

            this.isWaitingAutoCompleteResponse = false;
        },

        /**
         * Получение городов
         */
        async getMountCities() {
            this.isLoading = true;
            ServicePoi.getCities((cities) => {
                this.additionalFilterOptions = cities;
                // this.selectedAdditionalFilter = String(cities[0].id);
                this.isLoading = false;
            });
        },

        /**
         * Получение групп для текущего города
         */
        async getGroups() {
            this.isLoading = true;
            ServicePoi.getGroups(this.selectedAdditionalFilter, (groups) => {
                this.dataGroups = groups;
                this.isLoading = false;

                if (!this.isShare) {
                    const poiChecked = ServicePoiSelector.getPoiGroupsChecked(
                        this.selectedAdditionalFilter
                    );
                    const poiGroupdsIdsChecked = Object.keys(poiChecked);
                    poiGroupdsIdsChecked.forEach((groupId) => {
                        this.getPoiMarkers(groupId, 1, (markers) => {
                            const poiMarkersIdsChecked =
                                poiChecked[groupId] ?? [];
                            this.groupMarkers[groupId] = [
                                ...this.groupMarkers[groupId],
                                ...this.updateActivity(markers, false),
                            ];
                            markers.forEach((marker) => {
                                const { extraData = {} } = marker;
                                const {
                                    groupId: markerGroupId = "",
                                    id: markerId = "",
                                } = extraData;

                                if (
                                    String(markerGroupId) === String(groupId) &&
                                    poiMarkersIdsChecked.includes(
                                        String(markerId)
                                    )
                                )
                                    this.checkPoi(
                                        true,
                                        String(markerId),
                                        String(groupId),
                                        false
                                    );
                            });
                        });
                        this.checkedParentList.push(String(groupId));
                    });
                }
            });
        },

        getMoiPoiGroups() {
            this.isLoading = true;
            ServicePoi.getMoiPoiGroups(
                this.selectedAdditionalFilter,
                (groups) => {
                    this.moiPoiItems = groups;
                    this.isLoading = false;

                    if (!this.isShare) {
                        const poiChecked =
                            ServicePoiSelector.getPoiGroupsChecked(
                                this.selectedAdditionalFilter
                            );
                        const poiGroupdsIdsChecked = Object.keys(poiChecked);
                        poiGroupdsIdsChecked.forEach((groupId) => {
                            this.getPoiMarkers(groupId, 1, (markers) => {
                                const poiMarkersIdsChecked =
                                    poiChecked[groupId] ?? [];
                                this.groupMarkers[groupId] = [
                                    ...this.groupMarkers[groupId],
                                    ...this.updateActivity(markers, false),
                                ];
                                markers.forEach((marker) => {
                                    const { extraData = {} } = marker;
                                    const {
                                        groupId: markerGroupId = "",
                                        id: markerId = "",
                                    } = extraData;

                                    if (
                                        String(markerGroupId) ===
                                            String(groupId) &&
                                        poiMarkersIdsChecked.includes(
                                            String(markerId)
                                        )
                                    ) {
                                        this.checkPoi(
                                            true,
                                            String(markerId),
                                            String(groupId),
                                            false
                                        );
                                    }
                                });
                            });
                            this.checkedParentList.push(String(groupId));
                        });
                    }
                }
            );
        },

        /**
         * Получение групп для текущего города
         */
        async getIcons() {
            ServicePoi.getIconList((icons) => {
                this.dataIcons = icons ?? [];
            });
        },

        /**
         * Обработка клика по названию группы
         * Получение точек данной группы с бекенда
         * @async
         * @param {String} groupId - key группы
         */
        onToggleShowPoiList(groupId) {
            if (!Object.keys(this.groupMarkers).includes(groupId)) {
                this.groupMarkers[groupId] = [];
                this.getPoiMarkers(groupId, 1, (markers) => {
                    this.groupMarkers[groupId] = [
                        ...this.groupMarkers[groupId],
                        ...this.updateActivity(markers, false),
                    ];
                    this.childrenGroupId = String(groupId);
                });
            } else {
                this.childrenGroupId =
                    String(this.childrenGroupId) === String(groupId)
                        ? ""
                        : String(groupId);
            }
        },

        /**
         * Обработка клика по чекбоксу группы
         * @async
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} groupId - key группы
         */
        async onCheckedGroup(isChecked, groupId) {
            if (isChecked) {
                this.checkedParentList.push(groupId);
                this.drawMarkers(groupId, () => {
                    this.setPoiGroupChecked(groupId);
                });
            } else {
                this.checkedParentList = this.checkedParentList.filter(
                    (competitorParentActiveItem) =>
                        competitorParentActiveItem !== groupId
                );
                this.updateActivityByGroupId(groupId, false);
                this.$emit("onClearPoiMarkers", groupId);

                if (!this.isShare)
                    ServicePoiSelector.unsetPoiGroupChecked(
                        this.selectedAdditionalFilter,
                        groupId
                    );
            }
        },

        setPoiGroupChecked(groupId = "") {
            if (!this.isShare) {
                const poiMarkers = this.groupMarkers[groupId] ?? [];
                const poiMarkersIds = poiMarkers.map((poiMarker) =>
                    String(poiMarker?.id)
                );
                ServicePoiSelector.setPoiGroupChecked(
                    this.selectedAdditionalFilter,
                    groupId,
                    poiMarkersIds
                );
            }
        },

        /**
         * Отрисовка маркеров
         *
         * @param {String} groupId - id группы пои
         */
        drawMarkers(groupId, actionAfter = () => {}) {
            if (Object.keys(this.groupMarkers).includes(groupId)) {
                this.updateActivityByGroupId(groupId, true);
                actionAfter();
                this.$emit("onDrawPoiMarkers", this.groupMarkers[groupId]);
            } else {
                this.getPoiMarkers(groupId, 1, (markers) => {
                    this.appendAndDrawNewMarkers(groupId, markers);
                    actionAfter();
                });
            }
        },

        /**
         * Выбор иконки из списка
         * @property {String} groupId - выбранная иконка
         * @property {Number} defaultPage
         * @property {Function} actionAfter
         */
        async getPoiMarkers(groupId, defaultPage = 1, actionAfter = () => {}) {
            this.isLoading = true;
            const group = this.findGroup(groupId);
            this.groupMarkers[groupId] = [];
            const isMoiPoi = this.moiPoiItems.find(
                (item) => item.id === groupId
            );

            if (isMoiPoi) {
                ServicePoi.getMoiPoiPoints(
                    group,
                    !this.isShare ? this.selectedAdditionalFilter : 0,
                    (markers, pageCount, groupData = {}) => {
                        actionAfter(markers);
                        let countUploaded = defaultPage;
                        
                        const moiPoiItemIndex = this.moiPoiItems.findIndex(moiPoiItem => String(moiPoiItem?.id ?? '') === String(groupData?.id ?? ''));
                        this.moiPoiItems.splice(moiPoiItemIndex, 1, { ...this.moiPoiItems[moiPoiItemIndex], count: String(groupData?.poi_count ?? '') });
                        
                        for (
                            var page = defaultPage + 1;
                            page <= pageCount;
                            page++
                        ) {
                            ServicePoi.getMoiPoiPoints(
                                group,
                                page,
                                (stepMarkers) => {
                                    actionAfter(stepMarkers);
                                    countUploaded++;
                                    if (countUploaded >= pageCount) {
                                        this.isLoading = false;
                                    }
                                },
                                () => {
                                    this.isLoading = false;
                                    localStorage.removeItem("poiDataChecked");
                                }
                            );
                        }
                        if (defaultPage >= pageCount) {
                            this.isLoading = false;
                            localStorage.removeItem("poiDataChecked");
                        }
                    }
                );
            } else {
                ServicePoi.getPoints(
                    group,
                    defaultPage,
                    (markers, pageCount) => {
                        actionAfter(markers);
                        let countUploaded = defaultPage;
                        for (
                            var page = defaultPage + 1;
                            page <= pageCount;
                            page++
                        ) {
                            ServicePoi.getPoints(
                                group,
                                page,
                                (stepMarkers) => {
                                    actionAfter(stepMarkers);
                                    countUploaded++;
                                    if (countUploaded >= pageCount) {
                                        this.isLoading = false;
                                    }
                                },
                                () => {
                                    this.isLoading = false;
                                    localStorage.removeItem("poiDataChecked");
                                }
                            );
                        }
                        if (defaultPage >= pageCount) {
                            this.isLoading = false;
                        }
                    },
                    () => {
                        this.isLoading = false;
                        localStorage.removeItem("poiDataChecked");
                    }
                );
            }
        },

        /**
         *
         * @param {Array} markers - маркеры группы
         * @param {Boolean} isActive - флаг активности маркера
         */
        updateActivity(markers, isActive) {
            markers.forEach((marker) => {
                marker.extraData.isActive = isActive;
            });
            return markers;
        },

        /**
         * Изменение активности группы маркеров
         *
         * @param {Boolean} isActive - флаг активности маркера
         * @param {String} groupId - id группы маркеров
         */
        updateActivityByGroupId(groupId, isActive) {
            let markers = { ...this.groupMarkers };
            let groupMarkers = markers[groupId] || [];
            markers[groupId] = this.updateActivity(groupMarkers, isActive);
            this.groupMarkers = { ...markers };
        },

        /**
         * Поиск объекта с инфо о группе
         * @property {String} groupId - выбранная иконка
         */
        findGroup(groupId) {
            return this.allPoiItems.filter(
                (dataGroup) => dataGroup.key === groupId
            )[0] ?? null;
        },

        onClickTab(tabId) {
            this.widgetMapActiveTabId = tabId;
        },

        onChangeRadiusDirectionType(type) {
            this.radiusDirectionType = type;
        }
    },
};
</script>

<style lang="scss" scoped>
.container-poi {
    display: flex;
    align-items: flex-start;

    .widget-map-poi {
        margin-left: 10px;
    }

    .color-panel {
        margin-left: 10px;
    }
}
</style>
