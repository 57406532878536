<template>
	<div class="datepicker">
		<div class="header">
			<!-- Стрелка влево -->
			<div
				class="header__arrow-left"
				@click="onFlipThrough(-1)"
			>
                <span class="arrow-icon icon-tabler-icon-chevron-left"></span>
			</div>
			<!-- Название года -->
			<div
				class="header__period"
				@click="onToggleYearMod"
			><span>{{currentYear}}</span></div>
			<!-- Стрелка вправо -->
			<div
				class="header__arrow-right"
				@click="onFlipThrough(1)"
			>
                <span class="arrow-icon icon-tabler-icon-chevron-right"></span>
			</div>
		</div>
		<!-- Блок выбора года и месяца -->
		<div class="body">
			<div
				v-for="i of getTimeValues"
				:key="i"
				class="cell"
				:class="getCellClasses(i)"
				@click="onCheck(i)"
			>
				<span>{{i}}</span>
			</div>
		</div>
		<!-- Панель управления -->
		<div class="footer">
			<div class="footer__wrapper">
				<BaseButton
					v-if="isEdit"
					view="simple"
					@click="onReset"
				>Сбросить</BaseButton>
				<BaseButton
					v-if="isMultiple && isEdit"
					view="simple"
					@click="onSelectYear"
				>Выбрать год</BaseButton>
			</div>
			<div class="footer__btn-container">
				<BaseButton
					view="cancel"
					class="footer__cancel"
					@click="onCancel"
				>Закрыть</BaseButton>
				<BaseButton
					v-if="isEdit"
					view="secondary"
					form="oval"
					class="footer__ok"
					@click="onApprove"
				>Ок</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton"

export default {
	name: "DatePicker",
	components: {
		BaseButton,
	},
	props: {
		/**
		 * Ожидает массив объектов
		 * {year: 2021, month: 5},
		 */
		accessDates: {
			type: [Array, String],
			default: () => []
		},
		/**
		 * Ожидает массив объектов
		 * {year: 2021, month: 5},
		 */
		checkedDates: {
			type: Array,
			default: () => []
		},
		/* можно ли выбрать несколько месяцев */
		isMultiple: {
			type: Boolean,
			default: false
		},
		isEdit: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			months: [
				'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
				'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
			],
			yearMode: false,
			currentYear: new Date().getFullYear(),
			datesBeforeConfirm: this.checkedDates.slice(),
			datesAfterConfirm: this.checkedDates.slice(),
		}
	},
	computed: {
		/**
		 * Вернет либо массив месяцев, либо массив годов
		 */
		getTimeValues() {
			let result = []

			if (!this.yearMode) {
				result = this.months
			} else {
				const startYear = this.currentYear - 4

				for (let i = 0; i < this.months.length; i++) {
					result.push(startYear + i)
				}
			}

			return result
		},
		/**
		 * @returns {Array, Boolean}
		 */
		accessYears() {
			if (Array.isArray(this.accessDates)) {
				const allYears = this.accessDates.map(item => item.year)
				const uniqueYears = Array.from(new Set(allYears))
				return uniqueYears.sort((a,b) => a-b)
			}

			return false
		},
		/**
		 * вернет true, если нет ограничений на даты
		 */
		isAllDatesAccess() {
			return this.accessDates.length === 0
		}
	},
	mounted() {
		if (this.checkedDates.length > 0) {
			const {year = 2021} = this.checkedDates[this.checkedDates.length - 1];
			this.currentYear = parseInt(year);
		}
	},
	methods: {
		/**
		 * Возвращает объект для отображения активной ячейки
		 * @param {Number, String} - год/месяц
		 * @returns {Object}
		 */
		getCellClasses(time) {
			if (this.yearMode) {
				const isAccessYear = this.isAllDatesAccess || this.accessDates.findIndex(item => item.year === time) !== -1
				const isActiveYear = time === this.currentYear

				return {
					'cell_active': isActiveYear,
					'cell_disabled': !isAccessYear
				}
			} else {
				const monthNumber = this.months.indexOf(time) + 1
				const isActiveMonth = this.datesBeforeConfirm.filter(item => item.year === this.currentYear && item.month === monthNumber).length > 0
				const isAccessMonth = this.isAllDatesAccess || this.accessDates.findIndex(item => item.year === this.currentYear && item.month === monthNumber) != -1

				return {
					'cell_active': isActiveMonth,
					'cell_disabled': !isAccessMonth
				}
			}
		},
		/**
		 * Эмичу объект содержащий год и месяц
		 * @param {Number, String} - Название месяца или год
		 */
		onCheck(time) {
			if (this.isEdit) {
				if (this.yearMode) {
					const accessTime = this.isAllDatesAccess || this.accessYears.includes(time)
	
					if (accessTime) {
						setTimeout(() => {
							this.yearMode = false
						}, 0);
						this.currentYear = time
					}
				} else {
					const params = {
						year: this.currentYear,
						month: this.months.indexOf(time) + 1
					}
					const monthNumber = this.months.indexOf(time) + 1
					const isAccessMonth = this.isAllDatesAccess || this.accessDates.findIndex(item => item.year === this.currentYear && item.month === monthNumber) != -1
	
					if (this.isMultiple) {
						if (this.isAllDatesAccess || isAccessMonth) {
							const index = this.datesBeforeConfirm.findIndex(item => item.year == params.year && item.month == params.month)
							if (index === -1) {
								this.datesBeforeConfirm.push(params)
							} else {
								this.datesBeforeConfirm.splice(index, 1)
							}
						}
					} else {
						this.datesBeforeConfirm = [params]
					}
	
					this.$emit('check', this.datesBeforeConfirm.slice())
				}
			}
		},
		/**
		 * Выбрать все доступные месяца в году
		 */
		onSelectYear() {
			if (this.isAllDatesAccess) {
				for (let i = 0; i < this.months.length; i++) {
					const year = this.currentYear
					const month = i+1
					const isNotExist = this.datesBeforeConfirm.findIndex(date => date.year === year && date.month === month) === -1

					if (isNotExist) {
						this.datesBeforeConfirm.push({year, month})
					}
				}
			} else {
				const accessMonth = this.accessDates.filter(item => item.year === this.currentYear)
				this.datesBeforeConfirm = accessMonth
			}

			this.$emit('check', this.datesBeforeConfirm)
		},
		/**
		 * Очистить выделенное
		 */
		onReset() {
			const time = new Date()

			const currentDateData = {
				month: time.getMonth() + 1,
				year: time.getFullYear()
			}

			const findIndex = this.accessDates.findIndex(date => date.year === currentDateData.year && date.month === currentDateData.month)

			/**
			 * Если текущая дата доступна
			 * в противном случае первую доступную
			 */
			if (findIndex != -1) {
				this.datesBeforeConfirm = [currentDateData]
			} else if(this.accessDates.length){
				const date = this.accessDates.reduce((prev, cur) => {
					const sumPrev = prev.year * 12 + prev.month
					const sumCur = cur.year * 12 + cur.month

					return sumPrev < sumCur ? prev : cur
				}, this.accessDates[0])

				this.datesBeforeConfirm = [date]
			}else {
                this.datesBeforeConfirm = []
            }
		},
		/**
		 * Переключение режима выбора года
		 */
		onToggleYearMod() {
			this.yearMode = !this.yearMode
		},
		/**
		 * Срабатывает при нажатии на стрелку увеличения/уменьшения года
		 * @param {Number} - принимает значение 1 или -1 в зависимости от того на какую стрелку нажали
		 */
		onFlipThrough(num) {
			/**
			 * если isAllDatesAccess === true, то навигация проходит по обычному,
			 * иначе навигация проходит только по доступным годам
			 */
			if (this.isAllDatesAccess) {
				this.currentYear += num
			} else {
				if (num === -1) {
					const position = this.accessYears.indexOf(this.currentYear)
					if (position !== 0) {
						this.currentYear = this.accessYears[position - 1]
					}
				} else {
					const position = this.accessYears.indexOf(this.currentYear)
					if (position !== this.accessYears.length - 1) {
						this.currentYear = this.accessYears[position + 1]
					}
				}

			}
		},
		onApprove() {
			this.datesAfterConfirm = this.datesBeforeConfirm.slice()

			this.$emit('approve', this.datesBeforeConfirm.slice())
		},
		onCancel() {
			this.$emit('cancel')

			this.datesBeforeConfirm = this.datesAfterConfirm.slice()
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.datepicker {
	display: inline-block;
	width: 290px;
	user-select: none;
	padding: 20px;

	.header {
		height: 34px;
		display: flex;
		justify-content: space-between;

		%arrow {
			width: 34px;
			height: 100%;
			cursor: pointer;
			transition: .2s;
			border: 1px solid $primary-color;
			border-radius: 4px;
			color: $assistant-color;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				color: #000;
			}

			.arrow-icon {
				width: 10px;
			}
		}

		&__arrow-left {
			@extend %arrow;
		}
		&__arrow-right {
			@extend %arrow;
		}

		&__period {
			width: 140px;
			margin: 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
			cursor: pointer;
			border-radius: 4px;
			transition: .2s;
			font-weight: 700;

			&:hover {
				// background: $primary-color;
				color: $success-color;
			}
		}
	}

	.body {
		padding: 10px 0px;
		display: grid;
		grid-template-columns: repeat(3, 1fr );
		grid-gap: 5px;

		.cell {
			display: flex;
			background: #fff;
			border-radius: 6px;
			height: 36px;
			align-items: center;
			text-align: center;
			justify-content: center;
			cursor: pointer;
			font-weight: 700;
			background: $primary-lighten-color;
			font-size: 12px;
			transition: .2s;

			&:hover:not(.cell_disabled) {
				color: $success-color;
				background: transparent;
			}

			&_active {
				border: 1px solid $success-color;
				color: $success-color;
				background: transparent;
			}
			&_disabled {
				color: #ececec;
				background: transparent;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		&__wrapper {
			display: flex;
			align-items: center;

			button {
				line-height: 1.5;
				// font-size: 12px;
				// transition: .2s;
				// color: $assistant-color;
				// font-weight: 500;

				// &:hover {
				// 	cursor: pointer;
				// 	color: $active-color;
				// }

				&:not(:first-child) {
					margin-left: 15px;
				}
			}
		}

		&__btn-container {
			display: flex;
			align-items: center;
			margin-top: 10px;
		}
		&__cancel {
			font-weight: 500;
		}
		&__ok {
			font-weight: 500;
			margin-left: 4px;
		}
	}
}
</style>
