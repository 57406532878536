import ServiceApi from '@/services/ServiceApi.js';

export default {
    methods: {
        /**
         * Запрос для удаления набора
         * @param {Number | String} kitId - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postRemoveKit(kitId = -1, actionAfter = () => {}, filter = {}) {
            const postData = { set_id: kitId, set_filter: { ...filter } }
            try {
                const responseBody = await ServiceApi.post('free', '/map/set-remove', postData);
                actionAfter(kitId);

                return responseBody;
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для дублирования набора
         * @param {Number} kitId - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postDuplicateKit(kitId = -1, actionAfter = () => {}) {
            const postData = {set_id: kitId}
            try {
                const res = await ServiceApi.post('free', '/map/set-duplicate', postData);
                const {data = {}} = res;
                const {data: kit = {}} = data;
                actionAfter(kit);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для экспорта набора
         * @param {Object} postData - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postExportKit(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/export/set', postData);
                const {data = {}} = res;
                const {data: obj = {}} = data;
                actionAfter(obj.url);
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         * Запрос для экспорта набора через отложенные задачи
         * @param {Object} postData - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postExportKitQueue(postData = {}, actionAfter = () => {}, actionError = () => {}) {
            try {
                const response = await ServiceApi.post('free', '/export/set-queue', postData);
                const {data: responseData = {}} = response;
                const {data = {}} = responseData;
                const {is_task: isTask = false, history = {}} = data;
                // if(isTask){
                //     alert("Создание файла может занять некоторое время. Пожалуйста, подождите.");
                // }
                actionAfter(history, isTask);
            }
            catch(error) {
                const {response = {}} = error;
                const {status = -1, data: {data = {}}} = response;
                if(status === 400){
                    const key = Object.keys(data)[0];
                    // alert(data[key][0]);
                    actionError(data[key][0]);
                }
                console.log(response);
            }
        },

        /**
         * Запрос для получения списка кастомных наборов
         * @async
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postKitsList(actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-list');
                const {data = {}} = res;
                const {data: kits = []} = data;
                actionAfter(kits);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для создания набора
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postCreateKit(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-create', postData);
                const {data = {}} = res;
                const {data: kit = {}} = data;
                actionAfter(kit);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос для переименования набора
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postRenameKit(postData, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-edit', postData);
                const {data = {}} = res;
                const {data: kit = {}} = data;
                const {id = '-1', name = ''} = kit;
                actionAfter(id, name);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения таблицы найдено
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataFound(postData = {}, actionAfter = () => {}) {
            try {
                this.isProgressBarLoading = true;
                const res = await ServiceApi.post('free', '/map/table', postData);
                const {data = {}} = res;
                const {data: dataFound = {}} = data;
                const {count = 0, rows = []} = dataFound;
                actionAfter(count, rows);
                this.isProgressBarLoading = false;
            }
            catch(error) {
                console.log(error);
                this.isProgressBarLoading = false;
            }
        },
        // /**
        //  * Запрос на получения маркеров найдено
        //  * @async
        //  * @param {Object} postData - тело запрос
        //  * @param {Function} actionAfter - событие, сработающее после отработки запроса
        //  */
        // async postMarkersDataFound(postData = {}, actionAfter = () => {}) {
        //     try {
        //         const res = await ServiceApi.post('free', '/map/markers', postData);
        //         const {data = {}} = res;
        //         const {data: markers = []} = data;
        //         actionAfter(markers);
        //     }
        //     catch(error) {
        //         console.log(error);
        //     }
        // },
        /**
         * Запрос на получения маркеров найдено (новое АПИ)
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkersDataFound(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/clusters-found', postData);
                const {data = {}} = res;
                actionAfter(data.data);
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         *
         * @param postData
         * @param actionAfter
         * @returns {Promise<void>}
         */
        async postGetClusterZoom(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/get-cluster-zoom', postData);
                const {data = {}} = res;
                actionAfter(data.data);
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         *
         * @param {Array} setIds
         * @param actionAfter
         * @returns {Promise<void>}
         */
        async postCalculateSetBound(setIds = [], actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post(
                    'free',
                    '/map/set-calculate-bound', {set_ids: setIds}
                );
                const {data = {}} = res;
                actionAfter(data.data);
            }
            catch(error) {
                console.log(error);
            }
        },


        /**
         * Запрос на получения маркеров без цен
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkersDataWithoutPrices(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/without-prices', postData);
                const {data = {}} = res;
                const {data: markers = []} = data;
                actionAfter(markers);
            }
            catch(error) {
                console.log(error);
            }
        },
        async postClustersDataWithoutPrices(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/without-prices-cluster-objects', postData);
                const {data = {}} = res;
                actionAfter(data.data);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных карточки конструкции
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkerData(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/marker-info', postData);
                const {data = {}} = res;
                const {data: markerData = {}} = data;
                const {rows = []} = markerData;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных карточки конструкции маркера из справочника
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMarkerCatalogData(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/catalog-marker-info', postData);
                const {data = {}} = res;
                // console.log(data);
                const {data: markerData = {}} = data;
                // const {rows = []} = markerData;
                actionAfter(markerData);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на добавление/удаления цен
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postSyncDataCustom(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-sync', postData);
                const {data = {}} = res;
                const {data: sideIds = []} = data;
                actionAfter(sideIds);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных для строки при добавлении в набор
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataRow(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-table', postData);
                const {data = {}} = res;
                const {data: dataRows = {}} = data;
                const {rows = [], info: kitItem = {}} = dataRows;
                actionAfter(rows, kitItem);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения данных для строк найдено
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataRowFound(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/table', postData);
                const {data = {}} = res;
                const {data: dataRows = {}} = data;
                const {rows = []} = dataRows;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на изменение стороны
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableEditSide(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/free/edit-side', postData);
                const {data = {}} = res;
                const {data: dataPrices = []} = data;
                const relations  = dataPrices.reduce((relationsUnion, dataPrice) => {
                    const relationsUnionIds = relationsUnion.map(relationUnion => {
                        const {id = ''} =relationUnion;
                        return  String(id);
                    });
                    const {relations = []} = dataPrice;
                    const relationsToUnion = relations.filter(relation => {
                        const {id = ''} = relation;
                        return !relationsUnionIds.includes(String(id));
                    });
                    return [...relationsUnion, ...relationsToUnion];
                }, []);
                actionAfter(relations);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получения истории действий
         * @async
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postHistory(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/history/list', postData);
                const {data = {}} = res;
                const {data: history = {}} = data;
                actionAfter(history);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTemplateList(actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/export/get-templates');
                const {data = {}} = res;
                const {data: obj = {}} = data;
                actionAfter(obj);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         *
         * @param {Object} postData - тело запрос
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTemplateSave(postData = {},actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/export/template-save', postData);
                const {data = {}} = res;
                const {data: obj = {}} = data;
                actionAfter(obj);
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         *
         * @param {Number} templateId - id шаблона
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTemplateRemove(templateId = {},actionAfter = () => {}) {
            let postData = {template_id: templateId};
            try {
                await ServiceApi.post('free', '/export/template-remove', postData);
                actionAfter();
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         * Запрос на добавление файла мэтчинга
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postAddMatchingFile(postData = {}, actionAfter = () => {}) {
            const reqConfig = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'accept': '*/*'
                }
            };

            try {
                const res = await ServiceApi.post('free', '/upload/add-file', postData, reqConfig);
                const {data = {}} = res;
                let {data: dataBody} = data;
                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Проверка состояния задачи
         * @async
         * @param {Number} taskId - id Задачи
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postCheckTask(taskId, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/upload/check-task', {task_id: taskId});
                const {data = {}} = res;
                let {data: dataBody} = data;
                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Проверка состояния задачи
         * @async
         * @param {Number} fileId - id Задачи
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postMatchingInfo(fileId, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/match-prepare', {file_id: fileId});
                const {data = {}} = res;
                let {data: dataBody} = data;
                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на добавление файла мэтчинга
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postStepMatching(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/match-search', postData);
                const {data = {}} = res;
                let {data: dataBody} = data;
                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на создание набора из выбранных дат и конструкий
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postCreateSetFromFile(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/match-link', postData);
                const {data = {}} = res;
                let {data: dataBody} = data;
                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на экспорт не найденных конструкций
         * @async
         * @param {Object} postData - тело запроса
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postExportRows(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/export/file-rows', postData);
                const {data = {}} = res;
                let {data: dataBody} = data;
                actionAfter(dataBody);
            }
            catch(error) {
                console.log(error);
            }
        },
    }
}
