<template>
	<div class="country">
		<img :src="`https://catalog.oohdesk.ru/images/countries/${abbreviation}.png`">
	</div>
</template>

<script>
export default {
	name: "CountryFlag",
	props: {
		abbreviation: {
			type: String,
			default: "RU"
		}
	}
}
</script>

<style lang="scss" scoped>
.country {
	width: 100%;
	// display: flex;
	// justify-content: center;

	img {
		width: 30px;
		display: block;
		margin: 0 auto;
	}
}
</style>
