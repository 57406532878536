<template>
    <div class="photo">
		<div class="photo-place">
            <div class="photo-place__wrap">
                <BaseIcon
                    v-if="photoSmall === '-'"
                    icon="icon-tabler-icon-camera"
                    style="color: #ccc;"
                />
                <img
                    v-if="photoSmall !== '-'"
                    :src="photoSmall"
                    @click="openPopup"
                >
            </div>
			<PopupWindow
                v-if="photoSmall !== '-'"
				v-show="isPopupVisible"
				@close="closePopup"
				class="popup-window"
			>
				<div class="popup-window__wrapper">
					<img :src="photoBig">
				</div>
			</PopupWindow>
        </div>
	</div>
</template>

<script>
import PopupWindow from '@/components/Popup'
import BaseIcon from "@/components/Base/BaseIcon";

export default {
	name: "Photo",
    components: {
        BaseIcon,
        PopupWindow
    },
    props: {
        photoSmall: {
            type: String,
            default: ""
        },
        photoBig: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isPopupVisible: false,
        }
    },
    methods: {
        openPopup() {
            this.isPopupVisible = true
        },
        closePopup() {
            this.isPopupVisible = false
        },
    }
}
</script>

<style lang="scss" scoped>
.photo {
	border-radius: 4px;
	overflow: hidden;

    &-place {
        width: 100%;
		height: 100%;
        position: relative;

        &__wrap {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            background: #fafafa;
            align-items: center;
            display: flex;
            justify-content: center;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }
    }
}

.popup-window {
    &__wrapper {
        padding: 15px;
        background: #fff;
    }

    img {
        max-width: 800px;
        max-height: 80vh;
        display: block;
    }
}
</style>
