<template>
    <svg class="svg-icon" :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.6668 1.66663C12.1271 1.66663 12.5002 2.03972 12.5002 2.49996V5.83329H15.8335C16.2937 5.83329 16.6668 6.20639 16.6668 6.66663C16.6668 7.12686 16.2937 7.49996 15.8335 7.49996H12.5002C12.0581 7.49996 11.6342 7.32436 11.3217 7.0118C11.0091 6.69924 10.8335 6.27532 10.8335 5.83329V2.49996C10.8335 2.03972 11.2066 1.66663 11.6668 1.66663Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.8335 3.33329C5.61248 3.33329 5.40052 3.42109 5.24424 3.57737C5.08796 3.73365 5.00016 3.94561 5.00016 4.16663V15.8333C5.00016 16.0543 5.08796 16.2663 5.24424 16.4225C5.40052 16.5788 5.61248 16.6666 5.8335 16.6666H14.1668C14.3878 16.6666 14.5998 16.5788 14.7561 16.4225C14.9124 16.2663 15.0002 16.0543 15.0002 15.8333V7.0118L11.3217 3.33329H5.8335ZM4.06573 2.39886C4.53457 1.93002 5.17045 1.66663 5.8335 1.66663H11.6668C11.8878 1.66663 12.0998 1.75442 12.2561 1.9107L16.4228 6.07737C16.579 6.23365 16.6668 6.44561 16.6668 6.66663V15.8333C16.6668 16.4963 16.4034 17.1322 15.9346 17.6011C15.4658 18.0699 14.8299 18.3333 14.1668 18.3333H5.8335C5.17045 18.3333 4.53457 18.0699 4.06573 17.6011C3.59689 17.1322 3.3335 16.4963 3.3335 15.8333V4.16663C3.3335 3.50358 3.59689 2.8677 4.06573 2.39886Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 8.33337C10.4601 8.33337 10.8332 8.70647 10.8332 9.16671V14.1667C10.8332 14.6269 10.4601 15 9.99984 15C9.5396 15 9.1665 14.6269 9.1665 14.1667V9.16671C9.1665 8.70647 9.5396 8.33337 9.99984 8.33337Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.32757 11.4941C7.65301 11.1686 8.18065 11.1686 8.50608 11.4941L10.0002 12.9882L11.4942 11.4941C11.8197 11.1686 12.3473 11.1686 12.6728 11.4941C12.9982 11.8195 12.9982 12.3472 12.6728 12.6726L10.5894 14.7559C10.264 15.0814 9.73634 15.0814 9.41091 14.7559L7.32757 12.6726C7.00214 12.3472 7.00214 11.8195 7.32757 11.4941Z" :fill="color"/>
</svg>

</template>

<style scoped>
   .svg-icon path {
        fill: currentColor;
    }
</style>

<script>
export default {
    name: 'IconDownload',
    props: {
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'black'
        },
    },
}
</script>
