import UtilNumber from '../../utils/UtilNumber';
import ServiceEditPricesTable from '../../services/ServicesEditPrices/ServiceEditPricesTable.js';
import SchemePageBayuerdesk from '@/schemes/SchemePageBayuerdesk';
const {kitTableRowKeys, kitTableColumns, kitTableBudgetColumns} = SchemePageBayuerdesk;
export default {
    /**
     * @property {Object} tableSort - данные сортировки по столбцам
     * @property {Array} tableFilter - данные фильтрации по столбцам
     * @property {Number} totalRows - общее кол-во строк в таблице
     * @property {Number} tableFoundPerPage - размер страницы для пагинации
     * @property {Number} tableFoundPageNumber - номер страницы для пагинации
     * @property {Array} tableRows - строки
     * @property {Array} tableFoundRowKeys - нужные ключи из строк
     * @property {Array} tableColumns - колонки
     * @property {Array} tableColumnsBase - базовые колонки
     * @property {Array} selectedRows - выделенные строки
     */
    data: () => ({
        tableSort: {column: '', type: ''},
        tableFilter: [],
        totalRows: 0,
        tableFoundPerPage: 40,
        tableFoundPageNumber: 1,
        tableRowsFromApi: [],
        tableRows: [],
        tableFoundRowKeys: kitTableRowKeys,
        tableColumns: [],
        tableColumnsBase: kitTableColumns,
        selectedRows: []
    }),
    computed: {
        /**
         * Кол-во выбранных rows
         * @returns {Number}
         */
        selectedRowsCount() {
            return this.selectedRows.length ?? 0;
        },
        /**
         * Общее кол-во страниц
         * @returns {Number}
         */
        tableTotalPages() {
            return Math.ceil(this.totalRows / this.tableFoundPerPage);
        },
        /**
         * Тело запроса для таблицы найдено
         * @returns {Object}
         */
        tableFoundRequestBody() {
            const tableFoundRequestBody = {
                pagination: {
                    per_page: parseInt(this.tableFoundPerPage),
                    page: parseInt(this.tableFoundPageNumber)
                },
                sort: this.tableSort,
                filter: this.tableFilter,
                filter_params: this.filterSelectedData
            };
            return tableFoundRequestBody;
        },
        /**
         * Колонки с датами
         * @returns {Array}
         */
        tableColumnsDates() {
            const tableColumnsDates = this.tableColumns.filter(column => {
                const {isDate = false} = column;
                return isDate === true;
            }).map(column => column.prop);
            return tableColumnsDates;
        },
        /**
         * Колонки модифицируемые (особенности таблицы)
         * @returns {Array}
         */
        tableColumnsModifered() {
            const tableColumnsModifered = this.tableColumns.filter(column => column.isModifiered === true).map(column => column.prop);
            return tableColumnsModifered;
		},
        /**
         * Колонки для страницы резервирования
         * @returns {Array}
         */
        tableColumnsForReservePage() {
            const selectedDate = this.selectedDates[0];
            const status = {
                prop: 'status',
                    label: 'Статус',
                isSlot: true,
                columnStyles: {
                    width: '132px'
                }
            };
            const tableColumnsFiltered = this.tableColumns.filter(column => {
                const {prop = '', isDate = false} = column;
                return prop !== 'format_id' && prop !== 'ots' && prop !== 'grp' && prop !== 'nds' && (isDate ? String(selectedDate) === String(prop) : true);
            });
            const modifiedTableColumns = [...tableColumnsFiltered];
            modifiedTableColumns.unshift(status);
            return modifiedTableColumns;
        },
        /**
         * Строки таблицы для страницы бронирования
         * @returns {Array}
         */
        tableRowsForReservePage() {
            const selectedDate = this.selectedDates[0];
            const tableRowsForReservePage = this.tableRows.filter(row => Object.keys(row).includes(String(selectedDate)));
            return tableRowsForReservePage;
        }
    },
    methods: {
        /**
         * Получение схемы колонок для таблицы
         */
        async fetchTableColumns(columns = []) {
            try {
                const newTableColumns = await ServiceEditPricesTable.setTableColumnsSchemeCustom(columns);
                this.tableColumns = [...newTableColumns];
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        /**
         * Изменение схемы колонок таблицы
         */
        changeTableColumns(columns = []) {
            this.fetchTableColumns(columns);
        },

        /**
         * Адаптер для строк
         * @param {Array} rows - строки
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Array}
         */
        adapterRows(rows = [], keyPrices = 'prices') {

            let mappedPrices = rows.reduce((mappedRows, row) => {
                let prices = row[keyPrices] ?? [];
                return prices.reduce((mappedPrices, price) => {
                    const {date, side_id:sideId} = price;
                    if (!mappedPrices[date]) {
                        mappedPrices[date] = {};
                    }
                    mappedPrices[date][sideId] = price
                    return mappedPrices;
                }, mappedRows);
            }, {})

            const rowsAdapted = rows.map(row => {
                const {id: sideId} = row;
                const rowKeys = Object.keys(row);
                const rowKeysFiltered = rowKeys.filter(rowKey => this.tableFoundRowKeys.includes(rowKey));
                let rowAdapted = {};
                rowKeysFiltered.forEach(rowKey => {
                    if (rowKey !== keyPrices)
                        rowAdapted[rowKey] = row[rowKey];
                    else {
                        rowAdapted[rowKey] = row[rowKey];
                        Object.keys(mappedPrices).forEach((date) => {
                            // const {price = "0"} = mappedPrices[date][sideId] ?? {};
                            const {price_month: price = "0"} = mappedPrices[date][sideId] ?? {};
                            rowAdapted[date] = parseInt(price);
                            rowAdapted[`${date}__without-tax`] = UtilNumber.getPriceWithoutNds(price);
                        })
                    }
                });
                return rowAdapted;
            });
            return rowsAdapted;
        },
        adapterBudgetRowsAdditionally(rows = [], keyPrices = 'prices') {
            const rowsAdapted = rows.map(row => {
                const rowKeys = Object.keys(row);
                const rowKeysFiltered = rowKeys.filter(rowKey => this.tableFoundRowKeys.includes(rowKey));
                let rowAdapted = {};
                rowKeysFiltered.forEach(rowKey => {
                    if (rowKey === 'tax')
                        rowAdapted.nds = Boolean(parseInt(row[rowKey]));
                    else if (['print_price', 'install_price', 'additional_install_price'].includes(String(rowKey))) {
                        const sideLinkSet = row?.sideLinkSet ?? {};
                        rowAdapted[rowKey] = {
                            price: parseInt(row[rowKey]),
                            offer_price: parseInt(sideLinkSet[rowKey])
                        };
                    }
                    else if (rowKey !== keyPrices)
                        rowAdapted[rowKey] = row[rowKey];
                    else {
                        rowAdapted[rowKey] = row[rowKey];
                        // const prices = row[keyPrices] || [];
                        const allPrices = row?.allPrices ?? [];
                        const prices = allPrices.map(allPrice => {
                            const price = row?.prices.find(price => price?.id === allPrice?.id && price?.date === allPrice?.date) ?? {};
                            const priceLinkSet = price?.priceLinkSet ?? {};
                            const allPriceMapped = { ...allPrice, priceLinkSet };

                            return allPriceMapped;
                        });
                        prices.forEach(priceItem => {
                            // const {date = '', price = 0, install_price: installPrice = 0, print_price: printPrice = 0, priceLinkSet = {}} = priceItem;
                            // const {offer_price: offerPrice = 0, print_price: linkPrintPrice = 0, install_price: linkInstallPrice = 0} = priceLinkSet;
                            const { date = '', price = 0, price_month = 0, priceLinkSet = {} } = priceItem;
                            const { offer_price: offerPrice = 0, offer_price_month = 0, offer_price = 0 } = priceLinkSet;
                            // const linkWithoutNdsPrice = offerPrice ? UtilNumber.getPriceWithoutNds(offerPrice) : 0;
                            // const withoutNdsPrice = price ? UtilNumber.getPriceWithoutNds(price) : 0;

                            // const rowAdaptedKeyWithoutNds = `${date}__without_nds_price`
                            const rowAdaptedKeyPrice = `${date}__price`;
                            // const rowAdaptedKeyPrint = `${date}__print_price`;
                            // const rowAdaptedKeyInstall = `${date}__install_price`;

                            // eslint-disable-next-line no-unused-vars
                            const rowAdaptedKeyPriceMonth = `${date}__price_month`;
                            // eslint-disable-next-line no-unused-vars
                            const rowAdaptedKeyOfferPrice = `${date}__offer_price`;

                            // if(status !== "busy"){
                                // rowAdapted[rowAdaptedKeyWithoutNds] = {price: withoutNdsPrice, offer_price: linkWithoutNdsPrice};
                                rowAdapted[rowAdaptedKeyPrice] = {price: parseInt(price_month), offer_price: parseInt(offerPrice)};
                                // rowAdapted[rowAdaptedKeyPrint] = {price: parseInt(printPrice), offer_price: parseInt(linkPrintPrice)};
                                // rowAdapted[rowAdaptedKeyInstall] = {price: parseInt(installPrice), offer_price: parseInt(linkInstallPrice)};

                                rowAdapted[rowAdaptedKeyPriceMonth] = { price: parseFloat(price_month), offer_price: parseFloat(offer_price_month) };
                                rowAdapted[rowAdaptedKeyOfferPrice] = { price: parseFloat(price),  offer_price: parseFloat(offer_price)};
                            // }
                        });
                    }
                });

                rowAdapted.isDigital = row?.is_digital ?? false;

                rowAdapted.period_price = { price: row?.prices.reduce((offerPrice, priceItem) => offerPrice + parseFloat(priceItem?.price ?? 0), 0) };
                rowAdapted.offer_price = { price: row?.prices.reduce((offerPrice, priceItem) => offerPrice + parseFloat(priceItem?.priceLinkSet?.offer_price ?? 0), 0) };
                rowAdapted.offer_price_month = { price: row?.prices.reduce((offerPrice, priceItem) => offerPrice + parseFloat(priceItem?.priceLinkSet?.offer_price_month ?? 0), 0) / row?.prices.length };

                // eslint-disable-next-line no-debugger
                // debugger;

                return rowAdapted;
            });

            return rowsAdapted;
        },
        /**
         * Адаптер для строк формирования бюджета
         * @param {Array} rows - строки
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Array}
         */
        adapterBudgetRows(rows = [], keyPrices = 'prices') {
            const rowsAdapted = rows.map(row => {
                const rowKeys = Object.keys(row);
                const rowKeysFiltered = rowKeys.filter(rowKey => this.tableFoundRowKeys.includes(rowKey));
                let rowAdapted = {};
                rowKeysFiltered.forEach(rowKey => {
                    if (rowKey === 'tax')
                        rowAdapted.nds = Boolean(parseInt(row[rowKey]));
                    if (rowKey !== keyPrices)
                        rowAdapted[rowKey] = row[rowKey];
                    else {
                        rowAdapted[rowKey] = row[rowKey];
                        const prices = row[keyPrices] || [];
                        prices.forEach(priceItem => {
                            const {date = '', price = 0, priceLinkSet = {}} = priceItem;
                            const {offer_price: offerPrice = 0} = priceLinkSet;
                            rowAdapted[date] = {price: parseInt(price), offer_price: parseInt(offerPrice)};
                        });
                    }
                });

                rowAdapted.isDigital = row?.is_digital ?? false;

                return rowAdapted;
            });
            return rowsAdapted;
        },
        // eslint-disable-next-line no-unused-vars
        adapterColumnsDatesAdditionally(rows = [], keyPrices = 'prices', modeId = 'month', isUncover = true) {
            if ((modeId === 'month' && isUncover) || modeId === 'period') {
                const dates = rows.map(row => {
                    // const prices = row[keyPrices] || [];
                    const prices = row?.allPrices || [];
                    const dates = prices.map(price => price.date);
                    return dates;
                });
                const datesUnion = dates.reduce((datesUnion, dateCurrent) => {
                    const datesDifference = dateCurrent.filter(date => !datesUnion.includes(String(date)));
                    return [...datesUnion, ...datesDifference];
                }, []);
                datesUnion.sort();

                if (modeId === 'month' && isUncover) {
                    const datesAdapted = datesUnion.reduce((datesAdapted, currentDate) => {
                        const dateString = new Date(currentDate).toLocaleString('ru', {year: 'numeric', month: 'long'});
                        const dateStringArr = dateString.split(' ');
                        const monthName = dateStringArr[0].slice(0, 1).toUpperCase() + dateStringArr[0].slice(1,3);
                        const propPostfixes = {
                            // 'without_nds_price': 'Без НДС',
                            'price': '',
                            // 'install_price': 'Монтаж',
                            // 'print_price': 'Печать',
                        };
                        const columnDates = Object.keys(propPostfixes).map(propPostfixesKey => {
                            let label = propPostfixes[propPostfixesKey]
                                ? `${monthName} ${propPostfixes[propPostfixesKey]} без НДС`
                                : `${monthName} без НДС`;
                            const columnDate = {
                                label: label,
                                prop: `${currentDate}__${propPostfixesKey}`,
                                isDate: true,
                                isSlot: true,
                                isShow: true,
                                isCustomizable: false,
                                columnStyles: {
                                    width: '90px',
                                    padding: '0',
                                },
                                classes: ['date-cell']
                            };
                            return columnDate;
                        });
                        return [...datesAdapted, ...columnDates];
                    }, []);

                    return [
                        {
                            label: 'Период',
                            prop: 'period_date',
                            isSlot: true,
                            isShow: true,
                            isCustomizable: false,
                            columnStyles: {
                                width: '200px',
                            },
                        },
                        ...datesAdapted
                    ];
                } else if (modeId === 'period') {
                    const datesAdaptedPeriod = datesUnion.reduce((datesAdaptedPeriod, currentDate) => {
                        const dateString = new Date(currentDate).toLocaleString('ru', {year: 'numeric', month: 'long'});
                        const dateStringArr = dateString.split(' ');
                        const monthName = dateStringArr[0].slice(0, 1).toUpperCase() + dateStringArr[0].slice(1,3);

                        const columnDatesPeriod = [
                            {
                                label: `Цена за месяц ${monthName}`,
                                prop: `${currentDate}__price_month`,
                                isDate: true,
                                isSlot: true,
                                isShow: true,
                                isCustomizable: false,
                                columnStyles: {
                                    width: '90px',
                                    padding: '0',
                                },
                                classes: ['date-cell']
                            },
                            {
                                label: `Период ${monthName}`,
                                prop: `${currentDate}__period_date`,
                                isSlot: true,
                                isShow: true,
                                isDatepickerRange: true,
                                isCustomizable: false,
                                columnStyles: {
                                    width: '240px',
                                },
                            },
                            {
                                label: `Цена за период ${monthName}`,
                                prop: `${currentDate}__offer_price`,
                                isDate: true,
                                isSlot: true,
                                isShow: true,
                                isCustomizable: false,
                                columnStyles: {
                                    width: '90px',
                                    padding: '0',
                                },
                                classes: ['date-cell']
                            },
                        ]

                        return [...datesAdaptedPeriod, ...columnDatesPeriod];
                    }, []);

                    return datesAdaptedPeriod;
                }

            } else if (modeId === 'month' && !isUncover) {
                const columnsDates = [
                    {
                        label: 'Период',
                        prop: 'period_date',
                        isSlot: true,
                        isShow: true,
                        isCustomizable: false,
                        columnStyles: {
                            width: '200px',
                        },
                    },
                    {
                        label: 'Цена оператора',
                        prop: 'period_price',
                        isDate: true,
                        isShow: true,
                        isSlot: true,
                        isCustomizable: false,
                        columnStyles: {
                            width: '90px',
                            padding: '0',
                        },
                        classes: ['date-cell']
                    },
                    {
                        label: 'Цена за период',
                        prop: 'offer_price',
                        isDate: true,
                        isShow: true,
                        isSlot: true,
                        isCustomizable: false,
                        columnStyles: {
                            width: '90px',
                            padding: '0',
                        },
                        classes: ['date-cell']
                    },
                    {
                        label: 'Ср. цена за месяц',
                        prop: 'offer_price_month',
                        isDate: true,
                        isShow: true,
                        isSlot: true,
                        isCustomizable: false,
                        columnStyles: {
                            width: '90px',
                            padding: '0',
                        },
                        classes: ['date-cell']
                    }
                ];

                return columnsDates;
            }
        },
        /**
         * Адаптер для колонок с датами
         * @param {Array} rows
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @returns {Array}
         */
        adapterColumnsDates(rows = [], keyPrices = 'prices') {
            const dates = rows.map(row => {
                const prices = row[keyPrices] || [];
                const dates = prices.map(price => price.date);
                return dates;
            });
            const datesUnion = dates.reduce((datesUnion, dateCurrent) => {
                const datesDifference = dateCurrent.filter(date => !datesUnion.includes(String(date)));
                return [...datesUnion, ...datesDifference];
            }, []);
            datesUnion.sort();

            const datesAdapted = datesUnion.reduce((datesAdapted, date) => {
                const dateString = new Date(date).toLocaleString('ru', {year: 'numeric', month: 'long'});
                const dateStringArr = dateString.split(' ');
                const monthName = dateStringArr[0].slice(0, 1).toUpperCase() + dateStringArr[0].slice(1,3);

                const columnDate = {
                    isDate: true,
                    isSlot: true,
                    isShow: true,
                    isCustomizable: false,
                    columnStyles: {
                        width: '100px',
                        padding: '0',
                    },
                    classes: ['date-cell']
                };

                const columnsDate = [
                    {
                        label: `${monthName}, без НДС`,
                        prop: date,
                        isCanCheck: true,
                        ...columnDate
                    }/*,
                    {
                        label: `${monthName}, без НДС`,
                        prop: `${date}__without-tax`,
                        isCanCheck: false,
                        ...columnDate
                    }*/
                ];

                return [...datesAdapted, ...columnsDate];
            }, [])

            return datesAdapted;
        },
        /**
         * Собрать массив для отрисовки колонок
         * @param {Array} columnsDatesAdapted - колонки после адаптера
         */
        collectColumns(columnsDatesAdapted = []) {
            const firstPart = this.tableColumnsBase.slice(0, 7);
            const secondPart = this.tableColumnsBase.slice(7);
            this.tableColumns = [...firstPart, ...columnsDatesAdapted, ...secondPart];
        },
        /**
         * Собрать массив для отрисовки колонок таблицы формирования бюджета
         * @param {Array} columnsDatesAdapted - колонки после адаптера
         */
        async collectBudgetColumns(columnsDatesAdapted = []) {
            const firstPart = kitTableBudgetColumns.slice(0, 5);
            const secondPart = kitTableBudgetColumns.slice(5);
            const tableColumnsSchemeCustom = await this.getTableColumnsSchemeCustom([...firstPart, ...columnsDatesAdapted, ...secondPart]);

            this.tableColumns = [...tableColumnsSchemeCustom];
        },
        async getTableColumnsSchemeCustom(tableColumns = []) {
            const tableColumnsSchemeCustom = await ServiceEditPricesTable.getTableColumnsSchemeCustom(tableColumns);

            return tableColumnsSchemeCustom;
        },
        /**
         * Событие сработающее после запроса на получение таблицы найдено
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @param {Array} rows - строки
         */
        afterPostTableData(rows = [], keyPrices = 'prices', isRerender = false) {
            const rowsAdapted = this.adapterRows(rows, keyPrices);
            const rowsNew = !isRerender ? [...this.tableRows, ...rowsAdapted] : [...rowsAdapted];
            const columnsDatesAdapted = this.adapterColumnsDates(rowsNew, keyPrices);
            !isRerender ? this.tableRows.push(...rowsAdapted) : this.tableRows = [...rowsAdapted];
            this.collectColumns(columnsDatesAdapted);
        },
        afterPostSetPriceModificationCreate(rows = [], keyPrices = 'prices') {
            const rowsAdapted = !this.isModifidedDesign ? this.adapterBudgetRowsAdditionally(rows, keyPrices) : this.adapterBudgetRows(rows, keyPrices);

            const tableRowsTotal = this.tableRows.reduce((tableRowsTotal, tableRow) => {
                const rowAdaptedIndex = rowsAdapted.findIndex(row => String(row?.id ?? '') === String(tableRow?.id));
                rowAdaptedIndex !== -1 ? tableRowsTotal.push(rowsAdapted[rowAdaptedIndex]) : tableRowsTotal.push(tableRow);

                return tableRowsTotal;
            }, []);

            this.tableRows = [...tableRowsTotal];
        },
        /**
         * Событие сработающее после запроса на получение таблицы формирования бюджета
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @param {Array} rows - строки
         */
        async afterPostTableBudgetData(rows = [], keyPrices = 'prices', isRerender = false, modeId = 'month', isUncover = true) {
            !isRerender ? this.tableRowsFromApi.push(...rows) : this.tableRowsFromApi = rows;

            const rowsAdapted = !this.isModifidedDesign ? this.adapterBudgetRowsAdditionally(this.tableRowsFromApi, keyPrices) : this.adapterBudgetRows(this.tableRowsFromApi, keyPrices);
            const columnsDatesAdapted = !this.isModifidedDesign ? this.adapterColumnsDatesAdditionally(this.tableRowsFromApi, keyPrices, modeId, isUncover) : this.adapterColumnsDates(this.tableRowsFromApi, keyPrices);
            await this.collectBudgetColumns(columnsDatesAdapted);
            !isRerender ? this.tableRows.push(...rowsAdapted) : this.tableRows = rowsAdapted;
        },
        /**
         * Событие сработающее после ленивой загруки найдено
         * @param {Array} rows - строки
         */
        afterLazyloadTableDataFound(rows = []) {
            const rowsAdapted = this.adapterRows(rows);
            this.tableRows.push(...rowsAdapted);
        },
        /**
         * Событие сработающее после запроса на получения данных для строк найдено
         * @param {Array} rows - строки
         * @returns {Object}
         */
        afterPostTableDataRowFound(rows = []) {
            const rowIds = rows.map(row => {
                const {id = -1} = row;
                return id;
            });
            const prices = rows.map(row => {
                const {prices = []} = row;
                return prices;
            });
            const pricesReduced = prices.reduce((pricesUnion, priceCurrent) => {
                const pricesDifference = priceCurrent.filter(price => !pricesUnion.includes(String(price.id)));
                return [...pricesUnion, ...pricesDifference.map(price => String(price.id))];
            }, []);
            return {rowIds, pricesReduced};
        },
        /**
         * Событие, сработающее перед фильтрацией по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        beforeFilterTable(dataFilter = {}) {
            const filter = Object.keys(dataFilter).map(columnKey => {
                const filterItem = {
                    column: columnKey,
                    values: [dataFilter[columnKey]]
                };
                return filterItem;
            });
            this.tableFilter = filter;
        },
        /**
         * Событие, сработающее перед сортировкой
         * @param {Object} dataSort - данные сортировки
         */
        beforeSortTable(dataSort = {}) {
            const {type = '', key = ''} = dataSort;
            this.tableSort = {column: key, type};
        },
        /**
         * Событие, сработающее до запроса на добавление/удаления цен
         * @param {Object} data - данные из таблицы набора
         * @param {Object} pricesSelected - выбранные цены
         * @param {String} kitId - индентификатор набора
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @param {String} allPrices - ключ для добавление/удаления всех цен в ряду
         * @returns {Object}
         */
        beforePostSyncDataCustom(data, pricesSelected, kitId, keyPrices = 'prices', allPrices = '') {
            const {column = {}, row = {}} = data;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const prices = row[keyPrices] || [];
            const cellsChecked = pricesSelected[rowId] || [];
            const price = prices.find(price => price.date === prop) ?? {};
            const {id: priceId = ''} = price;
            const isPriceBusy = String(price?.status ?? '') === 'busy';

            let action = cellsChecked.includes(prop) ? 'remove' : 'add';
            let priceIds = isPriceBusy && action === 'add' ? [] : [priceId];
            let postData = {set_id: kitId, action, ids: priceIds};

            if (allPrices === 'remove') action = 'remove';

            if (allPrices === 'add') action = 'add';

            if (allPrices === 'remove' || allPrices === 'add') {
                priceIds = prices.filter(price => {
                    String(price?.status ?? '') !== 'busy'
                    return allPrices === 'add' ? String(price?.status ?? '') !== 'busy' : true;
                }).map(price => price.id);
                postData = {set_id: kitId, action, ids: priceIds};
                return {postData, rowId, action};
            }

            return {postData, rowId, action};
        }
    }
};
