/**
 * Схема для панели действий над картой
 * @property {Array} controlPanel - массив элементов панели
 * @property {Array} panelBlocks - блоки элементов панели
 */
const SchemeControlPanel = {
    controlPanel: [
        {
            id: 'pedestrianTraffic',
            icon: 'icon-footprints',
            description: 'Пешеходный трафик BestPlace',
            emit: 'onTogglePedestrianTraffic'
        },
        {
            id: 'autoTraffic',
            icon: 'icon-car',
            description: 'Автомобильный трафик BestPlace',
            emit: 'onToggleAutoTraffic'
        },
        {
            id: 'jamsTraffic',
            icon: 'icon-tabler-icon-traffic-lights',
            description: 'Автомобильные пробки real time',
            emit: 'onToggleJamsTraffic'
        },
        {
            id: 'colorMap',
            icon: 'icon-tabler-icon-palette',
            description: 'Цвет карты',
            emit: 'onToggleColorMap'
        },
        // {
        //     id: 'direction-list',
        //     icon: 'icon-compass',
        //     description: 'Показать/скрыть меню направлений',
        //     emit: 'onToggleDirection'
        // },
        {
            id: 'ruler',
            icon: 'icon-ruler',
            description: 'Линейка',
            emit: 'onToggleRuler'
        }
    ],

    panelBlocks: [
        {
            key: 'mode',
            title: 'Режим',
            items: [
                {
                    id: 'selection',
                    icon: 'icon-tabler-icon-live-view',
                    description: 'Выбор точки',
                    emit: 'onToggleSelectMode'
                },
                {
                    id: 'massSelection',
                    icon: 'icon-tabler-icon-map-pins',
                    description: 'Массовый выбор точек',
                    emit: 'onToggleMassSelectMode'
                }
            ]
        },
        {
            key: 'markers',
            title: 'Маркеры',
            items: [
                {
                    id: 'directionAngleView',
                    icon: 'icon-marker_direction',
                    description: 'Угол обзора',
                    emit: 'onToggleDirectionAngleView'
                },
                {
                    id: 'directionFlowSide',
                    icon: 'icon-tabler-icon-gps',
                    description: 'Поток на сторону',
                    emit: 'onToggleDirectionFlowSide'
                }
            ]
        },
        {
            key: 'traffic',
            title: 'Трафик',
            items: [
                {
                    id: 'pedestrianTraffic',
                    icon: 'icon-footprints',
                    description: 'Пешеходный трафик BestPlace',
                    emit: 'onTogglePedestrianTraffic'
                },
                {
                    id: 'autoTraffic',
                    icon: 'icon-car',
                    description: 'Автомобильный трафик BestPlace',
                    emit: 'onToggleAutoTraffic'
                },
                {
                    id: 'jamsTraffic',
                    icon: 'icon-tabler-icon-traffic-lights',
                    description: 'Автомобильные пробки real time',
                    emit: 'onToggleJamsTraffic'
                }
            ]
        },
        {
            key: 'tool',
            title: 'Инструменты',
            items: [
                {
                    id: 'colorMap',
                    icon: 'icon-tabler-icon-palette',
                    description: 'Цвет карты',
                    emit: 'onToggleColorMap'
                },
                {
                    id: 'ruler',
                    icon: 'icon-tabler-icon-ruler-2',
                    description: 'Линейка',
                    emit: 'onToggleRuler'
                },
                // {
                //     id: 'clustering',
                //     icon: 'icon-tabler-icon-box-multiple-1',
                //     description: 'Кластеризация',
                //     emit: 'onToggleClustering'
                // }
            ]
        }
    ],

    controlPanelModule: [
        {
            id: 'poi',
            icon: 'icon-push-pin', // icon-image
            description: 'POI',
            emit: 'onTogglePoi',
        },
        /*{
            id: 'legend',
            icon: 'icon-plus', // icon-folder-open
            description: 'Легенда',
            emit: 'onToggleLegend',
        },
        {
            id: 'efficiency',
            icon: 'icon-plus', // icon-pie-chart
            description: 'Эффективность',
            emit: 'onToggleEfficiency',
        },
        {
            id: 'competitor',
            icon: 'icon-plus', // icon-pie-chart
            description: 'Конкуренты',
            emit: 'onToggleСompetitor',
        },
        {
            id: 'layout',
            icon: 'icon-plus', // icon-pie-chart
            description: 'Слои',
            emit: 'onToggleLayout',
        },
        {
            id: 'summary data',
            icon: 'icon-plus', // icon-pie-chart
            description: 'Сводные данные',
            emit: 'onToggleSummaryData'
        },*/
    ],
};

export default SchemeControlPanel;
