<template>
    <div class="match-side-selection">
        <div class="match-side-selection__table">
            <div class="match-side-selection__table-row">
                <div class="match-side-selection__table-head match-side-selection__table-head--right">
                    <div
                        class="match-side-selection__block"
                        :class="blockClass"
                    >
                        <base-preloader-skeleton
                            v-if="isLoading"
                            class="match-side-selection__loader"
                        />

                        <div class="match-side-selection__block-header">
                            <div class="match-side-selection__block-title">
                                Файл
                                <span class="match-side-selection__block-title-count">{{sidesFileCount}}</span>
                            </div>
                            <button
                                v-if="getSideIsDraggable(tabs[activeTabId].key)"
                                class="match-side-selection__block-control"
                                @click="onClearSidesFile(tabs[activeTabId].key)"
                            >
                                Добавить все
                            </button>
                        </div>
                    </div>
                </div>
                <div class="match-side-selection__table-head match-side-selection__table-head--left">
                    <div
                        class="match-side-selection__block"
                        :class="blockClass"
                    >
                        <base-preloader-skeleton
                            v-if="isLoading"
                            class="match-side-selection__loader"
                        />

                        <div class="match-side-selection__block-header">
                            <div class="match-side-selection__block-title">
                                Набор
                                <span class="match-side-selection__block-title-count">{{sidesKitCount}}</span>
                            </div>
                            <button
                                class="match-side-selection__block-control"
                                @click="onClearSidesKit"
                            >
                                Убрать все
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="match-side-selection__table-row match-side-selection__table-row--content">
                <div
                    class="match-side-selection__table-column match-side-selection__table-column--right"
                    :class="blockClass"
                    @drop="onDropSide('file')"
                    @dragover.prevent
                    @dragenter.prevent
                >
                    <div ref="asdf" class="match-side-selection__tabs-wrap">
                        <base-preloader-skeleton
                            v-if="isLoading"
                            class="match-side-selection__loader"
                        />
                        <TabList
                            class="match-side-selection__tabs"
                            :tabs="tabs"
                            :tab-id-active="activeTabId"
                            :counts="tabCounts"
                            @onCheck="onToggleTab"
                            @onDownload="onDownloadSides"
                        />
                    </div>

                    <base-preloader-skeleton
                        v-if="isLoading"
                        class="match-side-selection__loader"
                    />
                    <match-sides
                        :sides="getRows(sidesFile, tabs[activeTabId].key)"
                        :sideInfo="sideInfo"
                        :isDraggable="getSideIsDraggable(tabs[activeTabId].key)"
                        :is-show-approx="tabs[activeTabId].key === 'approx'"
                        :is-show-distance="isShowDistance(tabs[activeTabId].key)"
                        :combination-id="combinationId"
                        class="match-side-selection__sides-file"
                        @onDragStart="onDragStartSide($event, 'file')"
                    />
                </div>
                <div
                    class="match-side-selection__table-column match-side-selection__table-column--left"
                    :class="blockClass"
                    @drop="onDropSide('kit')"
                    @dragover.prevent
                    @dragenter.prevent
                >
                    <base-preloader-skeleton
                        v-if="isLoading"
                        class="match-side-selection__loader"
                    />
                    <div v-if="isDisabled" class="match-side-selection__sides-empty">
                        Стороны для набора <br>
                        отсутсвтуют
                    </div>
                    <match-sides
                        :sides="sidesKitReduced"
                        :sideInfo="sideInfo"
                        :isDraggable="true"
                        :combination-id="combinationId"
                        class="match-side-selection__sides-kit"
                        @onDragStart="onDragStartSide($event, 'kit')"
                    />
                </div>
            </div>
        </div>
        <div class="match-side-selection__action">
            <div class="match-side-selection__action-item">
                <base-preloader-skeleton
                    v-if="isLoading"
                    class="match-side-selection__loader"
                />
                <base-button
                    view="border"
                    form="oval"
                    size="large"
                    @click="onBackStep"
                >
                    Назад
                </base-button>
            </div>
            <div class="match-side-selection__action-item">
                <base-preloader-skeleton
                    v-if="isLoading"
                    class="match-side-selection__loader"
                />
                <base-button
                    :disabled="isDisabled"
                    view="secondary"
                    form="oval"
                    size="large"
                    :class="{'match-side-selection__action-create--disabled': isDisabled}"
                    @click="onNextStep"
                >
                    Создать набор
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
/** Компонент шага: выбора сторон мэтчинга файла */
import MatchSides from './MatchSides.vue';
import BaseButton from "@/components/Base/BaseButton";
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton";
import TabList  from "@/components/Tabs/TabList";

export default {
    name: 'MatchSideSelection',
    components: {
        MatchSides,
        BaseButton,
        BasePreloaderSkeleton,
        TabList,
    },
    /**
     * Входные данные компонента
     * @property {Object} sideSelectionData - данные для выбора сторон
     * @property {Object} sideInfo - объект с ключами и заголовками с информацией о стороне
     * @property {Boolean} isLoading - условие загрузки шага
     * @property {Number} combinationId - Id комбинации (1 - координаты, 2 - Gid+Оператор+сторона, 3 - Адрес+сторона)
     */
    props: {
        sideSelectionData: {
            type: Object,
            default: () => ({
                approx: [],
                notFound: [],
                strict: []
            })
        },
        sideInfo: {
            type: Object,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        combinationId: {
            type: Number,
            default: -1
        }
    },
    /**
     * Данные компонента
     * @property {Object} tabs - список табов
     * @property {String} activeTabId - Id активного таба
     * @property {Object} sidesFile - стороны файла
     * @property {Object} sidesKit - стороны набора
     * @property {Object} currentDragData - текущие данные перетаскиваемой стороны
     */
    data: () => ({
        tabs: [
            {
                id: '0',
                key: 'approx',
                name: 'Похожие',
                mod: 'warning',
                isShowIcon: true,
            },
            {
                id: '1',
                key: 'notFound',
                name: 'Не найдено',
                mod: 'error',
                isShowIcon: true,
                isShowIconDownload: true,
            },
            {
                id: '2',
                key: 'strict',
                name: 'Найдено',
                mod: 'success',
                isShowIcon: true,
            },
        ],
        activeTabId: '2',
        sidesFile: {
            approx: [],
            notFound: [],
            strict: []
        },
        sidesKit: {
            approx: [],
            notFound: [],
            strict: []
        },
        currentDragData: {
            side: {},
            fromDropZoneKey: ''
        }
    }),
    computed: {
        /**
         * Колличество сторон файла
         * @returns {Number}
         */
        sidesFileCount() {
            return this.getRowsCount(this.sidesFile);
        },
        /**
         * Колличество сторон набора
         * @returns {Number}
         */
        sidesKitCount() {
            return this.getRowsCount(this.sidesKit);
        },
        /**
         * Идентификаторы сторон набора
         * @returns {Array}
         */
        sidesKitIds() {
            const sidesKitIds = this.sidesKitReduced.map(sidesKit => {
                const {match_info: matchInfo = {}} = sidesKit;
                const {side = {}} = matchInfo;
                const {id = -1} = side;
                return parseInt(id);
            });
            return sidesKitIds;
        },
        /**
         * Массив всех сторон набора
         * @returns {Array}
         */
        sidesKitReduced() {
            const sidesKitReduced = Object.keys(this.sidesKit).reduce((sidesKitReduced, sidesKitKey) => {
                sidesKitReduced = [
                    ...sidesKitReduced,
                    ...this.sidesKit[sidesKitKey]
                ];
                return sidesKitReduced;
            }, []);
            return sidesKitReduced;
        },
        /**
         * Номера не найденных сторон файла
         * @returns {Array}
         */
        sidesFileNotFoundNums() {
            const rows = this.getRows(this.sidesFile, 'notFound');
            const sidesFileNotFoundNums = rows.map(row => {
                const {row_num: rowNum = 0} = row;
                return parseInt(rowNum);
            });
            return sidesFileNotFoundNums;
        },
        /**
         * Класса для блока
         * @returns {String}
         */
        blockClass() {
            return this.isLoading ? 'match-side-selection__block-loading' : '';
        },

        /**
         * Установить disabled если не выбраны стороны для набора
         * @returns {Boolean}
         */
        isDisabled() {
            return this.sidesKitCount === 0;
        },

        /**
         * Список кантреров для каждой группы
         * @returns {Array}
         */
        tabCounts() {
            return [this.sidesFile.approx.length, this.sidesFile.notFound.length, this.sidesFile.strict.length]
        },
    },
    mounted() {
        /** При монтировании устанавливаем все найденные стороны в набор, остальное в файл */
        this.sidesFile = {
            ...this.sidesFile,
            approx: this.getRows(this.sideSelectionData, 'approx'),
            notFound: this.getRows(this.sideSelectionData, 'notFound')
        };

        this.sidesKit = {
            ...this.sidesKit,
            strict: this.getRows(this.sideSelectionData, 'strict'),
        };
    },
    methods: {
        /**
         * Получить строки из объекта со сторонами
         * @param {Object} sidesObject - объект со сторонами
         * @param {String} rowsType - тип строк
         * @returns {Array}
         */
        getRows(sidesObject = {}, rowsType = '') {
            return sidesObject[rowsType] ?? [];
        },
        /**
         * Получить колличество сторон из объекта со сторонами
         * @param {Object} sidesObject - объект со сторонами
         * @returns {Number}
         */
        getRowsCount(sidesObject = {}) {
            return Object.keys(sidesObject).reduce((sidesObjectCount, sidesObjectKey) => {
                const rows = this.getRows(sidesObject, sidesObjectKey);
                return sidesObjectCount + rows.length;
            }, 0);
        },
        /**
         * Получить таскаемая ли сторона
         * @param {String} rowsType - тип строк
         * @returns {Boolean}
         */
        getSideIsDraggable(rowsType = '') {
            return String(rowsType) !== 'notFound';
        },
        /**
         * Событие завершения перетаскивания строки
         * @param {String} toDropZoneKey - ключ зоны в которую перетаскиваем
         */
        onDropSide(toDropZoneKey = '') {
            const {side: currentSide = {}, fromDropZoneKey = ''} = this.currentDragData;
            if (String(toDropZoneKey) !== String(fromDropZoneKey)) {
                const {row_num: currentSideRowNum = '-1', match_info: currentSideMatchInfo = {}} = currentSide;
                const {status = ''} = currentSideMatchInfo;

                let sidesFromObj = String(fromDropZoneKey) === 'kit' ? {...this.sidesKit} : {...this.sidesFile};
                let sidesToObj = String(toDropZoneKey) === 'kit' ? {...this.sidesKit} : {...this.sidesFile};

                let sidesFromObjStatus = [...sidesFromObj[status]] ?? [];
                const sideFromObjIndex = sidesFromObjStatus.findIndex(sideFromObj => {
                    const {row_num: sideFromObjRowNum = '-1'} = sideFromObj;
                    return String(sideFromObjRowNum) === String(currentSideRowNum);
                });
                sidesFromObjStatus.splice(sideFromObjIndex, 1);

                let sidesToObjStatus = [...sidesToObj[status]] ?? [];
                sidesToObjStatus.push(currentSide);

                sidesFromObj = {
                    ...sidesFromObj,
                    [status]: [...sidesFromObjStatus]
                };
                sidesToObj = {
                    ...sidesToObj,
                    [status]: [...sidesToObjStatus]
                };

                this.sidesKit = String(fromDropZoneKey) === 'kit' ? {...sidesFromObj} : {...sidesToObj};
                this.sidesFile = String(fromDropZoneKey) === 'file' ? {...sidesFromObj} : {...sidesToObj};
            }
        },
        /**
         * Событие начала перетаскивания
         * @param {Object} side - сторона
         * @param {String} fromDropZoneKey - зона откуда перетаскиваем
         */
        onDragStartSide(side = {}, fromDropZoneKey = '') {
            this.currentDragData = {
                side: {...side},
                fromDropZoneKey: String(fromDropZoneKey)
            };
        },
        /**
         * Событие перетаскивания сторон из набора
         */
        onClearSidesKit() {
            this.sidesFile = this.onMoveSides(this.sidesFile, () => {return true});
            this.sidesKit = {
                approx: [],
                notFound: [],
                strict: []
            };
        },
        /**
         * Событие перетаскивания сторон из файла
         * @param {String} rowsType - тип строк
         */
        onClearSidesFile(rowsType = '') {
            this.sidesKit = {
                ...this.sidesKit,
                [rowsType]: [
                    ...this.sidesKit[rowsType],
                    ...this.sidesFile[rowsType]
                ]
            };
            this.sidesFile = {
                ...this.sidesFile,
                [rowsType]: []
            };
        },
        /**
         * Событие перемещения стороны
         * @param {Object} toSidesObj - объект со сторонами в который перетаскиваем
         * @param {Function} actionFilter - событие фильтрации для типов строк
         */
        onMoveSides(toSidesObj, actionFilter = () => {return true}) {
            const toSidesObjNew = Object.keys(toSidesObj).filter(toSidesObjKey => actionFilter(toSidesObjKey)).reduce((toSidesObjReduced, toSidesObjKey) => {
                let toSidesObj = {
                    ...toSidesObjReduced,
                    [toSidesObjKey]: [
                        ...this.sidesKit[toSidesObjKey],
                        ...this.sidesFile[toSidesObjKey],
                    ]
                };
                return toSidesObj;
            }, {
                approx: [],
                notFound: [],
                strict: []
            });
            return toSidesObjNew;
        },
        /**
         * Событие загрузки сторон
         */
        onDownloadSides() {
            this.$emit('onDownloadSides', this.sidesFileNotFoundNums);
        },
        /**
         * Событие вернуться на предыдущий шаг
         */
        onBackStep() {
            this.$emit('onBackStep');
        },
        /**
         * Событие перейти к следующему шагу
         */
        onNextStep() {
            this.$emit('onNextStep', this.sidesKitIds);
        },

        /**
         * Событие переключения таба
         */
        onToggleTab(id = '-1') {
            this.activeTabId = String(id);
        },

        /**
         * Показать радиус в похожих конструкциях
         * @param {String} kitKey - Ключ набора
         */
        isShowDistance(kitKey) {
            return kitKey === 'approx' && (Object.keys(this.sideInfo).includes('lat') || Object.keys(this.sideInfo).includes('lng'))
        }
    }
}
</script>

<style lang="scss" scoped>
    .match-side-selection {

        &__tabs-wrap {
            position: relative;
        }

        &__tabs {
            margin-bottom: 15px;
        }

        &__table {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 394px;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            margin-bottom: 30px;

            &-row {
                display: flex;

                &--content {
                    flex-grow: 1;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #f2f2f2;
                }
            }
            &-head {
                //position: relative;
                width: 50%;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                text-align: left;
                &:not(:last-child) {
                    border-right: 1px solid #f2f2f2;
                }
                &--right {
                    width: 65%;
                }
                &--left {
                    width: 35%;
                }
            }
            &-column {
                position: relative;
                padding: 20px;
                &:not(:last-child) {
                    border-right: 1px solid #f2f2f2;
                }
                &--right {
                    width: 65%;
                }
                &--left {
                    width: 35%;
                }
            }
        }

        &__loader {
            z-index: 2;
        }

        &__block {
            position: relative;

            &-header {
                display: flex;
                justify-content: space-between;
                padding: 10px 20px;
            }

            &-control {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: $assistant-color;
                cursor: pointer;

                &:hover {
                    color: #000;
                }
            }

            &-title {
                position: relative;
                display: inline-block;
                font-weight: 700;
            }

            &-title-count {
                position: absolute;
                top: -6px;
                right: -30px;

                width: 30px;
                font-size: 10px;
                line-height: 20px;
                color: $assistant-color;
            }

            &-loading {
                padding: 0;
            }
        }

        &__sides {
            &-kit {}
            &-file {}
            &-empty {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                font-size: 14px;
                line-height: 20px;
            }
        }

        &__action {
            display: flex;
            justify-content: flex-end;

            &-item {
                position: relative;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            &-create {
                &--disabled {
                    background: #f2f2f2 !important;
                    color: $assistant-color;
                }
            }
        }
    }
</style>
