<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C12.5523 8 13 8.44772 13 9V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V9C11 8.44772 11.4477 8 12 8ZM12 14C12.5523 14 13 14.4477 13 15V15.01C13 15.5623 12.5523 16.01 12 16.01C11.4477 16.01 11 15.5623 11 15.01V15C11 14.4477 11.4477 14 12 14Z" fill="#FF6666"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4664 2.38397C10.928 2.11181 11.4541 1.96826 11.99 1.96826C12.5259 1.96826 13.052 2.11181 13.5136 2.38397C13.9721 2.65428 14.3504 3.04165 14.6097 3.50629L21.7052 15.7486C21.7287 15.7892 21.7494 15.8314 21.767 15.875C21.9506 16.3287 22.0203 16.8205 21.9702 17.3074C21.92 17.7943 21.7515 18.2615 21.4793 18.6683C21.2071 19.0752 20.8395 19.4092 20.4086 19.6413C19.9777 19.8734 19.4965 19.9966 19.007 20L19 20H5C4.98172 20 4.96356 19.9995 4.94552 19.9986C4.91027 20.0005 4.87456 20.0005 4.83851 19.9987C4.36061 19.9741 3.89553 19.8354 3.4821 19.5944C3.06868 19.3534 2.71891 19.017 2.46199 18.6133C2.20507 18.2096 2.04846 17.7502 2.00524 17.2737C1.96201 16.7971 2.03342 16.3171 2.21351 15.8737C2.23101 15.8306 2.2515 15.7888 2.27482 15.7486L9.37032 3.50631C9.62963 3.04167 10.0079 2.65428 10.4664 2.38397ZM4.94475 18.0015C4.96304 18.0005 4.98146 18 5 18H18.9959C19.1581 17.9984 19.3174 17.9574 19.4602 17.8805C19.6038 17.8031 19.7264 17.6918 19.8171 17.5562C19.9078 17.4206 19.964 17.2648 19.9807 17.1025C19.9954 16.9596 19.9792 16.8155 19.9333 16.6799L12.8748 4.50147L12.865 4.48414C12.7785 4.32784 12.6518 4.19755 12.4979 4.10683C12.344 4.01611 12.1686 3.96826 11.99 3.96826C11.8114 3.96826 11.636 4.01611 11.4821 4.10683C11.3282 4.19755 11.2015 4.32784 11.115 4.48414L11.1052 4.50147L4.04626 16.6806C4.00125 16.8129 3.9844 16.9534 3.99706 17.093C4.01147 17.2519 4.06367 17.405 4.14931 17.5395C4.23495 17.6741 4.35154 17.7863 4.48935 17.8666C4.62715 17.9469 4.78218 17.9931 4.94149 18.0013C4.94258 18.0014 4.94367 18.0015 4.94475 18.0015Z" fill="#FF6666"/>
    </svg>
</template>

<script>
export default {
    name: 'IconAlarm'
}
</script>