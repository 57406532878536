<template>
    <div class="match-file-attaching">
        <!-- <attach-file
            class="match-file-attaching__attach"
            :is-match-file="true"
            :file-formats="formatFile"
            :attach-title="title"
            @change="onAttach" /> -->
        <AttachFile
            :file="file" 
            @change="onAttach"
        />
    </div>
</template>

<script>
/** Компонент шага мэтчинга: прикрепление файла */
// import AttachFile from "@/components/AttachFile";

export default {
    name: 'MatchFileAttaching',
    components: {
        // AttachFile,
    },
    data: () => ({
        formatFile: ['.xls', '.xlsx'],
        title: 'Перетащите сюда файл или выберите на компьютере до 10 mb',
        file: null
    }),
    methods: {
        /**
         * Событие прикрепления файла
         * @param {Object} file - объект файла
         */
        onAttach(file = null) {
            if (file !== null) {
                this.file = file;
                this.$emit('attach', file);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .match-file-attaching {
        &__attach {
            max-height: 466px;
        }
    }
</style>
