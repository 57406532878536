export default {
    namespaced: true,
    /**
     * @property {Object} tableCustomPricesSelected - выбранные цены текущего набора
     * @property {Array} tableCustomRows - строки текущего набора
     */
    state: {
        tableCustomPricesSelected: {},
        tableCustomRows: []
    },
    getters: {
        /**
         * Выбранные строки текущего набора
         * @param {Object} state - состояния
         * @returns {Array}
         */
        tableFoundRowsSelected: (state) => {
            const {tableCustomPricesSelected = {}} = state;
            const tableFoundRowsSelected = Object.keys(tableCustomPricesSelected);
            return tableFoundRowsSelected;
        }
    },
    mutations: {
        /**
         * Установить выбранные цены текущего набора
         * @param {Object} state - состояния
         * @param {Object} tableCustomPricesSelected - выбранные цены текущего набора
         */
        setTableCustomPricesSelected(state, tableCustomPricesSelected) {
            state.tableCustomPricesSelected = tableCustomPricesSelected;
        },
        /**
         * Установить строки текущего набора
         * @param {Object} state - состояния 
         * @param {Array} tableCustomRows - установить строки текущего набора
         */
        setTableCustomRows(state, tableCustomRows) {
            state.tableCustomRows = tableCustomRows;
        }
    }
};