import ServiceApi from "../ServiceApi";

export default class ServiceShare {
    /**
     * Название микросервиса
     * @static
     * @type {String}
     */
    static microserviceName = 'free';
    /**
     * Маршрут для получения ссылки
     * @static
     * @type {String}
     */
    static routeGetLink = '/shared/get-link';

    /**
     * Маршрут для получения ссылки
     * @static
     * @type {String}
     */
    static routeRemoveLink = '/shared/remove';

    /**
     * Маршрут логина как гость
     * @static
     * @type {String}
     */
    static routeLoginGuest = '/login-guest';

    /**
     * Получить ссылку
     * @static
     * @async
     * @param {Object} requestBody - тело запроса
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    static async getLink(requestBody = {}, actionAfter = () => {}) {
        try {
            const response = await ServiceApi.post(this.microserviceName, this.routeGetLink, requestBody);
            const {data = {}} = response;
            const {data: dataLink = {}} = data;
            const {hash = '', data: {poi: {groups = []} = {}} = {}} = dataLink;
            const link = `${window.location}?shareToken=${hash}`;
            actionAfter(link, groups);
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить ссылку
     * @static
     * @async
     * @param {Number} kitId - id набора
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    static async removeLink(kitId = {}, actionAfter = () => {}) {
        try {
            await ServiceApi.delete(this.microserviceName, this.routeRemoveLink + `/${kitId}`);
            actionAfter();
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Конструктор
     * @param {String} shareToken - токен шары
     */
    constructor(shareToken = '') {
        this.shareToken = String(shareToken);
        this.shareProductToken = null;
    }

    /**
     * Логин как гость
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError
     */
    async loginGuest(actionAfter = () => {}, actionError = () => {}) {
        try {
            const requestBody = {share_token: this.shareToken};
            const response = await ServiceApi.post(ServiceShare.microserviceName, ServiceShare.routeLoginGuest, requestBody);
            const {data: responseData = {}} = response;
            const {data = {}} = responseData;
            const {product_token: productToken = ''} = data;
            this.setShareProductToken(productToken);
            actionAfter();
        }
        catch (error) {
            console.log(error);
            actionError(error)
        }
    }

    /**
     * Установить токен продукта шары в контекст
     * @param {String} shareProductToken - токен продукта
     */
    setShareProductToken(shareProductToken = '') {
        this.shareProductToken = String(shareProductToken);
    }
}
