<template>
    <div>
        <h2>Как данные попадают в справочник?</h2>
        <div>Данные в справочник поступают из Oohdesk тремя способами:</div>
        <ol>
            <li>после каждого импорта свободных конструкций;</li>
            <li>после импорта предложений;</li>
            <li>после импорта текущего размещения (только новые конструкции, которым не присвоен OOHDESK ID конструкции);</li>
        </ol>
    
        <h2>Структура данных</h2>
        <ol>
            <li>Маркер - по сути это сама конструкция, включает в себя следующие свойства: широта, долгота, азимут, страна, город, тип, вид, формат, оператор, OOHDESK ID маркера.</li>
            <li>Side - это сторона маркера, включает в себя следующие свойства: 
                OOHDESK ID маркера, страна, город, название стороны, освещение, адрес, 
                гид, espar id, ots, grp, направление движения, параметры цифровых конструкций, 
                материал, ссылку на сайт оператора и фото, которое парсит система их этой ссылки.</li>
            <li>ots, grp и направление движения рассчитываем нашими сервисами !</li>
            <li>property - это отдельная таблица, в которую сохраняются некоторые свойства конструкции, такие как: тип, вид, формат, оператор</li>
            <li>modification - все модификации конструкций</li>
        </ol>
        <h2>Нормализация данных</h2>
        <ol>
            <li>в формате убираются пробелы x(русская), * - меняются на x (латинская);</li>
            <li>для свойств город, страна, тип, вид, формат, оператор создаются алиасы (переводятся в транслит, убираются спецсимволы), чтобы избежать дублирований при сравнении и увеличить точость нахождения нового свойства;</li>
            <li>освещение переводится в формат флага (0 - нет, 1 - есть);</li>
            <li>название стороны приводится к верхнему регистру и переводится в транслит. Если GID относится к оператору Russ OutDoor, то сторона берется из него;</li>
            <li>OOHDESK ID маркера состоит из 3х символов названия города и 6 символов порядковый номер;</li>
            <li>OOHDESK ID конструкции состоит из 3х символов названия города, 3 первые и 3 последние символы в названии оператора, вид, сторона и 6 символов порядковый номер маркера</li>
        </ol>
        <h2>Краткий алгоритм синхронизации справочника</h2>
        <ol>
            <li>Осуществляется поиск конструкций по OOHDESK id конструкции, если он есть у входящей конструкции, либо по стороне и координатам. Сравнение координат проводится до 5 знака после запятой. Если конструкция или сторона не найдена, создается новая. Если найдена, то сравниваются свойства справочника и входящией конструкции, все расхождения заносятся в модификации.</li>
            <li>Параллельно формируются ответные данные справочника со всеми подтвержденными свойствами и присвоенным oohdesk id конструкции.</li>
            <li>Из модификаций отбрасываются дубли для каждого пользователя, который импортировал конструкции.</li>
        </ol>
        <h2>Конструкция не вернет OOHDESK id в следующих случаях:</h2>
        <ol>
            <li>у конструкции невалидные координаты;</li>
            <li>сторона пустая или содержит одни спецсимволы в названии;</li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'PageHelp'
}
</script>