/** Базовый сервис по работе с кластеризацией */
export default class ServiceBaseClusterer {
    /**
     * Конструктор
     * @param {Object} params - параметры кластеризации 
     */
    constructor(params = {}) {
        const {
            map = {}, 
            markers = [], 
            gridSize = 50, 
            minimumClusterSize = 50, 
            iconUrl = 'https://demo.oohdeskbase.ru/map/draw/marker-tag?showMode=0&color=3600ff',
            width = 50,
            height = 50,
            anchorTextY = 18,
            anchorTextX = 0,
            textColor = '#fff'
        } = params;
        this.map = map;
        this.markers = markers;
        this.gridSize = parseInt(gridSize);
        this.minimumClusterSize = parseInt(minimumClusterSize);
        this.iconUrl = String(iconUrl);
        this.width = parseInt(width);
        this.height = parseInt(height);
        this.anchorText = [parseInt(anchorTextY), parseInt(anchorTextX)];
        this.textColor = String(textColor);
    }
}