<template>
    <div>
        <div
            @click.self="isOpenedRequestDropdown = false"
            class="poi-modal__row"
        >
            <p class="poi-modal__text">
                {{ addGroupModal.textGroupName }}
                <span style="color: red">*</span>
            </p>
            <base-text-input
                view="primary"
                :placeholder="addGroupModal.textGroupNamePlaceholder"
                v-model.trim="groupName"
            />
        </div>

        <slot name="icons" />

        <div
            @click.self="isOpenedRequestDropdown = false"
            class="poi-modal__row _select"
        >
            <div
                class="poi-modal__select"
                :class="{ _active: composingType === 'address' }"
                @click="setActiveSelect('address')"
            >
                <icon-address
                    :color="composingType === 'address' ? '#4A91F1' : '#AAB0B8'"
                />
                <div>
                    <div>Адреса</div>
                    <div>
                        например <br />
                        улица Пушкина, д.1
                    </div>
                </div>
            </div>
            <div
                class="poi-modal__select"
                :class="{ _active: composingType === 'coords' }"
                @click="setActiveSelect('coords')"
            >
                <icon-coords
                    :color="composingType === 'coords' ? '#4A91F1' : '#AAB0B8'"
                />
                <div>
                    <div>Координаты</div>
                    <div>
                        например<br />
                        56.123; 35.789
                    </div>
                </div>
            </div>
        </div>

        <div
            @click.self="isOpenedRequestDropdown = false"
            class="poi-modal__row"
        >
            <base-drop-zone
                :class="['drop-area', { active: file }]"
                @files-dropped="addFiles"
            >
                <!-- #default="{ dropZoneActive }" -->
                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        font-size: 12px;
                        padding: 0 10px;
                    "
                >
                    <div
                        style="
                            width: 68%;
                            color: #aab0b8;
                            margin-bottom: 10px;
                            text-align: center;
                        "
                    >
                        Прикрепите .xls или .xlsx файл размером не более 5 mb, в
                        котором содержится не более 50 000 строк. Первая строка
                        в файле должна быть с заголовками
                    </div>

                    <div
                        v-if="file"
                        style="font-size: 12px; color: #4a91f1; cursor: pointer"
                        @click="removeFile"
                    >
                        удалите файл
                    </div>

                    <label v-else for="file-input">
                        <div
                            style="
                                font-size: 12px;
                                color: #4a91f1;
                                cursor: pointer;
                            "
                        >
                            выберите файл
                        </div>

                        <input
                            type="file"
                            id="file-input"
                            @change="onFileInputChange"
                        />
                    </label>
                </div>
            </base-drop-zone>
        </div>

        <div class="poi-modal__actions">
            <base-button
                class="poi-modal__cancel"
                form="square"
                view="border"
                @click="onCloseModal"
                >{{ addGroupModal.textCancel }}</base-button
            >
            <base-button
                form="square"
                view="secondary"
                :disabled="isDisabled"
                @click="onUpload"
                >{{ addGroupModal.textUpload }}</base-button
            >
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import IconAddress from "@/components/Icons/IconAddress";
import IconCoords from "@/components/Icons/IconCoords";
import BaseTextInput from "@/components/Base/BaseTextInput";
import BaseDropZone from "@/components/Base/BaseDropZone";
import containerPoiSchemes from "../../schemes/SchemeWidgetMap";
const { containerPoi } = containerPoiSchemes;

export default {
    name: "AddPoiFromFileModalContent",
    components: {
        BaseButton,
        IconAddress,
        IconCoords,
        BaseTextInput,
        BaseDropZone,
    },
    props: {
        composingType: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        addGroupModal: containerPoi.addGroupModal,
        groupName: "",
        // composingType: "address",
        file: null,
    }),

    computed: {
        isDisabled() {
            return this.groupName.length === 0 || !this.file;
        },
    },

    methods: {
        setActiveSelect(type) {
            // this.composingType = type;

            this.$emit('onChangeFileComposingType', type);
        },
        onInput(value) {
            this.groupName = value;
        },
        onUpload() {
            const requestCreateGroupParams = {
                name: this.groupName,
                request_id: "1",
                request_item_ids: ["1"],
                file: this.file,
            };
            this.$emit("onCreateNewFileGroup", requestCreateGroupParams);
        },
        onCloseModal() {
            this.$emit("onCloseModal");
        },
        onFileInputChange(e) {
            this.addFiles(e.target.files);
        },
        addFiles(newFiles) {
            this.file = newFiles[0] || null;
        },
        removeFile() {
            this.file = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.drop-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 55px 0px;

    color: #aab0b8;
    border: 1px dashed #aab0b8;

    transition: all 0.2s ease;

    &[data-active="true"],
    &.active {
        border-color: #4a91f1;
        color: #4a91f1;
        background: #f3f9ff;
    }
}
label {
    font-size: 36px;
    cursor: pointer;
    display: block;
    span {
        display: block;
    }
    input[type="file"]:not(:focus-visible) {
        // Visually Hidden Styles taken from Bootstrap 5
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
    .smaller {
        font-size: 16px;
    }
}
.image-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
}
.upload-button {
    display: block;
    appearance: none;
    border: 0;
    border-radius: 50px;
    padding: 0.75rem 3rem;
    margin: 1rem auto;
    font-size: 1.25rem;
    font-weight: bold;
    background: #369;
    color: #fff;
    text-transform: uppercase;
}
</style>
