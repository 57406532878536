<template>
    <div
        class="hamburger"
        :class="{'hamburger_active': isActive}"
        @click="onCLick"
    >
        <span class="bar bar1"></span>
        <span class="bar bar2"></span>
        <span class="bar bar3"></span>
    </div>
</template>

<script>
export default {
    name: 'AppHamburger',
    props: {
        isActive: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        onCLick() {
            this.$emit('hamburger', !this.isActive)
        }
    }
}
</script>

<style lang="scss" scoped>
.hamburger {
    width: 12px;
    height: 12px;
    position: relative;
    cursor: pointer;

    .bar {
        padding: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(0, 0, 0);
        display: block;
        transition: all 0.4s ease-in-out;
        position: absolute;
    }

    .bar1 {
        top: 0;
        transform-origin: 5%;
    }

    .bar2 {
        top: 50%;
        transform: translateY(-1px);
    }

    .bar3 {
        bottom: 0;
        transform-origin: 5%;
    }

    &.hamburger_active {
        .bar1 {
            transform: rotate(45deg);
            height: 1px;
            width: 17px;
        }

        .bar2 {
            transform: rotate(-45deg);
            background-color: transparent;
        }

        .bar3 {
            transform: rotate(-45deg);
            height: 1px;
            width: 17px;
        }
    }
}
</style>