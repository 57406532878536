import ServicePriceFilter from "@/services/ServicesPrice/ServicePriceFilter";
import ServiceApi from "@/services/ServiceApi";

export default {
    namespaced: true,
    state: {
        totalCount: 0,
        selectedRows: [],
        isLoadingFilters: false,
        filtersSelected: {},
        filtersScheme: [
            {
                key: "date",
                label: "Период",
                componentName: "DatePickerDropdown"
            },
            {
                key: "type_id",
                label: "Тип",
                componentName: "BaseSelectMultipleV2",
            },
            {
                key: "kind_code_id",
                label: "Обозначение",
                componentName: "BaseSelectMultipleV2"
            },
            {
                key: "format_id",
                label: "Формат",
                componentName: "BaseSelectMultipleV2",
            },
            {
                key: "kind_id",
                label: "Вид",
                componentName: "BaseSelectMultipleV2",
            },
            {
                key: "material_id",
                label: "Материал",
                componentName: "BaseSelectMultipleV2",
            },
            {
                key: "owner_id",
                label: "Владелец",
                componentName: "BaseSelectMultipleV2",
            },
            {
                key: "side_name",
                label: "Сторона",
                componentName: "BaseSelectMultipleV2",
            },
            {
                key: "supplier_id",
                label: "Оператор",
                componentName: "BaseSelectMultipleV2",
            },
        ],
        filtersOptions: {},
        filtersItems: [...ServicePriceFilter.filterDataScheme],
        activeMode: 'month',
        accessedDates: [],
    },
    getters: {
        selectedRows(state) {
            return state.selectedRows;
        },
        accessedDates(state) {
            return state.accessedDates;
        },
        selectedRowsCount(state) {
            return state.selectedRows.length;
        },
        filtersScheme(state) {
            let result = [...state.filtersScheme].map(schemeItem => {
                const optionsByKey = state.filtersOptions[schemeItem.key]?.items;

                return {
                    ...schemeItem,
                    options: optionsByKey ? optionsByKey : []
                }
            });

            return result;
        },
        filtersSelected(state) {
            return state.filtersSelected;
        },
        isLoadingFilters(state) {
            return state.isLoadingFilters;
        },
        activeMode(state) {
            return state.activeMode;
        },
        isUncover(state) {
            return state.activeMode === 'period_uncover';
        },
        totalCount(state) {
            return state.totalCount ?? 0;
        }
    },
    mutations: {
        setFilterByKey(state, {key, value}) {
            if (!key) return;

            state.filtersSelected = {
                ...state.filtersSelected,
                [key]: value
            };
        },
        setFiltersSelected(state, value) {
            state.filtersSelected = value;
        },
        removeFilterValueByKey(state, {key, value}) {
            let result = {...state.filtersSelected};
            const selectedValuesByKey = state.filtersSelected[key];

            if (Array.isArray(selectedValuesByKey)) {
                result[key] = selectedValuesByKey.filter(valueItem => valueItem !== value);
            }

            state.filtersSelected = result;
        },
        setActiveMode(state, value) {
            state.activeMode = value;
        },
        setLoadingFilters(state, value) {
            state.isLoadingFilters = value;
        },
        setFiltersItems(state, value) {
            state.filtersItems = value;
        },
        setTotalCount(state, value) {
            state.totalCount = value;
        },
        setFiltersOptions(state, data = []) {
            state.filtersOptions = data.reduce((acc, item) => {
                const options = item.items.map(optionItem => {
                    if (typeof optionItem === "string") {
                        return {
                            id: optionItem,
                            value: optionItem
                        }
                    } else {
                        return optionItem;
                    }
                });

                acc[item.attribute] = {
                    ...item,
                    items: options
                };
                return acc;
            }, {});
        },
        setSelectedRows(state, data = []) {
            state.selectedRows = data;
        },
        setAccessedDates(state, data = []) {
            state.accessedDates = data;
        },
    },
    actions: {
        async fetchFiltersData({ commit, state }, kitId) {
            try {
                const responseFilterSelected = await ServiceApi.post('free', '/map/set-filter', {
                    set_id: kitId,
                    ...state.filtersSelected
                });
                const responseFilterAll = await ServiceApi.post('free', '/map/set-filter', {
                    set_id: kitId,
                });

                const filterOptionsSelected = responseFilterSelected?.data?.data?.items ?? [];
                const filterOptionsAll = responseFilterAll?.data?.data?.items ?? [];

                const filterOptions = filterOptionsAll.map(filterOptionAll => {
                    let filterOption = { ...filterOptionAll };

                    const filterOptionAllAttribute = String(filterOptionAll?.attribute ?? '');
                    const filterOptionSelected = filterOptionsSelected.find(filterOptionSelected => String(filterOptionSelected?.attribute ?? '') === filterOptionAllAttribute) ?? {};
                    const filterItemsSelected = filterOptionSelected?.items ?? [];

                    if (filterOptionAllAttribute !== 'date') {
                        filterOption.items = filterOption?.items.map(filterItem => {
                            const filterItemId = String(filterItem?.id ?? '');
                            const isFilterItemSelected = filterItemsSelected?.findIndex(filterItemSelected => String(filterItemSelected?.id ?? '') === filterItemId) !== -1;
                            const filterItemNew = {
                                ...filterItem,
                                isDisabled: !isFilterItemSelected
                            };

                            return filterItemNew;
                        });
                    }

                    return filterOption;
                });

                if ("data" in responseFilterSelected && "data" in responseFilterSelected.data) {
                    commit('setFiltersOptions', filterOptions);
                    // commit('setTotalCount', responseFilterSelected.data.data.count);
                }
            } catch (error) {
                console.log(error);
                throw error;
            }
        }
    }
}
