<template>
    <div
        :class="[classIsActive, classModify]"
        class="tab-list__item"
    >
        <div
            v-if="isShowIcon"
            class="tab-list__item-icon"
            :class="tabIconClass"
        ></div>
        <div class="tab-list__item-title" @click="onCheck">
            {{name}}
            <span v-if="count">({{ count }})</span>
        </div>
        <div
            v-if="isShowIconDownload"
            @click="onDownload"
            class="tab-list__item-action-btn"
        >
            <BaseIcon
                icon="icon-tabler-icon-file-download"
                class="tab-list__item-action"
            />
        </div>
    </div>
</template>

<script>
/** Компонент для элемента списка табов */
import BaseIcon from "@/components/Base/BaseIcon";

export default {
    name: 'TabItem',
    components: { BaseIcon },
    /**
     * Входные данные компонента
     * @property {Object} tab - таб
     * @property {Boolean} isActive - условие активности таба
     */
    props: {
        tab: {
            type: Object,
            default: () => ({})
        },
        isActive: {
            type: Boolean,
            default: false
        },
        count: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        /**
         * Идентификатор таба
         * @returns {String}
         */
        id() {
            const {id = '-1'} = this.tab;
            return String(id);
        },

        /**
         * Название таба
         * @returns {String}
         */
        name() {
            const {name = ''} = this.tab;
            return String(name);
        },

        /**
         * Модификатор для класса
         * @returns {String}
         */
        mod() {
            const {mod = ''} = this.tab;
            return String(mod);
        },

        /**
         * Показать иконку
         * @returns {Boolean}
         */
        isShowIcon() {
            const {isShowIcon = false} = this.tab;
            return Boolean(isShowIcon);
        },

        /**
         * Показать иконку/кнопку для действия
         * @returns {Boolean}
         */
        isShowIconDownload() {
            const {isShowIconDownload = false} = this.tab;
            return Boolean(isShowIconDownload);
        },

        /**
         * Класс для активного таба
         * @returns {String}
         */
        classIsActive() {
            return this.isActive ? 'tab-list__item--active' : '';
        },

        /**
         * Добавить класс с модификатором
         * @returns {String}
         */
        classModify() {
            return this.mod ? `tab-list__item--${this.mod}` : '';
        },

        /**
         * Добавить класс с модификатором для иконки
         * @returns {String}
         */
        tabIconClass() {
            return this.mod ? `tab-list__item-icon--${this.mod}` : '';
        },
    },
    methods: {
        /**
         * Событие выбора таба
         */
        onCheck() {
            this.$emit('onCheck', this.id);
        },

        /** Событие загрузки */
        onDownload() {
            this.$emit('onDownload');
        },
    }
}
</script>

<style lang="scss" scoped>
    .tab-list__item {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid #DCE1E8;
        border-radius: 50px;
        color: #DCE1E8;
        padding: 8px 19px;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }

        &--active {
            border-color: #6EC87A;
            color: #000000;
        }
        &--active.tab-list__item--success {
            background-color: $success-bg-color;
            border-color: transparent;
        }
        &--active.tab-list__item--warning {
            background-color: $warning-bg-color;
            border-color: transparent;
        }
        &--active.tab-list__item--error {
            background-color: $danger-bg-color;
            border-color: transparent;
        }
        &--success {
            background-color: transparent;
        }
        &--warning {
            background-color: transparent;
        }
        &--error {
            background-color: transparent;
        }

        &-icon {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 5px;

            &--success {
                background-color: $green-color;
            }
            &--warning {
                background-color: $warning-color;
            }
            &--error {
                background-color: $red-color;
            }
        }

        &-title {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            font-size: 14px;
            line-height: 20px;
            color: #000;
            cursor: pointer;
        }

        &-action-btn {
            cursor: pointer;
            margin-left: 15px;
        }

        &-action {
            color: #000;
            font-size: 20px;
        }
    }
</style>
