<template>
    <div class="control-panel">
        <control-panel-block
            v-for="(panelBlock, index) in panelBlocks"
            :key="index"
            :panelBlock="panelBlock"
            :panelIdsActive="panelIdsActiveBuff"
            :isShow="getPanelBlockShow(panelBlock)"
            @onClickPanelItem="onClickPanelItem"
            @onToggleShowPanelBlock="onToggleShowPanelBlock"
        />
    </div>
</template>

<script>
import ControlPanelBlock from "./ControlPanelBlock";
import MixinPanel from '../../mixins/MixinPanel';
import SchemeControlPanel from '../../schemes/SchemeControlPanel';
const {panelBlocks: panelBlocksScheme} = SchemeControlPanel;
export default {
    name: "ControlPanel",
    components: {ControlPanelBlock},
    mixins: [MixinPanel],
    /**
     * Входные данные компонента
     * @property {Array} panelIdsActive - массив активных элементов панели контроллеров карты
     * @property {Array} panelBlocksUnvisible - массив блоков, которые нужно скрыть
     */
    props: {
        panelIdsActive: {
            type: Array,
            default: () => ([])
        },
        panelBlocksUnvisible: {
            type: Array,
            default: () => ([])
        },
        panelItemsUnvisible: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        /**
         * Блоки элементов панели
         * @type {Array}
         */
        panelBlocksScheme: panelBlocksScheme,
        /**
         * Буфферный массив активных элементов панели контроллеров карты
         * @type {Array}
         */
        panelIdsActiveBuff: [],
        /**
         * Видимые блоки
         * @type {Array}
         */
        panelBlockKeysActive: []
    }),
    computed: {
        /**
         * Блоки
         * @returns {Array}
         */
        panelBlocks() {
            const panelBlocks = panelBlocksScheme.filter(panelBlock => {
                const {key = ''} = panelBlock;
                return !this.panelBlocksUnvisible.includes(String(key));
            }).map(panelBlock => {
                const {items = []} = panelBlock;
                return {...panelBlock, items: items.filter(item => !this.panelItemsUnvisible.includes(String(item?.id)))};
            });
            return panelBlocks;
        },
        /**
         * Элементы панели
         * @returns {Array}
         */
        panelItems() {
            return this.panelBlocks.reduce((panelItems, block) => {
                const {items = []} = block;
                panelItems.push(...items);
                return panelItems;
            }, []);
        }
    },
    watch: {
        /** Следим за изменением массив активных элементов панели контроллеров карты и обновляем буффер */
        // panelIdsActive: function() {
        //     this.panelIdsActiveBuff = [...this.panelIdsActive];
        // },
    },
    mounted() {
        /**
         * При монтировании устанавливаем выбор точки и видимые блоки
         */
        this.panelIdsActiveBuff.push(...['selection', 'clustering']);
        this.panelBlockKeysActive = this.panelBlocks.map(block => {
            const {key = ''} = block;
            return String(key);
        });
    },
    methods: {
        /**
         * Переключить видимость блоков
         * @param {String} panelBlockKey - ключ блока
         */
        onToggleShowPanelBlock(panelBlockKey = '') {
            const index = this.panelBlockKeysActive.findIndex(blockKey => String(blockKey) === String(panelBlockKey));
            if (index !== -1)
                this.panelBlockKeysActive.splice(index, 1);
            else
                this.panelBlockKeysActive.push(panelBlockKey);
        },
        /**
         * Получить активность элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         * @returns {Boolean}
         */
        getPanelItemActive(panelItemId = '') {
            return this.panelIdsActiveBuff.includes(String(panelItemId));
        },
        /**
         * Получить видимость блока
         * @param {Object} panelBlock - блок
         * @returns {boolean}
         */
        getPanelBlockShow(panelBlock = {}) {
            const {key = ''} = panelBlock;
            return this.panelBlockKeysActive.includes(String(key));
        },
        /**
         * Получить название эмита элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         * @returns {String}
         */
        getPanelItemEmit(panelItemId = '') {
            const {emit = ''} = this.panelItems.find(panelItem => String(panelItem.id) === String(panelItemId));
            return String(emit);
        },
        /**
         * Событие переключения активности элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         */
        onTogglePanelItemActive(panelItemId = '') {
            const active = this.getPanelItemActive(panelItemId);
            if (String(panelItemId) === 'selection' || String(panelItemId) === 'massSelection') {
                this.onToggleMode(panelItemId);
            } else if (active) {
                this.panelIdsActiveBuff.splice(this.panelIdsActiveBuff.findIndex(panelId => String(panelId) === String(panelItemId)), 1);
            } else {
                if (panelItemId === 'pedestrianTraffic'){
                    this.disableOtherTraffic('autoTraffic');
                    this.disableOtherTraffic('jamsTraffic');
                }else if (panelItemId === 'autoTraffic'){
                    this.disableOtherTraffic('pedestrianTraffic');
                    this.disableOtherTraffic('jamsTraffic');
                }else if (panelItemId === 'jamsTraffic'){
                    this.disableOtherTraffic('pedestrianTraffic');
                    this.disableOtherTraffic('autoTraffic');
                } else if (String(panelItemId) === 'directionAngleView')
                    this.disableOtherTraffic('directionFlowSide');
                else if (String(panelItemId) === 'directionFlowSide')
                    this.disableOtherTraffic('directionAngleView');
                if (panelItemId === 'ruler')
                    this.onClickPanelItem('selection');
                this.panelIdsActiveBuff.push(String(panelItemId));
            }
        },
        /**
         * Событие отключения активности
         * @param {String} name - идентификатор элемента панели
         */
        disableOtherTraffic(name){
            let panelIndex = this.panelIdsActiveBuff.findIndex(panelId => String(panelId) === name);
            if (panelIndex !== -1) {
                const emit = this.getPanelItemEmit(this.panelIdsActiveBuff[panelIndex]);
                this.$emit(emit, false);
                this.panelIdsActiveBuff.splice(panelIndex, 1);
            }
        },
        /**
         * Переключить режим выбора
         * @param {String} panelItemId - идентификатор элемента панели
         */
        onToggleMode(panelItemId = '') {
            if (String(panelItemId) === 'massSelection' && this.panelIdsActiveBuff.includes('ruler')) {
                this.onClickPanelItem('ruler');
            }
            const panelItemIdRemove = String(panelItemId) === 'selection' ? 'massSelection' : 'selection';
            const indexRemove = this.panelIdsActiveBuff.findIndex(panelId => String(panelId) === panelItemIdRemove);
            if (indexRemove !== -1)
                this.panelIdsActiveBuff.splice(indexRemove, 1);
            if (!this.panelIdsActiveBuff.includes(String(panelItemId)))
                this.panelIdsActiveBuff.push(String(panelItemId));
        },
        /**
         * Событие клик по элементу панели
         * @param {String} panelItemId - идентификатор элемента панели
         */
        onClickPanelItem(panelItemId = '') {
            this.onTogglePanelItemActive(panelItemId);
            const active = this.getPanelItemActive(panelItemId);
            const emit = this.getPanelItemEmit(panelItemId);
            this.$emit(emit, active);
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel {
        width: 90px;
        background: #FFFFFF;
        box-shadow: 2px 3px 20px #3B4A741A;
        border-radius: 10px;
        padding: 8px;
    }
</style>
