<template>
    <div class="preloader">
    <div class="container-fluid">
        <div class="spinner">
            <span class="ball-1"></span>
            <span class="ball-2"></span>
            <span class="ball-3"></span>
            <span class="ball-4"></span>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'BasePreloaderGif',
    /**
     * Входные данные компонента
     * @property {Number} size - размер
     */
    props: {
        size: {
            type: Number,
            default: 150
        }
    },
    computed: {
        /**
         * Стили для картинки
         * @returns {Object}
         */
        imgStyle() {
            const imgStyle = {
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
            return imgStyle;
        }
    }
}
</script>

<style lang="scss" scoped>
    .preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: +2000;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    }
    .preloader .container-fluid {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .spinner {
    top: 50%;
    left: 50%;
    z-index: 1;
    height: 30px;
    width: 30px;
    margin-left: -15px;
    margin-top: -40px;

    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    position: relative;
    }
    .spinner span {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        display: block;
        position: absolute;
        opacity: 0.7;
    }
    .spinner .ball-1 {
        z-index: 4;
        background-color: #7BB1FE;
        left: -20px;
        -webkit-transform: translate(50%, 50%) scale(0.5);
        -webkit-animation: animateSway 2s infinite ease-in-out, animateScale 2s infinite ease-in-out;
        animation: animateSway 2s infinite ease-in-out, animateScale 2s infinite ease-in-out;
    }
    .spinner .ball-2 {
        z-index: 3;
        background-color: #3F8FFE;
        left: -20px;
        -webkit-transform: translate(50%, 50%) scale(0.5);
        -webkit-animation: animateSway 2s infinite ease-in-out 500ms, animateScale 2s infinite ease-in-out 500ms;
        animation: animateSway 2s infinite ease-in-out 500ms, animateScale 2s infinite ease-in-out 500ms;
    }
    .spinner .ball-3 {
        z-index: 2;
        background-color: #A8CBFC;
        left: -20px;
        -webkit-transform: translate(50%, 50%) scale(0.5);
        -webkit-animation: animateSway 2s infinite ease-in-out 1s, animateScale 2s infinite ease-in-out 1s;
        animation: animateSway 2s infinite ease-in-out 1s, animateScale 2s infinite ease-in-out 1s;
    }
    .spinner .ball-4 {
        z-index: 1;
        background-color: #0068F8;
        left: -20px;
        -webkit-transform: translate(50%, 50%) scale(0.5);
        -webkit-animation: animateSway 2s infinite ease-in-out 1.5s, animateScale 2s infinite ease-in-out 1.5s;
        animation: animateSway 2s infinite ease-in-out 1.5s, animateScale 2s infinite ease-in-out 1.5s;
    }

    @-webkit-keyframes animateSway {
        0% { left: -20px }
        50% { left: 20px }
        100% { left: -20px }
    }

    @keyframes animateSway {
        0% { left: -20px }
        50% { left: 20px }
        100% { left: -20px }
    }

    @-webkit-keyframes animateScale {
        0% { -webkit-transform: translate(50%, 50%) scale(0.5); z-index: -1 }
        25% { -webkit-transform: translate(50%, 50%) scale(1); z-index: +1 }
        50% { -webkit-transform: translate(50%, 50%) scale(0.5); z-index: -1 }
        75% { -webkit-transform: translate(50%, 50%) scale(0.25); z-index: -2 }
        100% { -webkit-transform: translate(50%, 50%) scale(0.5); z-index: -1 }
    }

    @keyframes animateScale {
        0% { transform: translate(50%, 50%) scale(0.5); z-index: -1 }
        25% { transform: translate(50%, 50%) scale(1); z-index: +1 }
        50% { transform: translate(50%, 50%) scale(0.5); z-index: -1 }
        75% { transform: translate(50%, 50%) scale(0.25); z-index: -2 }
        100% { transform: translate(50%, 50%) scale(0.5); z-index: -1 }
    }
</style>
