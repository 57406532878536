/** Утилита для работы с цифрами */
export default class UtilNumber {
    /**
     * Привести цифру к формату "Колличество"
     * @public
     * @static
     * @param {Number|String} num - цифра
     * @returns {String}
     */
    static toCount(num = 0) {
        return isNaN(parseFloat(num)) ? '' : `${this.toLocaleNum(num)} ст`;
    }

    /**
     * Привести цифру к формату "Цена"
     * @public
     * @static
     * @param {Number|String} num - цифра
     * @returns {String}
     */
    static toPrice(num = 0) {
        return isNaN(parseFloat(num)) ? '' : `${this.toLocaleNum(num)} ₽`;
    }

    static toPercent(num = 0) {
        return isNaN(parseFloat(num)) ? '' : `${this.toLocaleNum(num * 100)} %`;
    }

    /**
     * Разбить цифру по разрядам
     * @public
     * @static
     * @param {Number|String} num - цифра
     * @returns {String}
     */
    static toLocaleNum(num = 0) {
        return isNaN(parseFloat(num)) ? '' : parseFloat(num).toLocaleString('ru');
    }

    static getSumNds(price = 0) {
        return (parseFloat(price) / 120) * 20;
    }

    static getSumNdsLocale(price = 0) {
        return isNaN(parseFloat(price)) ? '' : this.getSumNds(price).toLocaleString('ru');
    }

    static getPriceWithoutNds(price = 0) {
        return (parseFloat(price) - this.getSumNds(price));
    }

    static getPriceWithoutNdsLocale(price = 0) {
        return isNaN(parseFloat(price)) ? '' : this.getPriceWithoutNds(price).toLocaleString('ru');
    }
}