<template>
    <svg class="svg-icon" :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5891 4.4107C15.9145 4.73614 15.9145 5.26378 15.5891 5.58921L5.58909 15.5892C5.26366 15.9147 4.73602 15.9147 4.41058 15.5892C4.08514 15.2638 4.08514 14.7361 4.41058 14.4107L14.4106 4.4107C14.736 4.08527 15.2637 4.08527 15.5891 4.4107Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.41058 4.4107C4.73602 4.08527 5.26366 4.08527 5.58909 4.4107L15.5891 14.4107C15.9145 14.7361 15.9145 15.2638 15.5891 15.5892C15.2637 15.9147 14.736 15.9147 14.4106 15.5892L4.41058 5.58921C4.08514 5.26378 4.08514 4.73614 4.41058 4.4107Z" :fill="color"/>
</svg>
</template>

<style scoped>
    .svg-icon path {
        fill: currentColor;
    }
</style>

<script>
export default {
    name: 'IconClose',
    props: {
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'black'
        },
    },
}
</script>
