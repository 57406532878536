import ServiceApi from '../ServiceApi.js';

export default class ServicePriceFilter {
    static _microserviceName = 'free';

    static _requestRouts = {
        getFilter: '/map/set-filter'
    };

    static filterDataScheme = [
        {
            id: 'date',
            componentName: 'DatePickerDropdown',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Даты',
            getValuesAfter: (values) => values.map(value => ({ id: String(value), value: String(value) })),
        },
        {
            id: 'format_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Формат',
            getValuesAfter: (values) => values,
        },
        {
            id: 'kind_code_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Обозначение',
            getValuesAfter: (values) => values,
        },
        {
            id: 'kind_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Вид',
            getValuesAfter: (values) => values,
        },
        {
            id: 'supplier_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Оператор',
            getValuesAfter: (values) => values,
        },
        {
            id: 'type_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Тип',
            getValuesAfter: (values) => values,
        },
        {
            id: 'owner_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Владелец',
            getValuesAfter: (values) => values,
        },
        // {
        //     id: 'material_id',
        //     componentName: 'BaseSelectMultipleV2',
        //     values: [],
        //     valuesSelected: [],
        //     defaultTitle: 'Материал',
        //     getValuesAfter: (values) => values,
        // }
    ];

    static async getFilter(kitId = '', filterData = []) {
        return this._getFilter(kitId, filterData);
    }

    static async _getFilter(kitId = '', filterData = []) {
        const requestBody = this._getFilterBefore(kitId, filterData);

        try {
            const responseBody = await ServiceApi.post(this._microserviceName, this._requestRouts.getFilter, requestBody);
            const { data : { data: { items: filterItems = [], count = "" } = {} } = {} } = responseBody;
            const filterDataAfter = this._getFilterBeforeAfter(this.filterDataScheme, filterItems)

            return {
                items: filterDataAfter,
                count: isNaN(parseInt(count)) !== true ? parseInt(count) : 0,
            }
        } catch (error) {
            console.log(error);
        }
    }

    static _getFilterBefore(kitId = '', filterData = []) {
        const filterDataSelected = filterData.reduce((filterDataSelected, filterItem) => {
            filterDataSelected = {
                ...filterDataSelected,
                [filterItem.id]: filterItem?.valuesSelected ?? []
            };

            return filterDataSelected;
        }, {});

        const requestBody = {
            set_id: String(kitId),
            ...filterDataSelected
        };

        return requestBody;
    }

    static _getFilterBeforeAfter(filterData = [], filterItems = []) {
        const filterDataAfter = filterData.map(filterDataItem => {
            const filterItem = filterItems.find(filterItem => String(filterItem?.attribute ?? '') === String(filterDataItem?.id ?? '')) ?? {};
            const filterValues = filterItem?.items ?? [];

            const filterDataItemAfter = {
                ...filterDataItem,
                values: filterDataItem.getValuesAfter(filterValues)
            };

            return filterDataItemAfter;
        });

        return filterDataAfter;
    }
}
