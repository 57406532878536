/* eslint-disable no-extra-semi */
import ServiceGooglePolygon from '../../services/ServicesGoogle/ServiceGooglePolygon';
/** Фабрика для создания полигонов через гугл мап апи */
export default class FactoryGooglePolygon {
    /**
     * Создать массив полигонов
     * @param {Array} polygons - полигоны
     * @param {Object} map - объект карты
     * @returns {Array}
     */
    static createPolygons(polygons = [], map = {}) {
        return polygons.map(polygon => new ServiceGooglePolygon({...polygon, map}));
    };
};