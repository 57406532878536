import ServiceAuth from "./ServiceAuth";
import ServiceToken from "./ServiceToken";

/** Сервис-наблюдатель за токенами в печеньках */
export default class ServiceTokenObserver {
    static checkTokenInterval = 2000;

    /**
     * Конструктор
     */
    constructor(isTokenListener = true) {
        this.timerId = null;
        if (isTokenListener)
            this.tokenListener();
    }

    /**
     * Токен продукта
     * @returns {String|null}
     */
    get productToken() {
        return ServiceToken.productToken;
    }

    /**
     * Токен сервиса авторизации
     * @returns {String|null}
     */
    get authToken() {
        return ServiceToken.authToken;
    }

    /**
     * Есть ли токен продукта
     * @returns {Boolean}
     */
    get isProductToken() {
        return this.productToken !== null;
    }

    /**
     * Есть ли токен сервиса авторизации
     * @returns {Boolean}
     */
    get isAuthToken() {
        return this.authToken !== null;
    }

    /**
     * Необходима ли аутентификация по паре логин и пароль
     * @returns {Boolean}
     */
    get isLogin() {
        return this.authToken === null;
    }

    /**
     * Необходима ли аутентификация на сервисе авторизации
     * @returns {Boolean}
     */
    get isAuth() {
        return this.authToken !== null && this.productToken === null;
    }

    /**
     * Слушатель за событиями над токенами
     */
    tokenListener() {
        let productTokenBuff = '-1';
        let authTokenBuff = '-1';
        const tokenListener = async () => {
            const isProductTokenChanged = productTokenBuff !== this.productToken;
            const isAuthTokenChanged = authTokenBuff !== this.authToken;
            if (isProductTokenChanged)
                productTokenBuff = this.productToken;
            if (isAuthTokenChanged)
                authTokenBuff = this.authToken;
            if (isProductTokenChanged || isAuthTokenChanged) {
                await this.notify();
            }
        };
        tokenListener();
        this.timerId = setInterval(tokenListener, ServiceTokenObserver.checkTokenInterval);
    }

    /**
     * Снять слушателя за событиями над токенами
     */
    clearTokenListener() {
        clearInterval(this.timerId);
        this.timerId = null;
    }

    /**
     * Уведомление подписчику, сервису аутентификации
     */
    async notify() {
        if (!this.isProductToken && this.isAuthToken) {
            await ServiceAuth.auth();
        }
        else if (!this.isAuthToken) {
            this.clearTokenListener();
            await ServiceAuth.logout();
        }
    }
}
