/* eslint-disable no-extra-semi */
import ServiceGoogleInfoWindow from '../../services/ServicesGoogle/ServiceGoogleInfoWindow';
import SchemeInfoWindow from '../../schemes/SchemeInfoWindow';
const {contents, replaceHtml} = SchemeInfoWindow;
/** Фабрика для создания информационных окон через гугл мап апи */
export default class FactoryGoogleInfoWindow {
    /**
     * Создать информационное окно
     * @param {Object} infoWindowParams - параметры для информационного окна
     * @param {String} infoWindowKey - ключ для получения контента информационного окна
     * @param {String} insertHtml - изменяемое значение html
     * @returns {Object}
     */
    static createInfoWindow(infoWindowParams = {}, infoWindowKey = 'default', insertHtml = '') {
        const content = contents[infoWindowKey] || '';
        return new ServiceGoogleInfoWindow({...infoWindowParams, content: content.replace(replaceHtml, insertHtml)});
    };

    /**
     * Создать список чекбоксов
     * @param {string} selector - селектор
     * @param {Array} items - массив элементов
     * @returns {String}
     */
    static generateCheckboxList(selector, items = []) {
        let string = '';
        items.forEach(item => {
            let checked = item.checked ? 'checked' : "";
            string += `
                <div class="info-window__content-item">
                    <label class="info-window__checkbox">
                        <input id="checkbox_${item.name}" class="${selector}" type="checkbox" ${checked} value="${item.value}">
                        <span class="checkmark" />
                    </label>
                    <div class="info-window__checkbox-value">${item.name}</div>
                </div>
            `;
            // <div>
            //     <input id="checkbox_${item.name}" class="${selector}" type="checkbox" ${checked} value="${item.value}">
            //     <label for="checkbox_${item.name}">${item.name}</label>
            // </div>
        })
        return string;
    };

    /**
     * Создать список чекбоксов
     * @param {Object} poiInfo - массив элементов
     * @returns {String}
     */
    static generatePoiInfo(poiInfo) {
        const {address = '', description = ''} = poiInfo;
        const string = `
            <div class="">Описание:</div>
            <div class="info-window__content-description">${description}</div>
            <div class="">Адрес:</div>
            <div class="info-window__content-address">${address}</div>
        `;
        return string;
    };
};
