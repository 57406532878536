<template>
	<div v-if="link" class="link">
		<a :href="link" target="_blank">{{content}}</a>
	</div>
</template>

<script>
export default {
	name: "Link",
	props: {
        content: {
            type: String,
            default: "Перейти на сайт"
        },
		link: {
			type: String,
			default: ""
		}
	}
}
</script>

<style lang="scss" scoped>
.link {
	width: 100%;
	word-break: break-all;
	color: blue;
}
</style>
