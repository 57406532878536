<template>
    <div
        class="wrapper"
        :class="getRootClasses"
    >
<!-- Перевёл это всё в один input, который взависимости от видимости пароля меняет свой type      -->
<!--        <input-->
<!--            v-show="!isShowPass"-->
<!--            class="input"-->
<!--            type="password"-->
<!--            ref="input"-->
<!--            v-model="password"-->
<!--            @input="onInput"-->
<!--            @blur="onBlur"-->
<!--        >-->
        <input
            class="input"
            :type="isShowPass ? 'text' : 'password'"
            ref="input"
            v-model="password"
            @input="onInput"
            @blur="onBlur"
        >
        <span 
            v-if="view === 'outline' && label" 
            class="input-label"
            :style="inputLabelStyleObj"
        >
            {{ label }}
        </span>

        <div
            v-if="isShowPassIcon"
            class="show-pass"
            @click="onShowPass">
            <span v-if="isShowPass" class="pass-icon icon-tabler-icon-eye-off"></span>
            <span v-if="!isShowPass" class="pass-icon pass-icon--hidden icon-tabler-icon-eye"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BasePasswordInput",
    props: {
        modificator: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
            validator: value => ['text', 'password'].includes(value)
        },
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
        },
        canClear: {
            type: Boolean,
            default: false
        },
        isShowPassIcon: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
            default: 'line',
            validator: value => ['base', 'line', 'primary', 'outline'].includes(value)
        }
    },
    data: () => ({
        inputLazyTimerId: null,
        isShowPass: false,
        password: '',
    }),
    computed: {
        /**
         * Условие пустоты значения
         * @returns {Boolean}
         */
        isEmpty() {
            return this.value === '' || this.value === undefined;
        },
        /**
         * Условие рендера крестика очистки инпута
         * @returns {Boolean}
         */
        isClear() {
            return this.canClear && this.value !== '' && this.value !== undefined;
        },
        /**
         * Классы для корневого элемента
         */
        getRootClasses() {
            const classes = ['wrapper_' + this.view];

            if (this.modificator) {
                classes.push(`wrapper--${this.modificator}`);
            }

            if (!this.isEmpty) classes.push('wrapper--filled');

            return classes;
        },
        inputLabelStyleObj() {
            return this.value !== '' ? {'top': 0, 'font-size': '12px'} : {};
        }
    },
    methods: {
        onInput(event) {
            const value = event === null ? '' : event.target.value
            this.$emit('input', value)
            //TODO: при событии ввода вызываю событие лениового ввода, которое либо дропнет предудыщее, либо выполнится
            this.onInputLazy(value);
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit('onInputLazy', value);
            }, 300);
        },
        onClear() {
            this.$refs.input.value = ''
            this.onInput(null)
        },
        /**
         * Событие для фокусировки инпута
         */
        onFocus() {
            this.$refs.input.focus();
        },
        /**
         * Событие снятия фокусировки с инпута
         */
        onBlur(event) {
            this.$emit('onBlur', event);
        },
        /**
         * Событие для показа символов пароля
         */
        onShowPass() {
            this.isShowPass = !this.isShowPass;
            this.$emit('onShowPass', this.onShowPass);
        },
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;
$bg-input-color:            #F5F5F5 !default;
$input-color:               #000000 !default;
$show-pass-color:           #E10032 !default;
$hidden-pass-color:         #848484 !default;

$color-text--outline: #000000 !default;
$color-background--outline: inherit !default;
$color-border--outline: #C6CDDC !default;
$color-border--outline-focus: #2B6BF3 !default;

.input-label {
    cursor: text;
    pointer-events: none;
    padding: 4px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: $color-text--outline;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    background-color: inherit;
    transition: all 0.15s ease-in-out;
}

.wrapper {
    position: relative;
    display: block;
    width: 100%;

    &_base {
        .input {
            display: block;
            width: 100%;
            padding: 0 .75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
            }
        }
    }

    &_line {
        .input {
            display: block;
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            background-color: #fff;
            border: none;
            border-bottom: 1px solid;
            border-color: #E7E7EB;
            background: none;

            &:focus {
                outline: none;
                border-color: #000;
                color: #000;
            }
        }
    }

    &_primary {
        .input {
            background: $primary-color;
            border: none;
            outline: none;
            height: 36px;
            width: 100%;
            display: block;
            padding-left: 15px;
            font-weight: 700;
            font-size: 12px;
            color: #000;
            border-radius: 4px;

            &::placeholder {
                color: inherit;
                font-weight: inherit;
                font-size: inherit;

            }
        }
    }

    .clear {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        cursor: pointer;
        line-height: 1.5;
        font-weight: 700;
        font-size: 10px;
        color: #000;

        &:hover {
            color: red;
        }
    }

    .show-pass {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        color: black;
    }

    .pass-icon {
        font-size: 18px;
        color: $show-pass-color;

        &--hidden {
            color: $hidden-pass-color;
        }
    }

    &_outline {
        .input {
            padding: 8px 20px;
            width: 100%;
            min-height: 54px;
            outline: none;
            color: $color-text--outline;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            background-color: $color-background--outline;
            border: 1px solid $color-border--outline;
            border-radius: 16px;
            transition: border 0.15s ease-in-out;
            &:focus {
                border-color: $color-border--outline-focus;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s, border-color 0.15s ease-in-out;
            }
            &[data-autocompleted] {
                background-color: transparent !important;
            }
        }

        .show-pass {
            display: flex;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
        }

        .pass-icon {
            color: $color-border--outline;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            transition: color 0.15s ease-in-out;
            &:hover {
                color: $color-text--outline;
            }
        }
    }

    &--filled .input-label,
    .input:focus + .input-label {
        top: 0;
        font-size: 12px;
    }
}
</style>
