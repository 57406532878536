<template>
    <div class="d-chips">
        <div class="d-chips__table">
            <div
                v-for="(chipsItem, index) in chips"
                :key="index"
                class="d-chips__item"
            >
                <div
                    class="d-chips__card"
                >
                    {{ chipsItem.value }}
                    <span
                        class="d-chips__card-close"
                        @click="onRemoveChips(chipsItem)"
                    >
                        <BaseIcon icon="icon-tabler-icon-x" class="d-chips__card-close-icon"/>
                    </span>
                </div>
            </div>
            <div v-if="isShowRemoveAll && !isFilterOpen" class="d-chips__item">
                <div class="d-chips__remove" @click="onRemoveAllChips">
                    {{ removeAllLabel }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
    name: "Chips",
    components: {
        BaseIcon,
    },

    /**
     * Входные данные компонента
     * @property {Array} chips - Список чипсов
     * @property {Object} customStyle - Кастомные стили для одного чипса
     * @property {Number} numberDisplayedChips - Количество чипсов, отображаемых по умолчанию
     */
    props: {
        chips: {
            type: Array,
            default: () => []
        },
        countToShowRemove: {
            type: Number,
            default: 2
        },
        removeAllLabel: {
            type: String,
            default: 'Сбросить всё'
        },
        isFilterOpen: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        isShowRemoveAll() {
            return this.countToShowRemove === 0 ? false : Object.keys(this.chips).length >= this.countToShowRemove;
        }
    },
    methods: {
        /**
         * Клик на кнопку удаления
         * @property  {Object} chipsItem - элемент для удаления
         */
        onRemoveChips(chipsItem) {
            this.$emit('remove', chipsItem);
        },
        /**
         * Клик на кнопку удаления всех элементов
         */
        onRemoveAllChips() {
            this.$emit('onRemoveAllChips');
        }
    }
}
</script>

<style lang="scss" scoped>
.d-chips {
    &__table {
        margin: -4px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
    }

    &__item {
        padding: 4px;
        flex: 0 0 auto;
        max-width: fit-content;
    }

    &__card {
        padding: 3px 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        color: var(--d-color--text-secondary);
        font-size: var(--d-font-size--sm);
        font-weight: var(--d-font-weight--regular);
        line-height: 18px;
        background-color: var(--d-color--blue-500);
        border-radius: var(--d-border-radius--3xl);

        &-close {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background-color: var(--d-color--text-secondary);
            border-radius: 100%;
            opacity: 0;
            visibility: hidden;
            transition: var(--d-transition--low);

            &-icon {
                color: var(--d-color--text-primary);
                font-size: var(--d-font-size--sm);
            }
        }

        &:hover {
            .d-chips__card-close {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__remove {
        cursor: pointer;
        padding: 2px 10px;
        color: var(--d-color--text-primary);
        font-size: var(--d-font-size--sm);
        font-weight: var(--d-font-weight--regular);
        line-height: 18px;
        border: 1px solid var(--d-color--gray-400);
        border-radius: var(--d-border-radius--3xl);
        transition: var(--d-transition--low);
        &:hover {
            border-color: var(--d-color--green-primary);
        }
    }
}
</style>
