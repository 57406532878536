<template>
    <RealtimeSystem
        :componentKey="componentKey"
        :onAnyEventsSocketScheme="onAnyEventsSocketScheme"
        @exportSet="exportSet"
    />
</template>

<script>
import RealtimeSystem from './RealtimeSystem.vue';
import { mapMutations } from 'vuex';
export default {
    name: 'RealtimeSystemMap',
    components: {RealtimeSystem},
    data: () => ({
        componentKey: 'RealtimeSystemMap',
        onAnyEventsSocketScheme: [
			{
				id: 'exportSet',
				eventNameSocket: 'export_set_queue_job',
				emitNameComponent: 'exportSet',
			},
		]
    }),
    methods: {
        ...mapMutations('history', [
            'addHistoryItem',
        ]),
        exportSet(data) {
            const {success = false, history = "", error = ""} = data;
            const {url = null} = history;
            this.addHistoryItem(history);
            console.log(data)
            if(success && url !== null){
                this.$notify.dNotify({
                    type: 'success',
                    title: 'Задача на экспорт успешно выполнена',
                    description: `<a href="${url}" target="_blank" style="color: #4A92F6">${history?.title}</a>`,
                    duration: Infinity,
                    position: 'bottom-right'
                });

            }
            if(!success){
                // alert(error);
                this.$notify.dNotify({
                    type: 'error',
                    title: 'Не удалось выполнить экспорт',
                    description: error,
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
        }
    }
}
</script>
