import Vue from 'vue';
import NotificationItem from './NotificationItem.vue';

const NotificationConstructor = Vue.extend(NotificationItem);

let zIndex = 1000;
const defaultOffsetY = 20;
const defaultOffsetX = 20;

/**
 * Показать уведомление.
 *
 * @param {Object} options - Параметры уведомления.
 * @param {'default', 'success' | 'error' | 'warning'} [options.type='success'] - Тип уведомления.
 * @param {'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'} [options.position='bottom-right'] - Позиция уведомления.
 * @param {string} [options.title] - Заголовок уведомления (может быть HTML).
 * @param {string} [options.description] - Описание уведомления (может быть HTML).
 * @param {number} [options.duration=3000] - Длительность показа уведомления в миллисекундах.
 * @param {boolean} [options.isShowClose=true] - Показывать ли кнопку закрытия уведомления.
 */
function dNotify(options) {
    const instance = new NotificationConstructor({
        propsData: options,
        data: {
            zIndex: zIndex++,
        },
    });

    instance.$mount();
    document.body.appendChild(instance.$el);

    instance.$on('close', () => {
        document.body.removeChild(instance.$el);
        instance.$destroy();
        reorderNotifications();
    });

    reorderNotifications();

    return instance;
}

function setXPosition (instance, direction, offsetX) {
    if (direction === 'left') {
        instance.$el.style.left = `${offsetX}px`;
    } else if (direction === 'right') {
        instance.$el.style.right = `${offsetX}px`;
    }
}

function setYPosition (instance, direction, offsetY) {
    if (direction === 'top') {
        instance.$el.style.top = `${offsetY}px`;
    } else if (direction === 'bottom') {
        instance.$el.style.bottom = `${offsetY}px`;
    }
}

function reorderNotifications() {
    const notifications = document.querySelectorAll('.d-notification');
    let offsetY = 20;

    notifications.forEach(notification => {
        const instance = notification.__vue__;

        if (notification.classList.contains('is-position--top-left')) {
            setYPosition(instance, 'top', offsetY + defaultOffsetY);
            setXPosition(instance, 'left', defaultOffsetX);
            offsetY += instance.$el.clientHeight + 10;
        } else if (notification.classList.contains('is-position--top-right')) {
            setYPosition(instance, 'top', offsetY + defaultOffsetY);
            setXPosition(instance, 'right', defaultOffsetX);
            offsetY += instance.$el.clientHeight + 10; // добавляем высоту текущего уведомления и отступ
        } else if (notification.classList.contains('is-position--bottom-left')) {
            setYPosition(instance, 'bottom', offsetY + defaultOffsetY);
            setXPosition(instance, 'left', defaultOffsetX);
            offsetY += instance.$el.clientHeight + 10; // добавляем высоту текущего уведомления и отступ
        } else if (notification.classList.contains('is-position--bottom-right')) {
            setYPosition(instance, 'bottom', offsetY + defaultOffsetY);
            setXPosition(instance, 'right', defaultOffsetX);
            offsetY += instance.$el.clientHeight + 10; // добавляем высоту текущего уведомления и отступ
        }
    });
}


const notifyPlugin = {
    install(Vue) {
        Vue.prototype.$notify = {
            dNotify,
        };
    },
};

export default notifyPlugin;
