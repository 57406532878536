<template>
    <base-modal @close="onCloseModal" class="poi-modal">
        <template v-slot:modalBody>
            <div v-if="isLoading" class="poi-modal__preloader">
                <BasePreloaderGifV2 :size="60" />
            </div>
            <template v-if="!isLoading">
                <div
                    @click.self="isOpenedRequestDropdown = false"
                    class="poi-modal__body"
                >
                    <h3 class="poi-modal__header">
                        {{ addGroupModal.textAddPoi }}
                    </h3>
                    <div class="poi-modal__tabs">
                        <div
                            v-for="tab in poiTabsList"
                            :key="tab.id"
                            @click="onTabClickAction(tab.id)"
                            :class="[
                                'poi-modal__tabs--item',
                                { _active: activeTabId === tab.id },
                            ]"
                        >
                            {{ tab.name }}
                        </div>
                    </div>

                    <component
                        :is="activeTabContent()"
                        :requestItems="requestItems"
                        :requestId="requestId"
                        :requestError="requestError"
                        :composingType="composingType"
                        @onCloseModal="onCloseModal"
                        @onAutoComplete="
                            (value) => $emit('onAutoComplete', value)
                        "
                        @onCreateNewGroup="
                            (data) => onApply(data, 'onCreateNewGroup')
                        "
                        @onCreateNewEmptyGroup="
                            (data) => onApply(data, 'onCreateNewGroup')
                        "
                        @onCreateNewMoiPoiGroup="
                            (data) => onApply(data, 'onCreateNewMoiPoiGroup')
                        "
                        @onCreateNewFileGroup="
                            (data) => onApply(data, 'onCreateNewFileGroup')
                        "
                        @onChangeFileComposingType="onChangeFileComposingType"
                    >
                        <template #icons>
                            <!-- <div
                                @click.self="isOpenedRequestDropdown = false"
                                class="poi-modal__row"
                            >
                                <div>
                                    {{ addGroupModal.textSelectColorAndIcon }}
                                </div>

                                <div
                                    style="
                                        display: flex;
                                        align-items: center;
                                        flex-wrap: wrap;
                                        margin-top: 10px;
                                    "
                                >
                                    <input
                                        type="color"
                                        name="color"
                                        id="input-color"
                                        v-model="color"
                                        :class="[
                                            'poi-modal__color',
                                            {
                                                'poi-modal__color--active':
                                                    !iconId && color,
                                            },
                                        ]"
                                        @focus="iconId = null"
                                    />

                                    <div
                                        style="
                                            font-weight: 400;
                                            font-size: 12px;
                                            line-height: 14px;
                                            color: #aab0b8;
                                            margin-right: 10px;
                                        "
                                    >
                                        или
                                    </div>

                                    <div class="poi-modal__icons">
                                        <div
                                            v-for="(icon, index) in icons"
                                            :key="index"
                                            class="poi-modal__icon-wrap"
                                            :class="{
                                                'poi-modal__icon-wrap--active':
                                                    String(iconId) ===
                                                    String(icon.iconId),
                                            }"
                                            @click="onSelectIcon(icon.iconId)"
                                        >
                                            <img
                                                :src="icon.src"
                                                :alt="'Иконка ' + (index + 1)"
                                            />
                                        </div>
                                    </div>

                                    <div class="poi-modal__file">
                                        <span
                                            v-for="iconUploadError in iconUploadErrors"
                                            :key="iconUploadError"
                                            class="poi-modal__error"
                                        >
                                            {{ iconUploadError }}
                                        </span>
                                    </div>
                                    <base-button
                                        :class="[
                                            'poi-modal__attach-file-button',
                                            { margin: icons.length >= 4 },
                                        ]"
                                        type="button"
                                        view="primary"
                                        role="label"
                                    >
                                        <icon-clip />
                                        <input
                                            type="file"
                                            hidden
                                            @change="onAddFile"
                                        />

                                        {{ addGroupModal.textAddFile }}
                                    </base-button>
                                </div>
                            </div> -->
                        </template>
                    </component>
                </div>
            </template>
        </template>
    </base-modal>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BaseIcon from "@/components/Base/BaseIcon";
import IconClip from "@/components/Icons/IconClip";
import BaseModal from "@/components/Base/BaseModal";
import AddPoiModalContent from "./AddPoiModalContent";
import AddMoiPoiModalContent from "./AddMoiPoiModalContent";
import AddEmptyPoiModalContent from "./AddEmptyPoiModalContent";
import AddPoiFromFileModalContent from "./AddPoiFromFileModalContent";
import containerPoiSchemes from "../../schemes/SchemeWidgetMap";
const { containerPoi } = containerPoiSchemes;

export default {
    name: 'AddPoiModal',
    components: {
        BaseIcon,
        BaseModal,
        BaseButton,
        IconClip,
        AddPoiModalContent,
        AddMoiPoiModalContent,
        AddEmptyPoiModalContent,
        AddPoiFromFileModalContent,
    },

    props: {
        activeTabId: {
            type: String,
            default: "",
        },
        poiTabsList: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        icons: {
            type: Array,
            default: () => [],
        },
        requestItems: {
            type: Array,
            default: () => [],
        },
        uploadIconId: {
            type: String,
            default: "",
        },
        iconUploadError: {
            type: String,
            default: "",
        },
        isLoadingIcon: {
            type: Boolean,
            default: false,
        },
        isLoadingRequest: {
            type: Boolean,
            default: false,
        },
        iconUploadErrors: {
            type: Array,
            default: () => [],
        },

        requestId: {
            type: Number,
            default: null,
        },
        requestError: {
            type: String,
            default: "",
        },

        composingType: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            fileData: null,
            color: "#000000",
            iconId: null,

            groupData: {},

            addGroupModal: containerPoi.addGroupModal,
        };
    },

    watch: {
        uploadIconId(newVal) {
            if (newVal) {
                this.iconId = newVal;
            }
        },
        color() {
            this.iconId = null;
        },
    },

    computed: {
        isEmptyFile() {
            return this.fileData === null;
        },
    },

    methods: {
        onChangeFileComposingType(composingType) {
            this.$emit('onChangeFileComposingType', composingType);
        },
        activeTabContent() {
            switch (this.activeTabId) {
                case "oohdesk":
                    return AddPoiModalContent;
                case "moi":
                    return AddMoiPoiModalContent;
                case "file":
                    return AddPoiFromFileModalContent;
                case "empty":
                    return AddEmptyPoiModalContent;
            }
        },
        onTabClickAction(id) {
            this.$emit("onTabClickAction", id);
        },

        onAddFile(evt) {
            this.fileData = evt?.target?.files[0] ?? null;
            this.iconId = null;

            this.$emit("onUploadIcon", this.fileData);
        },
        onSelectIcon(iconId) {
            this.iconId = Number(iconId);
        },
        onUploadIcon(iconId) {
            this.$emit("onUploadIcon", iconId);
        },
        onCloseModal() {
            this.$emit("onCloseModal");
        },
        onApply(data, emit) {
            if (this.color) {
                this.groupData = {
                    ...this.groupData,
                    ...data,
                    color: this.color,
                };
            }
            if (this.iconId) {
                this.groupData = {
                    ...this.groupData,
                    ...data,
                    icon_id: this.iconId,
                };
            }
            this.groupData = { ...this.groupData, ...data };

            return this.$emit(emit, this.groupData);
        },
    },
};
</script>

<style lang="scss">
.poi-modal {
    &__tabs {
        width: max-content;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 9px 10px;
        border-radius: 10px;
        background: #f5f5f5;

        &--item {
            padding: 3px 10px;
            margin-right: 20px;
            border-radius: 4px;
            line-height: 18px;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;
            transition: all ease 0.1s;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: #eaeced;
            }

            &._active {
                color: #fff;
                background: #4a91f1;
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        /*width: 360px;*/
        min-height: max-content;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
    }

    &__preloader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    &__header {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #000;

        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    &__row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 25px;
        }

        &._select {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__select {
        display: flex;
        align-items: center;

        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #aab0b8;
        flex: 1;
        padding: 15px 22px;
        border: 1px solid #aab0b8;
        border-radius: 7px;
        cursor: pointer;
        transition: all ease 0.1s;

        &:first-child {
            margin-right: 10px;
        }

        & svg {
            margin-right: 10px;
        }

        &._active {
            border-color: #4a91f1;
            color: #4a91f1;
            background: #f3f9ff;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
    }

    &__color {
        position: relative;
        border: none;
        width: 36px;
        height: 36px;
        padding: 0;
        margin-right: 10px;
        border-radius: 10px;
        border: 1px solid #eaeced;
        -webkit-appearance: none;
        transition: all ease 0.2s;

        &:hover {
            border-color: #71c376;
        }

        &--active::before {
            content: url("../../../../assets/images/icons/icons-active.svg");
            position: absolute;
            top: 3x;
            right: 3px;
        }

        &::-webkit-color-swatch {
            border: none;
            border-radius: 10px;
            padding: 0;
        }
        &::-webkit-color-swatch-wrapper {
            border: none;
            border-radius: 10px;
            padding: 0;
        }
    }

    &__error {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
        color: #ff7c8e;
    }

    &__attach-file-button {
        display: flex;

        & svg {
            margin-right: 8px;
        }

        &.margin {
            margin-top: 5px;
        }
    }

    &__icons {
        display: flex;
        gap: 5px;
    }

    &__icon-wrap {
        position: relative;
        width: 36px;
        height: 36px;
        background: none;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 10px;
        border: 1px solid #eaeced;
        transition: all ease 0.2s;

        &:hover {
            border-color: #71c376;
        }

        &--active::before {
            content: url("../../../../assets/images/icons/icons-active.svg");
            position: absolute;
            top: 3px;
            right: 3px;
        }

        & img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }

    &__file-info {
        //max-width: 100%;
        //width: 100%;
        display: flex;
        text-align: left;

        &-title {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-icon {
            font-size: 12px;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__cancel {
        color: #c8cad1;

        &:hover,
        &:active {
            color: #333;
        }
    }
}
</style>
