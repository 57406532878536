import ServiceBaseClusterer from '../ServicesBase/ServiceBaseClusterer';
const MarkerClusterer = window.MarkerClusterer;
/**
 * Сервис для работы с кластеризацией в гугл мап апи
 * @extends ServiceBaseClusterer
 */
export default class ServiceGoogleClusterer extends ServiceBaseClusterer {
    /**
     * Конструктор
     * @param {Object} params - параметры кластеризации
     */
    constructor(params = {}) {
        super(params);
        this.clustererObj = new MarkerClusterer(this.map, this.getMarkersClusterer(this.markers), {
            gridSize: this.gridSize,
            minimumClusterSize: this.minimumClusterSize,
            styles: [{
                url: this.iconUrl,
                width: this.width,
                height: this.height,
                anchorText: this.anchorText,
                textColor: this.textColor
            }]
        });
    }
    /**
     * Получить маркеры для кластеризации
     * @param {Array} markers - исходный массив маркеров
     * @returns {Array}
     */
    getMarkersClusterer(markers) {
        const markersClusterer = markers.filter(marker => {
            const {extraData = {}, isRender = false} = marker;
            const {kitId = "0", type = 'default'} = extraData;
            return (String(kitId) === "0" || String(kitId) === '-2') && type !== 'poi' && isRender;
        }).map(marker => marker.markerObj);
        return markersClusterer;
    }
    /** Уничтожить экземпляр кластеризации с карты */
    destroyClusterer() {
        this.clustererObj.clearMarkers();
    }
}
