/* eslint-disable no-extra-semi */
import ServiceGoogleMarker from '../../services/ServicesGoogle/ServiceGoogleMarker';
/** Фабрика для создания маркеров через гугл мап апи */
export default class FactoryGoogleMarker {
    /**
     * Создать массив маркеров
     * @param {Array} markers - маркеры
     * @param {Object} map - объект карты
     * @param {Object} markerClickEvent - обработчик события клика по маркеру
     * @property {Boolean} isRender - условие отрисовки
     * @returns {Array}
     */
    static createMarkers(markers = [], map = {}, markerClickEvent = () => {}, isRender = true) {
        // let markersOpn = markers.filter(marker => marker.mode === 'opn');
        const markersMaped = markers.map(marker => {
            const {fill_color: fillColor = '', selected = false} = marker;
            const markerGoogle = new ServiceGoogleMarker({
                ...marker,
                color: fillColor,
                state: selected ? 1 : 0,
                map,
                isRender
            });
            markerGoogle.markerObj.addListener('click', () => {
                markerClickEvent(markerGoogle, 'left');
            });
            markerGoogle.markerObj.addListener('rightclick', () => {
                markerClickEvent(markerGoogle, 'right');
            });
            return markerGoogle;
        });
        return markersMaped;
    };
};
