<template>
    <div class="match-sides" :style="{'--max-height': maxHeight}">
        <match-side-item
            v-for="(side, index) in sides"
            :key="index"
            :side="side"
            :sideInfo="sideInfo"
            :isDraggable="isDraggable"
            :is-show-distance="isShowDistance"
            :is-show-approx="isShowApprox"
            :combination-id="combinationId"
            @onDragStart="onDragStart"
        />
    </div>
</template>

<script>
/** Компонент списка сторон мэтчинга */
import MatchSideItem from './MatchSideItem.vue';
export default {
    name: 'MatchSides',
    components: {MatchSideItem},
    /**
     * Входные данные
     * @property {Array} sides - стороны
     * @property {Object} sideInfo - объект с ключами и заголовками с информацией о стороне
     * @property {Boolean} isDraggable - таскаемый ли компонент
     * @property {Boolean} isShowDistance - Показать/скрыть радиус стороны
     * @property {Number} combinationId - Id комбинации (1 - координаты, 2 - Gid+Оператор+сторона, 3 - Адрес+сторона)
     * @property {Boolean} isShowApprox - Набор похожее
     */
    props: {
        sides: {
            type: Array,
            default: () => ([])
        },
        sideInfo: {
            type: Object,
            default: () => ({})
        },
        isDraggable: {
            type: Boolean,
            default: false
        },
        isShowDistance: {
            type: Boolean,
            default: false
        },
        combinationId: {
            type: Number,
            default: -1
        },
        isShowApprox: {
            type: Boolean,
            default: false
        },
    },
    /**
     * Данные компонента
     * @property {String} maxHeight - css св-во max-height
     */
    data: () => ({
        maxHeight: 'auto',
    }),
    /**
     * Вычислить максимальную высоту.
     * Добавить обработчик по изменению высоты окна браузера
     */
    mounted() {
        this.resizeHandler();
        window.addEventListener("resize", this.resizeHandler);
    },
    /**
     * Удалить обработчик при размонтировании компонета
     */
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
        /**
         * Вычислить максимальную высоту
         */
        resizeHandler() {
            const offsetHeight = document.documentElement.offsetHeight;
            const modalHeight = 315;
            const modalTitle = 48;
            const tabs = 55;
            const tableHead = 40;
            const tablePaddingTop = 20;
            const padding = 40 * 2;

            const result = offsetHeight - modalHeight - modalTitle - tabs - tableHead - tablePaddingTop - padding;

            if (offsetHeight < 800 && result > 0) {
                this.maxHeight = result + 'px';
            }
            if (offsetHeight > 800 && result > 0) {
                this.maxHeight = 255 + 'px';
            }
            if (result < 0) {
                this.maxHeight = 100 + 'px';
            }
        },

        /**
         * Событие начала перетаскивания
         * @param {Object} side - сторона
         */
        onDragStart(side) {
            this.$emit('onDragStart', side);
        }
    }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}
     .match-sides {
         overflow: auto;
         max-height: var(--max-height);
         min-height: 116px;
     }
</style>
