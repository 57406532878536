<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00057 11.9877C8.00063 11.7225 8.10602 11.4682 8.29357 11.2807L14.2936 5.28071C14.4822 5.09855 14.7348 4.99776 14.997 5.00004C15.2592 5.00232 15.51 5.10749 15.6954 5.29289C15.8808 5.4783 15.986 5.72911 15.9882 5.99131C15.9905 6.25351 15.8897 6.50611 15.7076 6.69471L10.4146 11.9877L15.7076 17.2807C15.8897 17.4693 15.9905 17.7219 15.9882 17.9841C15.986 18.2463 15.8808 18.4971 15.6954 18.6825C15.51 18.8679 15.2592 18.9731 14.997 18.9754C14.7348 18.9777 14.4822 18.8769 14.2936 18.6947L8.29357 12.6947C8.10602 12.5072 8.00063 12.2529 8.00057 11.9877Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconArrowV2'
}
</script>