export default {
    namespaced: true,
    /**
     * Стостояния
     * @property {Array} activeLegendPanelMarkersStatuses - Активные статусы легенды
     * @property {Array} allActiveLegendPanelMarkersStatuses - Список всех статусов легенды
     */
    state: {
        activeLegendPanelMarkersStatuses: [],
        allActiveLegendPanelMarkersStatuses: [],
        markersStatusesListDefault: [],
    },
    getters: {
        /**
         * Найти статус без цены в списке статусов
         * @property {Array} activeLegendPanelMarkersStatuses - Активные статусы легенды
         * @property {Array} allActiveLegendPanelMarkersStatuses - Список всех статусов легенды
         */
        onlyWithoutPrice(state) {
            const onlyWithoutPrice = state.markersStatusesListDefault;
            return onlyWithoutPrice.filter((el) => el.key === 'without_price');
        },
    },
    mutations: {
        /**
         * Установить активные статусы легенды
         * @param {Object} state - состояния
         * @param {Array} activeStatuses - Активные статусы легенды
         */
        setActiveMarkerStatuses(state, activeStatuses = []) {
            state.activeLegendPanelMarkersStatuses = activeStatuses;
        },
        /**
         * Установить все активные статусы легенды
         * @param {Object} state - состояния
         * @param {Array} allActiveStatuses - Активные статусы легенды
         */
        setAllActiveMarkerStatuses(state, allActiveStatuses = []) {
            state.allActiveLegendPanelMarkersStatuses = allActiveStatuses;
        },
        /**
         * Установить все активные расширенные статусы легенды
         * @param {Object} state - состояния
         * @param {Array} statuses - Расширенные статусы легенды
         */
        setMarkersStatusesListDefault(state, statuses = []) {
            state.markersStatusesListDefault = statuses;
        },
    }
}
