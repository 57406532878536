import UtilDate from '../../utils/UtilDate.js';

export default {
    data: () => ({
        pricesColor: {
            free: '#C5E9CA',
            reserved: '#FFB300',
            booked:'#F84967',
            busy: '#F84967'
        }
    }),
    props: {
		columns: {
			type: Array,
			default: () => ([])
		},
		tableData: {
			type: Array,
			default: () => ([])
		},
		isProgressBarLoading: {
			type: Boolean,
			default: false
		},
		modifieredCols: {
			type: Array,
			default: () => ([])
		}
	},
    methods: {
		/**
		 * @param {Object} $event - название колонки по которой был произведен клик в шапке таблицы
		 */
		onSort($event) {
			this.$emit('sort', $event)
		},
		/**
		 * @param {Object} $event - объект содержащий значения для фильрации
		 */
		onFilter($event) {
			this.$emit('filter', $event)
		},
		onLazyload() {
			this.$emit('lazyload')
		},
        getPriceData(tableData = {}) {
            const {column = {}, row = {}} = tableData;
            const {allPrices = null, prices = []} = row;
            const {prop = ''} = column;
            const propSplitted = String(prop).split('__');
			const propDate = propSplitted[0];
            const currentPrices = (allPrices === null || allPrices?.length === 0) ? Object.assign([], prices) : Object.assign([], allPrices);
            const price = currentPrices.find(price => String(price.date) === String(propDate)) || {};
            return price;
        },
        getPriceColor(tableData = {}) {
            const price = this.getPriceData(tableData);
            const {status = '', is_actual: isActual = "1"} = price;
            if(isActual === '1' || status === 'busy'){
               return this.pricesColor[status] || '';
            }else {
                return "#ADB0BB";
            }
        },
        getPriceBorder(tableData = {}) {
            const price = this.getPriceData(tableData);

            return price?.status ? 'border: 2px solid' : 'none';
        },
        isPriceBusy(tableData = {}) {
            const { status = '' } = this.getPriceData(tableData);
            return status === 'busy';
        },
        getPricePartInfo(tableData = {}) {
            const price = this.getPriceData(tableData);
            const { 
                is_full_period: isFullPeriod = true,
                periodStatuses: pricesPart = []
            } = price;

            if (!isFullPeriod) {
                const pricePartHead = pricesPart[0] ?? {};

                const pricePartHeadFrom = String(pricePartHead?.from_date ?? '');
                const pricePartHeadFromDay = UtilDate.getDay(pricePartHeadFrom);
                const pricePartHeadFromMonth = UtilDate.getMonthsName(pricePartHeadFrom);

                const pricePartHeadTo = String(pricePartHead?.to_date ?? '');
                const pricePartHeadToDay = UtilDate.getDay(pricePartHeadTo);
                const pricePartHeadToMonth = UtilDate.getMonthsName(pricePartHeadTo);

                const pricePartHeadInfo = `${ pricePartHeadFromDay } ${ pricePartHeadFromMonth } - ${ pricePartHeadToDay } ${ pricePartHeadToMonth }`;

                const pricePartTail = pricesPart[1] ?? {};

                const pricePartTailFrom = String(pricePartTail?.from_date ?? '');
                const pricePartTailFromDay = UtilDate.getDay(pricePartTailFrom);
                const pricePartTailFromMonth = UtilDate.getMonthsName(pricePartTailFrom);

                const pricePartTailTo = String(pricePartTail?.to_date ?? '');
                const pricePartTailToDay = UtilDate.getDay(pricePartTailTo);
                const pricePartTailToMonth = UtilDate.getMonthsName(pricePartTailTo);

                const pricePartTailInfo = `, ${ pricePartTailFromDay } ${ pricePartTailFromMonth } - ${ pricePartTailToDay } ${ pricePartTailToMonth }`;

                const pricePartInfo = pricesPart.length === 2 ? `${pricePartHeadInfo} ${pricePartTailInfo}` : `${pricePartHeadInfo}`;

                return pricePartInfo;
            } else
                return '';
        },
        isPricePart(tableData = {}) {
            return this.getPricePartInfo(tableData);
        },
        getActualityColor(tableData = {}) {
            const {column = {}, row = {}} = tableData;
            const {allPrices = null, prices = []} = row;
            const {prop = ''} = column;
            const propSplitted = String(prop).split('__');
            const propDate = propSplitted[0];
            const currentPrices = allPrices === null ? Object.assign([], prices) : Object.assign([], allPrices);
            const price = currentPrices.find(price => String(price.date) === String(propDate)) || {};
            const {is_actual: isActual = "1"} = price;
            if(isActual === '1'){
                return '';
            }else {
                return "#ADB0BB";
            }
        }
	},
};
