<template>
    <div :class="['d-notification', typeClass, positionClass]" :style="computedStyle">
        <div v-if="isShowClose" class="d-notification__close" @click="closeNotification">
            <svg  width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="d-notification__close-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5891 4.4107C15.9145 4.73614 15.9145 5.26378 15.5891 5.58921L5.58909 15.5892C5.26366 15.9147 4.73602 15.9147 4.41058 15.5892C4.08514 15.2638 4.08514 14.7361 4.41058 14.4107L14.4106 4.4107C14.736 4.08527 15.2637 4.08527 15.5891 4.4107Z"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41058 4.4107C4.73602 4.08527 5.26366 4.08527 5.58909 4.4107L15.5891 14.4107C15.9145 14.7361 15.9145 15.2638 15.5891 15.5892C15.2637 15.9147 14.736 15.9147 14.4106 15.5892L4.41058 5.58921C4.08514 5.26378 4.08514 4.73614 4.41058 4.4107Z"></path>
            </svg>
        </div>
        <div v-html="title" class="d-notification__title"></div>
        <div v-html="description" class="d-notification__description"></div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'default',
            validator: val => ['default', 'success', 'error', 'warning'].includes(val)
        },
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        duration: {
            type: Number,
            default: 3000,
        },
        position: {
            type: String,
            default: 'bottom-right',
            validator: val => ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(val)
        },
        isShowClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            zIndex: 1000,
        };
    },
    computed: {
        typeClass() {
            return `is-type--${this.type}`;
        },
        positionClass() {
            return `is-position--${this.position}`;
        },
        computedStyle() {
            return {
                zIndex: this.zIndex,
            };
        },
    },
    mounted() {
        if (this.duration !== Infinity) {
            setTimeout(this.closeNotification, this.duration);
        }
    },
    methods: {
        closeNotification() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.d-notification {
    position: fixed;
    padding: 12px 16px;
    width: 300px;
    background-color: #ffffff;
    border-left: 3px solid transparent;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: top 0.3s ease-in-out, bottom 0.3s ease-in-out, left 0.3s ease-in-out, right 0.3s ease-in-out,;
}

.d-notification.is-type--success {
    border-color: #6EC87A;
}

.d-notification.is-type--error {
    border-color: #F84967;
}

.d-notification.is-type--warning {
    border-color: #FFB300;
}

.d-notification__close {
    cursor: pointer;
    padding: 8px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.d-notification__close-icon {
    fill: #b9bbc2;
    width: 16px;
    height: 16px;
    transition: fill 0.15s ease-in-out;
}

.d-notification__close:hover .d-notification__close-icon {
    fill: #000000;
}

.d-notification__title {
    padding-right: 24px;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.d-notification__description {
    margin-top: 4px;
    color: #b9bbc2;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
}

.d-notification.is-position--top-left {
    top: 0;
    left: -100%;
}

.d-notification.is-position--top-right {
    top: 0;
    right: -100%;
}

.d-notification.is-position--bottom-left {
    bottom: 0;
    left: -100%;
}

.d-notification.is-position--bottom-right {
    bottom: 0;
    right: -100%;
}
</style>
