<template>
    <RouterLink
        :to="path"
        class="item"
    >
        <BaseIcon
            :icon="icon"
            class="item__icon"
        />
        <span
            class="item__label"
            v-show="!isHideLabel"
        >{{label}}</span>
    </RouterLink>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
    name: 'SidebarItem',
    components: {
        BaseIcon
    },
    props: {
        label: {
            type: String,
            required: true
        },
        path: {
            type: [String, Object],
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        isHideLabel: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    height: 40px;
    width: 100%;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 5px;
    cursor: pointer;
    

    &:hover,
    &.router-link-active {
        background: #f3f3f3;
    }

    &__icon {
        display: block;
    }

    &__label {
        font-size: 13px;
        margin-left: 10px;
        white-space: nowrap;
    }
}
</style>