<template>
    <div
        :style="kitPanelStyle"
        class="kit-panel kit-panel--modified"
    >
        <div class="kit-panel__left">
            <div class="kit-panel__item-container">
                <!-- <base-preloader-gif-v2
                    v-if="isLoadingKitFound"
                    class="kit-panel__preloader"
                /> -->
                <kit-panel-item
                    v-if="kitFoundActive && !isShare"
                    :kit="kitFound"
                    :kitSelected="true"
                    :kitTableActive="getTableActiveKit(kitFound)"
                    :kitMarkersActive="getMarkersActiveKit(kitFound)"
                    :kitIsRename="false"
                    :kitIsButtonContainer="kitIsButtonContainer"
                    :isLoading="isLoadingKitFound"
                    @onSelectKit="onSelectKit"
                    @onRenameKit="onRenameKit"
                    @onToggleKit="onToggleKit"
                />
            </div>
            <RouterLink
                to="/buyerdesk"
                class="item"
            >
                <BaseButton
                    class="kit-panel__btn kit-panel__btn--modified"
                    form="oval"
                    v-if="!kitFoundActive"
                >
                    <span class="kit-panel__btn-icon icon-tabler-icon-chevron-left"></span>
                </BaseButton>
            </RouterLink>
        </div>
        <div class="kit-panel__right kit-panel__right--modified">
            <!-- <base-preloader-gif-v2
                v-if="isLoadingKitsCustom"
                class="kit-panel__preloader"
            /> -->
            <div v-if="kitCanAdd">
                <div
                    class="kit-panel__add icon-tabler-icon-plus"
                    style="margin-bottom: 5px"
                    v-tooltip="'Создать набор'"
                    @click="onCreateKit"
                >
                    <base-preloader-skeleton
                        v-if="isLoadingKitsCustom"
                        class="kit-panel__add-preloader"
                    />
                </div>
                <div
                    class="kit-panel__add icon-tabler-icon-file-download"
                    v-tooltip="'Создать набор из Exel'"
                    @click="onMatchFromFile"
                >
                    <base-preloader-skeleton
                        v-if="isLoadingKitsCustom"
                        class="kit-panel__add-preloader"
                    />
                </div>
            </div>
            <kit-panel-item
                v-if="isLoadingKitsCustom && !kitsCustomVisible.length"
                :kit="{title: 'Phantom'}"
                :isLoading="true"
                class="kit-panel__kits-custom"
            />
            <kit-panel-item
                v-if="isLoadingKitsCustom && !kitsCustomVisible.length"
                :kit="{title: 'Phantom'}"
                :isLoading="true"
                class="kit-panel__kits-custom"
            />
            <kit-panel-item
                v-for="kit in kitsCustomVisible"
                :key="getKitId(kit)"
                :kit="kit"
                :kitSelected="getKitSelected(kit)"
                :kitTableActive="getTableActiveKit(kit)"
                :kitMarkersActive="getMarkersActiveKit(kit)"
                :kitIsRename="true"
                :kitIsRemove="true"
                :kitIsDuplicate="true"
                :kitIsShare="!isShare"
                :hasPanel="kitIsButtonContainer"
                :isLoading="isLoadingKitsCustom"
                class="kit-panel__kits-custom"
                @onSelectKit="onSelectKit"
                @onRenameKit="onRenameKit"
                @onShareKit="onShareKit"
                @onRemoveKit="onRemoveKit"
                @onDuplicateKit="onDuplicateKit"
                @onToggleKit="onToggleKit"
            />
            <modal-share
                v-if="shareIsModal"
                :shareLink="shareLink"
                @onCloseModal="onCloseShareModal"
            />
            <base-dropdown
                v-if="kitsCustomUnvisible.length !== 0"
                :position="kitPanelMorePosition"
                :styleList="kitPanelMoreStyle"
                class="kit-panel__more kit-panel__more--modified"
            >
                <template #preview>
                    <div v-if="!isModifidedDesign" class="more-btn more-btn--modified">Еще наборы</div>
                    <div v-else class="more-btn more-btn--modified">{{ moreText }}</div>
                </template>
                <template #content>
                    <div class="kit-panel__more-dropdown">
                        <kit-panel-item
                            v-for="(kit, index) in kitsCustomUnvisible"
                            :key="index"
                            :kit="kit"
                            :kitSelected="getKitSelected(kit)"
                            :kitTableActive="getTableActiveKit(kit)"
                            :kitMarkersActive="getMarkersActiveKit(kit)"
                            :kitIsRename="true"
                            class="kit-panel__kits-custom_unvisible"
                            @onSelectKit="onSelectKit"
                            @onRenameKit="onRenameKit"
                            @onToggleKit="onToggleKit"
                        />
                    </div>
                </template>
            </base-dropdown>
        </div>
    </div>
</template>

<script>
import KitPanelItem from './KitPanelItem';
import ServiceShare from '@/services/ServicesAuth/ServiceShare';
import BaseDropdown from '@/components/Base/BaseDropdown'
import BaseButton from "@/components/Base/BaseButton";
// import BasePreloaderGifV2 from "@/components/Base/BasePreloaderGifV2"
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton"
import ModalShare from '@/components/Folder/ModalShare';

export default {
    name: 'KitPanel',
    components: {
        KitPanelItem,
        BaseDropdown,
        BaseButton,
        // BasePreloaderGifV2,
        BasePreloaderSkeleton,
        ModalShare
    },
    /**
     * Входные данные компонента
     * @property {Array} kits - элементы панели набора
     * @property {Array} kitCustomIdSelected - идентификатор пользовательского выделенного набора
     * @property {Array} kitsTableActive - элементы набора с активированной таблицей
     * @property {Array} kitsMarkersActive - элементы набора с активированными маркерами
     * @property {Array} kitFoundActive - активен ли таб найденного
     * @property {Boolean} isShare - шара или нет
     */
    props: {
        kits: {
            type: Array,
            default: () => ([])
        },
        kitCustomIdSelected: {
            type: String,
            default: '-1'
        },
        kitsTableActive: {
            type: Array,
            default: () => ([])
        },
        kitsMarkersActive: {
            type: Array,
            default: () => ([])
        },
        kitFoundActive: {
            type: Boolean,
            default: true
        },
        kitIsButtonContainer: {
            type: Boolean,
            default: true
        },
        kitCanAdd: {
            type: Boolean,
            default: true
        },
        isMarginLeft: {
            type: Boolean,
            default: false
        },
        isModifidedDesign: {
            type: Boolean,
            default: false
        },
        isShare: {
            type: Boolean,
            default: false
        },
        isLoadingKitFound: {
            type: Boolean,
            default: false
        },
        isLoadingKitsCustom: {
            type: Boolean,
            default: false
        }
    },
    /**
     * Данные компонента
     * @property {Number} widthWindow - ширина окна
     * @property {Number} widthFound - ширина для блока найдено
     * @property {Number} widthCustomItem - ширина для элемента пользовательского набора
     * @property {String} shareIsModal - Активна ли модалка шары
     * @property {String} shareLink - Ссылка для шары
     */
    data: () => ({
        widthWindow: 340,
        widthFound: 340,
        widthCustomItem: 205,
        shareIsModal: false,
        shareLink: null,
        kitPanelMorePosition: 'right',
        kitPanelMoreStyle: {'background': '#FAFAFA'}
    }),
    computed: {
        kitPanelStyle() {
            const kitPanelStyle = this.isMarginLeft ? {'padding-left': '20px'} : {};
            return kitPanelStyle;
        },
        /**
         * Ширина для блока пользовательских наборов
         * @returns {Number}
         */
        widthCustom() {
            const currentWidth = this.widthWindow - this.widthFound - 88;
            return currentWidth >= 0 ? currentWidth : 0;
        },
        /**
         * Элемент набора найденное
         * @returns {Object}
         */
        kitFound() {
            const kitFound = this.kits.find(kit => {
                const {type = ''} = kit;
                return type === 'found' ? true : false;
            });
            return typeof kitFound !== 'undefined' ? kitFound : {};
        },
        /**
         * Элементы пользовательского набора
         * @returns {Array}
         */
        kitsCustom() {
            const kitsCustom = this.kits.filter(kit => {
                const {type = ''} = kit;
                return type === 'custom' ? true : false;
            });
            const kitsCustomSorted = kitsCustom.sort((kitA, kitB) => {
                const {timestamp: timestampA = -1} = kitA;
                const {timestamp: timestampB = -1} = kitB;
                return parseInt(timestampB) - parseInt(timestampA);
            });
            return kitsCustomSorted;
        },
        /**
         * Колличество видимых элементов пользовательского набора
         * @returns {Number}
         */
        kitsCustomVisibleLength() {
            return Math.floor(this.widthCustom / this.widthCustomItem);
        },
        /**
         * Видимые элементы пользовательского набора
         * @returns {Array}
         */
        kitsCustomVisible() {
            return this.kitsCustom.slice(0, this.kitsCustomVisibleLength);
        },
        /**
         * Скрытые элементы пользовательского набора
         * @returns {Array}
         */
        kitsCustomUnvisible() {
            return this.kitsCustom.slice(this.kitsCustomVisibleLength, this.kitsCustom.length);
        },
        /**
         * Отображать текст кнопки еще наборы
         * @returns {Array}
         */
        moreText() {
            const kitsLength = this.kitsCustomUnvisible.length ?? 0;
            let n = Math.abs(kitsLength);
            n %= 100;
            if (n >= 5 && n <= 20) {
                return `Еще ${kitsLength} наборов`;
            }
            n %= 10;
            if (n === 1) {
                return `Еще ${kitsLength} набор`;
            }
            if (n >= 2 && n <= 4) {
                return `Еще ${kitsLength} набора`;
            }
            return `Еще ${kitsLength} наборов`;
        }
    },
    created() {
        window.addEventListener('resize', this.updateWidthWindow);
        this.updateWidthWindow();
    },
    destroyed() {
        window.removeEventListener('resize', this.updateWidthWindow);
    },
    methods: {
        updateWidthWindow() {
            this.widthWindow = window.innerWidth;
        },
        /**
         * Событие создания элемента набора
         */
        onCreateKit() {
            const itemIndexNext = this.kitsCustom.length;
            this.$emit('onCreateKit', itemIndexNext);
        },
        /**
         * Событие создания набора из файла
         */
        onMatchFromFile(){
            this.$emit('onMatchFromFile');
        },
        /**
         * Событие выделения набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onSelectKit(id = '-1', type = '') {
            this.$emit('onSelectKit', id, type);
        },
        /**
         * Событие переименования набора
         * @param {String} id - идентификатор набора
         * @param {String} newName - новое название набора
         */
        onRenameKit(id = '-1', newName = '') {
            this.$emit('onRenameKit', id, newName);
        },
        /**
         * Событие удаления набора
         */
        onRemoveKit(id = '-1', type = ''){
            this.$emit('onRemoveKit', id, type);
        },
        /**
         * Событие поделиться ссылкой набора
         */
        onShareKit(id = '-1'){
            const requestBody = {set_id: id};
            ServiceShare.getLink(requestBody, (link,) => {
                this.shareLink = link;
                this.shareIsModal = true;
            });
        },
        onDuplicateKit(id = '-1'){
            this.$emit('onDuplicate', id);
        },
        /**
         * Закрыть модальное окно шары
         */
        onCloseShareModal() {
            this.shareIsModal = false;
        },
        /**
         * Событие переключения активности показа таблицы или маркеров набора
         * @param {String} typeEmit - тип эмита
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onToggleKit(typeEmit = '', id = '-1', type = '') {
            this.$emit(`onToggle${typeEmit}Kit`, id, type);
        },
        /**
         * Получить выделенность элемента набора
         * @property {Object} kit
         */
        getKitSelected(kit = {}) {
            const {id = '-1'} = kit;
            return this.kitCustomIdSelected === String(id);
        },
        /**
         * Получить активность показа таблицы элемента набора
         * @property {Object} kit - элемент набора
         */
        getTableActiveKit(kit = {}) {
            const {id = '-1'} = kit;
            return this.kitsTableActive.includes(String(id));
        },
        /**
         * Получить активность показа маркеров элемента набора
         * @property {Object} kit - элемент набора
         */
        getMarkersActiveKit(kit = {}) {
            const {id = '-1'} = kit;
            return this.kitsMarkersActive.includes(String(id));
        },
        getKitId(kit = {}) {
            return String(kit?.id ?? '');
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.kit-panel {
    background: #FAFAFA;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;

    &__item-container {
        position: relative;
    }

    &__preloader {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: white;
        top: 0;
        left: 0;
        opacity: 0.7;
    }

    &--modified {
        padding: 10px 20px;
    }

    &__btn {
        display: flex;
        align-items: center;

        &--modified {
            width: 59px;
            height: 56px;
            border-radius: 10px;
            box-sizing: border-box;
        }

        &-icon {
            width: 15px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &-title {
            margin-left: 8px;
            white-space: nowrap;
        }
    }
    &__more {
        margin-left: 10px;
        cursor: pointer;

        &--modified {
            margin-left: auto;
        }

        &-dropdown {
            width: 230px;
            display: flex;
            flex-direction: column;
            max-height: 500px;
            overflow: auto;
        }
    }
    .more-btn {
        background: $primary-color;
        color: #B9BBC2;
        cursor: pointer;
        padding: 1px 7px;
        border-radius: 100px;
        white-space: nowrap;
        transition: color .5s ease-out;

        &:hover {
            color: #000;
        }

        &--modified {
            border-radius: 10px;
            padding: 12px 15px 8px;
            width: 77px;
            height: 56px;
            box-sizing: border-box;
            white-space: break-spaces;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        margin-left: 10px;
        position: relative;

        &--modified {
            width: 100%;
        }
    }
    &__add {
        position: relative;
        background: $active-color;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        transition: .2s;

        &:hover {
            background: $active-darker-color;
        }

        &-icon {
            width: 60%;
        }

        &-preloader {
            border-radius: inherit;
        }
    }
    &__kits-custom {
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }

    }
    &__kits-custom_unvisible {
        margin: 5px;
    }
}
</style>
