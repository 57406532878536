import ServiceApi from "@/services/ServiceApi";

export default {
    namespaced: true,
    state: {
        kitId: null,
        isLoadingModifications: false,
        isLoadingCreateModification: false,
        isModificationsListChanged: false,
        modificationsList: []
    },
    getters: {
        kitId(state) {
            return state.kitId;
        },
        isModificationsListChanged(state) {
            return state.isModificationsListChanged;
        },
        isLoadingModifications(state) {
            return state.isLoadingModifications;
        },
        isLoadingCreateModification(state) {
            return state.isLoadingCreateModification;
        },
        modificationsList(state) {
            return state.modificationsList;
        },
        modificationsCount(state) {
            return state.modificationsList.length;
        }
    },
    mutations: {
        setIsLoadingModifications(state, value) {
            state.isLoadingModifications = value;
        },
        setModificationsList(state, data) {
            state.modificationsList = data;
        },
        setKitId(state, value) {
            state.kitId = value;
        },
        addModification(state, modification) {
            state.modificationsList.unshift(modification);
        },
        removeModification(state, modificationId) {
            state.modificationsList = state.modificationsList.filter(modificationItem => String(modificationItem.id) !== String(modificationId));
        },
        changeModificationData(state, newData) {
            const index = state.modificationsList.findIndex(item => item.id === newData.id);

            if (index !== -1) {
                // Обновляем элемент массива напрямую
                state.modificationsList[index] = {
                    ...state.modificationsList[index],
                    ...newData
                };
            }
        },
        setIsLoadingCreateModification(state, value) {
            state.isLoadingCreateModification = value;
        },
        setIsModificationsChanges(state) {
            state.isModificationsListChanged = true;
            setTimeout(() => {
               state.isModificationsListChanged = false;
            }, 300);
        }
    },
    actions: {
        /**
         * Получение списка модификаций
         * @param {getters, commit}
         */
        async fetchModificationsList({getters, commit}) {
            commit('setIsLoadingModifications', true);
            try {
                const response = await ServiceApi.post('free', '/map/set-modifications', {
                    set_id: getters.kitId
                });


                if ("data" in response && "data" in response.data) {
                    commit('setModificationsList', response.data.data);
                }
            } catch (error) {
                console.log(error);
                throw error;
            } finally {
                commit('setIsLoadingModifications', false);
            }
        },
        /**
         * Создание модификации
         * @param commit
         * @param postData
         */
        async createModification({commit}, postData = {}) {
            commit('setIsLoadingCreateModification', true);
            try {
                const response = await ServiceApi.post('free', '/map/set-add-modification', postData);
                if ("data" in response && "data" in response.data) {
                    commit('addModification', response.data.data);
                    commit('setIsModificationsChanges');
                }
            } catch (error) {
                console.log(error);
                throw error;
            } finally {
                commit('setIsLoadingCreateModification', false);
            }
        },
        /**
         * Удаление модификации
         * @param commit
         * @param {number | string} modificationId = id модификации
         */
        async removeModification({commit}, modificationId) {
            try {
                await ServiceApi.post('free', '/map/set-remove-modification', {set_modification_id: modificationId});
                commit('removeModification', modificationId);
                commit('setIsModificationsChanges');
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        /**
         * Изменение названия модификации
         * @param commit
         * @param newTitle - новый заголовок модификации
         * @param modificationId - id модификации
         */
        async renameModification({commit}, {newTitle, modificationId}) {
            if (!newTitle && newTitle === '') return;
            try {
                await ServiceApi.post('free', '/map/set-rename-modification', {
                    set_modification_id: modificationId,
                    name: newTitle
                });

                commit('changeModificationData', {id: modificationId, name: newTitle});
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
    }
}
