<template>
    <svg
        width="32"
        height="39"
        viewBox="0 0 32 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.02489 33.5V17.9749C4.02489 17.4365 4.46141 16.9999 4.99989 16.9999C5.53837 16.9999 5.97489 17.4365 5.97489 17.9749V33.5C5.97489 33.7899 6.20994 34.025 6.49989 34.025H18.2749C18.8134 34.025 19.2499 34.4615 19.2499 35C19.2499 35.5384 18.8134 35.9749 18.2749 35.9749H6.49989C5.13299 35.9749 4.02489 34.8669 4.02489 33.5Z"
            :fill="color"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.8699 33.59C26.9099 33.5366 26.9099 33.4633 26.8699 33.41L24.3499 30.0499C24.1014 29.7186 24.1685 29.2485 24.4999 29C24.8313 28.7514 25.3014 28.8186 25.5499 29.1499L27.5149 31.77C27.6949 32.01 28.0549 32.01 28.2349 31.77L30.1999 29.15C30.4484 28.8186 30.9185 28.7514 31.2499 29C31.5813 29.2485 31.6484 29.7186 31.3999 30.0499L28.8799 33.41C28.8399 33.4633 28.8399 33.5366 28.8799 33.59L31.3999 36.95C31.6484 37.2813 31.5813 37.7514 31.2499 38C30.9185 38.2485 30.4484 38.1813 30.1999 37.85L28.2349 35.2299C28.0549 34.99 27.6949 34.99 27.5149 35.2299L25.5499 37.85C25.3014 38.1813 24.8313 38.2485 24.4999 38C24.1685 37.7514 24.1014 37.2813 24.3499 36.95L26.8699 33.59Z"
            :fill="color"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.54989 7.28921C4.54989 6.9486 4.43397 6.61813 4.22119 6.35216L0.38276 1.55412C0.124003 1.23067 0.176444 0.758704 0.49989 0.499947C0.823337 0.24119 1.29531 0.293631 1.55406 0.617078L4.9485 4.86012C5.12865 5.08531 5.47114 5.08531 5.65128 4.86012L9.04572 0.617078C9.30447 0.293631 9.77644 0.24119 10.0999 0.499947C10.4233 0.758705 10.4758 1.23067 10.217 1.55412L6.37859 6.35216C6.16581 6.61813 6.04989 6.9486 6.04989 7.28921V11.7499C6.04989 12.1642 5.7141 12.4999 5.29989 12.4999C4.88568 12.4999 4.54989 12.1642 4.54989 11.7499V7.28921Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#AAB0B8",
        }, // #4A91F1
    },
};
</script>
