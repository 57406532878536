import ServiceApi from '../ServiceApi';

export default class ServiceMapLegend {
    static _microserviceName = 'free';

    static _requestRouts = {
        getLegendGroupsFound: '/legend/groups',
        getLegendGroupsWithoutPrices: '/legend/catalog-groups',
        getLegendGroupsCustom: '/legend/set-groups',
        putLegendEditStyle: '/legend/edit-style',
        getLegendAttributes: '/legend/attributes'
    };

    static async getLegendGroupsFound(filterData = {}, attribute = '', kitPanelItems = [], actionAfter = () => {}, actionError = () => {}) {
        await this._getLegendGroupsFound(filterData, attribute, kitPanelItems, actionAfter, actionError);
    }

    static async _getLegendGroupsFound(filterData = {}, attribute = '', kitPanelItems = [], actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._getLegendGroupsFoundBefore(filterData, attribute);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getLegendGroupsFound, reqBody);
            const {data = {}} = res;
            const {data: resBody = []} = data;
            const legendGroups = this._getLegendGroupsAfter(resBody, attribute, 'found', kitPanelItems);
            actionAfter(legendGroups);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async getLegendGroupsWithoutPrices(filterData = {}, attribute = '', kitPanelItems = [], actionAfter = () => {}, actionError = () => {}) {
        await this._getLegendGroupsWithoutPrices(filterData, attribute, kitPanelItems, actionAfter, actionError);
    }

    static async _getLegendGroupsWithoutPrices(filterData = {}, attribute = '', kitPanelItems = [], actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._getLegendGroupsFoundBefore(filterData, attribute);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getLegendGroupsWithoutPrices, reqBody);
            const {data = {}} = res;
            const {data: resBody = []} = data;
            const legendGroups = this._getLegendGroupsAfter(resBody, attribute, 'without_prices', kitPanelItems);
            actionAfter(legendGroups);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getLegendGroupsFoundBefore(filterData = {}, attribute = '') {
        const reqBody = {
            filter_params: {...filterData},
            legend: {attribute: String(attribute)}
        };

        return reqBody;
    }

    static async getLegendGroupsCustom(kitIds = [], attribute = '', kitPanelItems = [], actionAfter = () => {}, actionError = () => {}) {
        await this._getLegendGroupsCustom(kitIds, attribute, kitPanelItems, actionAfter, actionError);
    }

    static async _getLegendGroupsCustom(kitIds = [], attribute = '', kitPanelItems = [], actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._getLegendGroupsCustomBefore(kitIds, attribute);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getLegendGroupsCustom, reqBody);
            const {data = {}} = res;
            const {data: resBody = []} = data;
            const legendGroups = this._getLegendGroupsAfter(resBody, attribute, 'custom', kitPanelItems);
            actionAfter(legendGroups);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getLegendGroupsCustomBefore(kitIds = [], attribute = '') {
        const reqBody = {
            legend: {
                set_ids: kitIds.map(kitId => String(kitId)),
                attribute: String(attribute)
            }
        };

        return reqBody;
    }

    static _getLegendGroupsAfter(resBody = [], attribute = '', legendKey = '', kitPanelItems = []) {
        const legendGroups = resBody.map(legendGroup => {
            const {id = '', value = '', data: {color = '', figure = ''} = {}} = legendGroup;
            let {count = 0} = legendGroup;

            if (attribute === 'default' && legendKey === 'found') {
                const legendItem = kitPanelItems.find(legendItem => String(legendItem?.id ?? '') === String(id)) ?? {};
                const {description = ''} = legendItem;
                const descriptionArr = String(description).split(' / ');
                count = descriptionArr[0] ?? 0;
            }

            const legendGroupAfter = {
                id: `${legendKey}--${attribute}--${id}`,
                legendKey: String(legendKey),
                attribute: String(attribute),
                title: String(value),
                color: String(color),
                figure: String(figure),
                count: parseInt(count),
                active: true
            };

            return legendGroupAfter;
        });

        return legendGroups;
    }

    static async putLegendEditStyle(type = -1, itemId = '', attribute = '', color = '', figure = '', actionAfter = () => {}, actionError = () => {}) {
        await this._putLegendEditStyle(type, itemId, attribute, color, figure, actionAfter, actionError);
    }

    static async _putLegendEditStyle(type = -1, itemId = '', attribute = '', color = '', figure = '', actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._putLegendEditStyleBefore(type, itemId, attribute, color, figure);

        try {
            const res = await ServiceApi.put(this._microserviceName, this._requestRouts.putLegendEditStyle, reqBody);
            actionAfter(res);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _putLegendEditStyleBefore(type = -1, itemId = '', attribute = '', color = '', figure = '') {
        const reqBody = {
            legend: {
                type: parseInt(type),
                item_id: String(itemId),
                attribute: String(attribute),
                color: String(color),
                figure: String(figure)
            }
        };

        return reqBody;
    }

    static async getLegendAttributes(actionAfter = () => {}, actionError = () => {}) {
        await this._getLegendAttributes(actionAfter, actionError);
    }

    static async _getLegendAttributes(actionAfter = () => {}, actionError = () => {}) {
        try {
            const res = await await ServiceApi.get(this._microserviceName, this._requestRouts.getLegendAttributes);
            const {data = {}} = res;
            const {data: resBody = {}} = data;
            const legendAttributes = this._getLegendAttributesAfter(resBody);
            actionAfter(legendAttributes);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getLegendAttributesAfter(resBody = {}) {
        const legendAttributes = Object.keys(resBody).map(legendAttributeKey => {
            const legendAttribute = {
                id: String(legendAttributeKey),
                value: String(resBody[legendAttributeKey] ?? '')
            };

            return legendAttribute;
        });

        return legendAttributes;
    }
}
