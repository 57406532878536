<template>
    <div class="menu">
        <div class="menu__item-list">
            <MenuItem
                v-for="(item, index) of menuItems"
                :key="index"
                :itemData="item"
            />
        </div>

        <div class="menu-footer-wrapper">
            <div class="menu-footer">
                <div class="menu-footer-section menu-footer__support">
                    <p class="menu-footer-section__num-label">Служба поддержки</p>
                    <p class="menu-footer-section__num">+7 (495) 178-00-57</p>
                </div>
                <div class="menu-footer-section menu-footer__schedule">
                    <p>Режим работы<br>Пн-Пт с 10:00 до 19:00 по МСК</p>
                </div>
                <div class="menu-footer-section menu-footer__author">
                    <p>Политика конфиденциальности<br>© OOHDESK, 2018-2021</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuItem from './MenuItem'

export default {
    name: 'AppMenu',
    components: {
        MenuItem
    },
    data() {
        return {
            menuItems: [
                {
                    style: {
                        backgroundColor: '#fe948d',
                    },
                    title: 'Рабочий стол',
                    links: [
                        {
                            label: 'Аналитика',
                            path: 'https://map.oohdesk.ru/analytics/dashboard'
                        },
                    ]
                },
                {
                    style: {
                        backgroundColor: '#c5e1fe'
                    },
                    title: 'Размещение',
                    links: [
                        {
                            label: 'Текущее размещение',
                            path: 'https://map.oohdesk.ru/map'
                        },
                        {
                            label: 'Проекты',
                            path: 'https://map.oohdesk.ru/map/project'
                        },
                        {
                            label: 'Макеты',
                            path: 'https://map.oohdesk.ru/map/layout'
                        },
                    ]
                },
                {
                    style: {
                        backgroundColor: '#efd4ff'
                    },
                    title: 'Фотоотчет',
                    links: [
                        {
                            label: 'Фотоотчет',
                            path: 'https://map.oohdesk.ru/photoreport/default/index'
                        },
                        {
                            label: 'Избранный фотоотчет',
                            path: 'https://map.oohdesk.ru/photoreport/default/saved-supplyer'
                        },
                        {
                            label: 'Плохое качество',
                            path: 'https://map.oohdesk.ru/photoreport/default/disliked'
                        }
                    ]
                },
                {
                    style: {
                        backgroundColor: '#cffff5'
                    },
                    title: 'Предложения',
                    links: [
                        {
                            label: 'Предложения',
                            path: 'https://map.oohdesk.ru/user/order/agency-offers'
                        },
                    ]
                },
                {
                    style: {
                        backgroundColor: '#fff3d5'
                    },
                    title: 'Admetrix',
                    links: [
                        {
                            label: 'ESPAR',
                            path: 'https://map.oohdesk.ru/task_manager'
                        },
                    ]
                },
                {
                    style: {
                        backgroundColor: '#f8c7dd'
                    },
                    title: 'Конкуренты',
                    links: [
                        {
                            label: 'Макеты конкурентов',
                            path: 'https://map.oohdesk.ru/mockups/default/index'
                        },
                    ]
                },
                {
                    style: {
                        backgroundColor: '#ffa5ec'
                    },
                    title: 'Сервис рассылки',
                    links: [
                        {
                            label: 'Сервис рассылки',
                            path: 'https://buyer.inside.oohdeskbase.ru/'
                        },
                    ]
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    position: relative;
    padding: 80px 80px 0 80px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__item-list {
        max-width: 1010px;
        display: flex;
        flex-wrap: wrap;
    }

    &-footer-wrapper {
        padding-top: 80px;
        padding-bottom: 20px;
        margin-top: auto;
        align-self: flex-end;
    }

    &-footer {
        &-section {
            text-align: right;

            &__num-label {
                font-size: 11px;
            }

            &__num {
                font-size: 14px;
                margin-top: 5px;
            }
        }

        &__support {
            font-weight: bold;
            margin-bottom: 20px;
        }

        &__schedule {
            font-size: 12px;
            margin-bottom: 15px;
        }

        &__author {
            font-size: 10px;
            color: rgb(155, 155, 155);
        }
    }
}
</style>