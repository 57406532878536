<template>
    <BaseModal @close="onClose">
        <template v-slot:modalBody>
            <ExportTemplateBase
                v-if="formType === 'select'"
                :selectValues="selectValues"
                :selectedTemplate="selectedTemplate"
                :isRemoveDoubles="isRemoveDoubles"
                :is-loading-export="isLoadingExport"
                @onClosePopup="onClose"
                @onEditTemplate="onEditTemplate"
                @onCreateTemplate="onCreateTemplate"
                @onCheckTemplate="onCheckTemplate"
                @onExportTemplate="onExportTemplate"
                @onRemoveTemplate="onRemoveTemplate"
                @onClickRemoveDoubles="onClickRemoveDoubles"
            />
            <ExportTemplateEdit
                v-if="formType === 'edit'"
                :items="bufferCurrentTemplate.data"
                :name="bufferCurrentTemplate.name"
                :id="bufferCurrentTemplate.id"
                :countTemplates="userTemplates.length"
                @onClosePopup="onClose"
                @onCancel="onChangeType('select')"
                @onSaveTemplate="onSaveTemplate"
            />
        </template>
    </BaseModal>
</template>

<script>
import ExportTemplateBase from "@/components/Export/ExportTemplateBase";
import ExportTemplateEdit from "@/components/Export/ExportTemplateEdit";
import BaseModal from "../Base/BaseModal.vue";
export default {
    name: "ExportTemplate",
    components: {
        ExportTemplateBase,
        ExportTemplateEdit,
        BaseModal,
    },
    /**
     * Входные данные
     * @property {Array} userTemplates - шаблоны пользователя
     * @property {Object} currentTemplate - текущий шаблон
     */
    props: {
        userTemplates: {
            type: Array,
            default: () => [],
        },
        currentTemplate: {
            type: Object,
            default: () => ({}),
        },
        isLoadingExport: {
            type: Boolean,
            default: false
        }
    },
    /**
     * Локальные данные
     * @property {String} formType - режим работы с шаблоном
     * @property {Object} bufferCurrentTemplate - текущий шаблон
     */
    data() {
        return {
            formType: "select",
            bufferCurrentTemplate: {},
            isRemoveDoubles: false,
        };
    },
    mounted() {
        if (!this.userTemplates.length) {
            this.$emit("onGetTemplates");
        }
    },
    watch: {
        currentTemplate() {
            this.bufferCurrentTemplate = { ...this.currentTemplate };
        },
    },
    computed: {
        selectedTemplate() {
            let selected = Object.keys(this.currentTemplate).length
                ? [this.currentTemplate.id]
                : [];
            return selected;
        },
        selectValues() {
            let selectValues = [];
            this.userTemplates.forEach((item) => {
                selectValues.push({ id: item.id, value: item.name });
            });
            return selectValues;
        },
    },
    methods: {
        onClose() {
            this.$emit("onCloseExportFolder");
        },
        onChangeType(type) {
            this.formType = type;
        },
        onCreateTemplate() {
            this.bufferCurrentTemplate = { ...this.userTemplates[0] };
            this.formType = "edit";
        },
        onEditTemplate() {
            this.bufferCurrentTemplate = { ...this.currentTemplate };
            this.onChangeType("edit");
        },
        onSaveTemplate(data) {
            this.$emit("onSaveTemplate", data);
            this.onChangeType("select");
        },
        onCheckTemplate(id) {
            this.$emit("onCheckTemplate", id);
        },
        onExportTemplate() {
            this.$emit("onExportTemplate", this.isRemoveDoubles);
        },
        onRemoveTemplate() {
            this.$emit("onRemoveTemplate");
        },

        onClickRemoveDoubles(){
            this.isRemoveDoubles = !this.isRemoveDoubles;
        }
    },
};
</script>

<style lang="scss" scoped></style>
