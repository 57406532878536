<template>
    <div
        class="legend-panel-item"
        :class="{ 'legend-panel-item--active': isActiveStatus }"
        @click="onClickLegendPanelItem">
        <button
            class="legend-panel-item__btn"
            :style="{
                'background': colorBox,
                'borderColor': borderColor,
            }"
        >
        </button>
        <p class="legend-panel-item__title">{{ status }}</p>
    </div>
</template>

<script>
export default {
    name: 'LegendPanelItem',
    /**
     * Входные данные компонента
     * @property {String} color - Цвет маркера
     * @property {String} statusKey - Название статуса маркера на русском
     * @property {String} colorKey - Ключ статуса маркера, который приходит от бекенда
     * @property {Set} activeStatuses - Активные ключи статусов, для выбора активных PanelItem
     */
    props: {
        color: {
            type: String,
            default: '',
        },
        status: {
            type: String,
            default: '',
        },
        statusKey: {
            type: String,
            default: '',
        },
        activeStatuses: {
            type: Set,
        },
    },

    computed: {
        /**
         * Закрасить элемент цветом
         * @returns {String}
         */
        colorBox() {
            return (this.isActiveStatus ? this.color : 'none');
        },
        /**
         * Закрасить border цветом
         * @returns {String}
         */
        borderColor() {
            return (this.isActiveStatus ? this.color : '#D7D9D9');
        },
        /**
         * Проверяем, пришел ли props с активным статусом для данного panelItem
         * @returns {Boolean}
         */
        isActiveStatus() {
            if (this.activeStatuses.size > 0) {
                return Boolean(this.activeStatuses.has(this.statusKey));
            }
            return false;
        },

    },
    methods: {
        /**
         * Передаем родителю флаг активности элемента и его ключ
         */
        onClickLegendPanelItem() {
            this.$emit('onClickLegendPanelItem', !this.isActiveStatus, this.statusKey);
        },
    }
}
</script>

<style lang="scss" scoped>
$primary-color: #F0F3F8 !default;

.legend-panel-item {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 2px;
    transition: all .6s ease-out;

    &:hover {
        background-color: #f2f2f2;
    }

    &--active {
        .legend-panel-item__title {
            color: #000000;
        }
    }

    &__btn {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        font-size: 0;
        border-radius: 50%;
        border: 1px solid #D7D9D9;
        margin-right: 3px;
    }

    &__title {
        font-size: 10px;
        color: #C8CAD1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}



</style>
