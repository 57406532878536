/** Утилита для работы с локальным хранилищем */
export default class UtilLocalStorage {
    /**
     * Получить данные из локального хранилища
     * @static
     * @public
     * @param {String} dataKey - ключ данных
     * @returns {Object}
     */
    static getData(dataKey = '') {
        return JSON.parse(localStorage.getItem(dataKey));
    }

    /**
     * Установить данные в локальное хранилище
     * @static
     * @public
     * @param {String} dataKey - ключ данных
     * @param {Object|String|Number} dataValue - данные 
     */
    static setData(dataKey = '', dataValue = '') {
        localStorage.setItem(dataKey, JSON.stringify(dataValue));
    }

    /**
     * Проверить есть ли данные в локальном хранилище
     * @static
     * @public
     * @param {String} dataKey - ключ данных
     * @returns {Boolean}
     */
    static isData(dataKey = '') {
        return this.getData(dataKey) !== null;
    }
}