/* eslint-disable no-extra-semi */
import ServiceGoogleCircle from '../../services/ServicesGoogle/ServiceGoogleCircle';
/** Фабрика для создания окружностей через гугл мап апи */
export default class FactoryGoogleCircle {
    /**
     * Создать массив окружностей
     * @param {Array} circles - окружности
     * @param {Object} map - объект карты
     * @returns {Array}
     */
    static createCircles(circles = [], map = {}) {
        return circles.map(circle => new ServiceGoogleCircle({...circle, map}));
    };
};