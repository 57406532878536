export default {
    /**
     * Данные компонента
     * @property {Boolean} isReadyMap
     * @property {Array} actionOnMapCache
     */
    data: () => ({
        isReadyMap: false,
        actionOnMapCache: []
    }),
    methods: {
        /**
         * Добавление триггера
         * @param action
         */
        addTrigger(action = () => {}) {
            if (this.isReadyMap)
                action();
            else
                this.actionOnMapCache.push(action);
        },

        /**
         * Запуск триггеров
         */
        runTriggers() {
            this.isReadyMap = true;
            this.actionOnMapCache.forEach(action => action());
            this.actionOnMapCache = [];
        }
    }
};
