import ServiceApi from '../ServiceApi';
export default class ServiceKit {
    static _microserviceName = 'free';

    static _requestRouts = {
        _getKitItemsData: '/map/set-existed',
        _clearBusyPrices: '/map/set-drop-busy',
        sendKit: '/new_map/offer-send'
    };

    // static _kitItemDataScheme = {
    //     markerId: (responseDataKitItem = {}) => String(responseDataKitItem?.marker_id ?? ''),
    //     sideId: (responseDataKitItem = {}) => String(responseDataKitItem?.side_id ?? ''),
    //     priceId: (responseDataKitItem = {}) => String(responseDataKitItem?.price_id ?? ''),
    //     catalogId: (responseDataKitItem = {}) => String(responseDataKitItem?.catalog_id ?? ''),
    //     date: (responseDataKitItem = {}) => String(responseDataKitItem?.date ?? ''),
    // };

    static async getKitItemsData(kitId = '') {
        return this._getKitItemsData(kitId);
    }

    static async _getKitItemsData(kitId = '') {
        const { requestRoute, requestBody } = this._getKitItemsDataBefore(kitId);

        try {
            const responseData = await ServiceApi.post(this._microserviceName, requestRoute, requestBody);
            const kitItemsData = this._getKitItemsDataAfter(responseData);

            return kitItemsData;
        } catch (error) {
            console.log(error);
        }
    }

    static _getKitItemsDataBefore(kitId = '') {
        const requestData = {
            requestRoute: this._requestRouts._getKitItemsData,
            requestBody: { 'set_id': String(kitId) }
        };

        return requestData;
    }

    static _getKitItemsDataAfter(responseData = {}) {
        // const kitItemsData = responseData?.data?.data?.items?.map(responseDataItem => {
        //     const kitItemData = Object.keys(this._kitItemDataScheme).reduce((kitItemDataTotal, kitItemDataSchemeKey) => {
        //         kitItemDataTotal[kitItemDataSchemeKey] = this._kitItemDataScheme[kitItemDataSchemeKey](responseDataItem);

        //         return kitItemDataTotal;
        //     }, {});

        //     return kitItemData;
        // });

        const kitItemsData = responseData?.data?.data?.items?.reduce((kitItemsDataTotal, responseDataItem) => {
            const kitItemDataTotalIndex = kitItemsDataTotal?.findIndex(kitItemDataTotal => String(kitItemDataTotal?.id ?? '') === String(responseDataItem?.side_id ?? ''));

            if (kitItemDataTotalIndex !== -1) {
                const price = {
                    id: String(responseDataItem?.price_id ?? ''),
                    date: String(responseDataItem?.date ?? ''),
                    side_id: String(responseDataItem?.side_id ?? '')
                };

                kitItemsDataTotal[kitItemDataTotalIndex].prices.push(price);
            } else {
                const kitItemData = {
                    id: String(responseDataItem?.side_id ?? ''),
                    marker_id: String(responseDataItem?.marker_id ?? ''),
                    catalog_id: String(responseDataItem?.catalog_id ?? ''),
                    prices: [{
                        id: String(responseDataItem?.price_id ?? ''),
                        date: String(responseDataItem?.date ?? ''),
                        side_id: String(responseDataItem?.side_id ?? '')
                    }]
                };

                kitItemsDataTotal.push(kitItemData);
            }

            return kitItemsDataTotal;
        }, []);

        return kitItemsData;
    }

    static async clearBusyPrices(kitId = '', actionAfter = () => {}) {
        return this._clearBusyPrices(kitId, actionAfter);
    }

    static async _clearBusyPrices(kitId = '', actionAfter = () => {}) {
        const { requestRoute } = this._clearBusyPricesBefore(kitId);

        try {
            await ServiceApi.delete(this._microserviceName, requestRoute);
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static _clearBusyPricesBefore(kitId = '') {
        const requestData = {
            requestRoute: this._requestRouts._clearBusyPrices + "/" + kitId
        };

        return requestData;
    }

    static async sendKit(kitId = '', kitTitle = '', sendingEmail = '', selectedOfferTags = [], filter = {}) {
        return this._sendKit(kitId, kitTitle, sendingEmail, selectedOfferTags, filter)
    }

    static async _sendKit(kitId = '', kitTitle = '', sendingEmail = '', selectedOfferTags = [], filter = {}) {
        const requestBody = this._sendKitBefore(kitId, kitTitle, sendingEmail, selectedOfferTags, filter);

        try {
            const responseBody = await ServiceApi.post('free', '/new_map/offer-send', requestBody);
            const message = String(responseBody?.data?.message ?? '');

            return message;
        } catch (error) {
            console.log(error);
        }
    }

    static _sendKitBefore(kitId = '', kitTitle = '', sendingEmail = '', selectedOfferTags = [], filter = {}) {
        const requestBody = {
            set_name: String(kitTitle),
            set_id: String(kitId),
            receiver_email: String(sendingEmail),
            tags: [...selectedOfferTags],
            set_filter: { ...filter }
        };

        return requestBody;
    }
}
