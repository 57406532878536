/* eslint-disable no-extra-semi */
/** Базовый сервис для работы с окружностями */
export default class ServiceBaseCircle {
    /**
     * Конструктор
     * @param {Object} params - параметры для окружностей
     */
    constructor(params = {}) {
        const {
            map = null, 
            strokeColor = '#FF0000', 
            strokeOpacity = 0.8, 
            strokeWeight = 2,
            fillColor = '#FF0000',
            fillOpacity = 0.35,
            centerLat = '', 
            centerLng = '', 
            radius = 0
        } = params;
        this.map = map;
        this.strokeColor = String(strokeColor);
        this.strokeOpacity = parseFloat(strokeOpacity);
        this.strokeWeight = parseInt(strokeWeight);
        this.fillColor = String(fillColor);
        this.fillOpacity = parseFloat(fillOpacity);
        this.centerLat = parseFloat(centerLat);
        this.centerLng = parseFloat(centerLng);
        this.radius = parseFloat(radius);
    };
};