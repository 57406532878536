<template>
    <div class="wrapper">
        <div class="history-item-panel">
            <div :class="getItemClasses(itemData)" @click="goToUrl">
                {{itemData.title}}
            </div>
            <div :style="{'color' : itemOption.color}">
                {{itemOption.name}}
            </div>
        </div>
        <div class="history-item-created">
            {{createdAt}}
        </div>
    </div>
</template>

<script>

export default {
    name: 'HistoryItem',
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            itemOptions: [
                {
                    color: "#B5B5B5",
                    name: 'Ожидание'
                },
                {
                    color: "#798FC6",
                    name: 'Выполнение'
                },
                {
                    color: "#68B06E",
                    name: 'Выполнено'
                },
                {
                    color: "#CA6565",
                    name: 'Выполнено с ошибкой'
                }
            ],
        }
    },
    methods: {
        goToUrl(){
            if(this.itemData.status === 2){
                this.$emit('closeDropdown', false)
                window.open(this.itemData.url);
            }
        },
        getItemClasses(itemData) {
            return {
                'history-item-panel__button': itemData.status === 2
            }
        }
    },
    computed: {
        createdAt(){
            const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
            let a = new Date(this.itemData.created_at * 1000);
            let year = a.getFullYear();
            let month = a.getMonth();
            let date = a.getDate();
            date = date > 9 ? date : '0' + date;
            let hour = a.getHours();
            hour = hour > 9 ? hour : '0' + hour;
            let min = a.getMinutes();
            min = min > 9 ? min : '0' + min;
            let time = date + ' ' + months[month] + ' ' + year + ' ' + hour + ':' + min ;
            return time;
        },
        itemOption(){
            return this.itemOptions[this.itemData.status];
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    display: inline-block;
    width: 300px;
    padding: 15px 20px;

    .history-item-panel{
        display: flex;
        justify-content: space-between;

        &__button{
            cursor: pointer;
            color: #0b76ef;
        }
    }

    .history-item-created{
        color: #BEC0C7;
    }
}
</style>
