/* eslint-disable no-extra-semi */
import ServiceBasePolyline from '../ServicesBase/ServiceBasePolyline';
const google = window.google;
/**
 * Сервис для работы с полилиниями в гугл мап апи
 * @extends ServiceBasePolyline
 */
export default class ServiceGooglePolyline extends ServiceBasePolyline {
    constructor(params = {}) {
        super(params);
        this.polylineObj = new google.maps.Polyline({
            map: this.map,
            strokeColor: this.strokeColor,
            strokeOpacity: this.strokeOpacity,
            strokeWeight: this.strokeWeight,
            paths: this.paths,
            clickable: this.clickable,
            draggable: this.draggable,
            editable: this.editable
        });
    };
};