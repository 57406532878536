<template>
	<div
		v-if="isVisible"
		class="progress-bar"
	>
		<div class="progress-bar__track"></div>
		<div
			class="progress-bar__fill"
			:style="getFillStyles"
		></div>
	</div>
</template>

<script>
export default {
	name: "ProgressBar",
	props: {
		isLoading: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			progress: 0,
			isVisible: false
		}
	},
	computed: {
		getFillStyles() {
			return {width: this.progress + '%'}
		},
	},
	mounted() {
		if (this.isLoading) {
			this.init()
		}
	},
	watch: {
		isLoading(value) {
			if (value === true) {
				this.reset()
				this.init()
			}
		}
	},
	methods: {
		init() {
			const clearIntervalId = setInterval(() => {
				this.isVisible = true
				this.progress += .15

				if (this.progress > 80) {
					clearInterval(clearIntervalId)
				}

				if (!this.isLoading) {
					this.progress = 100
					clearInterval(clearIntervalId)

					setTimeout(() => {
						this.isVisible = false
					}, 1000);
				}
			}, 100);
		},
		reset() {
			this.progress = 0
			this.isVisible = true
		}
	},
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;

.progress-bar {
	width: 100%;
	height: 8px;
	position: relative;
	flex-shrink: 0;

	&__track {
		position: absolute;
		top: 0px;
		bottom: 0px;
		right: 0px;
		left: 0px;
		background: #e9ecef;
	}

	&__fill {
		position: absolute;
		top: 0px;
		left: 0px;
		bottom: 0px;
		background: $active-color;
		transition: .25s;
	}
}
</style>