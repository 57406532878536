export default {
    namespaced: true,
    state: {
        widgetPoi: {
            dataGroups: [],
            dataPoints: [],
            dataGroupsByCityIds: [],
            checkedParentList: [],
            checkedChildList: [],
            base64Icons: [],
            isLoadAllPoi: false,
            currentCityId: null,
            radius: '0',
        },
    },
    getters: {
        /**
         * Получить весь WidgetPoi
         */
        getWidgetPoi(state) {
            return state.widgetPoi;
        },
        /**
         * Получить id города
         */
        currentCityId(state) {
            return state.widgetPoi.currentCityId;
        },

        /**
         * Получить список групп
         */
        dataGroups(state) {
            return state.widgetPoi.dataGroups;
        },

        /**
         * Получить список poi
         */
        dataPoints(state) {
            return state.widgetPoi.dataPoints;
        },

        /**
         * Получить список отмеченных групп
         */
        checkedParentList(state) {
            return state.widgetPoi.checkedParentList;
        },

        /**
         * Получить список отмеченных poi
         */
        checkedChildList(state) {
            return state.widgetPoi.checkedChildList;
        },

        /**
         * Получить список кастомных пользовательских иконок
         */
        base64Icons(state) {
            return state.widgetPoi.base64Icons;
        },

        /**
         * Флаг загрузки всех пои
         */
        isLoadAllPoi(state) {
            return state.widgetPoi.isLoadAllPoi;
        },

        /**
         * Радиус poi
         */
        radius(state) {
            return state.widgetPoi.radius;
        },

        /**
         * Список id групп доступных в данном городе
         */
        dataGroupsByCityIds(state) {
            return state.widgetPoi.dataGroupsByCityIds;
        },

    },
    mutations: {
        setWidgetPoi(state, widget = {}) {
            state.widgetPoi = widget;
        },
        setDataGroups(state, dataGroups = []) {
            state.widgetPoi.dataGroups = dataGroups;
        },
        setRemoveDataGroups(state, index) {
            state.widgetPoi.dataGroups.splice(index, 1);
        },
        setAddDataGroups(state, dataGroups = []) {
            state.widgetPoi.dataGroups.push(...dataGroups);
            state.widgetPoi.dataGroups.sort((oneGroup, twoGroup) => oneGroup.name.localeCompare(twoGroup.name));
        },

        setDataPoints(state, dataPoints = []) {
            state.widgetPoi.dataPoints = dataPoints;
        },
        setRemoveDataPoints(state, index) {
            state.widgetPoi.dataPoints.splice(index, 1);
        },
        setAddDataPoints(state, dataPoints = []) {
            state.widgetPoi.dataPoints.push(...dataPoints);
        },

        setCheckedParentList(state, checkedParentList = []) {
            state.widgetPoi.checkedParentList = checkedParentList;
        },
        setAddCheckedParent(state, checkedList = []) {
            state.widgetPoi.checkedParentList.push(...checkedList);
        },
        setRemoveCheckedParent(state, index) {
            state.widgetPoi.checkedParentList.splice(index, 1);
        },

        setCheckedChildList(state, checkedChildList = []) {
            state.widgetPoi.checkedChildList = checkedChildList;
        },
        setAddCheckedChild(state, checkedList = []) {
            state.widgetPoi.checkedChildList.push(...checkedList);
        },
        setRemoveCheckedChild(state, index) {
            state.widgetPoi.checkedChildList.splice(index, 1);
        },

        setBase64Icons(state, dataIcon = {}) {
            state.widgetPoi.base64Icons.push(dataIcon);
        },

        setIsLoadAllPoi(state, isLoad) {
            state.widgetPoi.isLoadAllPoi = isLoad;
        },
        setCurrentCityId(state, cityId = null) {
            state.widgetPoi.currentCityId = cityId;
        },
        setRadius(state, radius = '0') {
            state.widgetPoi.radius = String(radius);
        },
        setDataGroupsByCityIds(state, groupIds = []) {
            state.widgetPoi.dataGroupsByCityIds = groupIds;
        },
        setAddDataGroupsByCityIds(state, groupId = '') {
            state.widgetPoi.dataGroupsByCityIds.push(groupId);
        },
    },
}
