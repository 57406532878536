import Vue from 'vue'
import Vuex from 'vuex'

import filter from './modules/filter'
import history from './modules/history'
import folders from './modules/folders'
import kit from './modules/kit';
import reconciliation from './modules/reconciliation';
import pageBayuerdesk from './modules/pageBayuerdesk';
import auth from './modules/auth';
import widgetPoi from './modules/widgetPoi';
import EditPrices from './modules/EditPrices';
import CustomKits from './modules/CustomKits';
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		filter,
		folders,
        kit,
        reconciliation,
        pageBayuerdesk,
        auth,
        widgetPoi,
        history,
        ...EditPrices,
        ...CustomKits
	},
    state: {
        currentComponent: ''
    },
    mutations: {
        setCurrentComponent(state, currentComponent = '') {
            state.currentComponent = currentComponent;
        }
    }
})
