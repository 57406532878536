<template>
    <svg class="svg-icon" :width="width" :height="height" viewBox="8 8 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect :width="width" :height="height" rx="4" fill="none"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.0774 11.5774C19.7862 10.8685 20.7476 10.4703 21.75 10.4703C22.7524 10.4703 23.7138 10.8685 24.4226 11.5774C25.1314 12.2862 25.5296 13.2475 25.5296 14.2499C25.5296 15.2524 25.1314 16.2137 24.4226 16.9225L21.0921 20.253C20.7436 20.6079 20.3279 20.8899 19.8694 21.0826C19.4094 21.2758 18.9156 21.3754 18.4167 21.3754C17.9178 21.3754 17.4239 21.2758 16.9639 21.0826C16.504 20.8893 16.0873 20.6062 15.7381 20.2498C15.416 19.9211 15.4214 19.3935 15.7501 19.0714C16.0789 18.7493 16.6065 18.7547 16.9286 19.0834C17.1225 19.2814 17.3541 19.4387 17.6096 19.546C17.8651 19.6534 18.1395 19.7087 18.4167 19.7087C18.6938 19.7087 18.9682 19.6534 19.2237 19.546C19.4793 19.4387 19.7108 19.2814 19.9048 19.0834L19.9107 19.0773L19.9107 19.0774L23.2441 15.744C23.6403 15.3478 23.8629 14.8103 23.8629 14.2499C23.8629 13.6896 23.6403 13.1521 23.2441 12.7559C22.8478 12.3596 22.3104 12.137 21.75 12.137C21.1896 12.137 20.6522 12.3596 20.2559 12.7559L19.8393 13.1725C19.5138 13.498 18.9862 13.498 18.6607 13.1725C18.3353 12.8471 18.3353 12.3195 18.6607 11.994L19.0774 11.5774Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.1309 14.9173C16.5909 14.7241 17.0847 14.6245 17.5836 14.6245C18.0825 14.6245 18.5764 14.7241 19.0364 14.9173C19.4963 15.1106 19.9131 15.3937 20.2622 15.7501C20.5843 16.0788 20.5789 16.6064 20.2502 16.9285C19.9215 17.2506 19.3938 17.2452 19.0717 16.9165C18.8778 16.7185 18.6462 16.5612 18.3907 16.4539C18.1352 16.3465 17.8608 16.2912 17.5836 16.2912C17.3065 16.2912 17.0321 16.3465 16.7766 16.4539C16.5211 16.5612 16.2895 16.7185 16.0956 16.9165L16.0896 16.9226L16.0896 16.9225L12.7562 20.2559C12.36 20.6521 12.1374 21.1895 12.1374 21.7499C12.1374 22.3103 12.36 22.8478 12.7562 23.244C13.1525 23.6403 13.6899 23.8629 14.2503 23.8629C14.8107 23.8629 15.3481 23.6403 15.7444 23.244L16.1611 22.8273C16.4865 22.5019 17.0141 22.5019 17.3396 22.8273C17.665 23.1528 17.665 23.6804 17.3396 24.0059L16.9229 24.4225C16.2141 25.1313 15.2527 25.5295 14.2503 25.5295C13.2479 25.5295 12.2865 25.1313 11.5777 24.4225C10.8689 23.7137 10.4707 22.7524 10.4707 21.7499C10.4707 20.7475 10.8689 19.7862 11.5777 19.0773L14.9082 15.7469C15.2567 15.392 15.6724 15.11 16.1309 14.9173Z" :fill="color"/>
</svg>
</template>

<style scoped>
    .svg-icon path {
        fill: currentColor;
    }
</style>

<script>
export default {
    name: 'IconShare',
    props: {
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'black'
        },
    },
}
</script>
