<template>
    <div class="d-auth">
        <img :src="require('@/assets/images/auth/background.svg')" alt="" class="d-auth__background">
        <div class="d-auth__content">
            <img
                class="d-auth__content-logo"
                :src="require('@/assets/images/OohdeskBUYER.svg')"
                alt="Логотип Oohdesk"
            >
            <h1 class="d-auth__content-title">
                Привет, <br>
                рады тебя видеть!
            </h1>
            <div class="d-auth__form">
                <label class="d-auth__form-item">
                    <BaseTextInput
                        class="d-auth__form-input"
                        v-model="login"
                        view="outline"
                        label="Ваш email"
                    />
                </label>
                <label class="d-auth__form-item">
                    <BasePasswordInput
                        class="d-auth__form-input"
                        :is-show-pass-icon="true"
                        v-model="password"
                        view="outline"
                        label="Пароль"
                    />
                </label>
            </div>
            <div v-if="responseMessage" class="d-auth__error">
                {{ responseMessage }}
            </div>
            <div class="d-auth__footer">
                <div class="d-auth__footer-item">
                    <div class="d-auth__footer-action" @click="onResetPassword">Забыли пароль ?</div>
                </div>
                <div class="d-auth__footer-item">
                    <BaseButton
                        class="d-auth__submit"
                        view="third"
                        size="extra-large"
                        form="oval"
                        :is-loading="isLoading"
                        @click="onLogin"
                    >
                        Войти
                    </BaseButton>
                </div>
            </div>
<!--            <div class="d-auth__policy">-->
<!--                Нажимая кнопку &laquo;Войти&raquo;, вы&nbsp;подтверждаете своё согласие на&nbsp;обработку-->
<!--                <a href="https://oohdesk.ru/policy" class="d-auth__policy-link" target="_blank">персональных данных</a>-->
<!--            </div>-->
        </div>
        <div class="d-auth__copyright">© OOHDESK, 2018-{{ year }}</div>
        <transition appear name="fade" mode="out-in">
            <Notification v-if="isShowAlert" class="d-auth__notification" type="warn" :text="alertText"/>
        </transition>
    </div>
</template>

<script>
import BaseTextInput from "@/components/Base/BaseTextInput";
import BasePasswordInput from "@/components/Base/BasePasswordInput";
import BaseButton from "@/components/Base/BaseButton";
import Notification from "@/components/Notification/Notification.vue";

export default {
    name: "AppLoginV2",
    components: {
        Notification,
        BaseTextInput,
        BasePasswordInput,
        BaseButton,
    },
    props: {
        responseMessage: {
            type: String,
            default: ''
        },
    },

    watch: {
        responseMessage() {
            this.isLoading = !this.responseMessage;
        },
    },
    data: () => ({
        isShowPass: false,
        login: '',
        password: '',
        alertText: '',
        isShowAlert: false,
        isLoading: false,
    }),
    computed: {
        year() {
            return new Date().getFullYear();
        },
    },
    methods: {
        onLogin() {
            this.isLoading = true;
            this.$emit('onLogin', this.login, this.password);
        },
        onResetPassword() {
            this.alertText = 'Для восстановления пароля обратитесь к Администратору';
            this.isShowAlert = true;
            setTimeout(() => {
                this.isShowAlert = false;
            }, 5000);
        },
    }
}
</script>

<style lang="scss" scoped>
$color-white: #FFFFFF;
$color-primary: #2B6BF3;
$color-secondary: #F70068;

$color-error: #F84967;

$color-text-primary: #000000;
$color-text-secondary: #787A7E;
$color-background-primary: #00196C;
$color-background-secondary: #FFFFFF;

.d-auth {
    padding: 16px 16px 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;
    overflow: auto;
    background-color: $color-background-primary;
    font-family: 'PT Sans', Arial, sans-serif;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 45%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__content {
        position: relative;
        padding: 48px;
        max-width: 432px;
        width: 100%;
        background-color: $color-background-secondary;
        border-radius: 60px;

        &-logo {
            margin: -26px 0 0 -26px;
            display: block;
            max-width: 214px;
            max-height: 95px;
        }

        &-title {
            margin-top: 20px;
            color: $color-text-primary;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.25;
        }
    }

    &__notification {
        position: fixed;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__form {
        margin-top: 40px;

        &-item {
            display: block;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &-input {
            background-color: $color-background-secondary;
        }
    }

    &__error {
        margin-top: 10px;
        position: absolute;
        color: $color-error;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
    }

    &__footer {
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &-item {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        &-action {
            cursor: pointer;
            width: fit-content;
            color: $color-primary;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            transition: all 0.15s ease-in-out;

            &:hover {
                color: $color-secondary;
            }
        }
    }

    &__submit {
        position: relative;
        overflow: hidden;
        &-loader {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: inherit;
            &__icon {
                display: flex;
            }
        }
    }

    &__policy {
        margin-top: 20px;
        text-align: center;
        color: $color-text-secondary;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        &-link {
            display: inline;
            color: $color-primary;
            transition: all 0.15s ease-in-out;
            &:hover {
                color: $color-secondary;
            }
        }
    }

    &__copyright {
        position: absolute;
        bottom: 16px;
        right: 16px;
        color: $color-white;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
    }

    @media screen and (max-width: 576px) {
        &__background {
            width: 60%;
        }

        &__notification {
            width: calc(100% - 32px);
        }

        &__content {
            padding: 12px 24px 24px;
            border-radius: 48px;
            &-logo {
                margin: 0 auto;
            }
            &-title {
                text-align: center;
            }
        }

        &__footer {
            flex-direction: column-reverse;
            &-item {
                width: 100%;
                &:not(:last-child) {
                    margin-right: 0;
                    margin-top: 24px;
                }
            }
            &-action {
                margin: 0 auto;
            }
        }

        &__submit {
            width: 100%;
        }
    }
}
</style>
