<template>
    <div class="base-preloader-gif">
        <div><img src="@/assets/images/1487.gif" /></div>
    </div>
</template>

<script>
export default {
    name: 'BasePreloaderGifV2'
}
</script>

<style lang="scss" scoped>
    .base-preloader-gif {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>