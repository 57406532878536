import ServiceApi from "../ServiceApi";

/** Сервис для работы с конкурентами */
export default class ServicePoi {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = "free";

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} poiCities - список доступных городов
     * @property {String} poiGroupList - список групп пои пользователя
     * @property {String} poiGetPoints - получение точек группы пользователя
     * @property {String} poiRemoveGroup - удаление группы
     * @property {String} poiAutoComplete - автозаполнение при создании группы
     * @property {String} poiUploadIcon - загрузка иконки
     * @property {String} poiIcon - получение иконки
     * @property {String} poiIconList - список иконок
     * @property {String} poiIconLink - привязка иконки к группе
     * @property {String} poiIconUnlink - отвязывание иконки от группы
     * @property {String} poiIconRemove - удаление иконки
     */
    static _requestRouts = {
        poiSearchAddress: "/poi/search",
        poiCountries: "/poi/countries",
        poiCities: "/poi/cities",
        poiGroupList: "/poi/group-list",
        poiGetPoints: "/poi/get-points",
        poiRemoveGroup: "/poi/remove-group",
        poiUpdateGroup: "/poi/update-group",
        poiAutoComplete: "/poi/auto-complete",
        poiCreateGroup: "/poi/create-group",
        poiUploadIcon: "/poi/upload-icon",
        poiIcon: "/poi/icon",
        poiIconShared: "/poi/icon-shared",
        poiIconList: "/poi/icon-list",
        poiIconLink: "/poi/link-icon",
        poiIconUnlink: "/poi/unlink-icon",
        poiIconRemove: "/poi/remove-icon",
        moiPoiGroupList: "/poi/my-group-list",
        moiPoiCreateGroup: "/poi/my-group-create",
        moiPoiCreatePoint: "/poi/my-point-create",
        moiPoiCreateBatch: "/poi/my-point-create-batch",
        moiPoiUpdateGroup: "/poi/my-group-update",
        moiPoiGetPoints: "/poi/my-group-detail",
        moiPoiRemoveGroup: "/poi/my-group-remove",
        moiPoiIconUnlink: "/poi/my-group-icon-unlink",
        moiPoiIconLink: "/poi/my-group-icon-link",
        moiPoiFindMatchAddress: "/poi/match-find-address",
        moiPoiTags: "/poi/my-tags",
        moiPoiUploadFile: "/poi/my-poi-file-upload",
        moiPoiComposeFile: "/poi/my-poi-file-compose",
    };

    static async getPoiAddress(query, actionAfter = () => {}) {
        try {
            const { data } = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiSearchAddress,
                { query }
            );
            actionAfter(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить доступные города
     * @public
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async getCities(actionAfter = () => {}) {
        let currentCountryId = null;
        try {
            await this.getCountries(
                (countries) => (currentCountryId = countries[0].id)
            );
            const res = await ServiceApi.get(
                this._microserviceName,
                `${this._requestRouts.poiCities}/${currentCountryId}`
            );
            const { data: cities = {} } = res;
            actionAfter(cities.data);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить доступную страну
     * @public
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async getCountries(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.poiCountries
            );
            const { data: countries = {} } = res;
            actionAfter(countries.data);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить иконки пользователя
     * @public
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async getIconList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.poiIconList
            );
            const { data: icons = [] } = res;
            actionAfter(this._getIconListAfter(icons.data));
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Адаптер для фронта
     * @param {Array} icons
     * @return {Array<Object>}
     * @private
     */
    static _getIconListAfter(icons) {
        icons.forEach((icon) => {
            icon.iconId = String(icon.id);
            icon.src = icon.link/*this._getSrcById(icon.id)*/;
        });
        return icons;
    }

    /**
     *
     * @param iconId
     * @private
     *
     * @deprecated
     */
    static _getSrcById(iconId) {
        // let url = isShared ? this._requestRouts.poiIconShared : this._requestRouts.poiIcon;
        return (
            process.env.VUE_APP_FREE_BACKEND_HOST +
            this._requestRouts.poiIcon +
            "/" +
            iconId
        );
    }

    /**
     * Соединение иконки и группы
     * @public
     * @static
     * @async
     * @param {String} groupId - группа
     * @param {String} iconId - id иконки
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async linkIcon(groupId, iconId, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiIconLink,
                { group_id: groupId, icon_id: iconId }
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static async linkMoiPoiIcon(groupId, iconId, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                `${this._requestRouts.moiPoiIconLink}?iconId=${iconId}&groupId=${groupId}`
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Соединение иконки и группы
     * @public
     * @static
     * @async
     * @param {String} query - запрос поиска пои
     * @param {String} cityId - id города
     * @param {Function} actionAfter - событие, сработающее после
     * @param {Function} actionError - событие, сработающее после
     */
    static async autoComplete(
        query,
        cityId,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiAutoComplete,
                { query, city_id: cityId }
            );
            const { data: responseResult = {} } = res;
            actionAfter(this._autoCompleteAfter(responseResult.data, "+"));
        } catch (error) {
            actionError(this._errorAfter(error));
            console.log(error);
        }
    }

    /**
     * Извлечение ошибок
     * @param error
     * @private
     */
    static _errorAfter(error) {
        const { response = {} } = error;
        const { data: responseData = {} } = response;
        const { data: responseErrors = {} } = responseData;
        return responseErrors;
    }

    /**
     * Создание группы
     * @public
     * @static
     * @async
     * @param {Object} requestBody
     * @param {Function} actionAfter - событие, сработающее после
     * @param {Function} actionError - событие, сработающее после
     */
    static async createGroup(
        requestBody,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiCreateGroup,
                requestBody
            );
            const { data: responseResult = {} } = res;
            let {
                group = {},
                items = [],
                page_count: pageCount = 0,
            } = responseResult.data;
            group = this._getGroupsAfter([group])[0];
            items = this._getPointsAfter(items, group);
            actionAfter(group, items, pageCount);
        } catch (error) {
            actionError(error);
            console.log(error);
        }
    }

    static async createMoiPoiGroup(
        requestBody,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const {data} = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.moiPoiCreateGroup,
                requestBody
            );
            data.data = this._getGroupsAfter([data.data], "moi")[0];
            actionAfter(data.data);
        } catch (error) {
            actionError(error);
            console.log(error);
        }
    }

    static async moiPoiCreatePoint(
        requestBody,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const { data } = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.moiPoiCreatePoint,
                requestBody
            );
            const { item, group } = data.data;
            actionAfter(this._getMoiPointsAfter([item], group));
        } catch (error) {
            actionError(error);
            console.log(error);
        }
    }

    /**
     *
     * @param {Object} response
     * @param {Object<Object>} response.poi_request параметры запроса
     * @param {Object<Array>} response.poi_request_items похожие подзапросы
     * @param countPostfix
     * @return {{resultItems: [], requestId: null}}
     * @private
     */
    static _autoCompleteAfter(response, countPostfix = "") {
        const {
            poi_request: poiRequest = {},
            poi_request_items: poiRequestItems = [],
        } = response;
        const { id: requestId = null } = poiRequest;
        let resultItems = [];
        poiRequestItems.sort(function(a, b) {
            return b.count - a.count;
        });
        poiRequestItems.forEach((poiRequestItem) => {
            const { id, name, count } = poiRequestItem;
            resultItems.push({
                id: String(id),
                value: name,
                count: String(count) + countPostfix,
            });
        });
        return { requestId, resultItems };
    }

    /**
     * Отсоединение иконки и группы
     * @public
     * @static
     * @async
     * @param {String} groupId - группа
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async unlinkIcon(groupId, actionAfter = () => {}) {
        try {
            await ServiceApi.delete(
                this._microserviceName,
                this._requestRouts.poiIconUnlink + "/" + groupId
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static async unlinkMoiPoiIcon(groupId, actionAfter = () => {}) {
        try {
            await ServiceApi.delete(
                this._microserviceName,
                `${this._requestRouts.moiPoiIconUnlink}?groupId=${groupId}`
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Удаление иконки
     * @public
     * @static
     * @async
     * @param {String} iconId - id иконки
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async removeIcon(iconId, actionAfter = () => {}) {
        try {
            await ServiceApi.delete(
                this._microserviceName,
                this._requestRouts.poiIconRemove + "/" + iconId
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Удаление группы
     * @public
     * @static
     * @async
     * @param {String} groupId
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async removeGroup(group_id, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiRemoveGroup,
                {
                    group_id,
                }
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static async removeMoiPoiGroup(group_id, actionAfter = () => {}) {
        try {
            await ServiceApi.delete(
                this._microserviceName,
                `${this._requestRouts.moiPoiRemoveGroup}/${group_id}`
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Загрузка иконки
     * @public
     * @static
     * @async
     * @param {File} file
     * @param {Function} actionAfter - событие, сработающее после
     * @param {Function} actionError - событие, сработающее после
     */
    static async uploadIcon(
        file,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const formData = new FormData();
            formData.append("icon", file);
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiUploadIcon,
                formData
            );
            const { data: icon = {} } = res;
            actionAfter(this._getIconListAfter([icon.data])[0]);
        } catch (error) {
            actionError(this._errorAfter(error));
            console.log(error);
        }
    }

    /**
     * Обновление группы
     * @public
     * @static
     * @async
     * @param {Object} group - группа
     * @param {Number} group.group_id - id Группы
     * @param {String} group.name - название группы
     * @param {String} group.color - цвет группы
     * @param {Number} group.icon_id - иконка группы
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async updateGroup(group, actionAfter = () => {}) {
        try {
            await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.poiUpdateGroup,
                group
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static async updateMoiPoiGroup(group, actionAfter = () => {}) {
        try {
            await ServiceApi.put(
                this._microserviceName,
                `${this._requestRouts.moiPoiUpdateGroup}/${group.group_id}`,
                { color: group.color, name: group.name }
            );
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить доступные группы
     * @public
     * @static
     * @async
     * @param {Number} cityId - выбранный город
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async getGroups(cityId, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiGroupList,
                { city_id: cityId }
            );
            const { data: groups = {} } = res;
            actionAfter(this._getGroupsAfter(groups.data));
        } catch (error) {
            console.log(error);
        }
    }

    static async getMoiPoiGroups(cityId, actionAfter = () => {}) {
        try {
            const { data: moi_poi } = await ServiceApi.get(
                this._microserviceName,
                `${this._requestRouts.moiPoiGroupList}/${cityId}`
            );
            actionAfter(this._getGroupsAfter(moi_poi.data, "moi"));
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить маркеры
     * @public
     * @static
     * @async
     * @param {Object} group - группа пои
     * @param {Number} page - страница
     * @param {Function} actionAfter - событие, сработающее после
     */
    static async getPoints(
        group = {},
        page = 0,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.poiGetPoints,
                { group_id: String(group?.key ?? ""), page }
            );
            const { data: result = {} } = res;
            const { items = [], page_count: pageCount = 0 } = result.data;
            actionAfter(this._getPointsAfter(items, group), pageCount);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async getMoiPoiPoints(
        group = {},
        cityId = 1,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                `${this._requestRouts.moiPoiGetPoints}/${cityId}/${group.key}`
            );
            const { data: result = {} } = res;
            const { items = [], page_count: pageCount = 0, group: groupData = {} } = result.data;
            actionAfter(this._getMoiPointsAfter(items, group), pageCount, groupData);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Адаптер для фронта
     * @param {Array} groups
     * @return {Array<Object>}
     * @private
     */
    static _getGroupsAfter(groups, poiType = "oohdesk") {
        let groupsResult = [];
        groups.forEach((group) => {
            const {
                id,
                color,
                icon_id: iconId = null,
                icon,
                poi_count: count,
                name,
            } = group;
            groupsResult.push({
                id: String(id),
                name,
                key: String(id),
                color,
                iconId,
                count: String(count),
                src: iconId !== null ? icon/*this._getSrcById(iconId)*/ : "",
                poiType,
            });
        });
        return groupsResult;
    }

    /**
     * Адаптер для фронта
     * @param {Array} points
     * @param {Object} groupParams
     * @return {Array<Object>}
     * @private
     */
    static _getPointsAfter(points, groupParams) {
        let markers = [];
        points.forEach((point) => {
            markers.push({
                id: String(point?.id ?? ""),
                additionalId: String(groupParams?.key),
                lat: String(point?.lat ?? ""),
                lng: String(point?.lng ?? ""),
                canEdit: false,
                iconType: /*isIcon ? 'poiIcon' : */ "poi",
                fill_color: groupParams.color,
                figure: "square",
                src: groupParams.src ?? "",
                extraData: {
                    id: point.id,
                    groupId: groupParams.key,
                    groupName: String(groupParams?.name ?? ""),
                    type: "poi",
                    address: point.address,
                    description: point.description,
                    isActive: true,
                    isMoiPoi: false,
                },
                clusterType: "poi",
                isMoiPoi: false,
            });
        });
        return markers;
    }

    static _getMoiPointsAfter(points, groupParams) {
        let markers = [];
        points.forEach((point) => {
            markers.push({
                id: String(point?.id ?? ""),
                additionalId: String(groupParams?.id),
                lat: String(point?.marker?.lat ?? ""),
                lng: String(point?.marker?.lng ?? ""),
                canEdit: false,
                iconType: /*isIcon ? 'poiIcon' : */ "poi",
                fill_color: groupParams.color,
                figure: "square",
                src: groupParams.src ?? "",
                extraData: {
                    id: String(point?.id ?? ""),
                    groupId: String(groupParams?.id ?? ""),
                    groupName: String(groupParams?.name ?? ""),
                    type: "poi",
                    address: point.address,
                    description: point.name,
                    isActive: true,
                    isMoiPoi: true,
                },
                clusterType: "poi",
                categories: point.categories.map((item) => item.name),
                isMoiPoi: true,
            });
        });
        return markers;
    }

    static async createPointsBatch(groupId, items, actionAfter = () => {}, actionError = () => {}) {
        try {
            const requestBody = this._createPointsBatchBefore(groupId, items);
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.moiPoiCreateBatch,
                requestBody
            );
            const {data: responseResult = {}} = res;
            console.log(responseResult)
            actionAfter(responseResult);
        } catch (error) {
            actionError(error);
            console.log(error);
        }
    }

    static _createPointsBatchBefore(groupId, items) {
        return {
            group_id: groupId,
            items
        }
    }

    static async findAddressByCoords(coords, actionAfter) {
        try {
            const { data } = await ServiceApi.get(
                this._microserviceName,
                `${this._requestRouts.moiPoiFindMatchAddress}?lat=${coords[0]}&lng=${coords[1]}`
            );
            actionAfter(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async getUserTags(actionAfter) {
        try {
            const { data } = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.moiPoiTags
            );
            actionAfter(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async uploadFile(file, actionAfter) {
        try {
            const formData = new FormData();
            formData.append("file", file);

            const { data } = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.moiPoiUploadFile,
                formData
            );
            actionAfter(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async composeFile(composeData, actionAfter) {
        try {
            const { data } = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.moiPoiComposeFile,
                composeData
            );
            // actionAfter(data.data.success);
            actionAfter(data?.data);
        } catch (error) {
            console.log(error);
        }
    }
}
