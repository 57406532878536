import ServiceApi from "../ServiceApi";
import lodash from "lodash";

export default class ServiceEditPricesTable {
    static _microserviceName = 'free';
    static _requestRoutes = {
        tableColumnsSchemeCustom: '/map/state',
        setChangePeriod: '/map/set-change-period'
    };
    static _tableKey = 'tableEditPrices';

    static async getTableColumnsSchemeCustom(tableColumnsScheme = []) {
        return this._getTableColumnsSchemeCustom(tableColumnsScheme);
    }

    static async _getTableColumnsSchemeCustom(tableColumnsScheme = []) {
        const tableColumnsSchemeCustom = await this._postTableColumnsSchemeCustom({}, tableColumnsScheme);

        return tableColumnsSchemeCustom;
    }

    static async setTableColumnsSchemeCustom(tableColumnsScheme = []) {
        return this._setTableColumnsSchemeCustom(tableColumnsScheme);
    }

    static async _setTableColumnsSchemeCustom(tableColumnsScheme = []) {
        const requestBody = this._setTableColumnsSchemeCustomBefore(tableColumnsScheme);
        const tableColumnsSchemeCustom = await this._postTableColumnsSchemeCustom(requestBody, tableColumnsScheme);

        return tableColumnsSchemeCustom;
    }

    static _setTableColumnsSchemeCustomBefore(tableColumnsScheme = []) {
        const tableColumnsSchemeBefore = tableColumnsScheme.map(tableColumn => ({
            prop: String(tableColumn?.prop ?? ''),
            isShow: Boolean(tableColumn?.isShow ?? false)
        }));
        const requestBody = { mapState: { [this._tableKey]: tableColumnsSchemeBefore } };

        return requestBody;
    }

    static async _postTableColumnsSchemeCustom(requestBody = {}, tableColumnsScheme = []) {
        try {
            const responseBody = await ServiceApi.post(this._microserviceName, this._requestRoutes.tableColumnsSchemeCustom, requestBody);
            const responseData = responseBody?.data?.data[this._tableKey] ?? [];
            const tableColumnsSchemeCustom = this._postTableColumnsSchemeCustomAfter(responseData, tableColumnsScheme);

            return tableColumnsSchemeCustom;
        } catch (error) {
            console.log(error);
        }
    }

    static _postTableColumnsSchemeCustomAfter(responseData = [], tableColumnsScheme = []) {
        const tableColumnsSchemeCustomizable = tableColumnsScheme.filter(column => Boolean(column?.isCustomizable ?? true));
        const tableColumnsSchemeNotCustomizable = tableColumnsScheme.filter(column => !(column?.isCustomizable ?? true));

        const tableColumnsSchemeCustomizableProp = tableColumnsSchemeCustomizable.map(column => String(column?.prop ?? ''));
        const responseDataCustomizable = responseData.filter(column => tableColumnsSchemeCustomizableProp.includes(String(column?.prop ?? '')));
       
        const tableColumnsSchemeCustomAdd = lodash.differenceBy(tableColumnsSchemeCustomizable, responseDataCustomizable, 'prop');
        const tableColumnsSchemeCustomRemove = lodash.differenceBy(responseDataCustomizable, tableColumnsSchemeCustomizable, 'prop').map(tableColumn => String(tableColumn?.prop ?? ''));

        let tableColumnsSchemeCustom = [...tableColumnsSchemeNotCustomizable, ...responseDataCustomizable, ...tableColumnsSchemeCustomAdd].filter(tableColumn => {
            return !tableColumnsSchemeCustomRemove.includes(String(tableColumn?.prop ?? ''));
        }).map(tableColumn => {
            const tableColumnExtraData = tableColumnsScheme.find(tableColumnScheme => String(tableColumnScheme?.prop ?? '') === String(tableColumn?.prop ?? ''));
            const tableColumnSchemeCustom = {
                ...tableColumnExtraData,
                isShow: Boolean(tableColumn?.isShow ?? false)
            };

            return tableColumnSchemeCustom;
        });

        const tableColumnsAction = lodash.remove(tableColumnsSchemeCustom, tableColumn => Boolean(tableColumn?.isAction ?? false));

        return[...tableColumnsSchemeCustom, ...tableColumnsAction];
    }

    static async setChangePeriod(dateRange = [], rowIds = [], kitId = '', filter) {
        try {
            return await this._setChangePeriod(dateRange, rowIds, kitId, filter);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static async _setChangePeriod(dateRange = [], rowIds = [], kitId = '', filter) {
        const requestBody = this._setChangePeriodBefore(dateRange, rowIds, kitId, filter);

        try {
            const response = await ServiceApi.post(this._microserviceName, this._requestRoutes.setChangePeriod, requestBody);

            return response?.data?.data ?? {};
        } catch (error) {
            if (error?.response?.data?.data?.to_date?.[0]) {
                // alert(error.response.data.data.to_date[0]);
                throw {error, errorMsg: error.response.data.data.to_date[0]};
            } else
                throw error;
        }
    }

    static _setChangePeriodBefore(dateRange = [], rowIds = [], kitId = '', filter) {
        const requestBody = {
            set_id: String(kitId),
            selected: rowIds,
            from_date: String(dateRange[0] ?? ''),
            to_date: String(dateRange[1] ?? ''),
            filtered: filter
        };

        return requestBody;
    }

    /**
     * Отправка выбранных rows
     * @param {"select-all" | "remove-all" | "add" | "remove"} type - Тип выборки:
     *   - "select-all": выбор всех элементов
     *   - "remove-all": удаление всех элементов
     *   - "add": добавление элементов
     *   - "remove": удаление элементов
     * @param {number[]} [ids] - Список идентификаторов элементов таблицы
     * @param {
     *     date: any[] | undefined,
     *     format_id: any[] | undefined,
     *     kind_code_id: any[] | undefined,
     *     kind_id: any[] | undefined,
     *     supplier_id: any[] | undefined,
     *     type_id: any[] | undefined,
     *     owner_id: any[] | undefined,
     *     material_id: any[] | undefined
     * } [filter] - фильтр
     */
    static async setSelectedTableRows(type, ids, filter, tableFilter = []) {
        try {
            const response = await ServiceApi.post('free', '/map/set-select', {
                type,
                ids,
                filter,
                table_filter: tableFilter
            });
            if ("data" in response && "data" in response.data) {
                return response.data.data;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    /**
     * Получение выбранных rows
     */
    static async getSelectedTableRows() {
        try {
            const {data = {}} = await ServiceApi.get('free', `/map/set-selected/${this.id}`)
            const {data: rows} = data;
            this.selectedRows = [...rows];
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

