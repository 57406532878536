export default {
    /**
     * Данные компонента
     * @property {Array} markers - маркеры
     * @property {Boolean} isCardVisible - условие рендера карточки
     * @property {Array} cardData - данные карточки
     * @property {Object} cardMarker - данные маркера карточки
     * @property {Object} cardMarkerSelected - выбранные цены маркера карточки
     */
    data: () => ({
        markers: [],
        clusters: {},
        markersWithoutPrices: [],
        isCardVisible: false,
        cardData: [],
        cardKitPrices: [],
        cardMarker: {},
        cardMarkerSelected: {},
        markersStatuses: [],
        currentSelectionSideMode: 'default',
        currentSideCheckList: [],
    }),
    methods: {
        /**
         * Адаптер для маркеров
         * @param {Array} markers - маркеры
         * @param {String} color - цвет
         * @param {String} kitId - идентификатор набора
         * @param {Boolean} selected - условие выбранности маркера
         * @param {String} iconType - тип иконки
         * @returns {Array}
         */
        adapterMarkers(
            markers = [],
            color = '',
            kitId = '-1',
            selected = false,
            iconType = 'default'
        ) {
            const markersAdapted = markers.map(marker => {
                const {side_ids = [], status = '', fill_color = '', colors = {}, side_status: sideStatus = {}, side_names: sideNames = []} = marker;
                const sideNamesCurrent = String(kitId) === '0' ? Object.keys(sideStatus) : sideNames
                const sidesColors = sideNamesCurrent.reduce((sidesColors, sideKey) => {
                    return {
                        ...sidesColors,
                        [sideKey]: colors[sideKey] ?? color
                    };
                }, {});
                const markerAdapted = {
                    ...marker,
                    additionalId: String(kitId),
                    clusterType: 'default',
                    extraData: {
                        ...marker,
                        kitId,
                        sideIds: side_ids,
                        status,
                        sideStatus
                    },
                    fill_color: color,
                    status,
                    color_legend: fill_color,
                    selected,
                    sidesColors,
                    iconType
                };
                return markerAdapted;
            });
            return markersAdapted;
        },
        onCloseCard() {
            this.isCardVisible = false;
            this.cardMarker = {};
            this.cardData = [];
            this.cardMarkerSelected = {};
            this.$refs.mapView.unsetMarkerClicked();
        },
        /**
         * Получение Id маркеров по id сторон
         * @param targetSideIds
         * @param state
         * @returns {[]}
         */
        getMarkerFoundIdsBySidesInBox(targetSideIds = [], state) {
            let currentSelectedSides = [];
            this.currentSideCheckList.forEach((side) => {
                if(side.checked){
                    currentSelectedSides.push(side.value)
                }
            })
            let markerIds = [];
            this.markers.forEach((marker) => {
                let {id = null, side_ids: sideIds = []} = marker;
                let countIncludes = 0;
                sideIds.forEach((sideId) => {
                    if(targetSideIds.includes(sideId) && !markerIds.includes(id)){
                        // markerIds.push(id);
                        countIncludes++;
                    }
                })
                 /* Первое условие для снятия выделения, второе для выделения */
                 if(
                     !currentSelectedSides.length && countIncludes && state === 1
                     || countIncludes && state === 0
                 ){
                     markerIds.push(id);
                 }
            });
            return markerIds;
        },
        /**
         * Получение id маркеров конструкций без цен по id сторон
         * @param targetSideIds
         * @param state
         * @returns {[]}
         */
        getMarkerWPIdsBySidesInBox(targetSideIds = [], state) {
            let markerIds = [];
            let currentSelectedSides = [];
            this.currentSideCheckList.forEach((side) => {
                if(side.checked){
                    currentSelectedSides.push(side.value)
                }
            })
            this.markersWithoutPrices.forEach((marker) => {
                let {id = null, catalog_sides: catalogSides = {}} = marker;
                let countIncludes = 0;
                Object.values(catalogSides).forEach((sideId) => {
                    if(targetSideIds.includes(sideId) && !markerIds.includes(id)){
                        countIncludes++;
                    }
                })
                /* Первое условие для снятия выделения, второе для выделения */
                if(
                    !currentSelectedSides.length && countIncludes && state === 1
                    || countIncludes && state === 0
                ){
                    markerIds.push(id);
                }
            });
            return markerIds;
        },
    }
}
