export default {
    namespaced: true,
    /**
     * Стостояния
     * @property {Boolean} fieldsIsSelectedAll - выбраны ли все поля
     */
    state: {
        fieldsIsSelectedAll: false
    },
    mutations: {
        /**
         * Установить флаг выбраны ли все поля
         * @param {Object} state - состояния
         * @param {Boolean} fieldsIsSelectedAll - выбраны ли все поля
         */
        setFieldsIsSelectedAll(state, fieldsIsSelectedAll = false) {
            state.fieldsIsSelectedAll = Boolean(fieldsIsSelectedAll);
        }
    }
}