<template>
    <div class="match-field-selection">
        <div class="match-field-selection__block-description mb-10">
            <base-preloader-skeleton
                v-if="isLoading"
                class="match-field-selection__loader"
            />
            <div class="match-field-selection__description">
                Выберите способ, по которому будут сравниваться стороны из файла со сторонами в системе.
                Это позволит заполнить недостающие данные у сторон в файле.
            </div>
        </div>
        <div class="match-field-selection__block-select">
            <base-preloader-skeleton
                v-if="isLoading"
                class="match-field-selection__loader"
            />
            <base-select
                :options="combinationsSelect"
                :value="combinationSelectedId"
                class="match-field-selection__select"
                @check="onCheckCombination"
            />
        </div>
        <div
            class="match-field-selection__block-content"
            :class="{'match-field-selection__block-content--no-padding': isLoading}"
        >
            <base-preloader-skeleton
                v-if="isLoading"
                class="match-field-selection__loader"
            />
            <div class="match-field-selection__check-text mb-10">
                Проверьте, правильно определились столбцы из файла?
            </div>
            <div class="match-field-selection__items">
                <div
                    v-for="(item, index) in combinationSelectedItems"
                    :key="index"
                    class="match-field-selection__item"
                    :class="{'mb-10': combinationSelectedItems.length > 2}"
                >
                    <div class="match-field-selection__item-label">
                        {{ getCombinationItemLabel(item) }}
                        <span class="match-field-selection__item-label-color"></span>
                    </div>
                    <base-select
                        :canSearch="true"
                        :options="combinationsItemsSelect"
                        :value="getCombinationItemSelectedValue(item)"
                        custom-title="В файле:"
                        class="match-field-selection__item-select"
                        @check="onCheckCombinationItemSelect($event, index)"
                    />
                </div>
            </div>
        </div>
        <div class="match-field-selection__block-actions">
            <div class="match-field-selection__action-item">
                <base-preloader-skeleton
                    v-if="isLoading"
                    class="match-field-selection__loader"
                />
                <base-button
                    view="border"
                    form="oval"
                    size="large"
                    @click="onBackStep"
                >
                    Назад
                </base-button>
            </div>
            <div class="match-field-selection__action-item">
                <base-preloader-skeleton
                    v-if="isLoading"
                    class="match-field-selection__loader"
                />
                <base-button
                    :disabled="isDisabled"
                    view="secondary"
                    form="oval"
                    size="large"
                    :class="{'match-field-selection__next-btn--disabled': isDisabled}"
                    @click="onNextStep"
                >
                    Далее
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
/** Компонент шага мэтчинга: выбор комбинации и полей */
import BaseSelect from "@/components/Base/BaseSelect";
import BaseButton from "@/components/Base/BaseButton";
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton";
export default {
    name: 'MatchFieldSelection',
    components: {
        BaseSelect,
        BaseButton,
        BasePreloaderSkeleton
    },
    /**
     * Входные данные компонента
     * @property {Array} combinations - комбинации
     * @property {Array} values - значения селектов
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        combinations: {
            type: Array,
            default: () => ([])
        },
        values: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    /**
     * Данные компонента
     * @property {Number} combinationSelectedId - id выбранной комбинации
     * @property {Array} combinationsBuffer - буфер комбинаций
     */
    data: () => ({
        combinationSelectedId: -1,
        combinationsBuffer: []
    }),
    computed: {
        /**
         * Комбинации для рендера в селекте
         * @returns {Array}
         */
        combinationsSelect() {
            const combinationsSelect = this.combinationsBuffer.map(combination => {
                const {id = -1, label = ''} = combination;
                return {id: parseInt(id), value: String(label)};
            });
            return combinationsSelect;
        },
        /**
         * Выбранная комбинация
         * @returns {Object}
         */
        combinationSelected() {
            const combinationSelected = this.combinationsBuffer.find(combination => {
                const {id = -1} = combination;
                return parseInt(id) === parseInt(this.combinationSelectedId);
            }) ?? {};
            return combinationSelected;
        },
        /**
         * Индекс выбранной комбинации
         * @returns {Number}
         */
        combinationSelectedIndex() {
            const combinationSelectedIndex = this.combinationsBuffer.findIndex(combination => {
                const {id = -1} = combination;
                return parseInt(id) === parseInt(this.combinationSelectedId);
            });
            return combinationSelectedIndex;
        },
        /**
         * Элементы выбранной комбинации
         * @returns {Array}
         */
        combinationSelectedItems() {
            const {items = []} = this.combinationSelected;
            return items;
        },
        /**
         * Элементы для рендера в селектах полей выбранной комбинации
         * @returns {Array}
         */
        combinationsItemsSelect() {
            const combinationsItemsSelect = this.values.map((value, index) => {
                return {id: index, value: String(value)};
            });
            return combinationsItemsSelect;
        },
        /**
         * Не пускать на следующий шаг если хоть один столбец не найден
         * @returns {Boolean}
         */
        isDisabled() {
            let isDisabled = this.combinationSelectedItems.some((select) => !select.selectValue.value);
            return isDisabled;
        },
    },
    mounted() {
        /** при монтировании устанавливаем комбинацию по умолчанию */
        if (this.combinations.length > 0) {
            this.combinationsBuffer = [...this.combinations];
            const {id = -1} = this.combinationsBuffer[0];
            this.combinationSelectedId = parseInt(id);
        }
    },
    methods: {
        /**
         * Получить метку комбинации
         * @param {Object} combinationItem - комбинация
         * @returns {String}
         */
        getCombinationItemLabel(combinationItem = {}) {
            const {label = {}} = combinationItem;
            const {value = ''} = label;
            return String(value);
        },
        /**
         * Получить индекс выбранного поля выбранной комбинации
         * @param {Object} combinationItem - комбинация
         * @returns {Number}
         */
        getCombinationItemSelectedValue(combinationItem = {}) {
            const {selectValue = {}} = combinationItem;
            const {index = -1} = selectValue;
            return parseInt(index);
        },
        /**
         * Событие выбора комбинации
         * @param {Object} combination - комбинация
         */
        onCheckCombination(combination = {}) {
            const {id = -1} = combination;
            this.combinationSelectedId = parseInt(id);
        },
        /**
         * Событие выбора поля выбранной комбинации
         * @param {Object} combinationItemSelect - элемент селекта полей выбранной комбинации
         * @param {Number} combinationItemIndex - индекс элемента селекта полей выбранной комбинации
         */
        onCheckCombinationItemSelect(combinationItemSelect = {}, combinationItemIndex = -1) {
                const combination = this.combinationsBuffer[this.combinationSelectedIndex] ?? {};
                const {items: combinationItems = []} = combination;
                const combinationItem = combinationItems[combinationItemIndex] ?? {};
                const {id: combinationItemSelectId = -1, value: combinationItemSelectValue = ''} = combinationItemSelect;
                const combinationItemNew = {
                    ...combinationItem,
                    selectValue: {
                        index: parseInt(combinationItemSelectId),
                        value: String(combinationItemSelectValue)
                    }
                };
                let combinationItemsNew = [...combinationItems];
                combinationItemsNew.splice(combinationItemIndex, 1, combinationItemNew);
                const combinationNew = {
                    ...combination,
                    items: [...combinationItemsNew]
                };
                this.combinationsBuffer.splice(this.combinationSelectedIndex, 1, combinationNew);
        },
        /**
         * Событие возврата на предыдущий шаг
         */
        onBackStep() {
            this.$emit('onBackStep');
        },
        /**
         * Событие перехода к следующему шагу
         */
        onNextStep() {
            const combinationReduced = this.combinationSelectedItems.reduce((combinationData, combinationItem) => {
                const {label = {}, selectValue = {}} = combinationItem;
                const {key = ''} = label;
                const {index = -1} = selectValue;
                combinationData = {
                    ...combinationData,
                    [key]: parseInt(index)
                };
                return combinationData;
            }, {});
            const combinationData = {
                fields: combinationReduced,
                combinationId: this.combinationSelectedId
            };
            this.$emit('onNextStep', combinationData);
        }
    }
}
</script>

<style lang="scss" scoped>
    .match-field-selection {
        font-size: 14px;
        line-height: 20px;
        color: #000;

        &__loader {
            z-index: 2;
        }

        &__select {
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background-color: #f2f2f2;
            font-weight: 700;
        }

        &__block {
            &-description {
                position: relative;
            }

            &-select {
                position: relative;
                margin-bottom: 30px;
            }

            &-content {
                position: relative;
                padding: 30px;
                background-color: #f8f8f8;
                border-radius: 10px;
                margin-bottom: 30px;

                &--no-padding {
                    padding: 0;
                }
            }

            &-actions {
                display: flex;
                justify-content: flex-end;
            }

        }

        &__description {
            color: #000;
        }

        &__check-text {
            color: $assistant-color;
        }

        &__items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        &__item {
            &-label {
                display: flex;
                align-items: center;
                position: relative;
                font-size: 14px;
                line-height: 20px;
                color: #000;

                margin-bottom: 5px;
            }

            &-label-color {
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #F64A4A;
                margin-left: 3px;
            }

            &-value {
                position: relative;
            }

            &-select {
                width: 220px;
                height: 44px;
                border-radius: 4px;
                background-color: #f2f2f2;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                color: #000;
                border: 1px solid $assistant-color;
            }
        }

        &__action-item {
            position: relative;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__next-btn {
            &--disabled {
                background: #f2f2f2 !important;
                color: $assistant-color;
            }
        }

        .mb-10 {
            margin-bottom: 10px;
        }

        .match-field-selection__select::v-deep .select-preview,
        .match-field-selection__item-select::v-deep .select-preview {
            height: 42px;
        }

        .match-field-selection__select::v-deep .dropdown {
            max-width: unset;
            max-height: 280px;
            width: 100%;
            top: 50px;
        }

        .match-field-selection__item-select::v-deep .dropdown {
            min-width: 220px;
            top: 50px;
        }
    }
</style>
