/* eslint-disable no-extra-semi */
import ServiceBaseInfoWindow from '../ServicesBase/ServiceBaseInfoWindow';
const google = window.google;
/**
 * Сервис для работы с информационными окнами в гугл мап апи
 * @extends ServiceBaseInfoWindow
 */
export default class ServiceGoogleInfoWindow extends ServiceBaseInfoWindow {
    /**
     * Конструктор 
     * @param {Object} params - параметры для информационных окон 
     */
    constructor(params = {}) {
        super(params);
        this.infoWindowObj = new google.maps.InfoWindow({
            content: this.content,
            disableAutoPan: this.disableAutoPan,
            maxWidth: this.maxWidth,
            minWidth: this.minWidth,
            position: new google.maps.LatLng(this.positionLat, this.positionLng),
            zIndex: this.zIndex,
            noSuppress: true
		});
    };
    /**
     * Открыть информационное окно
     * @param {Object} position - координаты окна
     * @param {Object} map - объект карты
     */
    open(position = {}, map = {}) {
        this.infoWindowObj.setPosition(position);
        this.infoWindowObj.open(map);
    }
};