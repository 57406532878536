<template>
    <svg
        width="38"
        height="36"
        viewBox="0 0 38 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.7498 3C12.7498 1.75736 13.7572 0.75 14.9998 0.75C16.2425 0.75 17.2498 1.75736 17.2498 3V33C17.2498 34.2426 16.2425 35.25 14.9998 35.25C13.7572 35.25 12.7498 34.2426 12.7498 33V3ZM14.9998 2.25C14.5856 2.25 14.2498 2.58579 14.2498 3V33C14.2498 33.4142 14.5856 33.75 14.9998 33.75C15.414 33.75 15.7498 33.4142 15.7498 33V3C15.7498 2.58579 15.414 2.25 14.9998 2.25Z"
            :fill="color"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.2622 6.44696C18.9796 5.05308 20.0451 3.75 21.4673 3.75H32.0122C32.5668 3.75 33.1018 3.95481 33.5146 4.32512L36.7559 7.23265C37.7527 8.12678 37.7527 9.68827 36.7559 10.5824L33.5146 13.4899C33.1018 13.8602 32.5668 14.065 32.0122 14.065H22.646C21.5756 14.065 20.6534 13.311 20.4408 12.262L19.2622 6.44696ZM21.4673 5.25C20.9933 5.25 20.6381 5.68436 20.7323 6.14899L21.9109 11.964C21.9818 12.3137 22.2892 12.5651 22.646 12.5651H32.0122C32.1971 12.5651 32.3754 12.4968 32.513 12.3733L35.7543 9.46582C36.0865 9.16777 36.0865 8.64728 35.7543 8.34923L32.513 5.44171C32.3754 5.31827 32.1971 5.25 32.0122 5.25H21.4673ZM5.00089 8.20858C5.37484 7.95312 5.81718 7.81645 6.27006 7.81645H12.9534V9.31645H6.27006C6.1191 9.31645 5.97165 9.36201 5.847 9.44716L2.236 11.9139C1.80008 12.2117 1.80008 12.8547 2.236 13.1525L5.847 15.6193C5.97165 15.7044 6.1191 15.75 6.27006 15.75H12.9534V17.25H6.27006C5.81718 17.25 5.37484 17.1133 5.00089 16.8579L1.38989 14.3911C0.0821119 13.4977 0.0821148 11.5687 1.38989 10.6753L5.00089 8.20858Z"
            :fill="color"
        />
    </svg>
</template>


<script>
export default {
    props: {
        color: {
            type: String,
            default: "#AAB0B8",
        }, // #4A91F1
    },
};
</script>
