<template>
	<div class="layout">
		<slot />
	</div>
</template>

<script>
export default {
	name: "Layout",
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.layout {
	display: inline-block;
	background: $primary-lighten-color;
	border-radius: 4px;
	padding: 8px 16px;
}
</style>