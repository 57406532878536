/* eslint-disable no-extra-semi */
import SchemeMap from '../../schemes/SchemeMap';
let {mapPramsDefault, tileUrl} = SchemeMap;
/** Базовый сервис для работы с картой */
export default class ServiceBaseMap {
    static zoomChangeRange = 1;
    /**
     * Конструктор
     * @param {Object} params - параметры для карты
     */
    constructor(params = {}) {
        const {markerClickEvent = () => {}} = params;
        this.map = {};
        this.mapPramsDefault = mapPramsDefault;
        this.isClustering = true;
        this.clusterer = null;
        this.markers = [];
        this.circles = [];
        this.polygons = [];
        this.tileUrl = tileUrl;
        this.mapStyles = {};
        this.trafficLayer = {};
        this.polygonInitData = {
            isInit: false,
            polyline: {},
            polylineDraw: () => {},
            polylineClick: () => {}
        };
        this.circleInitData = {
            isInit: false,
            infoWindow: {},
            circleSetCenter: () => {},
            circleInfoWindow: () => {}
        };
        this.sideSelectionInitData = {
            isInit: false,
            infoWindow: {},
            selectionInfoWindow: () => {}
        };
        this.rulerInitData = {
            isInit: false,
            polyline: {},
            polylineDraw: () => {},
            rulerMarkers: []
        };
        this.markerClickEvent = markerClickEvent;
        this.markerClicked = null;
    };
};
