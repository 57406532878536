<template>
  <div class="checkboxes">
		<label
			v-for="(item, index) of items"
			:key="index"
			class="checkbox-block"
		>
			<input
				v-model="checkboxes"
				:value="item.value"
				type="checkbox"
				@change="onCheck({id: item.value, checked:$event.target.checked})"
			>
			<span>{{ item.title }}</span>
		</label>
  </div>
</template>

<script>
export default {
	name: "Checkboxes",
	props: {
		items: {
			type: Array,
			default: () => []
		},
		selected: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			checkboxes: this.selected.slice()
		}
	},
	watch: {
		selected() {
			this.checkboxes = this.selected.slice()
		}
	},
	methods: {
		onCheck(event) {
			this.$emit('check', event);
		}
	}
}
</script>

<style lang="scss" scoped>
.checkbox-block {
	display: flex;
	align-items: center;
	user-select: none;
    margin-right: 20px;
    margin-bottom: 30px;

    &:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

	span {
		display: block;
		margin-left: 8px;
		font-weight: 500;
	}

    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
    }

    @media screen and (max-width: 685px) {
        margin-bottom: 0;
    }
}
</style>
