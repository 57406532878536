<template>
	<div class="summary">
		<div class="summary__list">
			<p
				v-for="(summary, index) of summaryData"
				:key="index"
				class="summary-item"
			>
				<span class="summary-item__title">{{ summary.title }}: </span>
				<span class="summary-item__value">{{ summary.value }}</span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "SummaryData",
	props: {
		summaryData: {}
	}
}
</script>

<style lang="scss" scoped>
.summary {
	&__list {
		display: flex;

		p:not(:first-child) {
			margin-left: 20px;
		}
	}

	.summary-item {
		font-weight: 500;

		&__title {
			color: #B9BBC2;
		}
		// &__value {}
	}
}
</style>