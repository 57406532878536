export default {
    methods: {
        /**
         * Аккумулировать элементы блоков
         * @param {Array} blocks - блоки
         * @returns {Array}
         */
        getItems(blocks = []) {
            const items = blocks.reduce((itemsReduced, block) => {
                const {items = []} = block;
                itemsReduced.push(...items);
                return itemsReduced;
            }, []);
            return items;
        },
        /**
         * Получить название эмита элемента блока
         * @param {String} panelItemId - идентификатор элемента блока
         * @returns {String}
         */
        getItemEmit(itemId = '', items = []) {
            const {emit = ''} = items.find(item => String(item.id) === String(itemId));
            return String(emit);
        },
    }
};