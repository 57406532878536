<template>
    <svg class="svg-icon" :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 19C18.5523 19 19 18.5523 19 18L19 6C19 5.44771 18.5523 5 18 5L6 5C5.44772 5 5 5.44771 5 6L5 18C5 18.5523 5.44771 19 6 19L18 19ZM21 18C21 19.6569 19.6569 21 18 21L6 21C4.34315 21 3 19.6569 3 18L3 6C3 4.34314 4.34315 3 6 3L18 3C19.6569 3 21 4.34315 21 6L21 18Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.10535 18.4472C3.85836 17.9533 4.05859 17.3526 4.55257 17.1056L18.5526 10.1056C19.0465 9.85861 19.6472 10.0588 19.8942 10.5528C20.1412 11.0468 19.941 11.6475 19.447 11.8945L5.44699 18.8945C4.95301 19.1414 4.35234 18.9412 4.10535 18.4472Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.11745 14.4706C2.85755 13.9833 3.0419 13.3776 3.52922 13.1177L18.5292 5.11769C19.0165 4.8578 19.6223 5.04215 19.8822 5.52946C20.1421 6.01677 19.9577 6.6225 19.4704 6.8824L4.47039 14.8824C3.98308 15.1423 3.37735 14.9579 3.11745 14.4706Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.10535 9.44724C2.85836 8.95326 3.05859 8.35259 3.55257 8.1056L13.5526 3.1056C14.0465 2.85861 14.6472 3.05883 14.8942 3.55281C15.1412 4.04679 14.941 4.64746 14.447 4.89445L4.44699 9.89445C3.95301 10.1414 3.35234 9.94122 3.10535 9.44724Z" :fill="color"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1316 20.4962C10.8576 20.0167 11.0242 19.4058 11.5037 19.1318L18.5037 15.1318C18.9832 14.8578 19.5941 15.0244 19.8681 15.5039C20.1421 15.9835 19.9755 16.5943 19.496 16.8683L12.496 20.8683C12.0164 21.1423 11.4056 20.9757 11.1316 20.4962Z" :fill="color"/>
</svg>
</template>

<style scoped>
    .svg-icon path {
        fill: currentColor;
    }
</style>

<script>
export default {
    name: 'IconFull',
    props: {
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'black'
        },
    },
}
</script>
