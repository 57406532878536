<template>
    <div class="draggble-window">
        <div class="draggble-window__content" @mousedown="onMove($event, $el)">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            startPointX: 0,
            startPointY: 0,
            finishPointX: 0,
            finishPointY: 0,
        };
    },
    methods: {
        onMove(event, target) {
            document.body.style.userSelect = "none";
            this.startPointX = event.clientX;
            this.startPointY = event.clientY;

            let leftPosition = "0";
            let topPosition = "0";

            const move = (event) => {
                this.finishPointX = event.clientX;
                const differenceX = this.finishPointX - this.startPointX;
                const left = parseInt(getComputedStyle(target).left);
                leftPosition = left + differenceX + "px";
                target.style.left = leftPosition;
                this.startPointX = this.finishPointX;

                this.finishPointY = event.clientY;
                const differenceY = this.finishPointY - this.startPointY;
                const top = parseInt(getComputedStyle(target).top);
                topPosition = top + differenceY + "px";
                target.style.top = topPosition;
                this.startPointY = this.finishPointY;
            };

            // Удаляю слушатели
            document.addEventListener("mousemove", move);
            document.addEventListener(
                "mouseup",
                () => {
                    document.removeEventListener("mousemove", move);
                    document.body.style.userSelect = "initial";

                    this.$emit("onMoved", leftPosition, topPosition);
                },
                { once: true }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.draggble-window {
    overflow: hidden;
    position: fixed;
    z-index: 5;
    background: #fff;
    min-width: 140px;
    min-height: 140px;
    display: flex;
    flex-direction: column;

    &__content {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        flex-shrink: 0;
        // padding: 10px 10px 10px 10px;
        cursor: move;
        z-index: 3;
    }
}
</style>
