<template>
	<CardLayout class="desc">
		<MiniTable
			:tableData="tableData"
		/>
	</CardLayout>
</template>

<script>
import CardLayout from "./Layout"
import MiniTable from "../Assets/MiniTable"

export default {
	name: "CardDescription",
	components: {
		CardLayout,
		MiniTable
	},
	props: {
		tableData: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style lang="scss" scoped>
.desc {
	overflow-y: auto;
}
</style>