/* eslint-disable no-extra-semi */
/** Базовый сервис для работы с полигонами */
export default class ServiceBasePolygon {
    /**
     * Конструктор
     * @param {Object} params - параметры для полигона
     */
    constructor(params = {}) {
        const {
            map = null, 
            strokeColor = '#FF0000', 
            strokeOpacity = 0.8, 
            strokeWeight = 2,
            fillColor = '#FF0000',
            fillOpacity = 0.35,
            paths = [],
        } = params;
        this.map = map;
        this.strokeColor = String(strokeColor);
        this.strokeOpacity = parseFloat(strokeOpacity);
        this.strokeWeight = parseInt(strokeWeight);
        this.fillColor = String(fillColor);
        this.fillOpacity = parseFloat(fillOpacity);
        this.paths = paths.map(path => {
            const {lat = '', lng = ''} = path;
            const latLng = {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            };
            return latLng;
        });
    };
};