<template>
	<div class="light">
		<BaseIcon
			:icon="iconName"
			:color="color"
            size="mlg"
		/>
	</div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
	name: "Light",
	components: {
		BaseIcon
	},
	props: {
        iconName: {
            type: String,
            default: ''
        },
		isLight: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		color() {
			return this.isLight ? '#F1CD19' : '#000'
		}
	}
}
</script>

<style lang="scss" scoped>
.light {
	width: 100%;
	text-align: center;
}
</style>
